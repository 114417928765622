import React, { useState } from 'react';
import VideoPlayer from './VideoPlayer';

const TestVideoPlayer = ({ onStreamReady }) => {
  // Hard-coded parameters for testing
  const videoPath = 'L1VzZXJzL2hsLzEtY29kZQ==/L1VzZXJzL2hsLzEtY29kZS9Eb2N1bWVudC_wn4y3IEdpb3Zhbm5pIE1hcnJhZGkgLSBKdXN0IEZvciBZb3UubXA0'; // Replace with your actual video path
  const title = 'Sample Video Title'; // Replace with your actual video title

  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoStart = () => {
    console.log('Video started');
  };

  const handleVideoEnd = () => {
    console.log('Video ended');
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {!isPlaying && (
        <button onClick={handlePlayButtonClick} style={{ marginBottom: '20px' }}>
          Play Video
        </button>
      )}
      {isPlaying && (
        <VideoPlayer
          videoPath={videoPath}
          title={title}
          pageOneVisible={true}
          onVideoStart={handleVideoStart}
          onVideoEnd={handleVideoEnd}
          showPlayer={true}
          playWhenLoaded={true}
          introTime={3000} // Adjust the intro time if needed
          onStreamReady={onStreamReady} // Pass the onStreamReady callback
        />
      )}
    </div>
  );
};

export default TestVideoPlayer;
