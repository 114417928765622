// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#image-gallery {
  align-self: flex-start;
  width: 100%;
  height: 100%;
  margin: 2px;
}

#image-gallery div img {
  cursor: default;
}

/* TODO:   cursor: pointer; Implement click thumbnail.*/`, "",{"version":3,"sources":["webpack://./src/Components/MediaGridView/PintoPanel.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA,uDAAuD","sourcesContent":["#image-gallery {\n  align-self: flex-start;\n  width: 100%;\n  height: 100%;\n  margin: 2px;\n}\n\n#image-gallery div img {\n  cursor: default;\n}\n\n/* TODO:   cursor: pointer; Implement click thumbnail.*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
