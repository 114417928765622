/**
 * Why use useCallback: https://chatgpt.com/c/b33dbe6a-1a61-4691-acab-a39c28dcaa75
 * Attempt to prevent OneDirectory to re-render. Not work...
 */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import TwoSectionsPage from '../TwoSectionsPage/TwoSectionsPage';
import CinemaVideo from './CinemaVideo';
import OneDirectory from '../DirectoryView/OneDirectory';

const VideoPlayer2P = (props) => {
  const {
    videoTitleInput,
    videoPathInput,
    dirInfoInput,
    onClickPhoto,
    onClickFolder,
    onPage2VisibleVideo
  } = props;
  const [pageOneVisible, setPageOneVisible] = useState(false);
  const [videoPath, setVideoPath] = useState(videoPathInput);
  const [videoTitle, setVideoTitle] = useState(videoTitleInput);
  const [dirInfo, setDirInfo] = useState(dirInfoInput);

  useEffect(() => {
    setVideoPath(videoPathInput);
    setVideoTitle(videoTitleInput);
    setDirInfo(dirInfoInput);
  }, [videoPathInput, videoTitleInput, dirInfoInput]);

  // Handle item clicked in OneDirectory. No need to be handled by parent component.
  // const handleOneClickVideo = (videoRelPath, title, dirInfo) => {
  //   //onClickVideo(videoRelPath, title, dirInfo);
  //   setVideoPath(videoRelPath);
  //   setVideoTitle(title);
  //   setDirInfo(dirInfo);
  // };
  const handleOneClickVideo = useCallback((videoRelPath, title, dirInfo) => {
    setVideoPath(videoRelPath);
    setVideoTitle(title);
    setDirInfo(dirInfo);
  }, []);

  // When photo is clicked, it needs to pass the event to parent of VideoPlayer2P
  // as VideoPlayer2P is video player. Parent should invoke PhotoPlayer2P
  // const handleOneClickPhoto = (photoRelPath, dirInfo) => {
  //   onClickPhoto(photoRelPath, dirInfo);
  // };
  const handleOneClickPhoto = useCallback((photoRelPath, dirInfo) => {
    onClickPhoto(photoRelPath, dirInfo);
  }, [onClickPhoto]);
  
  // const handleOneClickFolder = (folderRelPath, dirInfo, dirName) => {
  //   onClickFolder(folderRelPath, dirInfo, dirName);
  // }
  const handleOneClickFolder = useCallback((folderRelPath, dirInfo, dirName) => {
    onClickFolder(folderRelPath, dirInfo, dirName);
  }, [onClickFolder]);

  // const handleOnPage1Visible = () => {
  //   setPageOneVisible(true);
  //   onPage2VisibleVideo(false);
  // };

  // const handleOnPage2Visible = () => {
  //   setPageOneVisible(false);
  //   onPage2VisibleVideo(true);
  // };

  const handleOnPage1Visible = useCallback(() => {
    setPageOneVisible(true);
    onPage2VisibleVideo(false);
  }, [onPage2VisibleVideo]);

  const handleOnPage2Visible = useCallback(() => {
    setPageOneVisible(false);
    onPage2VisibleVideo(true);
  }, [onPage2VisibleVideo]);

  // Attempt to minimize the scroll event impact on OneDirectory re-render. But...
  // const memoizedOneDirectory = useMemo(() => (
  //   <OneDirectory 
  //     relPath={videoPath} 
  //     dirInfo={dirInfo}
  //     onClickVideo={handleOneClickVideo} 
  //     onClickPhoto={handleOneClickPhoto}
  //     onClickFolder={handleOneClickFolder} />
  // ), [dirInfo]);   

  //triggerPage1Visible={clicedVideo}
  // key is to making sure scrolling to OneDirectory to work. Without it, it does not scroll to
  // page2, which is OneDirectory.
  return (
    <div key={videoPath}>
      <TwoSectionsPage
          keyValue={videoPath} 
          onScroll0={handleOnPage1Visible} 
          onScroll30={handleOnPage2Visible}>
        <CinemaVideo 
          videoTitle={videoTitle} 
          videoPath={videoPath} 
          pageOneVisible={pageOneVisible}
          showIntro={false} />
        <OneDirectory 
          relPath={videoPath} 
          dirInfo={dirInfo}
          onClickVideo={handleOneClickVideo} 
          onClickPhoto={handleOneClickPhoto}
          onClickFolder={handleOneClickFolder} 
          onePageView={false}
        />
      </TwoSectionsPage>
    </div>
  );
};

export default VideoPlayer2P;