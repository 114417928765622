// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TwoSectionsPage.css */
.outer-container {
  position: relative;
  width: 100%;
}

.outer-container .div-section {
  transition: opacity 0.5s;
}

.outer-container .background-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full viewport height */
  z-index: 1;
}

.outer-container .content-section {
  position: absolute;
  width: 100%;
  top: 100vh; /* Initially position it just below the viewport */
  padding: 20px; /* Adjust padding as needed */
  z-index: 2;
}

.touch-overlay {
  position: absolute;
  bottom: 22%;
  left: 10%;
  width: 80%;
  height: 20%;
  background-color: rgba(255, 255, 255, 0);
  z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TwoSectionsPage/TwoSectionsPage.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa,EAAE,yBAAyB;EACxC,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU,EAAE,kDAAkD;EAC9D,aAAa,EAAE,6BAA6B;EAC5C,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;EACV,WAAW;EACX,wCAAwC;EACxC,UAAU;AACZ","sourcesContent":["/* TwoSectionsPage.css */\n.outer-container {\n  position: relative;\n  width: 100%;\n}\n\n.outer-container .div-section {\n  transition: opacity 0.5s;\n}\n\n.outer-container .background-section {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh; /* Full viewport height */\n  z-index: 1;\n}\n\n.outer-container .content-section {\n  position: absolute;\n  width: 100%;\n  top: 100vh; /* Initially position it just below the viewport */\n  padding: 20px; /* Adjust padding as needed */\n  z-index: 2;\n}\n\n.touch-overlay {\n  position: absolute;\n  bottom: 22%;\n  left: 10%;\n  width: 80%;\n  height: 20%;\n  background-color: rgba(255, 255, 255, 0);\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
