import React, { useRef, useState, useEffect } from 'react';
import Muuri from 'muuri';
import { MuuriList } from './MuuriListBase';
import { FileExplorer } from '../FileExplorer/FileExplorer';
import { FileExplorerHoc } from '../FileExplorer/FileExplorer';
import StockList from '../StockMonitor/StockList';


/**
 * Integrating File Explore by reading items from FileExplorerHoc
 * 
 * @param {*} props 
 */
const MuuriListSource = (props) => {
}


export const MuuriEditKanban = ({
    titleLeft, 
    listLeft, 
    titleRight, 
    listRight}) => {
  const listRefLeft = useRef(null);
  const listRefRight = useRef(null);

  titleLeft = 'Channel';
  titleRight = 'Medias';
  listLeft = ['Item 1', 'Item 2', 'Item 3'];
  listRight = ['Item 11', 'Item 22', 'Item 33'];

  useEffect(() => {
    var dragContainer = document.querySelector('.drag-container');
    var itemContainers = [].slice.call(document.querySelectorAll('.board-column-content'));
    var columnGrids = [];
    var boardGrid;
    
    // Init the column grids so we can drag those items around.
    itemContainers.forEach(function (container) {
      var grid = new Muuri(container, {
        items: '.board-item',
        dragEnabled: true,
        dragSort: function () {
          return columnGrids;
        },
        dragContainer: dragContainer,
        dragAutoScroll: {
          targets: (item) => {
            return [
              { element: window, priority: 0 },
              { element: item.getGrid().getElement().parentNode, priority: 1 },
            ];
          }
        },
      })
      .on('dragInit', function (item) {
        item.getElement().style.width = item.getWidth() + 'px';
        item.getElement().style.height = item.getHeight() + 'px';
      })
      .on('dragReleaseEnd', function (item) {
        item.getElement().style.width = '';
        item.getElement().style.height = '';
        item.getGrid().refreshItems([item]);
      })
      .on('layoutStart', function () {
        boardGrid.refreshItems().layout();
      });
    
      columnGrids.push(grid);
    });
    
    // Init board grid so we can drag those columns around.
    boardGrid = new Muuri('.board', {
      dragEnabled: false,
      dragHandle: '.board-column-header'
    });
  }, []);

  useEffect(() => {
    
  }, [listRefLeft, listRefRight]);

  const handleListRefLeft = (ref) => {
    // Save the listRef
    listRefLeft.current = ref;
  };

  const handleListRefRight = (ref) => {
    // Save the listRef
    listRefRight.current = ref;
  };

  const layoutDoneLeft = () => {

  }

  const layoutDoneRight = () => {

  }

  return (
    <>
      <div className="drag-container"></div>
      <div className="board">
        <MuuriList 
            title={titleLeft}
            titleCss={'destination'}
            items={listLeft} 
            notifyLayoutDone={layoutDoneLeft}
            onListRef={handleListRefLeft} />
        <MuuriList 
            title={titleRight}
            titleCss={'source'}
            items={listRight} 
            notifyLayoutDone={layoutDoneRight}
            onListRef={handleListRefRight} />
      </div>
      <FileExplorer />
      <StockList />
    </>
  );
}