// src/components/WebPage.js

import React from 'react';
import './MediaPage.css'; // Create this CSS file for styling

function MediaPage() {
  return (
    <div className="container">
      <div className="item video-thumbnail">
        <img src="video-thumbnail.jpg" alt="Video Thumbnail" />
        <p>Video Title</p>
      </div>
      <div className="item photo-thumbnail">
        <img src="photo-thumbnail.jpg" alt="Photo Thumbnail" />
        <p>Photo Title</p>
      </div>
      <div className="folder">
        <p className="folder-name">Folder 1</p>
        <div className="item video-thumbnail">
          <img src="video-thumbnail.jpg" alt="Video Thumbnail" />
          <p>Video Title 1</p>
        </div>
        <div className="item photo-thumbnail">
          <img src="photo-thumbnail.jpg" alt="Photo Thumbnail" />
          <p>Photo Title 1</p>
        </div>
        <div className="item folder">
          <p className="folder-name">Subfolder 1</p>
          <div className="item video-thumbnail">
            <img src="video-thumbnail.jpg" alt="Video Thumbnail" />
            <p>Video Title 2</p>
          </div>
          {/* Add more items or subfolders as needed */}
        </div>
      </div>
      {/* Add more items or folders as needed */}
    </div>
  );
}

export default MediaPage;
