/*
Regular Expression:

^([A-Za-z0-9_-]{11}): Matches exactly 11 characters that are alphanumeric, 
hyphens, or underscores, corresponding to the YouTube video ID.

\/([A-Za-z0-9-_]+=*)?$: Matches a / followed by any number of alphanumeric, 
hyphens, underscores, or equal signs. The ([A-Za-z0-9-_]+=*)? captures the Base64 
string, including potential padding (=), and is optional.

*/

export class f2futil {
  constructor() {}
  
  generateRandomString(length=6) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  parseYtUrl(input) {
    // Regular expression to match the format {youtubeVideoId}/{base64String}
    //const regex = /^([A-Za-z0-9_-]{11})\/([A-Za-z0-9-_]+=*)?$/;
    // Regular expression to match the format {youtubeVideoId}/{base64String}
    //const regex = /^([A-Za-z0-9_-]{11})\/([A-Za-z0-9+/=]+)$/;
    const regex = /^([A-Za-z0-9_-]{11})(?:\/([A-Za-z0-9-_]+=*))?$/;

    // Apply the regex to the input string
    const match = input.match(regex);
    
    if (match) {
      // Extract the YouTube video ID and Base64 string
      const ytid = match[1];
      const relpath = match[2] || null;
      
      return { ytid, relpath };
    } else {
      throw new Error('Input string does not match the expected format');
    }
  }

  parseMediaUrl(input) {
    // Regular expressions to match the different formats
    // 'yv' + youtube id + optional relpath if not in root
    const youtubeRegex = /^\/yv\/([A-Za-z0-9_-]{11})(?:\/([A-Za-z0-9-_]+=*))?$/;
    // const localVideoRegex = /^\/lv\/([A-Za-z0-9-_]+=*)$/;
    // const localVideoRegex = /^\/lv\/([A-Za-z0-9-_]+=*)\/([A-Za-z0-9-_]+=*)$/;
    // 'lv' + video file name + optional relpath if not in root
    const localVideoRegex = /^\/lv\/([A-Za-z0-9-_]+=*)(?:\/([A-Za-z0-9-_]+=*))?$/;

    // Check if the input matches the YouTube format
    let match = input.match(youtubeRegex);
    if (match) {
      // Extract the YouTube video ID and Base64 string
      const media_id = match[1];
      const relpath = match[2] || null;
      return { type: 'yv', media_id, relpath };
    }
  
    // Check if the input matches the local video format
    match = input.match(localVideoRegex);
    if (match) {
      // Extract the Base64 string for local video
      const media_id = match[1]
      const relpath = match[2] || null;  // It include relpath + media
      return { type: 'lv', media_id, relpath };
    }
  
    // If the input does not match any expected format, throw an error
    throw new Error('Input string does not match the expected format');
  }
}


// Example usage
// try {
//   const result1 = parseYtUrl("-b3t37SIyBs");
//   console.log(result1);  // Output: { ytid: '-b3t37SIyBs', relpath: null }

//   const result2 = parseYtUrl("-b3t37SIyBs/dGVzdA==");
//   console.log(result2);  // Output: { ytid: '-b3t37SIyBs', relpath: 'dGVzdA==' }

//   const result3 = parseYtUrl("fBz2bOhcYQw");
//   console.log(result3);  // Output: { ytid: 'fBz2bOhcYQw', relpath: null }

//   const result4 = parseYtUrl("fBz2bOhcYQw/dGVzdA==");
//   console.log(result4);  // Output: { ytid: 'fBz2bOhcYQw', relpath: 'dGVzdA==' }
// } catch (error) {
//   console.error(error.message);
// }

// // Example usage:
// const util = new f2futil();
// try {
//   const result = util.parseYtUrl('dQw4w9WgXcQ/QmFzZTY0');
//   console.log(result); // Output: { youtubeVideoId: 'dQw4w9WgXcQ', base64String: 'QmFzZTY0' }
// } catch (error) {
//   console.error(error.message);
// }

// // Example usage:
// const util = new f2futil();
// try {
//   const result = util.parseYtUrl('1rTPJhe-/QmFzZTY0');
//   console.log(result); // Output: { shortId: '1rTPJhe-', base64String: 'QmFzZTY0' }
// } catch (error) {
//   console.error(error.message);
// }
  
  // // Example usage:
  // const input = '</1rTPJhe->/QmFzZTY0';
  // try {
  //   const result = parseGeneratedString(input);
  //   console.log(result); // Output: { shortId: '1rTPJhe-', base64String: 'QmFzZTY0' }
  // } catch (error) {
  //   console.error(error.message);
  // }
  