import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import B from './B';
import Manager from './Manager';

const A = () => {
  const [editMode, setEditMode] = useState(false);
  const bRef = useRef(null);
  const containerRef = useRef(null);
  const managerRef = useRef(null);

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleCustomAction = () => {
    if (bRef.current) {
      bRef.current.customMethod();
    }
  };

  const renderComponent = (editMode) => {
    if (containerRef.current) {
      ReactDOM.render(
        <B ref={bRef} editMode={editMode} />,
        containerRef.current
      );
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      managerRef.current = new Manager(renderComponent);
    }
  }, []);

  useEffect(() => {
    if (managerRef.current) {
      managerRef.current.invokeRender(editMode);
    }
  }, [editMode]);

  return (
    <div>
      <button onClick={handleToggleEditMode}>
        Toggle Edit Mode
      </button>
      <button onClick={handleCustomAction}>
        Call Custom Method in B
      </button>
      <div ref={containerRef}></div>
    </div>
  );
};

export default A;
