import 'process/browser';
import { Buffer } from 'buffer';

import React from 'react';

//import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { RootProvider } from './RootContext';
import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // Strict mode will cause component (some?) render 2 times.
//   // <React.StrictMode>
//   //   <App />
//   // </React.StrictMode>
//   <>
//     <App />
//   </>
// );


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <RootProvider>
    <App />
  </RootProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
