import React, { useEffect, useRef, useState } from 'react';
import './TwoSectionsOverlay.css';

const TwoSectionsOverlay = ({
  onScroll10Percent,
  onScroll20Percent,
  onScroll30Percent
}) => {
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);
  const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const firstSection = firstSectionRef.current;
      const secondSection = secondSectionRef.current;
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;

      console.log(`window.scrollY=${window.scrollY}`);

      // Handle additional events based on scroll percentage
      const scrollPercent = (scrollY / viewportHeight) * 100;

      if (scrollPercent >= 10 && onScroll10Percent) {
        onScroll10Percent();
      }

      if (scrollPercent >= 20) {
        firstSection.style.opacity = `${1 - (scrollPercent - 20) / 10}`;
        if (onScroll20Percent) {
          onScroll20Percent();
        }
      } else {
        firstSection.style.opacity = '1';
      }

      if (scrollPercent >= 30) {
        firstSection.style.opacity = '0';
        setTimeout(() => {
          firstSection.classList.add('absolute');
          secondSection.classList.add('visible');

          // Set the scroll position and ensure it is correct
          window.scrollTo(0, 0);
          setTimeout(() => {
            if (window.scrollY !== 0) {
              window.scrollTo(0, 0);
            }
            setIsSecondSectionVisible(true);
            console.log(`2nd section auto scroll finish. window.scrollY=${window.scrollY}`);
          }, 50); // Small delay to ensure the scroll position is set correctly
        }, 300); // Delay to match the opacity transition

        if (onScroll30Percent) {
          onScroll30Percent();
        }
      } else {
        firstSection.classList.remove('absolute');
        secondSection.classList.remove('visible');
        setIsSecondSectionVisible(false);
      }
    };

    const handleMouseWheel = (e) => {
      const firstSection = firstSectionRef.current;
      const secondSection = secondSectionRef.current;

      if (isSecondSectionVisible && e.deltaY < 0 && window.scrollY === 0) {
        setIsSecondSectionVisible(false);
        firstSection.classList.remove('absolute');
        secondSection.classList.remove('visible');
        window.scrollTo(0, window.innerHeight);
      }
    };

    window.scrollTo(0, 0); // Set the initial scroll position to the top

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleMouseWheel);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleMouseWheel);
    };
  }, [onScroll10Percent, onScroll20Percent, onScroll30Percent, isSecondSectionVisible]);

  return (
    <div className="two-page-overlay-container">
      <section ref={firstSectionRef} id="firstSection" className="section first-section">
        <h1>First Section</h1>
        <p>This section takes the whole viewport.</p>
      </section>
      <section
        ref={secondSectionRef}
        id="secondSection"
        className={`section second-section ${isSecondSectionVisible ? 'fixed' : ''}`}
      >
        <div className="content">
          <h1>Second Section</h1>
          <p>This section overlays the first section when scrolling from bottom to top.</p>
          {/* Add more content here to make scrolling effect visible */}
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
          <p>Additional content to make the second section longer...</p>
        </div>
      </section>
    </div>
  );
};

export default TwoSectionsOverlay;
