import React, { useRef, useState, useEffect } from 'react';
import Muuri from 'muuri';

const DraggableListWrapper = ({ onLayoutDone, listRef }) => {
  const gridRef = useRef(listRef); // no need of this
  const [grid, setGrid] = useState(null);
  const isMuuriInitialized = useRef(false);

  useEffect(() => {
    if (listRef.current) {
      const className = listRef.current.current.className;

      if (!isMuuriInitialized.current) {
        setTimeout(() => {
          gridRef.current = new Muuri('.original-list-style', {
              items: '.list-item-style',
            dragEnabled: true, // Enable dragging
          });
          setGrid(gridRef.current);
          isMuuriInitialized.current = true;

          gridRef.current.on('layoutEnd', function(items) {
            onLayoutDone();
            gridRef.current.refreshItems().layout();
          });
        }, 0);
      }
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.destroy();
        isMuuriInitialized.current = false;
      }
    };
  }, [listRef]);

  return null;
};

export default DraggableListWrapper;
