import React from 'react';
import EditCheckbox from './ArticleEditMode';
import OnlineUsers from '../Online/OnlineUsers';
import './MenuPanel.css'; 


export const MenuPanel = ({ text, alignLeft=false }) => {
  const panelClass = `text-panel ${alignLeft ? 'align-left' : 'align-right'}`;

  return (
    <div className={panelClass}>
      <p>{text}</p>
    </div>
  );
};

export const MenuPanelLeft = ({text}) => {

  return (
    <>
      {/* <OnlineUsers /> */}
      <MenuPanel text={text}/>
    </>
  );
};

export const MenuPanelRight = ({text}) => {

  return (
    <MenuPanel text={text} 
               alignLeft={true} />
  );
};

export const MenuPanelCtrl = ({ ctrl, alignLeft=false }) => {
  const panelClass = `text-panel ${alignLeft ? 'align-left' : 'align-right'}`;

  return (
    <div className={panelClass}>
      {ctrl}
    </div>
  );
};

export const MenuPanelCtrlLeft = ({ctrlComp}) => {

  return (
    <MenuPanelCtrl ctrl={ctrlComp} 
                   alignLeft={true} />
  );
};

export const MenuPanelCtrlRight = ({ctrlComp}) => {

  return (
    <MenuPanelCtrl ctrl={ctrlComp} 
                   alignLeft={false} />
  );
};
