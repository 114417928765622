/**
 * Display room users at bottom bar.
 */
import React from 'react';
import { useSimplePeerUsers, SimplePeerUserProvider } from '../Online/SimplePeerUserContext';
import './OnlineUsers.css'; // Import the CSS file


const ChatSPRoomUsersDisplay = ({ excludeSelf = false }) => {
  const { onlineUsers, loginUserName } = useSimplePeerUsers();

  const filteredUsers = excludeSelf
    ? onlineUsers.filter(user => user !== loginUserName)
    : onlineUsers;

  return (
    <div className="online-users">
      {filteredUsers.map((user, index) => (
        <div key={`${user}-${index}`} className="online-user">
          <span className="username">{user}</span>
          <img 
            src={`https://avatars.dicebear.com/api/human/${user}.svg`} 
            alt={user} 
            className="user-icon" 
          />
        </div>
      ))}
    </div>
  );
};

const ChatSPRoomUsers = ({ excludeSelf = false }) => {
  return (
    <SimplePeerUserProvider>
      <ChatSPRoomUsersDisplay excludeSelf={excludeSelf} />
    </SimplePeerUserProvider>
  );
};

export default ChatSPRoomUsers;
