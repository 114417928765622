// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TestYtPlayer.css */
.test-yt-player-container {
  width: 100%;
/* height: 100vh; Full viewport height */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#youtube-player-container, #youtube-player {
  width: 100%;
  height: 100%;
}

.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/YoutubeView/TestYtPlayer.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,WAAW;AACb,wCAAwC;EACtC,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["/* TestYtPlayer.css */\n.test-yt-player-container {\n  width: 100%;\n/* height: 100vh; Full viewport height */\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n#youtube-player-container, #youtube-player {\n  width: 100%;\n  height: 100%;\n}\n\n.player-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
