/*
https://chatgpt.com/c/8076d189-cf5c-47c8-bcf8-d2220393d021

*/

// VideoPlayContext.js
import React, { createContext, useState, useContext } from 'react';

// Create Context
const VideoPlayPageContext = createContext();

// Create a provider component
export const VideoPlayPageProvider = ({ children }) => {
  // Track currently playing video.
  const [playingVideoPage, setPlayingVideoPage] = useState(null);

  // Track 2-page big player
  const [playing2PagePlayer, setPlaying2PagePlayer] = useState(false);
  
  return (
    <VideoPlayPageContext.Provider value=
      {{ 
        playingVideoPage, 
        setPlayingVideoPage,
        playing2PagePlayer, 
        setPlaying2PagePlayer 
      }}
    >
      {children}
    </VideoPlayPageContext.Provider>
  );
};

// Custom hook to use the VideoPlayContext
export const useVideoPlayPage = () => useContext(VideoPlayPageContext);
