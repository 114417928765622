import React, { useEffect, useRef, useState } from 'react';
import useIsTouchDevice from '../Util/HookTouchDevice.jsx';
import useIsAppleDevice from '../Util/HookIsAppleDeivce.jsx';
import { useVideoPlayPage } from '../System/VideoPlayPageContext';
import './YoutubePlayerAdv.css';

const YouTubePlayerAdv = ({ 
  ytId, 
  relpath,
  playerRef,  // Receive playerRef from parent
  onVideoStart = null,
  onVideoEnd = null,
  onStreamReady = null,
  enableYtPlayerTouchOverlay = false,  // Detect swipe. It simple add 
                                       // a div to pass touch/click events.
                                       // Not sure if this is needed as
                                       // Yt swipe hood has this bar at 
                                       // bottom.
  isFirstVideo = false  // Hanle Apple device autoplay by mute 1st video. 
                        // User clicks to unmute. 
                        // It assumes that YouTubePlayerAdv's parent
                        // is rendered once, so that singleton object can be
                        // kept in parent component.
}) => {
  console.log(`isFirstVideo=${isFirstVideo}`);

  const isTouchDevice = useIsTouchDevice();
  const isAppleDevice = useIsAppleDevice();

  // Mute only the first video on Apple devices
  // const [isMuted, setIsMuted] = useState(isFirstVideo && isAppleDevice); 

  const { playingVideoPage, setPlayingVideoPage, playing2PagePlayer, setPlaying2PagePlayer } = useVideoPlayPage();
  const isPlayingPageSig = `${ytId}-${relpath}`;
 
  useEffect(() => {
    console.log(`YouTubePlayerAdv mounted: ${ytId}`);

    const createPlayer = (videoId) => {
      if (playerRef.current) {
        console.log('Player already created, skipping creation.');
        playerRef.current.loadVideoById(videoId); // Load the new video ID into the existing player
        return; // Prevent creating a new player if one already exists
      }

      if (window.YT && window.YT.Player) {
        const ytPlayer = new window.YT.Player('youtube-player', {
          videoId: videoId,
          playerVars: {
            autoplay: 1,
            playsinline: 1,
            controls: 1,
            modestbranding: 1,
            origin: window.location.origin,
            enablejsapi: 1,
            mute: isAppleDevice ? 1 : 0,
            //mute: 1, // Mute only if it's the first video on an Apple device
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
            onError: onPlayerError,
          },
        });
        playerRef.current = ytPlayer;
      } else {
        console.log('YouTube API is not ready yet.');
      }
    };

    const loadYouTubeIframeAPI = () => {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      script.onload = () => {
        if (window.YT) {
          if (ytId) {
            createPlayer(ytId);
          }
        } else {
          console.error('YT object is not available after script load');
        }
      };
      document.body.appendChild(script);
    };

    if (!window.YT) {
      loadYouTubeIframeAPI();
    } else if (window.YT && window.YT.Player) {
      if (ytId) {
        createPlayer(ytId);
      }
    }

    window.onYouTubeIframeAPIReady = () => {
      if (ytId) {
        createPlayer(ytId);
      }
    };

    return () => {
      console.log(`YouTubePlayerAdv unmounted: ${ytId}`);
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [ytId]);

  const onPlayerError = (event) => {
    if (onVideoEnd) {
      onVideoEnd();
    }
  };

  const onPlayerReady = (event) => {
    // if (playerRef.current) {
    //   playerRef.current.unMute();
    //   //event.target.setVolume(70);
    //   playerRef.current.playVideo(); // Start playing the video
    // }

    // Mute the video to allow autoplay on mobile devices
    if (playerRef.current) {
      // if (!isMuted) {
      //   playerRef.current.unMute(); // Unmute the video if not muted initially
      // }
      //playerRef.current.unMute();
      playerRef.current.playVideo(); // Start playing the video
    }


    if (playerRef.current && onStreamReady) {
      // Capture the stream from the player and pass it to the callback
      const stream = playerRef.current.getIframe().contentWindow.document.querySelector('video').captureStream();
      onStreamReady(stream);
    }
  };

  const onPlayerStateChange = (event) => {
    console.log(`onPlayerStateChange: ${JSON.stringify(event.data)}`);
    if (event.data === window.YT.PlayerState.PLAYING) {
      setPlayingVideoPage(isPlayingPageSig);
      setPlaying2PagePlayer(true);
      if (onVideoStart) {
        onVideoStart();
      }

      if (isAppleDevice) {
        // Set a timer to unmute the video after 5 seconds
        setTimeout(() => {
          if (playerRef.current) {
            playerRef.current.unMute();
            // setIsMuted(false); // Update state to reflect that the video is unmuted
          }
        }, 100); // Adjust the time as needed
        
      }      
    } else if (event.data === window.YT.PlayerState.ENDED) {
      setPlayingVideoPage(null);
      setPlaying2PagePlayer(false);
      if (onVideoEnd) {
        onVideoEnd();
      }
    }
  };

  return (
    <div className="player-container">
      {ytId ? (
        <div id="youtube-player-container">
          <div id="youtube-player"></div>
          {isTouchDevice && enableYtPlayerTouchOverlay && (
            <div className="touch-overlay"></div>
          )}
        </div>
      ) : (
        <p>No video ID provided!</p>
      )}
    </div>
  );
};

export default YouTubePlayerAdv;


/*
like the button. reason to keep

  // Unmute does not work as it's not possible to put div on top of iframe
  // after video is started. The handler does not work.
  const handleUnmute = () => {
    if (playerRef.current) {
      playerRef.current.unMute();
      setIsMuted(false); // Update state to reflect that the video is unmuted
    }
  };

          {isAppleDevice && isMuted && (
            <button onClick={handleUnmute} className="unmute-button">
              <span role="img" aria-label="Unmute">🔊</span> Tap to Unmute
            </button>
          )}

.unmute-button {
  position: absolute;
  bottom: 160px;
  left: 0%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7); 
  color: #ffffff; 
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 10001; 
}

.unmute-button:hover {
  background-color: rgba(255, 255, 255, 0.9); 
  color: #000000; 
  transform: translateX(-50%) scale(1.05); 
}

.unmute-button span {
  margin-right: 8px; 
} 

*/