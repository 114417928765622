import React, { useState, useEffect, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useHistoryTracking, useBrowserHistoryNav, useLeaveSiteWarning } from '../PlayerViews/PlayerViews.jsx';
import Yt2P from './CinemaYt/Yt2P.jsx';
import YtDirectory from './Section/YtDirectory.jsx';
import { useConfig } from '../System/ConfigContext.jsx';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { IconMenuBar } from '../IconMenuBar/IconMenuBar';


const YoutubeView = ({
  defaultUrl='',
  onPage2Visible=null,
}, ref) => {
  const api = new ApiFileX();
  const navigate = useNavigate();
  const { captureState, goBack, goForward, navDone, getCurrentState } = useHistoryTracking();
  const { config } = useConfig(); // Access context

  // Input from url defined in app.js.
  const { param_ytid, param_title, param_ytpath, param_dirname } = useParams();

  const [ytId, setYtId] = useState(param_ytid);
  const [relpath, setRelpath] = useState(param_ytpath);
  const [playVideo, setPlayVideo] = useState(param_ytid ? true : false);

  // This is to handle double renders. 
  // Double render does not harm. 
  // Why it happens? This is due to design of this component. It reload its self
  // just be cause relpath changed.
  // When navigate(...) triggers  <Youtubeview />, 
  // const [relpath, setRelpath] = useState(param_ytpath); set new 'relpath', which
  // is from url parameter. But, if not set the parameter useEffect() and set
  // as const [relpath, setRelpath] = useState(param_ytpath); the render code
  // will not take new value. So, it does not work for 2nd relpath, as the componet
  // think there is not change, and no update is performed.
  // So, we need to use useEffect() to set this value, but, the problem is that
  // old 'relpath' will be rederned 1st. It does not matter for child component
  // as the chidren think there is no change, and does not perform update.
  // Even though, we want to prevent 1st render of old 'relpath' to make children
  // to have less dependency. It means that even current logic prevent children
  // to render old 'relpath', but it is unknown, if children will change in future.
  // What we know is if this component just trigger 1 render for children, there
  // is no potential feature break in the fugure.
  // That's why this flag is used.
  const [isReady, setIsReady] = useState(false);

  const [videoTitle, setVideoTitle] = useState(param_title ? api.decodeBase64ToUrl(param_title) : '');
  const [endirname, setEnDirName] = useState(param_dirname);

  const [ytEditMode, setYtEditMode] = useState(config.ytEditMode);
  const [dirInfo, setDirInfo] = useState(null);

  const [page2Visible, setPage2Visible] = useState(false);

  // Update in useEffect() is crucial when YoutubeView reload by navigate()
  // for new paramters to take effect.
  useEffect(() => {
    setPlayVideo(param_ytid ? true : false);
    setYtId(param_ytid);
    setRelpath(param_ytpath);
    setIsReady(true); // Indicate that state is synchronized
  }, [param_ytid, param_ytpath]);

  const handleOnClickVideo = (ytId, title, dirInfo) => {
    setPlayVideo(true);
    setYtId(ytId);
    setVideoTitle(title);
    setDirInfo(dirInfo);
  }

  const handlePage2VisibleVideo = (visible) => {
    if (visible) {
      setPage2Visible(true);
    } else {
      setPage2Visible(false);
    }

    if (onPage2Visible) {
      onPage2Visible(visible);
    }
  }

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url);
  }

 return (
    <>
      {isReady && (
        <>
          {!playVideo ? (
            <YtDirectory 
              relpath={relpath}
              dirInfo={dirInfo}
              onClickVideo={handleOnClickVideo} 
              ytEditMode={ytEditMode}
              onePageView={true}
            />
          ) : (
            <Yt2P 
              relpath={relpath}
              ytIdInput={ytId}
              videoTitleInput={videoTitle}
              dirInfoInput={dirInfo}
              onPage2VisibleVideo={handlePage2VisibleVideo} 
              ytEditMode={ytEditMode} 
            />
          )}
          <IconMenuBar
            onClickRotatingBall={handleBallClick} 
            // This parameter is only for 2-page.
            {...(playVideo ? { showIconMenuBar: page2Visible } : {})}
          /> 
        </>
      )}
      {!isReady && <div>Loading...</div>} {/* Optional loading state */}
    </>
  );
};

export default YoutubeView;