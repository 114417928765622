import React, { useState, useEffect } from 'react';
import { useSimplePeerUsers } from './SimplePeerUserContext';

const ChatSP = () => {
  const [message, setMessage] = useState('');
  const { loginUserName, onlineUsers = [], messages, sendMessage } = useSimplePeerUsers();
  const [ otherUsers, setOtherUsers ] = useState([]);

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message.trim() !== '') {
      sendMessage(message);
      setMessage('');
    }
  };

  useEffect(() => {
    const others = onlineUsers.filter(user => user !== loginUserName);
    setOtherUsers(others);
  }, [onlineUsers]);

  return (
    <div>
      <h1>Peer-to-Peer Chat</h1>
      <div>
        <h3>Users in the Room</h3>
        <p>Login User:<strong>{loginUserName}</strong> </p>
        <h5>Other Users:</h5>
        <ul>
          {otherUsers.map((user, index) => (
            <li key={index}>{user}</li>
          ))}
        </ul>
      </div>
      <div style={{ border: '1px solid black', height: '300px', overflowY: 'scroll', marginBottom: '10px' }}>
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
      </div>
      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ChatSP;
