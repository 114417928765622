import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ApiFileX } from '../../comjs/filex.api/filex-api';
import styles from './UserProfilePanel.module.css'; // Import the CSS module

const UserProfilePanel = ({ logoutHandler }) => {
  const navigate = useNavigate();
  const { loginUserName, updateLoginUserName } = useContext(AppContext);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    const api = new ApiFileX();
    let ret = await api.logOut();

    updateLoginUserName(null);
    setShowMenu(false);

    if (window.location.pathname === '/') {
      // Navigate to a different route and then back to the root with a delay
      navigate('/sysicon', { replace: true });   // Prevent leave site dialog popup
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 100); // Adjust the delay as needed
    } else {
      navigate('/', { replace: true });  
    }

    if (logoutHandler) {
      logoutHandler();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className={styles.userProfilePanel}>
      <span className={styles.userName}>{loginUserName}</span> {/* User name label */}
      <img
        src="/static/img/user-login.png"
        alt="User Icon"
        className={styles.userIcon}
        onClick={toggleMenu}
      />
      {showMenu && (
        <div className={styles.contextMenu} ref={menuRef}>
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default UserProfilePanel;
