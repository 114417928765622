import React, { useEffect, useState, useRef, useContext } from 'react'
import './LoginName.css'
import { LanguageContext } from '../Localization/LanguageContext/LanguageContext';
import UserInfoContext from '../ImageCarousel/UserInfoContext';


//class LoginName extends React.Component {
const LoginName = ({parentHandleLoginName, updateLoginName}) => { 
  const { language } = useContext(LanguageContext);
  const {updateLoginUser} = useContext(UserInfoContext);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const usernameInputRef = useRef(null); // Create a ref for the username input
  const labels = {
    en: { whoAreYou: "Who are you?", enterName: "Enter your name:", namePlaceholder: "Name?", enter: "Enter" },
    zh: { whoAreYou: "你是谁？", enterName: "输入用户名：", namePlaceholder: "用户名?", enter: "核实用户名。。。" }
  };

  useEffect(() => {
    // Focus the input when the component is loaded
    usernameInputRef.current.focus();
  }, []);

  const clearErrorMessage = () => {
    const error = document.getElementById("errorMessage").innerText = "";
  }

  const onLogin = (status, data) => {
    // Call the callback function passed from the parent component LoginBg
    if (status) {
      // If login name is successful, data stores login user name.
      // Update context so that login key component and parent backgroud
      // component knows login name.
      updateLoginUser(data.data);
    }
    parentHandleLoginName(status, data);
  };

  const validateUserName = async () => {
    const loginButton = document.querySelector(".login-button");
    loginButton.classList.add("hourglass-cursor"); // Change cursor to hourglass

    // Retrieve the input value
    let username = document.getElementById("usernameInput").value;
    username = username.trim();

    // Add your validation logic here
    // For example, you can check if the username is not empty
    if (username === "") {
        document.getElementById("errorMessage").innerText = "Please enter your name.";
        loginButton.classList.remove("hourglass-cursor");
    } else {
        // Perform the login logic here
        // For this example, we'll just clear any previous error messages
        document.getElementById("errorMessage").innerText = "";

        // Simulate a delay for demonstration purposes (remove this in your real login logic)
        setTimeout(() => {
            // Once the login action is completed, change the cursor back to normal
            loginButton.classList.remove("hourglass-cursor");
        }, 2000); // Replace 2000 with the actual time it takes to perform the login action

        // Add your actual login logic here
        try {
          const response = await fetch("/check_username", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username }),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            // Authentication successful
            onLogin(true, data);
            setMessage("Login successful!");
            // Store token or perform any necessary actions after successful login
          } else {
            // Authentication failed
            //setMessage(data.error || "Login failed. Please try again.");
            setMessage("Login failed. Please try again.");
            onLogin(false, data);
          }
        } catch (error) {
          setMessage("An error occurred. Please try again later.");
          onLogin(false, error);
        }
    }
  };

  const handleKeyDown = (event) => {
    setMessage("");
    if (event.key === "Enter") {
      // Trigger login action on Enter key press
      validateUserName();
    }
  };

  return (
    <>
      <div className="login-container">
          <h2>{labels[language].whoAreYou}</h2>
          <p>{labels[language].enterName}</p>
          <div className="input-container">
              <input type="text"
              id="usernameInput" 
              value={username}
              maxLength={10}
              placeholder={labels[language].namePlaceholder}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
              ref={usernameInputRef} // Attach the ref to the input element
              />
              <i className="fas fa-user input-icon"></i>
          </div>
          <button className="login-button" onClick={validateUserName}>{labels[language].enter}</button>
          
          <p className="error-message" id="errorMessage">{message}</p>
      </div>
    </>
  )
};

export default LoginName