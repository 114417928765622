/**
 * This is sample of integrating ES6 class and jquery with a React component. 
 */
import React, { useEffect, useState, useRef } from 'react';

//import '../JQueryPlugins/DragArrange.js';
import '../JQueryPlugins/JQueryDad.js';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import $ from 'jquery';
//import { homePane } from '../../comjs/home.pane/home-pane';
import './GridIcons.css';


const GridIcons = () => {
  const [isAdminUser, setIsAdminUser] = useState(true);
  const navigate = useNavigate();
  let myHomePane = null;

  const handlePhotoClick = (evt) => {
    navigate('/');
  };

  const handleDocumentClick = (evt) => {
    navigate('/article');
  }

  const handleSettingsClick = (evt) => {

  }

  const handleChannelClick = (evt) => {
    navigate('/');
  }

  const handleTheaterClick = (evt) => {

  }
  
  useEffect(() => {
    const options = {
      onClickPhoto: handlePhotoClick
    }

    //myHomePane = new homePane(options);
    $('#heros').dad();

    return () => {
      if (myHomePane) {
        myHomePane.destroy();
      }
    };
  }, []);

  return (
    <>
    <div id="heros" className="area area-flex dad-example hero-style" data-dad-active="true" >

      <div>
        <div className="item-400">
          <div id="div-my-media" className="sixth hero" onClick={handlePhotoClick}>
            <img className="hero-profile-img-center" src="/static/img/multimedia-png-3.png" />
            <div className="hero-description-bk"></div>
            <div className="hero-logo">
              <img src="/static/img/my-media-6.png" />
            </div>
            <div className="hero-description">
              <p>Media files</p>
            </div>
          </div>
          
        </div>
      </div>
      <div>
        <div className="item-400">
          <div id="div-my-doc" className="fifth hero" onClick={handleDocumentClick}>
            <img className="hero-profile-img-center" src="/static/img/icon-doc.png" />
            <div className="hero-description-bk"></div>
            <div className="hero-logo">
              <img src="/static/img/icon-write.png" />
            </div>
            <div className="hero-description">
              <p>Document</p>
            </div>
          </div>
          
        </div>
      </div>
      <div>
        <div className="item-400">
          <div id="div-settings" className="second hero" onClick={handleTheaterClick}>
            <img className="hero-profile-img-center" src="/static/img/movie-theater.png" />
            <div className="hero-description-bk"></div>
            <div className="hero-logo">
              <img src="/static/img/people-color.png" />
            </div>
            <div className="hero-description">
              <p>Tiny Theater</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="item-400">
          <div id="div-settings" className="seventh hero" onClick={handleChannelClick}>
            <img className="hero-profile-img-center" src="/static/img/carousel.png" />
            <div className="hero-description-bk"></div>
            <div className="hero-logo">
              <img src="/static/img/rotate.png" />
            </div>
            <div className="hero-description">
              <p>Channels</p>
            </div>
          </div>
        </div>
      </div>
      {isAdminUser && (
        <div>
          <div className="item-400">
            <div id="div-settings" className="third hero" onClick={handleSettingsClick}>
              <img className="hero-profile-img" src="/static/img/hill2.jpg" />
              <div className="hero-description-bk"></div>
              <div className="hero-logo">
                <img src="/static/img/settings2.png" />
              </div>
              <div className="hero-description">
                <p>Settings</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
}


export default GridIcons;
