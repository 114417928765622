// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-container {
  position: relative;
  display: inline-block;
}

.notification-container.small .bell-icon .fa-bell {
  font-size: 1.5em; /* Adjust size as needed for small */
}

.notification-container.large .bell-icon .fa-bell {
  font-size: 2em; /* This is the default size */
}

.notification-container.small .notification-label {
  height: 8px; /* Smaller size for small bell icon */
  width: 8px;  /* Smaller size for small bell icon */
}

.notification-container.large .notification-label {
  height: 12px; /* Default size for large bell icon */
  width: 12px;  /* Default size for large bell icon */
}

.bell-icon {
  position: relative;
  cursor: pointer;
}

.notification-label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Notification/NotifyBell.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB,EAAE,oCAAoC;AACxD;;AAEA;EACE,cAAc,EAAE,6BAA6B;AAC/C;;AAEA;EACE,WAAW,EAAE,qCAAqC;EAClD,UAAU,GAAG,qCAAqC;AACpD;;AAEA;EACE,YAAY,EAAE,qCAAqC;EACnD,WAAW,GAAG,qCAAqC;AACrD;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,qBAAqB;EACrB,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".notification-container {\n  position: relative;\n  display: inline-block;\n}\n\n.notification-container.small .bell-icon .fa-bell {\n  font-size: 1.5em; /* Adjust size as needed for small */\n}\n\n.notification-container.large .bell-icon .fa-bell {\n  font-size: 2em; /* This is the default size */\n}\n\n.notification-container.small .notification-label {\n  height: 8px; /* Smaller size for small bell icon */\n  width: 8px;  /* Smaller size for small bell icon */\n}\n\n.notification-container.large .notification-label {\n  height: 12px; /* Default size for large bell icon */\n  width: 12px;  /* Default size for large bell icon */\n}\n\n.bell-icon {\n  position: relative;\n  cursor: pointer;\n}\n\n.notification-label {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background-color: red;\n  border-radius: 50%;\n  border: 2px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
