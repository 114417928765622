/**
 * Program video does not have intro component as the tittle will be shown in video player.
 * Photo program display intro component with title defined in channel.json.
 * 
 * CinemaVideo has intro. Channel has intro. ProgramVideo disable intro and uses channel's
 * intro
 * 
 * Each program consists of:
 * 
 * 1. Introduction play. The title is from channel json, or original dir name if json does
 *    not define title.
 * 2. Image carousel play, which slides through all photos in a directory.
 */
import React, { useState, useEffect, useContext } from 'react';
import CinemaVideo from '../CinemaVideo/CinemaVideo';
import { f2futil } from '../../comjs/utils/utils.js';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';


/**
 * showIntro=false is a must setting to avoid continous video list playing issue (one of the cause).
 * 
 * @param {*} param0 
 * @returns 
 */
const ProgramVideo = ({ 
    onStart, 
    onFinish, 
    onYtVideoStart,
    onYtVideoEnd,
    mediaPath,   // video: full path, photo: 
    title: propTitle,   // Rename title to proTitle to avoid possible conflict. Default value is null.
    paShowPlayer=true,
    paPlay=false,
    forceRender=false,
    onSwipeNextProgram=null
  }) => {  
  const [title, setTitle] = useState(propTitle || null);
  const [playVideoType, setPlayVideoType] = useState('lv');
  
  useEffect(() => {
    // This causes ProgramVideo to re-render
  }, [forceRender]);

  // When playing video item in channel's video list, only relpath is specified in channel file.
  // relpath contains video title, which needs to be retrieved with rest api.
  useEffect(() => {
    const fetchVideoTitle = async () => {
      const api = new ApiFileX();
      try {
        const util = new f2futil();
        const urlInfo = util.parseMediaUrl(mediaPath);

        let ret = null;
        if (urlInfo.type == 'lv') {
          ret = await api.lvPlayUrl(urlInfo.media_id, urlInfo.relpath);
          setPlayVideoType('lv');
          if (ret.status) {
            setTitle(ret.data.title);
          } else {
            setTitle(null);
          }
        } else if (urlInfo.type == 'yv') {
          ret = await api.yvPlayUrl(urlInfo.media_id, urlInfo.relpath);
          setPlayVideoType('yv');
          if (ret.status) {
            setTitle(ret.data.file_name);
          } else {
            setTitle(null);
          }
        }
      } catch (error) {
        console.error('Error fetching video title:', error);
        setTitle(null);
      }
    };

    fetchVideoTitle();

  }, [mediaPath]);

  // showIntro=false is to disable intro conmponent.
  // Don't render CinemaVideo until 'title' is obtained by rest api.
  return title ? (
    <CinemaVideo 
      videoTitle={title} 
      videoPath={mediaPath} 
      playVideoType={playVideoType}
      onVideoStart={onStart}
      onVideoEnd={onFinish}
      onYtVideoStart={onYtVideoStart}
      onYtVideoEnd={onYtVideoEnd}
      showIntro={false}
      paShowPlayer={paShowPlayer}
      paPlay={paPlay} 
      onSwipeNextProgram={onSwipeNextProgram} />
  ) : <div>Loading...</div>;;
}

export default ProgramVideo;
