/**
 * It's to scroll to top when new plage is loaded when back/forward is clicked.
 * Two page auto scroll is scroll bar is in the middle, which is confusing for user
 * if not seeing main player after going to new page.
 */
// useScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


// TODO: not really trigger when back/forward button is clicked.
const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll the main window to the top
    window.scrollTo(0, 0);

    // Get all scrollable elements and set their scroll position to top
    document.querySelectorAll('[data-scroll]').forEach((element) => {
      element.scrollTop = 0;
    });
  }, [pathname]);
};

export default useScrollToTop;
