/**
 * ChatGPT gives not perfect answer
 * 
 * 
 * This solved the final mystery
 * 
 * https://stackoverflow.com/questions/78804986/how-to-make-2nd-div-to-overlay-1st-background-div-in-2-div-system-with-accurate/78808095#78808095
 */
import React, { useEffect, useRef, useState } from 'react';
import useIsTouchDevice from '../Util/HookTouchDevice.jsx';
import './TwoSectionsPage.css';

const TwoSectionsPage = ({
  onScroll0 = null,
  onScroll10 = null,
  onScroll30 = null,
  onScroll60 = null,
  onSection2Enter = null,
  onSection2TopReach = null,
  enableTouchOverlay = true,
  children,
}) => {
  const contentSectionRef = useRef(null);
  const backgroundSectionRef = useRef(null);
  const isTouchDevice = useIsTouchDevice();
  //const [isTouchDevice, setIsTouchDevice] = useState(false);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [hasTriggered10, setHasTriggered10] = useState(false);
  const [hasTriggered30, setHasTriggered30] = useState(false);
  const [hasTriggered60, setHasTriggered60] = useState(false);

  const handleScroll = () => {
    const viewportHeight = window.innerHeight;
    const contentSectionRect = contentSectionRef.current.getBoundingClientRect();
    const contentSectionHeight = contentSectionRef.current.offsetHeight;

    const scrolledInViewport = viewportHeight - contentSectionRect.top;
    const scrollPercentage = (scrolledInViewport / viewportHeight) * 100;
    const scrollTop = window.scrollY;

    const newScrollDirection = scrollTop > lastScrollTop ? 'down' : 'up';
    setScrollDirection(newScrollDirection);
    setLastScrollTop(scrollTop);

    if (scrollTop == 0) {
      console.log('============================');
      if (onScroll0) onScroll0();
    }

    console.log(`${scrollPercentage}. viewport h: ${viewportHeight}. 2nd div top: ${contentSectionRect.top}`);

    // Handle opacity changes based on scroll percentage
    if (scrollPercentage >= 10 && scrollPercentage < 30) {
      if (!hasTriggered10) {
        setHasTriggered10(true);
        if (onScroll10) onScroll10(newScrollDirection);
      }
      if (newScrollDirection === 'down' || scrollPercentage >= 10) {
        backgroundSectionRef.current.style.opacity = 1;
      }
    } else if (scrollPercentage >= 30 && scrollPercentage < 60) {
      if (!hasTriggered30) {
        setHasTriggered30(true);
        if (onScroll30) onScroll30(newScrollDirection);
        backgroundSectionRef.current.style.opacity = 0.7;
      }
    } else if (scrollPercentage >= 60 && scrollPercentage < 80) {
      if (!hasTriggered60) {
        setHasTriggered60(true);
        if (onScroll60) onScroll60(newScrollDirection);
        backgroundSectionRef.current.style.opacity = 0.3;
      }
    } else if (scrollPercentage >= 80) {
      backgroundSectionRef.current.style.opacity = 0;
    } else {
      // Reset triggers when scrolling up
      if (newScrollDirection === 'up') {
        if (scrollPercentage < 10) {
          setHasTriggered10(false);
        }
        if (scrollPercentage < 30) {
          setHasTriggered30(false);
          backgroundSectionRef.current.style.opacity = 1;
        }
        if (scrollPercentage > 80) {
          // Little different from scroll up.
          backgroundSectionRef.current.style.opacity = 0;
        }
        if (scrollPercentage > 60 && scrollPercentage < 80) {
          backgroundSectionRef.current.style.opacity = 0.7;
        }
        if (scrollPercentage > 30 && scrollPercentage < 60) {
          setHasTriggered60(false);
          backgroundSectionRef.current.style.opacity = 0.7;
        }
        if (scrollPercentage > 10 && scrollPercentage < 30) {
          setHasTriggered60(false);
          backgroundSectionRef.current.style.opacity = 0.3;
        }
      }
    }

    // Handle additional callbacks
    if (contentSectionRect.top <= viewportHeight && contentSectionRect.bottom > 0) {
      if (onSection2Enter) onSection2Enter();
    }

    if (contentSectionRect.top <= 0) {
      if (onSection2TopReach) onSection2TopReach();
    }
  };

  // Function to check if the device is touch-enabled
  // quick easy way.
  // const detectTouchDevice = () => {
  //   return ('ontouchstart' in window || navigator.maxTouchPoints > 0);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);

    if (onScroll0) onScroll0();
    
    //setIsTouchDevice(detectTouchDevice());

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="outer-container">
      <div className="div-section background-section" ref={backgroundSectionRef}>
        {children[0]}
        {/* {enableTouchOverlay && ( */}
        {isTouchDevice && enableTouchOverlay && (
          <div className="touch-overlay"></div>
        )}
      </div>
      <div className="div-section content-section" ref={contentSectionRef} tabIndex="-1">
        {children[1]}
      </div>
    </div>
  );
};

export default TwoSectionsPage;

