import React, { useState, useEffect, useMemo } from 'react';
import VideoPlayer from './VideoPlayer';
import AlbumIntro from '../Channels/AlbumIntro';
import { f2futil } from '../../comjs/utils/utils.js';
import CinemaYt from '../YoutubeView/CinemaYt/CinemaYt';
import useSwipeDetection from '../Util/HookSwipeDetection.jsx';


/**
 * For channel video playing, intro is shown in program. Video player intro is not needed.
 * 
 * 'hide':
 *    This is for video element only.
 *    1. means <video> html is loaded but not visible. It's not not loading html.
 *    2. If true, AlbumIntro is not loaded
 * 
 * @param {*} param0 
 * @returns 
 * 
 * 
 * TODO: remove intro as video player has default intro.
 */
const CinemaVideo = ({
    videoTitle, 
    videoPath, 
    playVideoType='lv',  // lv is more backward compatible.
    pageOneVisible,
    onVideoStart=null,
    onVideoEnd=null,
    onYtVideoStart=null,
    onYtVideoEnd=null,
    showIntro=false,
    paShowPlayer=true,
    paPlay=false,
    onSwipeNextProgram=null   
  }) => {
  const [showIntroduction, setShowIntroduction] = useState(showIntro);

  useSwipeDetection(
    onSwipeNextProgram ? () => onSwipeNextProgram(-1) : null, // onSwipeLeft
    onSwipeNextProgram ? () => onSwipeNextProgram(1) : null   // onSwipeRight
  );

  useEffect(() => {
    // console.log("CinemaVideo mounted.");

    return () => {
      // console.log("CinemaVideo unmounted.");
    };
  }, []);

  const handleIntroStart = () => {

  };

  const handleIntroEnd = () => {
    setShowIntroduction(false);
  };

  // Youtube iframe click/swipe does not work. This is swipe event which is fired
  // by an added div on top of iframe in youtube player (YoutubePlayerAdv).
  const handleYtSwipeNextProgram = (swipeDirection) => {
    if (onSwipeNextProgram) {
      onSwipeNextProgram(swipeDirection);
    }
  };

  // Why this is needed. Please refer to CinemaPhoto.jsx. I don't think video has issue.
  // It's to eliminate multiple scroll event triggerred re-render. Without useMemo, re-render
  // will be triggerred when scrolling for photo player, which will restart from 1st photo.
  // Photo player has the issue, due to re-render causing reinitialize component. Video has only
  // one item to play.
  //
  /*
  const memoizedVideoPlayer = useMemo(() => (
    <VideoPlayer 
      videoPath={videoPath} 
      title={videoTitle} 
      pageOneVisible={pageOneVisible} 
      onVideoStart={onVideoStart}
      onVideoEnd={onVideoEnd} 
      showPlayer={paShowPlayer}
      playWhenLoaded={paPlay}
    />
  ), [videoPath, videoTitle, pageOneVisible, 
      paShowPlayer, paPlay]);   // force re-render
  */

  const memoizedVideoPlayer = useMemo(() => {
    const ytId = '';
    const dirInfo = '';

    if (playVideoType === 'lv') {
      return (
        <VideoPlayer 
          videoPath={videoPath} 
          title={videoTitle} 
          pageOneVisible={pageOneVisible} 
          onVideoStart={onVideoStart}
          onVideoEnd={onVideoEnd} 
          showPlayer={paShowPlayer}
          playWhenLoaded={paPlay}
        />
      );
    } else if (playVideoType === 'yv') {
      const util = new f2futil();
      const urlInfo = util.parseMediaUrl(videoPath);

      if (urlInfo.type != 'yv') {
        // Assert
        alert('Wrong type of video!')
      }

      return (
        <CinemaYt 
          videoTitle={videoTitle}
          ytId={urlInfo.media_id} 
          relpath={urlInfo.relpath}
          onVideoStart={onYtVideoStart}
          onVideoEnd={onYtVideoEnd}
          pageOneVisible={pageOneVisible} 
          onSwipeNextProgram={handleYtSwipeNextProgram}
        />
      );
    } else {
      return <div>No player is available...</div>;
    }
  }, [videoPath, videoTitle, pageOneVisible, paShowPlayer, paPlay, playVideoType]);
    
  useEffect(() => {
    // This causes CinemaVideo to re-render
  }, [paShowPlayer, paPlay]);

  // <AlbumIntro> is for showing title of each video. For channel video list playing, this 
  // is redundant. 
  // TODO: remove AlbumIntro, as VideoPlayer has intro
  return (
    <>
      {showIntroduction ? (
        <AlbumIntro 
          onStart={handleIntroStart} 
          onFinish={handleIntroEnd} 
          title={videoTitle}
          introTimeout={5000} />
      ) : (
        memoizedVideoPlayer
      )}
    </>
  );
};

export default CinemaVideo;
