// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sysicon-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the minmax value as needed */
  grid-gap: 20px;
  gap: 20px; /* Adjust the gap between the grid items as needed */
  justify-content: center; /* Center the items within the grid */
  padding: 20px; /* Optional: Add padding to the container */
}
`, "",{"version":3,"sources":["webpack://./src/Components/HeroIcon/SysIcons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4DAA4D,EAAE,sCAAsC;EACpG,cAAS;EAAT,SAAS,EAAE,oDAAoD;EAC/D,uBAAuB,EAAE,qCAAqC;EAC9D,aAAa,EAAE,2CAA2C;AAC5D","sourcesContent":["#sysicon-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the minmax value as needed */\n  gap: 20px; /* Adjust the gap between the grid items as needed */\n  justify-content: center; /* Center the items within the grid */\n  padding: 20px; /* Optional: Add padding to the container */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
