// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* muuri-grid.css */

.lab-container {
  width: 300px; /* Set a width for the container */
  margin: 0 auto; /* Center the container on the page */
}

.lab-container .title {
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  cursor: move; /* Make the title draggable */
}

.lab-container .grid {
  display: flex;
  flex-direction: column;
}

.lab-container .item {
  width: calc(100% - 0px); /* Make each item take 100% width of the container */
  background-color: #3498db;
  color: white;
  margin: 0;
  padding: 0;
  height: 30px;
}

.lab-container .content {
  margin-left: 4px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/Components/StockMonitor/LabMuuriList.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;EACE,YAAY,EAAE,kCAAkC;EAChD,cAAc,EAAE,qCAAqC;AACvD;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,YAAY,EAAE,6BAA6B;AAC7C;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,uBAAuB,EAAE,oDAAoD;EAC7E,yBAAyB;EACzB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["/* muuri-grid.css */\n\n.lab-container {\n  width: 300px; /* Set a width for the container */\n  margin: 0 auto; /* Center the container on the page */\n}\n\n.lab-container .title {\n  background-color: #2c3e50;\n  color: white;\n  padding: 10px;\n  cursor: move; /* Make the title draggable */\n}\n\n.lab-container .grid {\n  display: flex;\n  flex-direction: column;\n}\n\n.lab-container .item {\n  width: calc(100% - 0px); /* Make each item take 100% width of the container */\n  background-color: #3498db;\n  color: white;\n  margin: 0;\n  padding: 0;\n  height: 30px;\n}\n\n.lab-container .content {\n  margin-left: 4px;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
