import React, { useState, useCallback, useMemo } from 'react';
import TwoSectionsPage from '../../TwoSectionsPage/TwoSectionsPage';
import CinemaYt from './CinemaYt';
import YtDirectory from '../Section/YtDirectory';


const Yt2P = (props) => {
  const {
    relpath,
    ytIdInput,
    videoTitleInput,
    dirInfoInput,
    videoPathInput,
    onClickFolder,
    ytEditMode=false,
    onPage2VisibleVideo=null
  } = props;
  const [ytId, setYtId] = useState(ytIdInput);
  const [videoTitle, setVideoTitle] = useState(videoTitleInput);
  const [dirInfo, setDirInfo] = useState(dirInfoInput);

  const [videoPath, setVideoPath] = useState(videoPathInput);
  const [pageOneVisible, setPageOneVisible] = useState(false);

  const handleClickVideo = (ytId, title, dirInfo) => {
    setYtId(ytId);
    setVideoTitle(title);
    setDirInfo(dirInfo);
  };

  const handleOnPage1Visible = useCallback(() => {
    setPageOneVisible(true);

    if (onPage2VisibleVideo) {
      onPage2VisibleVideo(false);
    }
  }, [onPage2VisibleVideo]);

  const handleOnPage2Visible = useCallback(() => {
    setPageOneVisible(false);

    if (onPage2VisibleVideo) {
      onPage2VisibleVideo(true);
    }
  }, [onPage2VisibleVideo]);

  // key is to making sure scrolling to work. Without it, it does not scroll to
  // page2.
  return (
    <div key={ytId}>
      <TwoSectionsPage
          keyValue={ytId} 
          onScroll0={handleOnPage1Visible} 
          onScroll30={handleOnPage2Visible} >
        <CinemaYt 
          videoTitle={videoTitle}
          ytId={ytId} 
          dirInfo={dirInfo}
          relpath={relpath}
          pageOneVisible={pageOneVisible} />
        <YtDirectory 
          relpath={relpath} 
          dirInfo={dirInfo}
          onClickVideo={handleClickVideo} 
          ytEditMode={ytEditMode}
          onePageView={false} />
      </TwoSectionsPage>
    </div>
  );
};

export default Yt2P;