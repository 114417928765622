import React from 'react';
import './RecipeCard.css';
//import asparagusImage from './asparagus.jpg';

const RecipeCard = () => {
    return (
        <div className="recipe-card">
            <h1>Roasted Asparagus with Creamy Blueberry Sauce</h1>
            <p className="source">Gourmet Trading Co.</p>
            <div className="image">
                <img src="https://d2gk7xgygi98cy.cloudfront.net/6267-3-large.jpg" alt="Roasted Asparagus with Creamy Blueberry Sauce" />
            </div>
            <div className="ingredients">
                <h2>Ingredients</h2>
                <ul>
                    <li><strong>12 oz</strong> U.S. grown asparagus, trimmed</li>
                    <li><strong>2 Tbsp</strong> extra-virgin olive oil</li>
                    <li><strong>1/4 tsp</strong> salt</li>
                    <li><strong>3/4 tsp</strong> fresh ground black pepper</li>
                </ul>
                <h3>Sauce</h3>
                <ul>
                    <li><strong>1/2 cup</strong> U.S. grown blueberries</li>
                    <li><strong>1 Tbsp</strong> water</li>
                    <li><strong>1/4 cup</strong> half-and-half</li>
                    <li>Zest of 1 small lemon</li>
                </ul>
            </div>
            <div className="instructions">
                <h2>Instructions</h2>
                <ol>
                    <li>Preheat oven to 400°F. Place asparagus on a sheet pan, drizzle with olive oil and add salt and pepper; toss gently to coat. Roast in oven for 15 minutes. Remove when tips are golden brown, and the spears are slightly tender.</li>
                    <li>Prepare the sauce: Place blueberries and water in a small nonstick skillet. Over high heat, bring to a boil then reduce heat. Simmer for 10 minutes, or until a lightly textured sauce is formed. Turn off heat. Add half-and-half; stir to blend.</li>
                    <li>Place roasted asparagus on a platter, drizzle with sauce and sprinkle with lemon zest. Serve warm.</li>
                </ol>
                <p><strong>Makes 4 servings.</strong></p>
                <p><em>Tip: Garnish with blueberries.</em></p>
            </div>
        </div>
    );
}

export default RecipeCard;
