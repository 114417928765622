import React, { useEffect, useRef } from 'react';
import Muuri from 'muuri';
import './MuuriList.css';


const ChannelDestination = () => {
  const gridRef = useRef(null);

  useEffect(() => {
    gridRef.current = new Muuri('.channel-destination', {
      items: '.item',
      dragEnabled: true,
    });
  }, []);

  return (
    <div className="channel-destination">
      {/* Channel destination items */}
    </div>
  );
};

export default ChannelDestination;
