// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.recipe-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  padding: 20px;
  margin: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.source {
  font-style: italic;
  color: #555;
  margin-bottom: 20px;
}

.image img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.ingredients, .instructions {
  margin-bottom: 20px;
}

.ingredients h2, .instructions h2 {
  font-size: 20px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.ingredients ul, .instructions ol {
  padding-left: 20px;
}

.ingredients ul li, .instructions ol li {
  margin-bottom: 10px;
}

.instructions p {
  margin-top: 20px;
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Article/RecipeCard.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG;;AAEH;EACE,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["/* body {\n  font-family: Arial, sans-serif;\n  background-color: #f8f8f8;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n} */\n\n.recipe-card {\n  background-color: white;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  max-width: 600px;\n  padding: 20px;\n  margin: 20px;\n}\n\nh1 {\n  font-size: 24px;\n  margin-bottom: 10px;\n}\n\n.source {\n  font-style: italic;\n  color: #555;\n  margin-bottom: 20px;\n}\n\n.image img {\n  width: 100%;\n  border-radius: 10px;\n  margin-bottom: 20px;\n}\n\n.ingredients, .instructions {\n  margin-bottom: 20px;\n}\n\n.ingredients h2, .instructions h2 {\n  font-size: 20px;\n  border-bottom: 2px solid #f0f0f0;\n  padding-bottom: 5px;\n  margin-bottom: 10px;\n}\n\n.ingredients ul, .instructions ol {\n  padding-left: 20px;\n}\n\n.ingredients ul li, .instructions ol li {\n  margin-bottom: 10px;\n}\n\n.instructions p {\n  margin-top: 20px;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
