/**
 * To display media grid view of channel player. Channel API will return
 * list of thumbnails by giving only relative path of a folder.
 * At present, channel displays photo only.
 */
import $ from 'jquery'
import React, { useEffect, useState, useRef } from 'react';
import './CustomAlert';

// PintoPanel component uses Jquery PintoPlugin.
import PintoPanel from './PintoPanel';


class DataFetcher {
  async fetchData(relPath) {
    let retData = null;
    try {
      let url = '/api_myfilex/tvchannel';
      if (relPath) {
        url += '/' + relPath;
      }

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.result.status) {
          const dataJson = JSON.parse(data.result.data);
          const tmp = dataJson.dir_files;
          data.result.dir_files = tmp;
          retData = data.result;
        }
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }
}

const ChannelMediaGrid = ({relPath}) => {
  const [photos, setPhotos] = useState([]);
  let photoGridPanel = null;
  let photoGridPanelTest = null;
  let $photoGridWrapper = null;
  const $photoContainerRef = useRef(null);
  const messageRef = useRef(null);
  
  useEffect(() => {
    // Run this code once the component is mounted and the DOM is ready
    $('#showAlert').customAlert({
      message: 'This is a custom alert box!',
      backgroundColor: '#ffa500', // Orange background
      textColor: '#fff', // White text color
      borderRadius: '10px', // Rounded corners
    });

    const fetchDataFromAPI = async (relPath) => {
      const dataFetcher = new DataFetcher();
      const result = await dataFetcher.fetchData(relPath);
      setPhotos(result);
    };

    if (relPath) {
      fetchDataFromAPI(relPath);
    }

    return () => {

    };
  }, [relPath]); // Empty dependency array to ensure the effect runs only once

  return (
    <>
    {photos && <PintoPanel imageList={photos} />}
    </>
  );
};

export default ChannelMediaGrid;
