/**
 * https://codepen.io/michmy/pen/GrzwVL
 * 
 */
import React, { useState, useEffect, useRef } from 'react';
import { articleEditor, articleEditToggle } from '../../comjs/article.view/article-editor'; 
import {IconMenuBar} from '../IconMenuBar/IconMenuBar'
import './CookingRecipe.css';

// Load/unload body css dynamically.
import styles from './CookingRecipeBody.module.css';


const CookingRecipe = ({ title, author, content }) => {
  const [editMode, setEditMode] = useState(null);
  const [editor, setEditor] = useState(null);
  const articleRef = useRef(null);

  useEffect(() => {
    // Apply styles to the body element when the component mounts
    document.body.classList.add(styles.bodyStyles);

    // Remove styles from the body element when the component unmounts
    return () => {
      document.body.classList.remove(styles.bodyStyles);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  useEffect(() => {
    if (articleRef.current) {
      // Get vanilla div id for ES6 code.
      const articleId = articleRef.current.id;

      const editToggle = new articleEditToggle('article-container', articleId);
      setEditMode(editToggle);

      const editorTmp = new articleEditor(articleId);
      setEditor(editorTmp);
    }

    return () => {
      if (editor) {
        editor.destroy();
      }

      if (editMode) {
        editMode.destroy();
      }
    };
  }, []);

  const handleClickRotatingBall = (evt) => {

  }

  const handleEditModeChange = (evt) => {
    const newCheckedStatus = evt.target.checked;

    if (editMode) {
      editMode.enableEdit(newCheckedStatus);

      if (newCheckedStatus) {
        editor.show();   // Show toolbar
      } else {
        editor.hide();   // Hide toolbar
      }
    }
  }

  return (
    <>
    <div id="article-container">
    <article id='article' className="sky-editable" ref={articleRef}>
      <div className='recipe-card'>

        <div className="recipe-card__body">
          <h1 className="recipe-card__heading">Oatmeal Cookies</h1>
          <h2 className="recipe-card__subhead">Crunchy around the edges, softer in the center, these oatmeal cookies feature the nutty taste and nubbly texture of oats. </h2>

          <div 
            style={{
              background: 'url(https://d2gk7xgygi98cy.cloudfront.net/6267-3-large.jpg) no-repeat 50% 50%',
              backgroundSize: 'cover',
              height: '150px',
            }}
          ></div>

          <ul className="recipe-card__nav">
            <li>
              <h3 className="active">Ingredients</h3>
            </li>
            <li>
              <h3>Method</h3>
            </li>
          </ul>

          <ul className="recipe-card__ingredients">
            <li>&frac14; cup unsalted butter</li>
            <li>&frac14; cup vegetable shortening</li>
            <li>&frac12; cup light brown sugar</li>
            <li>&frac14; cup granulated sugar</li>
            <li>1 teaspoon vanilla extract</li>
            <li>1 &frac14; teaspoons ground cinnamon</li>
            <li>&#8539; teaspoon ground nutmeg</li>
            <li>1/2 teaspoon salt</li>
            <li>1 teaspoon cider or white vinegar*</li>
            <li>1 large egg</li>
            <li>&frac12; teaspoon baking soda</li>
            <li>&frac34; cup All-Purpose Flour</li>
            <li>1 &frac12; cups rolled oats</li>
            <li>1 cup golden raisins, optional</li>
          </ul>

          <ul className="recipe-card__nav">
            <li>
              <h3 className="active">Methods</h3>
            </li>
          </ul>

          <ul className="recipe-card__ingredients">
            <li>&frac14; cup unsalted butter  cup unsalted butter  cup unsalted butterv cup unsalted butter</li>
            <li>&frac14; cup vegetable shortening</li>
            <li>&frac12; cup light brown sugar</li>
            <li>&frac14; cup granulated sugar</li>
            <li>1 teaspoon vanilla extract</li>
            <li>1 &frac14; teaspoons ground cinnamon</li>
            <li>&#8539; teaspoon ground nutmeg</li>
            <li>1/2 teaspoon salt</li>
            <li>1 cup golden raisins, optional</li>
          </ul>

        </div>
        
      </div>
    
      <IconMenuBar onClickRotatingBall={handleClickRotatingBall}
                    onEditModeChange={handleEditModeChange} />

    </article>
    </div>
    </>
  );
};

export default CookingRecipe;
