// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  position: relative;
}

.item {
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 5px;
  z-index: 1;
  background: #000;
  color: #fff;
}

.item.muuri-item-dragging {
  z-index: 3;
}
.item.muuri-item-releasing {
  z-index: 2;
}
.item.muuri-item-hidden {
  z-index: 0;
}
.item-content {
  position: relative;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Channels/MuuriLab2.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":[".grid {\n  position: relative;\n}\n\n.item {\n  display: block;\n  position: absolute;\n  width: 100px;\n  height: 100px;\n  margin: 5px;\n  z-index: 1;\n  background: #000;\n  color: #fff;\n}\n\n.item.muuri-item-dragging {\n  z-index: 3;\n}\n.item.muuri-item-releasing {\n  z-index: 2;\n}\n.item.muuri-item-hidden {\n  z-index: 0;\n}\n.item-content {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
