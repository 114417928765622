// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChannelEdit.css */
.channel-edit-container {
  display: flex;
  width: 100%;
}

.channel-source {
  flex: 1 1; /* Takes up 50% on the left */
  border: 1px solid #ccc; /* Add border to ChannelSource */
  padding: 10px; /* Add padding for better visibility */
}

.channel-destination {
  flex: 1 1; /* Takes up 50% on the right */
  border: 1px solid #ccc; /* Add border to ChannelDestination */
  padding: 10px; /* Add padding for better visibility */
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChannelEdit/ChannelEdit.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,SAAO,EAAE,6BAA6B;EACtC,sBAAsB,EAAE,gCAAgC;EACxD,aAAa,EAAE,sCAAsC;AACvD;;AAEA;EACE,SAAO,EAAE,8BAA8B;EACvC,sBAAsB,EAAE,qCAAqC;EAC7D,aAAa,EAAE,sCAAsC;AACvD","sourcesContent":["/* ChannelEdit.css */\n.channel-edit-container {\n  display: flex;\n  width: 100%;\n}\n\n.channel-source {\n  flex: 1; /* Takes up 50% on the left */\n  border: 1px solid #ccc; /* Add border to ChannelSource */\n  padding: 10px; /* Add padding for better visibility */\n}\n\n.channel-destination {\n  flex: 1; /* Takes up 50% on the right */\n  border: 1px solid #ccc; /* Add border to ChannelDestination */\n  padding: 10px; /* Add padding for better visibility */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
