/**
 * Two page. Top is photo or video player, bottom is directory grid view.
 */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import DirectoryView from '../DirectoryView/DirectoryView.js';
import OneDirectory from '../DirectoryView/OneDirectory.js';
import VideoPlayer2P from '../CinemaVideo/VideoPlayer2P.js';
import PhotoPlayer2P from '../PhotoPlay/PhotoPlayer2P.js';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';


export const useLeaveSiteWarning = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Show a warning message
      const confirmationMessage = 'Are you sure you want to leave this website?';
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

/**
 * Handle browser back/forward button clicks.
 * 
 * @param {*} onBackward 
 * @param {*} onForward 
 */
export const useBrowserHistoryNav = (onBackward, onForward) => {
    useEffect(() => {
      const handlePopstate = (event) => {
        const isForward = event.state && event.state._isForward;
  
        if (isForward) {
          onForward && onForward();
        } else {
          onBackward && onBackward();
        }
      };
  
      window.addEventListener('popstate', handlePopstate);
  
      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }, [onBackward, onForward]);
};


/**
 * Hook to implement backward/forward, so that history implementation has minimum impact
 * on PlayerViews logic.
 * 
 * In PlayerViews, there are 3 parameters to controls 4 components views. The parameters
 * required for displaying these components, including member variables and input variable of
 * the component.
 * 
 * @returns 
 */
export const useHistoryTracking = () => {
  const [historyStack, setHistoryStack] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  // Don't track goBack/goForward.
  const [isNavigating, setIsNavigating] = useState(false);

  const captureState = (state) => {
    //setHistoryStack((prevHistory) => [...prevHistory, state]);
    //setCurrentIndex(prevIndex => prevIndex + 1);

    if (!isNavigating) {
      // Remove items from historyStack after currentIndex before adding a new state
      const newHistory = historyStack.slice(0, currentIndex + 1);

      // Add the new state to the historyStack
      setHistoryStack([...newHistory, state]);

      // Update the currentIndex to point to the end of the updated historyStack
      setCurrentIndex(newHistory.length);
    }
  };

  const goBack = () => {
    if (currentIndex > 0) {
      // Set this value, so that re
      setIsNavigating(true); 
      setCurrentIndex(prevIndex => prevIndex - 1);
      return true;
    }

    return false;
  };

  const goForward = () => {
    if (currentIndex < historyStack.length - 1) {
      setIsNavigating(true); 
      setCurrentIndex(prevIndex => prevIndex + 1);
      return true;
    }

    return false;
  };

  // Reset this value, so that
  const navDone = () => {
    setIsNavigating(false); 
  }

  const getCurrentState = (index=null) => {
    if (index) {
      return index + currentIndex >= 0 ? historyStack[index + currentIndex] : null; 
    }

    return currentIndex >= 0 ? historyStack[currentIndex] : null;
  };

  useEffect(() => {
    // Clean up the history stack when the component unmounts
    return () => {
      setHistoryStack([]);
      setCurrentIndex(-1);
    };
  }, []);

  return {
    captureState,
    goBack,
    goForward,
    navDone,
    getCurrentState,
  };
};


/**
 * forwardRef is used to expose member functions of PlayerViews which are called by MainPage.
 */
export const PlayerViews = forwardRef (({
  onPage2Visible,
  photoRoot=null,
  photoId=null,
  videoRoot=null,
  videoId=null,
  folderRoot=null, 
  folderId=null
}, ref) => {
  const { captureState, goBack, goForward, navDone, getCurrentState } = useHistoryTracking();

  // When showing directory view, 1st layer directory view shows all directories 
  // under media root, which uses DirectoryView. It does not show sub directory icons. (TODO?)
  // When showing next layer of directories,
  // it shows all files and all (TODO) subdirectories recursively. Each sub directory is shown 
  // as in 2nd layer of directory. This view uses OneDirectory.
  //
  // DirectoryView = false: Use OneDirectory
  // DirectoryView = true: Use DirectoryView
  //
  const [oneDirView, setOneDirView] = useState(false);
  
  // This path is set by clicking folder link in OnDirectory component.
  // This path is not needed to initialize DirectoryView.
  const [oneDirViewFolderRelPath, setOneDirViewFolderRelPath] = useState('');
  const [folderTitle, setFolderTitle] = useState('');

  const [playVideo, setPlayVideo] = useState(false);
  const [relPathVideo, setRelPathVideo] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [photoDirTitle, setPhotoDirTitle] = useState('');
  const [playPhoto, setPlayPhoto] = useState(false);
  const [relPathPhoto, setRelPathPhoto] = useState('');

  // DirectoryView is shown first. After clicking a thumbnail, such as video,
  // the clicked video info, including its directory information is determined
  // and stored here. This is from DirectoryView's items.
  // VideoPlayer2P will use it to load all media files thumbnails of this directory.
  const [dirInfo, setDirInfo] = useState(null);

  // goBack() and getCurrentState() should be called consecutively, but React's
  // asynchronous nature of state updates prevent this to work. We need to 
  // call getCurrentState() in another state
  const [navStateChanged, setNavStateChanged] = useState(false);

  // Handle browser back/forward.
  const handleBrowserBackward = () => {
    handleGoBack();
  };

  const handleBrowserForward = () => {
    handleGoForward();
  };

  useBrowserHistoryNav(handleBrowserBackward, handleBrowserForward);
  useLeaveSiteWarning();

  //--- start: handle history
  useEffect(() => {
    // Capture the current state when any of the tracked variables change
    const currentState = {
      oneDirView,
      oneDirViewFolderRelPath,
      folderTitle,
      photoDirTitle,
      relPathPhoto,
      videoTitle,
      relPathVideo,
      dirInfo,
      playPhoto,
      playVideo,
      //onPage2Visible,
      photoRoot,
      photoId,
      videoRoot,
      videoId,
      folderRoot,
      folderId
    };
    captureState(currentState);
  }, [
    oneDirView,
    oneDirViewFolderRelPath,
    folderTitle,
    photoDirTitle,
    relPathPhoto,
    videoTitle,
    relPathVideo,
    dirInfo,
    playPhoto,
    playVideo,
    //onPage2Visible,
    photoRoot,
    photoId,
    videoRoot,
    videoId,
    folderRoot,
    folderId
  ]);

  useEffect(() => {
    if (navStateChanged) {
      applyState();
      navDone();

      // Finish applying nav transition, which should not be recorded as history.
      // Any subsequent state transition will be tracked in history.
      setNavStateChanged(false);

      // How about nav bar status? It needs to be updated with new backeward/forward
      // url text based on new state. 
      // Since PlayerViews knows that nav state transition is done, it needs to inform
      // IconMenuBar to update nav url status with new nav state

    }
  }, [navStateChanged]);

  // With current state, get backward/forward url names.
  const getCurrentNavInfo = () => {
    const backwardState = getCurrentState(-1);
    const forwardState = getCurrentState(1);
    let backwardName = null;
    let forwardName = null;

    if (backwardState) {
      // Get display url title
      if (backwardState.oneDirView) {
        // Root has no name. This is one directory view, which 1 level down.
        backwardName = backwardState.folderTitle;
      } else if (backwardState.playPhoto) {
        backwardName = backwardState.photoDirTitle;
      } else if (backwardState.playVideo) {
        backwardName = backwardState.videoTitle;
      } else {
        // Root has no name. Give it name 'Home'.
        backwardName = null;
      }
    }

    if (forwardState) {
      // Get display url title
      if (forwardState.oneDirView) {
        // Root has no name. This is one directory view, which 1 level down.
        forwardName = forwardState.folderTitle;
      } else if (forwardState.playPhoto) {
        forwardName = forwardState.photoDirTitle;
      } else if (forwardState.playVideo) {
        forwardName = forwardState.videoTitle;
      }
    }

    return {
      'backwardName': backwardName,
      'forwardName': forwardName
    }
  }

  const applyState = () => {
    const state = getCurrentState();

    if (state) {
      setOneDirView(state.oneDirView);
      setOneDirViewFolderRelPath(state.oneDirViewFolderRelPath);
      setFolderTitle(state.folderTitle);
      setPhotoDirTitle(state.photoDirTitle);
      setRelPathPhoto(state.relPathPhoto);
      setVideoTitle(state.videoTitle);
      setRelPathVideo(state.relPathVideo);
      setDirInfo(state.dirInfo);
      setPlayPhoto(state.playPhoto);
      setPlayVideo(state.playVideo);
    }

    return state;
  }

  const handleGoBack = () => {
    if (goBack()) {
      setNavStateChanged(true);
    }
  }

  const handleGoForward = () => {
    // Backward state will be tracked due to applyState(). Therefore, goForward() will disable
    // the tracking. navDone() will reenable it.
    if (goForward()) {
      setNavStateChanged(true);
    }
  };

  // Expose functions or properties through the ref, which is called by MainPage.
  useImperativeHandle(ref, () => ({
    // Functions or properties to expose
    getNavInfo: () => {
      return getCurrentNavInfo();
    },
    notifyGoBack: () => {
      handleGoBack();
    },
    notifyGoForward: () => {
      handleGoForward();
    }
  }));
  //-- end: handle history

  useEffect(() => {
    // Determin if it's url to load player:
    // <Route exact path="/video/:pavideoid" element={<MainPage />} />
    // <Route exact path="/photo/:paphotoid" element={<MainPage />} />
    // In these cases, it calls server to prepare photo or video to be displayed, including
    // the directory media files.
    if (photoRoot && photoId || videoRoot && videoId || folderRoot && folderId) {
      const api = new ApiFileX();
      if (photoId) {
        api.procPhotoUrl(photoRoot, photoId).then(async (retData) => {
          const result = retData.data;
          if (result) {
            const relPathPhoto = result.relpath;
            const title = result.title;
            const dirInfo = {
              dir_files: result.dir_files
            };

            handleOnClickPhoto(relPathPhoto, dirInfo, title);
          };
        });
      } else if (videoId) {
        api.procVideoUrl(videoRoot, videoId).then(async (retData) => {
          const result = retData.data;
          if (result) {
            const relPathVideo = result.relpath;
            const title = result.title;
            const dirInfo = {
              dir_files: result.dir_files
            };

            handleOnClickVideo(relPathVideo, title, dirInfo);
          }
        });
      } else if (folderId) {
        api.procFolderUrl(folderRoot, folderId).then(async (retData) => {
          const result = retData.data;
          if (result) {
            const relPathVideo = result.relpath;
            const title = result.title;
            const dirInfo = {
              dir_files: result.dir_files
            };

            handleOnClickFolder(relPathVideo, dirInfo, title);

            // Folder view is same as 2nd page visible. Borrowing notification
            // function to let MainPage's ball menu to show up.
            onPage2Visible(true);
          }
        });
      }
    }
  }, [photoRoot, photoId, videoRoot, videoId, folderRoot, folderId]);

  // Go back to main page, which contains main player.
  const handlePlayerViewsBallMenuClick = () => {
    ///onBallMenuClick();
  }
  
  const handlePlayerViewsBallMenuOneDirectoryClick = () => {
    // Entering OneDirectory is from DirectoryView. Therefore, it goes back to DirectoryView
    // if ball is clicked.
    setOneDirView(false);
  }

  const handleOnClickVideo = (relPathVideo, title, dirInfo) => {
    setOneDirView(false);
    setVideoTitle(title);
    setRelPathVideo(relPathVideo);
    setDirInfo(dirInfo);

    setPlayVideo(true);
    setPlayPhoto(false);
  }

  const handleOnClickPhoto = (relPathPhoto, dirInfo, dirName) => {
    setOneDirView(false);
    setRelPathPhoto(relPathPhoto);
    setDirInfo(dirInfo);
    setPhotoDirTitle(dirName)
    
    setPlayVideo(false);
    setPlayPhoto(true);
  }
  
  const handleOnClickFolder = (folderRelPath, dirInfo, dirName) => {
    // Switch to one directory view.
    setOneDirView(true);
    setOneDirViewFolderRelPath(folderRelPath);
    setDirInfo(dirInfo);
    setFolderTitle(dirName);

    setPlayVideo(false);
    setPlayPhoto(false);
  }

  // Go back from video player VideoPlayer2P to DirectoryView.
  const handleOnBallMenuClickVideoPlayer = () => {
    // Finish video player view and go back to DirectoryView.
    setPlayVideo(false);
    setPlayPhoto(false);
  }

  const handlePage2VisiblePhoto = (visible) => {
    onPage2Visible(visible);
  }

  const handlePage2VisibleVideo = (visible) => {
    onPage2Visible(visible);
  }

  useEffect(() => {
    //Even it's empty, these values will return() to re-render.
  }, [folderTitle,
      photoDirTitle,
      relPathPhoto,
      videoTitle,
      relPathVideo,
      dirInfo,
      playPhoto,
      playVideo,
      oneDirView, 
      oneDirViewFolderRelPath]);

  // ??? OneDirectory is used for display the current directory of player. That's why onePageView={false}
  return (
    <>
    {!playVideo && !playPhoto ? (
      oneDirView ? (
        <OneDirectory 
          relPath={oneDirViewFolderRelPath} 
          dirInfo={dirInfo}
          onBallMenuClick={handlePlayerViewsBallMenuOneDirectoryClick} 
          onClickVideo={handleOnClickVideo} 
          onClickPhoto={handleOnClickPhoto}
          onClickFolder={handleOnClickFolder}
          onPageView={true}
        />
      ) : (
        <DirectoryView 
          onBallMenuClick={handlePlayerViewsBallMenuClick} 
          onClickVideo={handleOnClickVideo}
          onClickPhoto={handleOnClickPhoto}
          onClickFolder={handleOnClickFolder}
        />
      )
    ) : null}
    {playVideo && <VideoPlayer2P 
        videoTitleInput={videoTitle} 
        videoPathInput={relPathVideo} 
        dirInfoInput={dirInfo}
        onClickPhoto={handleOnClickPhoto}
        onClickFolder={handleOnClickFolder}
        onPage2VisibleVideo={handlePage2VisibleVideo} 
      />}
    {playPhoto && <PhotoPlayer2P 
        photoTitleInput={photoDirTitle} 
        photoPathInput={relPathPhoto} 
        dirInfoInput={dirInfo}
        onClickVideo={handleOnClickVideo}
        onClickFolder={handleOnClickFolder}
        onPage2VisiblePhoto={handlePage2VisiblePhoto} 
      />}
    </>
  );
});
