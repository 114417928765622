// RotatingBall.js
import React, { useState } from 'react';
import styles from './RotatingBall.module.css';

const RotatingBall = ({onClick, onEnter=null, onLeave=null, cssOption=null}) => {
  const combinedClassName = `${styles.ball} ${cssOption || ''}`;
  const [rotation, setRotation] = useState(0);

  const handleMouseEnter = (evt) => {
    // Increment rotation
    setRotation(prevRotation => prevRotation + 360);

    if (onEnter) {
      onEnter(evt);
    }
  };

  const handleMouseLeave = (evt) => {
    // Decrement rotation
    setRotation(prevRotation => prevRotation - 360);
    if (onLeave) {
      onLeave(evt);
    }
  };

  const handleClick = (evt) => {
    // Add your click handling logic here
    // You can perform any action you want when the ball is clicked
    if (onClick) {
      onClick(evt);
    }
  };

  return (
    <div
      className={combinedClassName}
      style={{ transform: `rotate(${rotation}deg)` }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    />
  );
};

export default RotatingBall;
