import React, { useEffect, useState, useLayoutEffect } from 'react';

// If imported globally in index.html, it does not work for react component.
// This pinto lib is used by ES6 class in src as well. If loaded by react component,
// it works for ES6 class in src. If not loaded first by react component, the ES6
// class needs to load it from somewhere (? defined in index.htm?).
import './PintoPlugin.css'
import './PintoPlugin.js'

// Use jquery plugin.
import $ from 'jquery'
import './PintoPanel.css'


const PintoPanel = ({ imageList }) => {
  /*
  //useLayoutEffect(() => {
  useEffect(() => {
      let picWidth = 140;
    $('#image-gallery').pinto({
      //itemSelector: '.block',  //# child selector to hold image.
      itemWidth: picWidth,
      //marginX: 10,
      //marginY: 10,
      marginX: 6,
      marginY: 6,
      align: 'center',
      animate: true,
      autoResize: true,
      resizeDelay: 50,
      invisibleUntilLayoutDone: true
    });
  });
  */

  $(document).ready(function() {
    let picWidth = 120;
    $('#image-gallery').pinto({
      itemWidth: picWidth,
      marginX: 6,
      marginY: 6,
      align: 'center',
      animate: true,
      autoResize: true,
      resizeDelay: 50,
      invisibleUntilLayoutDone: true,
      eventLayoutDone: () => {
        $('#image-gallery').css('visibility', 'visible'); // Show the gallery after layout.
      },
    });
  })

  /*
  useEffect(() => {
    // This useEffect runs after the component has mounted and every time imageList changes.
    // Perform the layout calculation after all images have loaded and are visible.
    const doLayoutAfterImagesLoad = () => {
      $('#image-gallery').pinto('destroy'); // Destroy any existing Pinto layout.
      $('#image-gallery').css('visibility', 'hidden'); // Hide the gallery before relayout.

      // Perform the layout calculation.
      $('#image-gallery').pinto({
        itemWidth: 140,
        marginX: 6,
        marginY: 6,
        align: 'center',
        animate: true,
        autoResize: true,
        resizeDelay: 50,
        invisibleUntilLayoutDone: true,
        eventLayoutDone: () => {
          $('#image-gallery').css('visibility', 'visible'); // Show the gallery after layout.
        },
      });
    };

    // Add the doLayoutAfterImagesLoad function as an additional window.onload event handler.
    const existingOnLoad = window.onload; // Preserve any existing window.onload handler.
    window.onload = () => {
      if (existingOnLoad) {
        existingOnLoad(); // Call the existing handler if it exists.
      }
      doLayoutAfterImagesLoad();
    };
  }, [imageList]);
  */
  const handleImageClick = (item) => {
    // TODO: channel model. click photo. replay from the clicked item.

    // Simply scroll to top now. TODO: restart from clicked photo?
    window.scrollTo(0, 0);
  }

  return (
    <div id="image-gallery" className="pinto-grid">
      {imageList && imageList.dir_files && imageList.dir_files.map((item) => (
        item.file_type == 2 ? (
          <div key={item.id} >
            <img 
              src={`data:image/png;base64, ${item.thumbnail}`}
              onClick={() => handleImageClick(item)} />
          </div> 
        ) : null
      ))}
    </div>
  );
};

export default PintoPanel;
