// ChannelEdit.js
import React, { useState } from 'react';
import ChannelDestination from './ChannelDestination';
import ChannelSource from './ChannelSource';
import { FileExplorerHoc } from '../FileExplorer/FileExplorer';
import { IndexExplorerUI } from '../FileExplorer/IndexExplorerUI';
import './ChannelEdit.css';


const ChannelEdit = () => {
  const [channelSourceItems, setChannelSourceItems] = useState(['item1', 'item2']);

  const handleAddChannelSourceItem = (newItem) => {
    setChannelSourceItems([...channelSourceItems, newItem]);
  };

  return (
    <div className="channel-edit-container">
      <ChannelDestination />
      {/* <ChannelSource items={channelSourceItems} onAddItem={handleAddChannelSourceItem} /> */}
      <FileExplorerHoc ParamIndexExplorerUI={IndexExplorerUI}>
        {({ explorerData, ...props }) => (
          <ChannelSource items={channelSourceItems} onAddItem={handleAddChannelSourceItem}>
            <IndexExplorerUI explorerData={explorerData} {...props} />
          </ChannelSource>
        )}
      </FileExplorerHoc>
    </div>
  );
};

export default ChannelEdit;
