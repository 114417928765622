import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { IconMenuBar } from '../IconMenuBar/IconMenuBar.jsx';
import { useAutoPlay } from '../System/AutoPlayContext';
import './AutoPlaylists.css';

const AutoPlaylists = () => {
  const navigate = useNavigate();

  // This configuration page changes auto playlist. Here is to update the auto play context to
  // inform other components.
  // The loading of this component does not update the context status of 'hasPlaylist', as
  // this property is handled by useAutoPlay internally. Only saving auto channel of this component
  // feature, we update this status.
  const { setHasPlaylist } = useAutoPlay();
  const [channels, setChannels] = useState([]);
  const [autoPlayConfig, setAutoPlayConfig] = useState([]);
  const [transitionState, setTransitionState] = useState({ index: null, direction: null });
  const [isSaving, setIsSaving] = useState(false); // Track saving state
  const [message, setMessage] = useState(''); // Track message
  const [messageType, setMessageType] = useState(''); // Track message type ('error' or 'success')

  const channelRefs = useRef([]);
  const messageTimer = useRef(null);

  // Fetch channels from server
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const api = new ApiFileX();
        const resp = await api.getAutoPlaylistConfig();
        if (resp && resp.status) {
          const channelList = resp.data.channel_order.map((channel) => ({
            channel_json_id: channel.channel_json_id,
            channel_name: channel.channel_name,
          }));

          setChannels(channelList);
          setAutoPlayConfig(resp.data.autoplay_channels);
        } else {
          console.error("Failed to fetch channels.");
        }
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    fetchChannels();
  }, []);

  const handleMove = (index, direction) => {
    const newChannels = [...channels];
    const [removed] = newChannels.splice(index, 1);
    newChannels.splice(index + direction, 0, removed);
    setChannels(newChannels);
    setTransitionState({ index, direction });
    setTimeout(() => setTransitionState({ index: null, direction: null }), 300);
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    handleMove(index, -1);
  };

  const handleMoveDown = (index) => {
    if (index === channels.length - 1) return;
    handleMove(index, 1);
  };

  const handleToggleAutoplay = (channelId) => {
    setAutoPlayConfig(prevConfig => {
      if (prevConfig.includes(channelId)) {
        // Remove channelId from autoPlayConfig
        return prevConfig.filter(id => id !== channelId);
      } else {
        // Add channelId to autoPlayConfig
        return [...prevConfig, channelId];
      }
    });
  };

  const clearMessage = () => {
    setMessage('');
    setMessageType('');
    if (messageTimer.current) {
      clearTimeout(messageTimer.current);
      messageTimer.current = null;
    }
  };

  const handleSaveConfig = async () => {
    clearMessage(); // Clear any existing message
    setIsSaving(true); // Start saving state
    try {
      const api = new ApiFileX();
      const resp = await api.setAutoPlaylistConfig({
        autoplay_channels: autoPlayConfig,
        channel_order: channels,
      });
      if (resp && resp.status) {
        if (autoPlayConfig.length > 0) {
          setHasPlaylist(true);
        } else {
          setHasPlaylist(false);
        }
        setMessage('Configuration saved successfully.');
        setMessageType('success');
        messageTimer.current = setTimeout(clearMessage, 5000); // Clear success message after 5 seconds
      } else {
        setMessage('Failed to save auto play configuration.');
        setMessageType('error');
        messageTimer.current = setTimeout(clearMessage, 15000); // Clear error message after 15 seconds
      }
    } catch (error) {
      console.error('Error saving auto play configuration:', error);
      setMessage('Failed to save auto play configuration.');
      setMessageType('error');
      messageTimer.current = setTimeout(clearMessage, 15000); // Clear error message after 15 seconds
    } finally {
      setIsSaving(false); // End saving state
    }
  };

  useEffect(() => {
    channelRefs.current = channelRefs.current.slice(0, channels.length);
  }, [channels]);

  useEffect(() => {
    if (transitionState.index !== null) {
      const { index, direction } = transitionState;
      if (channelRefs.current[index]) {
        channelRefs.current[index].style.transition = 'transform 0.3s ease-in-out';
        channelRefs.current[index].style.transform = `translateY(${direction * 100}%)`;
      }
      if (channelRefs.current[index + direction]) {
        channelRefs.current[index + direction].style.transition = 'transform 0.3s ease-in-out';
        channelRefs.current[index + direction].style.transform = `translateY(${-direction * 100}%)`;
      }
    } else {
      channelRefs.current.forEach(ref => {
        if (ref) {
          ref.style.transition = '';
          ref.style.transform = '';
        }
      });
    }
  }, [transitionState]);

  const handleBallClick = () => {
    let url = `/settings`;
    navigate(url, { replace: true });
  }

  return (
    <>
      <div className="auto-playlists-container">
        <h3>Auto Playlists</h3>
        <ul className="auto-playlists-list">
          {channels.map((channel, index) => (
            <li
              key={channel.channel_json_id}
              className="auto-playlist-item"
              ref={el => (channelRefs.current[index] = el)}
            >
              <label className="autoplay-checkbox">
                <input
                  type="checkbox"
                  checked={autoPlayConfig.includes(channel.channel_json_id)}
                  onChange={() => handleToggleAutoplay(channel.channel_json_id)}
                />
                Autoplay
              </label>
              <button onClick={() => handleMoveUp(index)} disabled={index === 0} className="small-button">Up</button>
              <button onClick={() => handleMoveDown(index)} disabled={index === channels.length - 1} className="small-button">Down</button>
              <span className="channel-name">{channel.channel_name}</span>
            </li>
          ))}
        </ul>
        {message && <div className={`message ${messageType}`}>{message}</div>}
        <button
          onClick={handleSaveConfig}
          className={`save-button ${isSaving ? 'saving' : ''}`}
          disabled={isSaving}
          style={{ cursor: isSaving ? 'not-allowed' : 'pointer' }}
        >
          {isSaving ? 'Saving...' : 'Save Configuration'}
        </button>
      </div>
      <IconMenuBar
        onClickRotatingBall={handleBallClick} 
      /> 
    </>
  );
};

export default AutoPlaylists;
