import { createContext, useState } from 'react';

const UserInfoContext = createContext();

export const UserInfoContextProvider = ({ children }) => {
  const [loginUser, setLoginUser] = useState('');

  const updateLoginUser = (name) => {
    setLoginUser(name);
  };

  return (
    <UserInfoContext.Provider value={{ loginUser, updateLoginUser }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoContext;