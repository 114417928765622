import React, { useState, useEffect } from 'react';
import files from './files.json';
import './IndexExplorerUI.css';


export const IndexContentDisplay = ({ files, onClickFolder }) => {
    const [isExpanded, toggleExpanded] = useState(true);

    // Check if files is null
    if (!files) {
        return null; // or <></> (empty fragment) depending on your preference
    }

    const handleDirectoryExpand = (evt, folderName, relpath) => {
        toggleExpanded(!isExpanded);

        if (onClickFolder) {
            onClickFolder(folderName, relpath);
        }
    }

    if (files.type === 'folder') {
        // Sort items so that folders come before files.
        // We are assuming files.json put files above folder items. This is to reverse that
        // so that folder in UI is on top of files (large number usually). This is to make
        // folder visible on top of large file list.
        const sortedItems = files.items.sort((a, b) => {
            if (a.type === 'folder' && b.type === 'file') {
                return -1; // Move folder above file
            } else if (a.type === 'file' && b.type === 'folder') {
                return 1; // Move file below folder
            }
            return 0; // Maintain the order for items of the same type
        });

        return (
            <div className="mf-folder">
                <div className="folder-title" onClick={(evt) => handleDirectoryExpand(evt, files.name,
                                                                                      files.relpath)}>
                    {files.name}
                </div>
                {
                    isExpanded && sortedItems.map((item, index) => 
                        <IndexContentDisplay 
                            key={index} 
                            files={item} 
                            onClickFolder={onClickFolder}/>)
                }
            </div>
        )
    }

    return (
        <div className="file-container">
            <div className="thumbnail">
                <a href={files.albumgrid_url}>
                    <img src={`data:image/png;base64, ${files.thumbnail}`} alt="Thumbnail" />
                </a>
            </div>
            <h3 className="file-name">{files.name}</h3>
        </div>
    )
}


export const IndexExplorerUI = ({
        loadRootDirectory,
        nextPageDirectory,
        ...props
    }) => {

    const { 
        onStartLoadDir,
        onEndLoadDir 
        } = props;
    const [dirContent, setDirContent] = useState(null);

    useEffect(() => {
        if (onStartLoadDir) {
            onStartLoadDir();
        }
        loadRootDirectoryAsync();
        if (onEndLoadDir) {
            onEndLoadDir();
        }
    }, []);

    const sortFolderContent = (content) => {
        const dirItems = content.map(obj => {
            let type = null;

            if (1 === obj.file_type) {
                type ='folder';
            } else if (2 === obj.file_type || 3 === obj.file_type) {
                type ='file';
            } else {
                // error
                console.log('error');
            }

            let item = {
                name: obj.file_title,
                relpath: obj.file_name_url_only,
                type: type,
                thumbnail: obj.thumbnail
            };
            
            if (type == 'folder') {
                item.items = [];
            }

            return item;
        });

        return dirItems
    }

    const loadRootDirectoryAsync = async () => {
        const res = await loadRootDirectory();

        if (res.status) {
            const data = JSON.parse(res.data);
            const dirItems = sortFolderContent(data.dir_files);

            let root = {
                name: 'root',
                type: 'folder',
                items: [],
                relpath: null
            }
            root.items = dirItems;
            setDirContent(root);
        } else {
            console.log('Error in loadRootDirectoryAsync.')
        }
    }

    const handleOnClickFolder = async (folderName, relpath) => {
        const res = await nextPageDirectory(relpath);

        if (res.status) {
            const data = JSON.parse(res.data);
            const dirItems = sortFolderContent(data.dir_files);

            let parentFolder = {
                name: folderName,
                type: 'folder',
                items: [],
                relpath: null
            }
            parentFolder.items = dirItems;
            setDirContent(parentFolder);
        } else {
            console.log('Error in handleOnClickFolder.')
        }
    }

    return (
        <div className="file-manager-container">
        <div className="spacing">
            <IndexContentDisplay 
                files={dirContent}
                onClickFolder={handleOnClickFolder} />
        </div>
        </div>
    );
};
