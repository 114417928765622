import {f2fBase} from '../js/base.js'
//const {dlgOk} = require('../../utils/dialog.js');
//import {dlgOk} from '../../utils/dialog.js'
import { ApiFileExplorer } from '../../comjs/filex.api/filex-api';
import {dlgOk} from '../utils/dialog.js'
import $ from 'jquery';    // React only
//import './directory-view.css';

/**
 * Mixed photo and video grid view
 * 
 * Input:
 *    g_data: Directory info to show the directory medias
 *    file_info: Current playing media. This file is used to find similar medias.
 */
export class dirShowFiles extends f2fBase {
  //static get CSS() {return import('../../css/directory-view.css');}
  static get CSS() {
    return {
      name: './directory-view.css',
      import: import('./directory-view.css')
    };
  }
  static get polaroidCSS() {
    return {
      name: './polaroidalbum.css',
      import: import('./polaroidalbum.css')
    };
  }

  static get myMediaCSS() {
    return {
      name: '../my.media.main/my-media-main.css',
      import: import('../my.media.main/my-media-main.css')
    };
  }

  // TODO: kind of duplicated. Needed?
  static get CSS_SKY() {
    return {
      name: '../my.media.main/sky-list-media.css',
      import: import('../my.media.main/sky-list-media.css')
    };
  }

  constructor(g_data, $container, $SCRIPT_ROOT=null, 
              options=null, file_info, editMode=false,
              onePageView=true) {
    let defaultOptions = {
      onStartLoad: null,
      onEndLoadFirstPage: null,  // Used for inserting edit grid item. Since 1st page is loaded, insert is possible.
      onClickVideo: null,
      onClickPhoto: null,
      onClickFolder: null
    }
    const current_options = $.extend(defaultOptions, options);
    super(current_options);

    this.editMode = editMode;
    this.enablePlay = !this.editMode;
    this.onePageView = onePageView;

    // TODO: 
    const data = {'encoded_root': g_data.dir_files[0].media_root,
                  'encoded_fullpath': g_data.dir_files[0].dir_url_only}

    this.g_data = data;

    // This string contains file name and its full path. It's 2 strings seperated by /.
    // 1st part is media_root. 2nd part contains relative path and file name.
    // Server extract file name from it if needed.
    // this.file_info = file_info.split('/')[1];
    this.en_fullpath_file = file_info;

    this.file_name = g_data.dir_files[0].file_title + '.' + g_data.dir_files[0].file_ext;  // TODO: not used
    this.$SCRIPT_ROOT = $SCRIPT_ROOT;
    this.$container = $container;

    this.loadCSS(dirShowFiles.CSS)
    this.loadCSS(dirShowFiles.myMediaCSS);
    this.loadCSS(dirShowFiles.CSS_SKY);
    this.loadCSS(dirShowFiles.polaroidCSS);

    //this.$mainContent = $('#div-main-content');
    this.$dirView = $('#div-pinto-dir-view');

    // Sections.
    this.$video = null;
    this.$image = null;
    this.$folder = null;

    // Track last item in each section to be used to trigger next page load.
    this.$lastVideo = null;
    this.$lastPhoto = null;
    this.$lastFolder = null;
    this.curPage = 0;

    // Store all pages loaded in ui.
    this.pages = new Set();
    this.eventHandler();

    // It will load page 0 which is default in server side.
    this.loadFiles();

    // Append context menu to the container
    $container.append(`
      <div id="contextMenu" class="context-menu">
        <ul>
          <li id="getVideoUrl">Copy channel link</li>
        </ul>
      </div>
    `);

    // Initialize context menu
    this.initContextMenu($container);
  }

  destroy() {
    if (this.$container) {
      this.$container.empty();
    }

    this.unloadCSS(dirShowFiles.CSS);
    this.unloadCSS(dirShowFiles.myMediaCSS);
    this.unloadCSS(dirShowFiles.CSS_SKY);
    this.unloadCSS(dirShowFiles.polaroidCSS);
  }

  tmplMediaSection ({sec_title, sec_title_url}) {
    return `
      <section class="section-media-one-directory">
        <div class="div-section-title div-section-seperator">
          <a class="disable-hover">${sec_title}</a>
        </div>
        <div class="div-video-grid"></div>
        <div class="div-image-grid pinto-grid"></div>
        <div class="div-folder-grid"></div>
      </section>
    `;
  }

  tmpl_Folder ({album_url, album_name, album_image}) {
    return `
      <div class='album-cell'>
        <div class="album polaroids-album-cell">
          <a href="${album_url}" title="${album_name}">
            <img src="/${album_image}">
          </a>
        </div>
      </div>   
    `;
  }

  tmpl_Photo({albumgrid_url, thumbnail}) {
    return `
      <div class="div-thumbnail">
        <a href="${albumgrid_url}">
          <img src="data:image/png;base64, ${thumbnail}">
        </a>
      </div>
    `;
  }

  tmpl_Video({playmovie_url, movie_title, clipthumbnail}) {
    return `
      <div class="video-cell">
        <div class="movie">
          <a href="${playmovie_url}">
            <img class="img-thumbnail-video" src="data:image/png;base64, ${clipthumbnail}">
          </a>
        </div>
        <div class="div-play-button">
          <a href="${playmovie_url}">
            <div class="play-icon"></div>
          </a>
        </div>
        <div class="movie-contents">
          <a class="a-content" href="${playmovie_url}">
          ${movie_title}
          </a>
        </div>
      </div>
    `;
  }

  pageStartLoad(page) {

  }

  isPageLoadStarted(page) {

  }

  pageEndLoad(page) {

  }

  allLoaded() {
    if (this.curPage == -1) {
      return true;
    }

    return false;
  }

  eventHandler() {
    $(window).on('scroll', () => {
      // Display root mode. No need to scroll
      if (!(this.g_data.encoded_fullpath && this.g_data.encoded_root)) {
        return;
      }

      if (!this.allLoaded() && this.hasNextPage() && !this.pages.has(this.curPage + 1)) {
        this.appendNextPage(this.curPage + 1);
      }
    });
  }

  getMediaCpUri(mediaInfo, $this) {
    // Identify what kind of resource. local video - lv; youtube - yv.
    let uri = `/lv/`;
    uri += mediaInfo.dir_files[0].media_cp_uri;
    return uri;
  }

  // Initialize context menu
  initContextMenu($container) {
    const self = this; // Store reference to the class instance

    $(document).ready(function() {
      let currentVideoUrl = '';
    
      // Handle right-click on video thumbnails
      $container.on('contextmenu', '.video-cell', function(event) {
        event.preventDefault();

        // 'dir-info' is dynamically added to <div class="video-cell">
        const mediaInfo = $(this).data('dir-info');
    
        // If clicked on edit cell.
        if (!mediaInfo) return false;

        currentVideoUrl = self.getMediaCpUri(mediaInfo, $(this));

        // Calculate the position of the context menu
        const menuWidth = $('#contextMenu').outerWidth();
        const menuHeight = $('#contextMenu').outerHeight();
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
    
        //let left = event.clientX + $(document).scrollLeft(); // Adjust for horizontal scroll
        //let top = event.clientY + $(document).scrollTop(); // Adjust for vertical scroll
    
        // let left = event.clientX; // Adjust for horizontal scroll
        // let top = event.clientY; // Adjust for vertical scroll

        // Use event.pageX and event.pageY to get the coordinates relative to the document
        let left = event.pageX;
        let top = event.pageY;
        
        // Deduct the height of the first page (100vh) if it's 2-page view. Player on top that
        // takes 100vh.
        if (!self.onePageView) {
          top -= windowHeight;
        }

        // Adjust position if menu goes beyond window boundaries
        if (left + menuWidth > windowWidth) {
          left -= menuWidth;
        }
        if (top + menuHeight > windowHeight) {
          top -= menuHeight;
        }
    
        // Show the context menu at the calculated position
        $('#contextMenu').css({
          display: 'block', // Make the menu visible
          left: left, // Set horizontal position
          top: top // Set vertical position
        });
        // alert(`top: ${top}, left: ${left}`);
        return false; // Prevent the default context menu
      });
    
      // Hide the context menu on click outside
      $(document).on('click', function(event) {
        if (!$(event.target).closest('#contextMenu').length) {
          $('#contextMenu').hide(); // Hide the menu
        }
      });
    
      // Handle click on "Get Video URL" menu item
      $('#getVideoUrl').on('click', function() {
        // Clear the clipboard by writing an empty string first
        navigator.clipboard.writeText('').then(function() {
          // Now write the new video URL to the clipboard
          navigator.clipboard.writeText(currentVideoUrl).then(function() {
            // alert('Video URL copied to clipboard: ' + currentVideoUrl);
            $('#contextMenu').hide(); // Hide the context menu
            currentVideoUrl = '';
          }).catch(function(err) {
            console.error('Could not copy text: ', err);
          });
        }).catch(function(err) {
          console.error('Could not copy text: ', err);
        });
      });
    });
  }

  createView(data) {
    let files = data.dir_files;
    let dirInfo = data.dir_info;

    let breadCrumbMenu = null;
    let menuLength = null;
    let breadcrumbMenu = null;
    if (data.breadcrumb) {
      breadCrumbMenu = data.breadcrumb[1];
      menuLength = Object.keys(breadCrumbMenu).length;
      breadcrumbMenu = Object.keys(breadCrumbMenu)[menuLength - 1];
    } 

    // Display dir title.
    // Add new section
    let insertItem = null;
    let item = [];
    item.push({
      //sec_title: dirInfo['dir_name_orig'],
      sec_title: (data.breadcrumb) ? data.breadcrumb[0] : '',
      sec_title_url: dirInfo['file_name_url']
    });
    insertItem = item.map(this.tmplMediaSection).join('');
    const $sec = this.insertChildBottom(this.$container, insertItem);
    const $secTitle = $sec.find('.div-section-title');

    // Remove separator
    $secTitle.removeClass('div-section-seperator');

    this.$video = $sec.find('.div-video-grid');
    this.$image = $sec.find('.div-image-grid');
    this.$folder = $sec.find('.div-folder-grid');

    const retVal = this.appendViewItems(data);

    if (typeof this.options.onEndLoadFirstPage=== 'function') {
      this.options.onEndLoadFirstPage();
    }

    return retVal;
  }

  appendViewItems(data) {
    let files = data.dir_files;
    let dirInfo = data.dir_info;

    // Create video and photo sections.
    let visibleVideo = true;
    let visiblePhoto = true;
    let visibleFolder = true;

    // We just want to sort folder name.
    // Separate files into two arrays based on file_type
    const folderList = files.filter(file => file.file_type === 1);
    const otherFiles = files.filter(file => file.file_type !== 1);

    // Descent order by default.
    // Unicode working?
    // https://chatgpt.com/c/ec6a4f61-3ee0-4bd0-a887-34fd3e12f3e6
    folderList.sort((a, b) => a.file_title.localeCompare(b.file_title));

    // Combine the arrays.
    files = [...otherFiles, ...folderList];

    for (let file of files) {
      if (!(file.file_type == 2 || file.file_type == 3 || file.file_type == 1 )) {
        continue;
      }

      let insertItem = null;
      let item = [];
      // Photo
      if (file.file_type == 2 ) {
        item.push({
          albumgrid_url: file['file_name_url'],
          thumbnail: file['thumbnail']
        });
        insertItem = item.map(this.tmpl_Photo).join('');
        this.$lastPhoto = this.insertChildBottom(this.$image, insertItem);

        const $ele = this.$lastPhoto;
        if (this.options.onClickPhoto) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // dirInfo is relpath. 'file' is media file and has no relpath info.
          let fileObj = {
            relpath: dirInfo['dir_name_url_only'],  // not used
            dir_files: []
          };
          fileObj.dir_files.push(file);

          // Name 'dir-info' not accurate. It's media info.
          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickVideo=== 'function') {
                // Title is directory name of the photo.
                //const $parent = $(evt.target).parent().parent().parent();
                //const title = $parent.find('.movie-contents a').html();

                // Fire the event to parent.
                //this.options.onClickPhoto(evt, "title");
                this.options.onClickPhoto(evt);
              }
          })
        }
      } else if (file.file_type == 3) {
        // Video
        item.push({
          playmovie_url: file['file_name_url'],
          movie_title: file['file_title'],
          clipthumbnail: file['thumbnail']
        });
        insertItem = item.map(this.tmpl_Video).join('');
        this.$lastVideo = this.insertChildBottom(this.$video, insertItem);

        const $ele = this.$lastVideo;
        if (this.options.onClickVideo) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // Store the object, so that click even is able to retrieve it
          // which is used to get files under the directory.
          let fileObj = {
            relpath: dirInfo['dir_name_url_only'], // not used.
            dir_files: []
          };
          fileObj.dir_files.push(file);
          $ele.data('dir-info', fileObj);

          // 3 href handlers are added.
          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickVideo=== 'function') {
                let $item = $(evt.target);
                let foundItem = true;
                let $parent = null;

                if ($item.hasClass('img-thumbnail-video') || 
                    $item.hasClass('play-icon') || $item.hasClass('div-play-button')) {
                  $parent = $item.parent().parent().parent();
                } else if ($item.hasClass('a-content')) {
                  $parent = $item.parent().parent();
                } else {
                  foundItem = false;
                }

                if (foundItem) {
                  const title = $parent.find('.movie-contents a').html();
                  // Fire the event to parent.
                  this.options.onClickVideo(evt, title);
                } else {
                  console.debug('Error: clicked movie is not found!')
                }
              }
          })
        }
      } else if (file.file_type == 1) {
        // Folder
        item.push({
          album_url: file['file_name_url'],
          album_name: file['file_title'],
          album_image: file['thumbnail']
        });
        insertItem = item.map(this.tmpl_Folder).join('');
        this.$lastFolder = this.insertChildBottom(this.$folder, insertItem);

        const $ele = this.$lastFolder;
        if (this.options.onClickFolder) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // Store the object, so that click even is able to retrieve it
          // which is used to get files under the directory.
          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);

          // file.dir_url_only will be used to access folder's media file in fetch() call.
          // But in get_file_thumbnails(), this parameter is for video and photo type,
          // which contains clicked video, photo file parant relative (relative to media_root dir)
          // directory. 
          // In order for this class to work for access folder, which is different from clicked
          // photo, video file, it changed to folder, which contains relative path and folder name.
          file.dir_url_only = file.file_name_url_only;

          $ele.data('dir-info', fileObj);

          $link.unbind('mouseenter');  

          // Prevent status bar to show at bottom, which hide the menu bar.
          // $link.on('mouseenter', (evt) => {
          //   evt.preventDefault();
            
          //   //$(evt.currentTarget).css('text-decoration', 'none'); // not work
          //   $(evt.currentTarget).css('pointer-events', 'none'); // work but flashing
          //   //$(this).addClass('no-pointer-events');  // not work
          //   //$(evt.currentTarget).addClass('no-pointer-events');  // work but flashing
          // });
          // $link.on('mouseleave', (evt) => {
          //   evt.preventDefault();

          //   //$(evt.currentTarget).css('text-decoration', '');
          //   $(evt.currentTarget).css('text-decoration', 'auto');
          //   //$(evt.currentTarget).css('pointer-events', 'auto');
          //   //$(this).removeClass('no-pointer-events');  
          //   //$(evt.currentTarget).removeClass('no-pointer-events');
          // });

          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickFolder=== 'function') {
                // Get title
                const $parent = $(evt.target).parent().parent().parent();
                const title = $parent.find('.album a').attr('title');

                // Fire the event to parent.
                this.options.onClickFolder(evt, title);
              }
          });
        }
      }
    }
    
    let $image = this.$image;
    $(document).ready(function() {
      // pinto needs to calculate each image item size to figure out container height.
      // This is to make sure all images is loaded. Otherwise, the container height may
      // not be correct.
      let picWidth = 120;
      $image.pinto({
        itemWidth: picWidth,
        marginX: 6,
        marginY: 6,
        align: 'center',
        animate: true,
        autoResize: true,
        resizeDelay: 50,
        invisibleUntilLayoutDone: true,
        eventLayoutDone: () => {
          $('#image-gallery').css('visibility', 'visible'); // Show the gallery after layout.
        },
      });
    })

    return this.hasNextPage();
  }

  hasNextPage() {
    // Determine if there is next page.
    let nextPage = false;
    if (this.isJqueryObj(this.$lastVideo) && this.isElementInViewport(this.$lastVideo[0])) {
      nextPage = true;
    } else if (this.isJqueryObj(this.$lastPhoto) && this.isElementInViewport(this.$lastPhoto[0])) {
      nextPage = true;
    } else if (this.isJqueryObj(this.$lastFolder) && this.isElementInViewport(this.$lastFolder[0])) {
      nextPage = true;
    }

    return nextPage;
  }

  appendNextPage(page) {
    this.wait.startWait();

    this.pages.add(page);

    // Attempt to load page: page.
    //let url = '/api_myfilex/dirfiles/' + this.g_data.encoded_fullpath + '/' + page.toString();
    let url = '/api_myfilex/directoryfiles/' + this.g_data.encoded_fullpath + '/' + page.toString();

    fetch(url, {
      method: 'GET',
      credentials: 'include', // Include cookies with the request
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
    })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let resp = await response.json();

      let files = null;
      if (resp.result.status) {
        let data = JSON.parse(resp.result.data);
        this.curPage = data.page;
        // console.log('response page: ' + this.curPage);
        if (!this.allLoaded()) {
          // Make sure it's not loaded yet.
          if (this.appendViewItems(data) && !this.pages.has(this.curPage + 1)) {
            this.appendNextPage(this.curPage + 1);
          }
        }
      } else {
        console.log(response.result.error);
        let dlg = new dlgOk('Error',
            'Load media files appendNextPage failed! /api/dirfiles/ ' + 'Error: ' + response.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      console.error('Error:', error);
    })
    .finally(() => {
      this.wait.stopWait();
    });  
  }

  initialize() {
    this.wait.startWait();

    // Track page at the begining of the request, as UI other events may trigger in parallel.
    // this.curPage tracks loaded current page.
    // Therefore, this set combined with this.curPage are used to prevent multiple loading of a page.
    this.pages.add(0);

    /*
    //let urlTest = '/api_myfilex/dirfilesroot';  // ok
    
    //let urlTest = '/api_myfilex/dirfilestest/' + this.g_data.encoded_fullpath;  // ok
    let urlTest = '/api_myfilex/directoryfiles/' + this.g_data.encoded_fullpath;
    //let urlTest = '/api_myfilex/dirfilesbrief/' + this.g_data.encoded_fullpath;  // ok
    const dataFetcher = new ApiFileExplorer();
    let testRet = dataFetcher.test(urlTest);

    testRet.then((data) => {
      console.log(data);
    })
    */


    
    let url = '/api_myfilex/directoryfiles/' + this.g_data.encoded_fullpath;
    // let urlSimilar = '/api_myfilex/similarfiles/' + this.g_data.encoded_fullpath + '/' + this.file_info;
    let urlSimilar = '/api_myfilex/similarfiles/' + this.en_fullpath_file;

    const allPromises = [
      fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      }),
      fetch(urlSimilar, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      })
    ];

    Promise.all(allPromises)
    .then(async (responses) => {
      if (!responses[0].ok) {
        throw new Error('Directory files response is not ok');
      }
      if (!responses[1].ok) {
        throw new Error('Similar files response is not ok');
      }

      const resp = await responses[0].json();
      const respSimilar = await responses[1].json();
      
      if (resp.result.status && respSimilar.result.status) {
        let retDirectory = JSON.parse(resp.result.data);
        let dirFiles = retDirectory.dir_files;
        let dirInfo = retDirectory.dir_info;
        let similarFiles = respSimilar.result.data;

        // Remove similar files from directory file list.
        let filteredDirFiles = dirFiles.filter(item2 => {
          return !similarFiles.some(item1 => item1.id === item2.id);
        });

        // Merge similar file into directory files, and make similar files on top of array.
        let mergedList = similarFiles.concat(filteredDirFiles);
        retDirectory.dir_files = mergedList;

        // Display the result.
        this.curPage = retDirectory.page;
        if (this.createView(retDirectory)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else if (resp.result.status) {
        let retDirectory = JSON.parse(resp.result.data);
        this.curPage = retDirectory.page;
        if (this.createView(retDirectory)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else {
        console.log(responses.result.error);
        let dlg = new dlgOk('Error',
            'Load media files loadFiles() failed! /api/dirfiles/ ' + 'Error: ' + responses.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      // If any of the promises fail, this catch block will execute
      console.error('Error:', error);
    })
    .finally(() => {
      this.wait.stopWait();
    });  
    

    /*
    fetch(url, {
      method: 'GET',
      credentials: 'include', // Include cookies with the request
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
    })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let resp = await response.json();

      let files = null;
      if (resp.result.status) {
        let data = JSON.parse(resp.result.data);
        this.curPage = data.page;

        if (this.createView(data)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else {
        console.log(response.result.error);
        let dlg = new dlgOk('Error',
            'Load media files loadFiles() failed! /api/dirfiles/ ' + 'Error: ' + response.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      console.error('Error:', error);
    })
    .finally(() => {
      this.wait.stopWait();
    });
    */
  }

  // Overwrite it as it's called in base class constructor.
  loadFiles() {
    this.initialize();
  }
}
