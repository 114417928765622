// ConfigContext.js
import React, { createContext, useEffect, useState, useContext } from 'react';

import { ApiFileX } from '../../comjs/filex.api/filex-api';

const ConfigContext = createContext();

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

const defaultConfig = {
  // Default configuration values
  theme: 'light',
  language: 'en',
  ytEditMode: false, // Default values for your config
  // other default settings
};

export const ConfigProvider = ({ children }) => {
  
  const [config, setConfig] = useState(() => {
    const storedConfig = localStorage.getItem('appConfig');
    return storedConfig ? JSON.parse(storedConfig) : defaultConfig;
  });
  
  useEffect(() => {
    localStorage.setItem('appConfig', JSON.stringify(config));
  }, [config]);
  

  // const [config, setConfig] = useState({
  //   // Default configuration values
  //   theme: 'light',
  //   language: 'en',
  //   // Add other configuration parameters here
  //   ytEditMode: false,
  // });

  //const [config, setConfig] = useState(null); 

  // Load state from local storage on initialization
  useEffect(() => {
    const storedConfig = localStorage.getItem('appConfig');
    if (storedConfig) {
      setConfig(JSON.parse(storedConfig));
    }
  }, []);

  // // Update local storage whenever config state changes
  // useEffect(() => {
  //   localStorage.setItem('appConfig', JSON.stringify(config));
  // }, [config]);

  const updateConfig = (newConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  };

  // useEffect(() => {
  //   const api = new ApiFileX();
  //   api.getConfig().then(async (retData) => {
  //     if (retData && retData.status) {
  //       setConfig(retData.data);
  //     } else {

  //     }
  //   });
  // }, []);

  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};
