import React from 'react';
import './ShiningBall.css'; // Create this CSS file in the same directory


const ShiningBall = ({color, onClick, isVisible=true}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  
  const ballClass = `shining-ball ${color === 'green' ? 'green' : 'orange'}`;
  const animationName = `${color}-shine`;

  return (
    <>
      {isVisible && (
        <div className={ballClass} onClick={handleClick} style={{ animationName }}></div>
      )}
    </>
  );
};

export default ShiningBall;
