// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curved-text-container {
  position: absolute;
  bottom: 30px;
  right: 30px;
  shape-outside: polygon(100% 0, 100% 100%, 0% 100%, 0 70%); /* Customize the shape */
  padding: 15px;
  font-size: 20px;
  font-family: 'Arial', sans-serif;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ImageCarousel/LoginBg.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,yDAAyD,EAAE,wBAAwB;EACnF,aAAa;EACb,eAAe;EACf,gCAAgC;EAChC,YAAY;EACZ,oCAAoC;EACpC,mBAAmB;AACrB","sourcesContent":[".curved-text-container {\n  position: absolute;\n  bottom: 30px;\n  right: 30px;\n  shape-outside: polygon(100% 0, 100% 100%, 0% 100%, 0 70%); /* Customize the shape */\n  padding: 15px;\n  font-size: 20px;\n  font-family: 'Arial', sans-serif;\n  color: white;\n  background-color: rgba(0, 0, 0, 0.4);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
