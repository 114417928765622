/*

- simple file manger. good

https://dev.to/siddharthvenkatesh/creating-a-simple-file-explorer-with-recursive-components-in-react-458h
https://github.com/sidv93/react-recursive-component

- guy shows up from left
https://www.joshwcomeau.com/react/file-structure/

- real file manager. 
https://github.com/joni2back/react-filemanager

*/
import React, { useState, useEffect } from 'react';

import { ApiFileExplorer } from '../../comjs/filex.api/filex-api';
import { IndexExplorerUI } from './IndexExplorerUI';

/**
 * Use HOC to combine IndexExplorerUI and index API accesss. HOC is used to separate UI 
 * and data access.
 * 
 * @param {*} child 
 * @returns 
 */
export const FileExplorerHoc = (child) => {

  // Add index data access through API. props is passed in from child.
  const IndexExplorerData = (props) => {
    const dataFetcher = new ApiFileExplorer();
    const [explorerData, setExplorerData] = useState([]);

    const loadRootDirectory = async () => {
      const result = await dataFetcher.loadRootDirectory();
      setExplorerData(result);
      return result;
    };

    const nextPageDirectory = async (encodePath) => {
      const result = await dataFetcher.nextPageDirectory(encodePath);
      setExplorerData(result);
      return result;
    };

    return <child
        loadRootDirectory={loadRootDirectory}
        nextPageDirectory={nextPageDirectory} 
        explorerData={explorerData}
        {...props}  // Make sure to pass down all other props
    />;
  }

  return IndexExplorerData;
}


export const FileExplorer = () => {

  const FileExplorerFull = FileExplorerHoc(IndexExplorerUI);

  return (
    <>
      <FileExplorerFull />
    </>
  );
}
