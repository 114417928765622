import React, { useState, useCallback, useEffect, useMemo } from 'react';
import TwoPageBase from '../TwoPages/TwoPageBase';
import './TwoPageAlign.css'


const Player = () => {
  return (
    <div className="player">
      This is the Player
    </div>
  );
};


const Content = () => {
  return (
    <div className="content">
      This is the Content
    </div>
  );
};


const TwoPageAlign = ({

}) => {


  return (
    <TwoPageBase>
      <Player />
      <Content />
    </TwoPageBase>
  );
}

export default TwoPageAlign;