// Tooltip.jsx
import React, { useState, useRef } from 'react';
import './Tooltip.css';

const Tooltip = ({ children, text }) => {
  const [show, setShow] = useState(false);
  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setShow(true);
    }, 2000); // Show tooltip after 2 seconds
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current);
    setShow(false);
  };

  return (
    <div 
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {show && <div className="tooltip-text">{text}</div>}
    </div>
  );
};

export default Tooltip;
