import React from 'react';
import PropTypes from 'prop-types';
import './PlaylistTitle.css';

const PlaylistTitle = ({ title, alignment = 'left' }) => {
  return (
    <div className={`playlist-title ${alignment}`}>
      <span>{title}</span>
      <img src="/static/img/channel-play.webp" alt="Play" className="play-icon" />
    </div>
  );
};

PlaylistTitle.propTypes = {
  title: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['left', 'middle']).isRequired,
};

export default PlaylistTitle;
