/**
 * TODO: it's same copy.
 * babel to solve it.
 */
import $ from 'jquery';    // React only
import {f2fBase} from '../js/base.js'


//export class dlgOk extends loiBase {
export class dlgOk extends f2fBase {
  constructor(title, content, options=null) {
    super(options);

    this.tmplDialog = ({title, content}) => `
    <div class="modal fade" id="div-dlg-ok" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">${title}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ${content}
          </div>
          <div class="modal-footer">
            <button type="button" id="button-ok" class="btn btn-primary">Close</button>
          </div>
        </div>
      </div>
    </div>
    `;

    // Dynamically create dialog.
    this.$dlgContainer = this.insertChildBottom($('body'),
        $('<div id="div-dialog-okcancel-container"></div>'));

    let mapData = this.setDataToTmpl({
      title: title,
      content: content
    }, this.tmplDialog);      
    
    this.$dlgContainer.append(mapData);
    this.$dialog = this.$dlgContainer.find('#div-dlg-ok');
    this.$ok = this.$dialog.find('#button-ok');
  }

  close() {
    this.$dialog.modal('hide');
    // Not able to remove backdrop with bootstrap. Remove it.
    let $backDrop = this.$dlgContainer.nextAll('.modal-backdrop:first');
    $backDrop.remove();
    // Delete container.
    this.$dlgContainer.remove();
  }

  handlerFunctions(resolve) {
    this.$ok.off('click');
    this.$ok.click((evt) => {
      evt.preventDefault();
      resolve('ok');
      this.close();
      return;
    });
  }

  open() {
    let ret = new Promise(resolve => {
      this.handlerFunctions(resolve);
    });

    // Popup dialog.
    this.$dialog.modal({
      backdrop: 'static',
      keyboard: false
    });

    return ret
  }
}


export class dlgOkCancel extends f2fBase {
  constructor(title, content, options=null) {
    super(options);

    this.tmplDialog = ({title, content}) => `
    <div class="modal fade" id="div-dlg-okcancel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">${title}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ${content}
          </div>
          <div class="modal-footer">
            <button type="button" id="button-cancel" class="btn btn-default" data-dismiss="modal">Cancel</button>
            <button type="button" id="button-ok" class="btn btn-primary">OK</button>
          </div>
        </div>
      </div>
    </div>
    `;

    // Dynamically create dialog.
    this.$dlgContainer = this.insertChildBottom($('body'),
        $('<div id="div-dialog-okcancel-container"></div>'));

    let mapData = this.setDataToTmpl({
      title: title,
      content: content
    }, this.tmplDialog);      
    
    this.$dlgContainer.append(mapData);
    this.$dialog = this.$dlgContainer.find('#div-dlg-okcancel');
    this.$ok = this.$dialog.find('#button-ok');
    this.$cancel = this.$dialog.find('#button-cancel');
  }

  close() {
    this.$dialog.modal('hide');
    // Not able to remove backdrop with bootstrap. Remove it.
    let $backDrop = this.$dlgContainer.nextAll('.modal-backdrop:first');
    $backDrop.remove();
    // Delete container.
    this.$dlgContainer.remove();
  }

  handlerFunctions(resolve) {
    this.$cancel.off('click');
    this.$cancel.click((evt) => {
      evt.preventDefault();
      resolve('cancel');
      this.close();
      return;
    });

    this.$ok.off('click');
    this.$ok.click((evt) => {
      evt.preventDefault();
      resolve('ok');
      this.close();
      return;
    });
  }

  open() {
    let ret = new Promise(resolve => {
      this.handlerFunctions(resolve);
    });

    // Popup dialog.
    this.$dialog.modal({
      backdrop: 'static',
      keyboard: false
    });

    return ret
  }
}
