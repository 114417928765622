export class APIFileXBase {
  constructor() {
  }
  
  removeFileExtension(fileName) {
    // Find the position of the last dot in the file name
    const lastDotPosition = fileName.lastIndexOf('.');
  
    // If there is no dot, return the file name as is
    if (lastDotPosition === -1) return fileName;
  
    // Return the substring from the beginning to the last dot position
    return fileName.substring(0, lastDotPosition);
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  // Has problem with multiple space
  // https://chatgpt.com/c/e4cf2c8b-7af8-4a98-85b5-75854aac2720
  // encodeUrlToBase64(url) {
  //   // First, URI encode to handle special characters properly
  //   let uriEncoded = encodeURIComponent(url);
    
  //   // Convert the URI-encoded string to a Base64 string
  //   let base64Encoded = window.btoa(uriEncoded);

  //   // Replace '+' and '/' with URL-safe characters '-' and '_'
  //   let base64UrlSafe = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Remove '=' padding if present

  //   return base64UrlSafe;
  // }
  getRightmostSubdir(path) {
    // Handle empty path
    if (!path) return '';
  
    // Split the path by '/'
    const parts = path.split('/');
  
    // Filter out empty parts to handle leading and trailing slashes
    const filteredParts = parts.filter(part => part !== '');
  
    // If there are no filtered parts, return an empty string
    if (filteredParts.length === 0) {
      return '';
    }
  
    // Return the last part of the array
    return filteredParts[filteredParts.length - 1];
  }

  // Works for encoding unicode and so many cases, but it does not work if there is / for url.
  // For string with '/', after encoding, it still has '/'.
  // Server side decoding is decode_url_safe_base64(). 
  // Server side: f2furi.decode_name() work for this encoding?
  encodeUrlToBase64(str) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }

  decodeBase64ToUrl(str) {
    // Server side is base64 urlsafe encoding. 2 characters are treated specially.
    // https://stackoverflow.com/questions/28100601/decode-url-safe-base64-in-javascript-browser-side
    let data = str.replace(/_/g, '/').replace(/-/g, '+')
    return decodeURIComponent(escape(window.atob(data)));
  }

  // This is to handle '/', such as youtube link. Server decoding is: decode_url_safe_base64
  // Not sure if f2furi.decode_name works
  encodeUrlToBase64_UrlSafe(url) {
    // Encode the filename to Base64
    const base64Encoded = btoa(unescape(encodeURIComponent(url)));
    // Replace '+' and '/' with URL-safe characters '-' and '_'
    const urlSafeBase64 = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Remove '=' padding if present
    return urlSafeBase64;
  }

  decodeBase64ToUrl_UrlSave(base64UrlSafe) {
    // Replace URL-safe characters back to Base64 characters
    let base64 = base64UrlSafe.replace(/-/g, '+').replace(/_/g, '/');
    // Add padding if necessary
    const padding = 4 - (base64.length % 4);
    if (padding !== 4) {
      base64 += '='.repeat(padding);
    }
    // Decode the Base64 string
    const decodedString = decodeURIComponent(escape(atob(base64)));
    return decodedString;
  }

  /* Not work for unicode
  encodeUrlToBase64(url) {
    // Replace non-breaking spaces with regular spaces
    let sanitizedUrl = url.replace(/\u00A0/g, ' ');

    // First, URI encode to handle special characters properly
    let uriEncoded = encodeURIComponent(sanitizedUrl);

    // Convert the URI-encoded string to a Base64 string
    let base64Encoded = window.btoa(uriEncoded);

    // Replace '+' and '/' with URL-safe characters '-' and '_'
    let base64UrlSafe = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Remove '=' padding if present

    return base64UrlSafe;
  }


  decodeBase64ToUrl(base64UrlSafe) {
    if (!base64UrlSafe) {
      return null;
      // throw new Error('Input string is null or undefined');
    }
  
    // Replace URL-safe characters '-' and '_' back to '+' and '/'
    let base64Encoded = base64UrlSafe.replace(/-/g, '+').replace(/_/g, '/');
  
    // Add padding back to the Base64 string if necessary
    switch (base64Encoded.length % 4) {
      case 2:
        base64Encoded += '==';
        break;
      case 3:
        base64Encoded += '=';
        break;
    }
  
    // Decode the Base64 string back to a URI-encoded string
    let uriEncoded = window.atob(base64Encoded);
  
    // Decode the URI-encoded string back to the original URL
    let originalUrl = decodeURIComponent(uriEncoded);
  
    return originalUrl;
  }
  */
  /* Not work for unicode... seems. Above, same problem
  encodeUrlToBase64(url) {
    // Replace non-breaking spaces with regular spaces
    let sanitizedUrl = url.replace(/\u00A0/g, ' ');
  
    // First, URI encode to handle special characters properly
    let uriEncoded = encodeURIComponent(sanitizedUrl);
  
    // Convert the URI-encoded string to a Base64 string
    let base64Encoded = window.btoa(uriEncoded);
  
    // Replace '+' and '/' with URL-safe characters '-' and '_'
    let base64UrlSafe = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Remove '=' padding if present
  
    return base64UrlSafe;
  }
  
  decodeBase64ToUrl(base64UrlSafe) {
    // Replace URL-safe characters back to Base64 characters
    let base64Encoded = base64UrlSafe.replace(/-/g, '+').replace(/_/g, '/');

    // Add padding if needed
    while (base64Encoded.length % 4 !== 0) {
      base64Encoded += '=';
    }

    // Decode Base64 string to URI-encoded string
    let uriEncoded = window.atob(base64Encoded);

    // Decode URI-encoded string to original URL
    let decodedUrl = decodeURIComponent(uriEncoded);

    return decodedUrl;
  }
  */

  async fetchPostDataBase(url, data) {
    let retData = null;
    try {
      const response = await fetch((url), {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',  // not sure if needed.
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        let data = await response.json();
        retData = data.result;
      } else if (!response.ok && response.statusText === 'UNAUTHORIZED') {
        // Store the failed URL in local storage, so that this url will be accessed after successful login.
        localStorage.setItem('failedUrl', window.location.href);

        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      // TODO: improve error handling.
      retData = null; 
      console.log(error.message);
    }

    return retData;
  }

  async fetchDataBase(url) {
    let retData = null;
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        retData = data.result;
      } else if (!response.ok && response.statusText === 'UNAUTHORIZED') {
        // Store the failed URL in local storage, so that this url will be accessed after successful login.
        localStorage.setItem('failedUrl', window.location.href);

        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      // TODO: improve error handling.
      retData = null;
      console.log(error.message);
    }
  
    return retData;
  }
}


// export const apiFileX = new ApiFileX();
export class ApiFileExplorer extends APIFileXBase{
  constructor() {
    super();

    this.directoryHistory = new Map();

    // Store all pages loaded in ui + current page for each dir (relpath encoded)
    this.pages = new Set();
  }

  trackDirectory(visitedDir) {
    // Check if the directory is in the history map
    if (!this.directoryHistory.has(visitedDir)) {
      // If not, initialize the history for the directory
      this.directoryHistory.set(visitedDir, 0);

      return 0;
    }

    let curPage = this.directoryHistory.get(visitedDir);
    this.directoryHistory.set(visitedDir, curPage++);
  }

  getCurrentDirectoryPage(visitedDir) {
    if (!this.directoryHistory.has(visitedDir)) {
      return -1;
    }

    return this.directoryHistory.get(visitedDir);
  }

  async test(url) {
    return await this.fetchDataBase(url);
  }

  async loadRootDirectory() {

    //let url = '/api_myfilex/dirfiles/0';
    let url = '/api_myfilex/directoryfiles/0';
    //let url = '/api_myfilex/dirfilesroot';
    const ret = await this.fetchDataBase(url);

    // Check the condition
    if (ret.status == true) {
      this.trackDirectory('/');
    } 

    return ret;
  }

  async nextPageDirectory(encoded_fullpath) {
    // Get dir current page.
    let dirCurPage = 0;
    if (this.directoryHistory.has(encoded_fullpath)) {
      dirCurPage = this.directoryHistory.get(encoded_fullpath);
    }
    
    //let url = '/api_myfilex/dirfiles/' + encoded_fullpath + '/' + dirCurPage.toString();
    let url = '/api_myfilex/directoryfiles/' + encoded_fullpath + '/' + dirCurPage.toString();
    const ret = await this.fetchDataBase(url);

    // Check the condition
    if (ret && ret.status) {
      this.trackDirectory(encoded_fullpath);
    } 

    return ret;
  }
}

export class ApiFileX extends APIFileXBase{
  constructor() {
    super();
  }

  async getConfig() {
    const url = `/api_ctx/get_config`;
    return await this.fetchDataBase(url);
  }

  async updateConfig(newConfig) {
    const url = `/api_ctx/update_config`;
    return await this.fetchPostDataBase(url, newConfig);
  }

  redirectToCurrentDomain() {
    const currentDomain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    //window.location.href = currentDomain;
    // window.location.replace is used instead of window.location.href. 
    // This method does not create a new entry in the browser's history.

    // TODO: add dialog saying about to go login since login expired.
    window.location.replace(currentDomain);
  }

  /************* Login *************/
  async isAdmin(username) {
    const url = `/api_login/is_admin`;
    return await this.fetchPostDataBase(url, {
      username: username
    });
  }

  async isProduction() {
    const url = `/api_login/is_prod`;
    return await this.fetchDataBase(url);
  }

  /************* Auto play channel *************/
  async getAutoPlaylistConfig() {
    const url = `/api_chnl/getautoplaylistconfig`;
    return await this.fetchDataBase(url);
  }

  async setAutoPlaylistConfig(updateConfig) {
    const url = `/api_chnl/getautoplaylistconfig`;
    return await this.fetchPostDataBase(url, updateConfig);
  }

  async getAutoPlaylists() {
    const url = `/api_chnl/getautoplaylists`;
    return await this.fetchDataBase(url);
  }
  
  async getAutoPlaylists() {
    const url = `/api_chnl/getautoplaylists`;
    return await this.fetchDataBase(url);
  }

  /************* Channel API ***************/
  async setChannelRandomState(chanFileName, random) {
    const url = `/api_chnl/setchannelrandomstate`;
    return await this.fetchPostDataBase(url,
      {file_name: chanFileName, 
       random: random });
  }

  async getChannelRandomState(chanFileName) {
    const url = `/api_chnl/getchannelrandomstate`;
    return await this.fetchPostDataBase(url, {file_name: chanFileName});
  }
  
  async setAutoPlaylistConfig(autoChannels) {
    const url = `/api_chnl/setautoplaylistconfig`;
    return await this.fetchPostDataBase(url, {
      autoChannels: autoChannels
    });
  }

  async createPlaylist(newName) {
    const url = `/api_chnl/newchannel/${newName}`;
    return await this.fetchDataBase(url);
  }
  
  async getChannelPlaylist(fileName) {
    const url = `/api_chnl/getchannelplaylist`;
    return await this.fetchPostDataBase(url, { file_name: fileName });
  }

  async updatePlaylist(fileName, mediaList) {
    const url = `/api_chnl/updatechannellist`;
    return await this.fetchPostDataBase(url, { file_name: fileName, media_list: mediaList });
  }

  async updateChannelName(fileName, newName) {
    const url = `/api_chnl/updatechannelname`;
    return await this.fetchPostDataBase(url, { file_name: fileName, new_name: newName });
  }

  async addPlaylistVideo(fileName, videoRelPath) {
    const url = `/api_chnl/addchannelvideo`;
    return await this.fetchPostDataBase(url, { file_name: fileName, video_relpath: videoRelPath });
  }

  async deletePlaylistVideo(fileName, videoRelPath) {
    const url = `/api_chnl/deletechannelvideo`;
    return await this.fetchPostDataBase(url, { file_name: fileName, video_relpath: videoRelPath });
  }

  async deletePlaylist(fileName) {
    const url = `/api_chnl/deletechannel`;
    return await this.fetchPostDataBase(url, { file_name: fileName });
  }

  async listPlaylists() {
    const url = `/api_chnl/listchannels`;
    return await this.fetchDataBase(url);
  }

  /************** Article API *******************/
  async getAllArticles(relpath) {
    let url = `/api_article/getallarticles`;

    if (relpath) {
      url = `/api_article/getallarticles/${relpath}`;
    }

    const ret = await this.fetchDataBase(url);
    return ret;
  }

  async getPastedYtVideoInfo(articleUuid, pastedMediaUrl, relpath, ytId) {
    let url = `/api_article/getpastedytvideoinfo`;
    const data = {
      'type': 1,   // hosted_yt_video
      'article_uuid': articleUuid,
      'relpath': relpath,
      'ytid': ytId,
      'pastedUrl': pastedMediaUrl
    }
    return await this.fetchPostDataBase(url, data);
  }

  async artGetArticleMedia(mediaUrl) {
    let url = `/api_article/deleteytdir/${mediaUrl}`;
    const ret = await this.fetchDataBase(mediaUrl);
    return ret;
  }

  async updateArticle(file_id, relpath, content, medias) {
    let url = `/api_article/updatearticle`;
    const data = {
      'file_id': file_id,
      'relpath': relpath,
      'article_content': content,
      'medias': medias
    }
    return await this.fetchPostDataBase(url, data);
  }

  async saveNewArticle(relpath, brief, content, medias) {
    let url = `/api_article/savenewarticle`;
    const data = {
      'relpath': relpath,
      'article_content': content,
      'brief': brief,
      'medias': medias
    }
    return await this.fetchPostDataBase(url, data);
  }

  async getArticle(relpath, file_id) {
    let url = `/api_article/getarticle`;
    const data = {
      'relpath': relpath,
      'file_id': file_id
    }
    return await this.fetchPostDataBase(url, data);
  }

  /************** Youtube API *******************/
  async ytVideoList(data) {
    let url = `/api_yt/ytvideolist`;

    return await this.fetchPostDataBase(url, data);
  }

  async deleteYtDirectory(ytDir) {
    // ytDir is sent from server is relpath, which includes diretory name and is 
    // already encoded.
    let url = `/api_yt/deleteytdir/${ytDir}`;

    const ret = await this.fetchDataBase(url);
    return ret;
  }

  async updateYtDirectory(old_dir_relpath, ytDir) {
    // ytDir is user input, not encoded.
    const encodedYtDir = this.encodeUrlToBase64(ytDir);
    let url = `/api_yt/updateytdir/${old_dir_relpath}/${encodedYtDir}`;

    const ret = await this.fetchDataBase(url);
    return ret;
  }

  async saveYtDirectory(relpath, ytDir) {
    // ytDir is user input, not encoded.
    const encodedYtDir = this.encodeUrlToBase64(ytDir);

    let url = `/api_yt/saveytdir/${encodedYtDir}`;

    if (relpath) {
      // relpath is passed from server which is already encoded.
      url = `/api_yt/saveytdir/${encodedYtDir}/${relpath}`
    }

    const ret = await this.fetchDataBase(url);
    return ret;
  }

  async saveYtVideo(YtUrl, relpath) {
    // YtUrl is user input, not encoded yet.
    // const encodedUrl = this.encodeUrlToBase64(YtUrl); // not work for '/'.
    const encodedUrl = this.encodeUrlToBase64_UrlSafe(YtUrl);
    
    let url = `/api_yt/saveyt/${encodedUrl}`;

    // relpath is from server that's encoded already.
    if (relpath) {
      url += `/${relpath}`;
    }

    const ret = await this.fetchDataBase(url);
    return ret;
  }
  
  async deleteYtVideo(YtUrl, relpath=null) {
    const encodedUrl = this.encodeUrlToBase64(YtUrl);
    let url = `/api_yt/deleteyt/${encodedUrl}`;

    if (relpath) {
      url += '/' + relpath;
    }
        
    const ret = await this.fetchDataBase(url);
    return ret;
  }

  async getAllSections(relpath) {
    let url = `/api_yt/getallsections`;

    if (relpath) {
      url += '/' + relpath;
    }

    return await this.fetchDataBase(url);
  }

  async getYtRoot() {
    const url = `/api_yt/ytpath`;
    return await this.fetchDataBase(url);
  }

  async getDirInfo(relpath=null) {
    let url = `/api_yt/ytdirinfo`;

    if (relpath) {
      url += '/' + relpath;
    }

    return await this.fetchDataBase(url);
  }

  async getYtVideo(relpath, brief=false, editMode=false) {
    let url = null;

    const apiName = editMode ? 'ytvedit' : 'ytv';

    if (relpath) {
      url = `/api_yt/${apiName}/${relpath}`;
    }
    else {
      url = `/api_yt/${apiName}`;
    }

    if (brief) {
      url += '?brief=true';
    } else {
      url += '?brief=false';
    }

    return await this.fetchDataBase(url);
  }

  /************** MyFileX API *******************/
  async lvVideoList(data) {
    let url = `/api_myfilex/lvvideolist`;

    return await this.fetchPostDataBase(url, data);
  }

  async getPasteVideoInfo(file_name, relpath) {
    let url = `/api_myfilex/getpastevideoinfo`;
    const data = {
      'type': 2,   // hosted_video
      'file_name': file_name,
      'relpath': relpath
    }

    return await this.fetchPostDataBase(url, data);
  }

  async trackPlayedVideo(videoFullName) {
    const url = `/api_myfilex/trackplayedvideo/${videoFullName}`;
    return await this.fetchDataBase(url);
  }

  async getHistoryUrl() {
    const url = `/api_myfilex/historyurl`;
    return await this.fetchDataBase(url);
  }
  
  async procPhotoUrl(photoroot, photoId) {
    const url = `/api_myfilex/procphotourl/${photoroot}/${photoId}`;
    return await this.fetchDataBase(url);
  }

  async procVideoUrl(videoroot, videoId) {
    const url = `/api_myfilex/procvideourl/${videoroot}/${videoId}`;
    return await this.fetchDataBase(url);
  }

  async lvPlayUrl(videoId, relpath) {
    let url = `/api_myfilex/lvpurl/${videoId}`;
    if (relpath) {
      url += `/${relpath}`;
    }

    return await this.fetchDataBase(url);
  }

  async yvPlayUrl(videoId, relpath) {
    let url = `/api_yt/yvpurl/${videoId}`;

    if (relpath) {
      url += `/${relpath}`;
    }

    return await this.fetchDataBase(url);
  }

  async getSimilarFiles(videoroot, videoId) {
    const url = `/api_myfilex/similarfiles/${videoroot}/${videoId}`;
    return await this.fetchDataBase(url);
  }

  async procFolderUrl(folderRoot, folderId) {
    const url = `/api_myfilex/procfolderurl/${folderRoot}/${folderId}`;
    return await this.fetchDataBase(url);
  }

  async historyBackward() {
    let retData = null;
    try {
      const response = await fetch('/api_myfilex/backward', {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        retData = data.result;
      } else if (!response.ok && response.statusText == "UNAUTHORIZED") {
        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }

  async historyForward() {
    let retData = null;
    try {
      const response = await fetch('/api_myfilex/forward', {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        retData = data.result;
      } else if (!response.ok && response.statusText == "UNAUTHORIZED") {
        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }

  async initDirView() {
    let retData = null;
    try {
      const response = await fetch('/api_myfilex/initdirview', {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        retData = data.result;
      } else if (!response.ok && response.statusText == "UNAUTHORIZED") {
        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }

  async getRandMusic() {
    let retData = null;
    try {
      const response = await fetch('/api_myfilex/getrandmusic', {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        retData = data.result;
      } else if (!response.ok && this.statusText == "UNAUTHORIZED") {
        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;

  }

  async logOut() {
    let retData = null;
    try {
      const response = await fetch('/check_logout', {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        retData = data.result;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }

  async loadMediaRoot() {

    let retData = null;
    let url = '/api_myfilex/dirfilesroot';
    const ret = await this.fetchDataBase(url);

    if (ret.status) {
      // String to json.
      ret.data = JSON.parse(ret.data);

      if (ret.data.page != -1) {
        retData = ret;
      }
    }

    return retData;
  


    /*
    let retData = null;
    try {
      let url = '/api_myfilex/dirfilesroot';
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        let resp = await response.json();

        if (resp.result.status) {
          let data = JSON.parse(resp.result.data);

          resp.result.data = data;
          //this.curPage = data.page;
          if (data.page != -1) {

            retData = resp.result;
          }
        } else {
          console.log(resp.result.error);
        }
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
    */
  }
}