import React, { createContext, useState, useEffect, useContext } from 'react';
import { ApiFileX } from '../../comjs/filex.api/filex-api';
import { AppContext } from '../../AppContext';


// Create Context
const AutoPlayContext = createContext();

// Create a provider component
export const AutoPlayContextProvider = ({ children }) => {
  const { loginUserName } = useContext(AppContext);
  const [autoPlaylist, setAutoPlaylist] = useState(null);
  const [hasPlaylist, setHasPlaylist] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAutoPlaylistConfig = async () => {
      if (!loginUserName) return;

      try {
        const api = new ApiFileX();
        const resp = await api.getAutoPlaylistConfig();
        if (resp && resp.status) {
          setAutoPlaylist(resp.data.autoplay_channels);

          if (resp.data.autoplay_channels.length > 0) {
            setHasPlaylist(true);
          } else {
            setHasPlaylist(false);
          }
        } else {
          setHasPlaylist(false);
          setAutoPlaylist([]);
          console.error("Failed to fetch auto playlist in AutoPlayContextProvider.");
        }
      } catch (error) {
        setAutoPlaylist([]);
        setHasPlaylist(false);
        console.error("Error fetching auto playlist config:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAutoPlaylistConfig();
  }, [loginUserName]);

  return (
    <AutoPlayContext.Provider value={{ autoPlaylist, loading, hasPlaylist, setHasPlaylist }}>
      {children}
    </AutoPlayContext.Provider>
  );
};

// Custom hook to use the AutoPlayContext
export const useAutoPlay = () => useContext(AutoPlayContext);
