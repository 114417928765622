import React, { useRef, useState, useEffect, useContext } from 'react';
import './LoginKey.css'
import UserInfoContext from '../ImageCarousel/UserInfoContext';
import { LanguageContext } from '../Localization/LanguageContext/LanguageContext';
import { AppContext } from '../../AppContext';


const LoginKey = ({ parentHandleLoginKey }) => {
  const { language } = useContext(LanguageContext);
  const { updateLoginUserName } = useContext(AppContext);
  const {loginUser} = useContext(UserInfoContext);
  const [message, setMessage] = useState("");
  const passwordInputRef = useRef(null); // Create a ref for the username input
  const labels = {
    en: { hi: "Hi", enterKey: "Please enter key:", keyPlaceholder: "Key?", enter: "Enter" },
    zh: { hi: "嗨!", enterKey: "输入秘码：", keyPlaceholder: "秘码?", enter: "进入" }
  };

  useEffect(() => {
    // Focus the input when the component is loaded
    passwordInputRef.current.focus();
  }, []);


  const clearErrorMessage = () => {
    const error = document.getElementById("errorMessage").innerText = "";
  }

  const handleButtonClick = (status, data) => {
    // Call the callback function passed from the parent component with the event data
    parentHandleLoginKey(status, data);
  };

  const appendToPhone = (number) => {
    clearErrorMessage();

    let phoneInput = document.getElementById('phone');
    phoneInput.value += number;
    if (phoneInput.value.length > 10) {
        phoneInput.value = phoneInput.value.slice(0, 10);
    }
  }

  const removeFromPhone = () => {
    clearErrorMessage();

    let phoneInput = document.getElementById('phone');
      phoneInput.value = phoneInput.value.slice(0, -1);
  }

  const onLoginKey = (status, data) => {
    // Call the callback function passed from the parent component LoginBg
    parentHandleLoginKey(status, data);
  };

  const validateKey = async () => {
    const key = document.getElementById("phone").value;

    if (key.trim() === "") {
      document.getElementById("errorMessage").innerText = "Please enter a key.";
      return;
    }

    try {
      const response = await fetch("/check_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key }),
      });

      const data = await response.json();

      if (response.ok) {
        // Authentication successful
        onLoginKey(true, data);
        // updateLoginUserName(data.data);
      } else {
        // Authentication failed
        onLoginKey(false, data);
        setMessage(data.message);
      }
    } catch (error) {
      onLoginKey(false, error);
      setMessage(error);
    }
  }

  const handleKeyDown = (event) => {
    setMessage("");
    if (event.key === "Enter") {
      validateKey();
    }
  };

  return (
  <>
    <div className="login-key-container">
        <h3>{labels[language].hi} {loginUser}</h3>
        <p>{labels[language].enterKey}</p>
        <input type="password" 
          id="phone" 
          name="phone" 
          placeholder={labels[language].keyPlaceholder}
          maxLength="10" 
          required
          onKeyDown={handleKeyDown}
          ref={passwordInputRef} 
        />
        <div className="keypad-container">
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '1')}>1</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '2')}>2</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '3')}>3</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '4')}>4</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '5')}>5</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '6')}>6</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '7')}>7</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '8')}>8</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '9')}>9</button>
            <button className="keypad-button" type="button" onClick={appendToPhone.bind(null, '0')}>0</button>
            <button className="keypad-button" type="button" onClick={removeFromPhone}>&#9003;</button>
        </div>
        <button type="button" onClick={validateKey}>{labels[language].enter}</button>
        <p className="error-message" id="errorMessage">{message}</p>
    </div>
  </>
  );
};

export default LoginKey;
