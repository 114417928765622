import React, { useRef, useState, useEffect } from 'react';
import Muuri from 'muuri';

import './OriginalListComponent.css'


const OriginalListComponent = ({ items, notifyLayoutDone, onListRef }) => {
  const listRef = useRef(null);
  const [layoutDone, setLayoutDone] = useState(false);

  useEffect(() => {
    setLayoutDone(notifyLayoutDone);

  }, [notifyLayoutDone]);

  useEffect(() => {
    if (onListRef && typeof onListRef === 'function') {
      onListRef(listRef);
    }
  }, []);
  
  // useEffect(() => {
  //   //const grid = new Muuri('.grid');
  //   // To make the items draggable
  //   // try this next...
  //   const grid = new Muuri('.original-list-style', {
  //     items: '.list-item-style',
  //     dragEnabled: true});

  //   return () => {
  //     grid.destroy();
  //   };
  // }, []);

  return (
    <div className={`original-list-style ${layoutDone ? 'show' : 'hide'}`} ref={listRef}>
      {items.map((item, index) => (
        <div key={index} className="list-item-style">
          <div className="item-content-style">

              {item}

          </div>
        </div>
      ))}
    </div>
  );

  // return (
  //   <>
  //   <div className="original-list">
  //     <div className="list-item">
  //       <div className="item-content">
  //        This can be anything. 
  //       </div>
  //     </div>
  //     <div className="list-item">
  //       <div className="item-content">
  //         <div className="my-custom-content">
  //           Yippee!
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //   </>
  // );
};

export default OriginalListComponent;

//
/*
4. Add the styles
The grid element must be "positioned" meaning that it's CSS position property must be set to relative, absolute or fixed. Also note that Muuri automatically resizes the grid element's width/height depending on the area the items cover and the layout algorithm configuration.
The item elements must have their CSS position set to absolute.
The item elements must not have any CSS transitions or animations applied to them, because they might conflict with Muuri's internal animation engine. However, the grid element can have transitions applied to it if you want it to animate when it's size changes after the layout operation.
You can control the gaps between the items by giving some margin to the item elements.
One last thing. Never ever set overflow: auto; or overflow: scroll; to the grid element. Muuri's calculation logic does not account for that and you will see some item jumps when dragging starts. Always use a wrapper element for the grid element where you set the auto/scroll overflow values.
.grid {
  position: relative;
}
.item {
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 5px;
  z-index: 1;
  background: #000;
  color: #fff;
}
.item.muuri-item-dragging {
  z-index: 3;
}
.item.muuri-item-releasing {
  z-index: 2;
}
.item.muuri-item-hidden {
  z-index: 0;
}
.item-content {
  position: relative;
  width: 100%;
  height: 100%;
}
*/