import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import './NotifyBell.css';

const NotifyBell = ({ size = 'large' }) => {
  const [hasNotification, setHasNotification] = useState(true);

  return (
    <div className={`notification-container ${size}`}>
      <div className="bell-icon" onClick={() => setHasNotification(!hasNotification)}>
        <FontAwesomeIcon icon={faBell} className="bell-with-border" />
        {hasNotification && <span className="notification-label"></span>}
      </div>
    </div>
  );
};

export default NotifyBell;
