/**
 * From this, I understand how 2 peers got connected. Pls look at the sequence. 3 copies are needed.
 * These should be done with socket io in Flask server.
 * 
 * https://chatgpt.com/c/a9030807-3233-487a-a614-85d4cd783d43
 */
import React, { useState, useEffect } from 'react';
import SimplePeer from 'simple-peer';

const SimPeerComponent = () => {
  const [incomingSignal, setIncomingSignal] = useState('');
  const [outgoingSignal, setOutgoingSignal] = useState('');
  const [peer, setPeer] = useState(null);

  useEffect(() => {
    const isInitiator = window.location.hash === '#1';
    const p = new SimplePeer({
      initiator: isInitiator,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' }  // Google's public STUN server.
                                                    // no need for localhost.
        ]
      }
    });

    p.on('error', err => console.log('error', err));

    p.on('signal', data => {
      console.log('SIGNAL', JSON.stringify(data));
      setOutgoingSignal(JSON.stringify(data));
    });

    p.on('connect', () => {
      console.log('CONNECT');
      p.send('hey peer2, how is it going?');
    });

    p.on('data', data => {
      console.log('data: ' + data);
    });

    setPeer(p);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (peer) {
      try {
        const signalData = JSON.parse(incomingSignal);
        console.log('Received signal data:', signalData);
        peer.signal(signalData);
      } catch (error) {
        console.error('Invalid signal data', error);
      }
    }
  };

  return (
    <div>
      <h1>Peer-to-Peer Communication</h1>
      <p>First, open <strong>http://localhost:3000/test/simpeer/#1</strong> in one browser tab/window.</p>
      <p>Then, open <strong>http://localhost:3000/test/simpeer</strong> in another browser tab/window.</p>
      <p>Follow these steps for signaling:</p>
      <ol>
        <li>Copy the signaling data from the first peer (initiator) and paste it into the text area of the second peer (responder).</li>
        <li>Submit the form in the second peer (responder).</li>
        <li>Copy the signaling data generated by the second peer (responder) and paste it into the text area of the first peer (initiator).</li>
        <li>Submit the form in the first peer (initiator).</li>
      </ol>
      <form onSubmit={handleSubmit}>
        <textarea
          id="incoming"
          value={incomingSignal}
          onChange={(e) => setIncomingSignal(e.target.value)}
          placeholder="Paste signal data here"
        />
        <button type="submit">Submit Signal</button>
      </form>
      <pre id="outgoing" style={{ width: '600px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
        {outgoingSignal}
      </pre>
    </div>
  );
};

export default SimPeerComponent;
