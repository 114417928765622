// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RotatingBall.module.css */
/*background-image: linear-gradient(45deg, blue, red); */ /* Gradient fill */
.RotatingBall_ball__SJHVv {
  margin: 0px;
  width: 40px; /* Increased size for visibility */
  height: 40px; /* Increased size for visibility */
  border-radius: 50%;
  cursor: pointer; 
  background-image: linear-gradient(45deg, rgb(255, 153, 0), rgb(255, 251, 0)); /* Gradient fill */
  transition: transform 0.5s ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/Components/MainPage/RotatingBall.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B,wDAAwD,EAAE,kBAAkB;AAC5E;EACE,WAAW;EACX,WAAW,EAAE,kCAAkC;EAC/C,YAAY,EAAE,kCAAkC;EAChD,kBAAkB;EAClB,eAAe;EACf,4EAA4E,EAAE,kBAAkB;EAChG,mCAAmC;AACrC","sourcesContent":["/* RotatingBall.module.css */\n/*background-image: linear-gradient(45deg, blue, red); */ /* Gradient fill */\n.ball {\n  margin: 0px;\n  width: 40px; /* Increased size for visibility */\n  height: 40px; /* Increased size for visibility */\n  border-radius: 50%;\n  cursor: pointer; \n  background-image: linear-gradient(45deg, rgb(255, 153, 0), rgb(255, 251, 0)); /* Gradient fill */\n  transition: transform 0.5s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ball": `RotatingBall_ball__SJHVv`
};
export default ___CSS_LOADER_EXPORT___;
