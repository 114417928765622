import React, { useState, useContext, useEffect, useMemo } from 'react';
import AlbumIntro from '../Channels/AlbumIntro';
import PhotoPlayer from '../PhotoPlay/PhotoPlayer';
import PhotoPlayerUI from '../PhotoPlay/PhotoPlayerUI';
import MuteButton from '../Mute/Mute';
import { AppContext } from '../../AppContext';


const CinemaPhoto = ({
    photoDirTitle, 
    photoPath, 
    pageOneVisible,
    onPhotoStart=null,
    onPhotoEnd=null,
    showIntro=true
  }) => {
  const [showIntroduction, setShowIntroduction] = useState(showIntro);
  const {musicState, updateMusicState} = useContext(AppContext);

  useEffect(() => {
    console.log("CinemaPhoto mounted.");

    return () => {
      console.log("CinemaPhoto unmounted.");
    };
  }, []);

  const handleIntroStart = () => {
    updateMusicState(0);
  };

  const handleIntroEnd = () => {
    setShowIntroduction(false);
  };
  
  //onStart, onFinish, relPath, title
  const handlePhotoPlayerStart = () => {
    updateMusicState(1);

    if (onPhotoStart) {
      onPhotoStart();
    }
  };

  const handlePhotoPlayerEnd = () => {
    // TODO: 1/25/24. This causes AppContext changes, which causes re-render MainPage.
    // How to store values in AppContext without trigger unknown re-render?
    // updateMusicState(2);

    if (onPhotoEnd) {
      onPhotoEnd();
    }
  };

  const PhotoPlayerFull = PhotoPlayer(PhotoPlayerUI);
  
  /*
  1/16/24. 
  This is to prevent PhotoPlayer2p.js

  <TwoPageBase
    keyValue={photoPath} 
    onPage1Visible={handleOnPage1Visible} 
    onPage2Visible={handleOnPage2Visible} >

  event of handleOnPage2Visible. This event handles page2 visible. It's sent to MainPage, and changes
  a state, which cause MainPage to re-render, and its siblings. CinemaPhoto.js is one of them.
  In this case, PhotoPlayerFull has no state change, and player may be in progress.
  When PhotoPlayerFull re-render, it will cause player restart playing again.

  With useMemo, it should guarantee only changes in state of [photoPath, photoDirTitle] to cause
  re-render. And this solve the problem, and also make code more efficient.
  */

  // 1/27/24. Prevent undesired parent re-render which is triggered by scrolling that 
  // changes page 2 visible status. PhotoPlayerFull will restart play.
  // Scroll to causes PhotoPlayerFull to restart play from 1st picture, even photoPath are the same
  // during scroll. Not sure why, but useMemo solved the issue.
  const memoizedPhotoPlayerFull = useMemo(() => (
    <PhotoPlayerFull 
      onStart={handlePhotoPlayerStart} 
      onFinish={handlePhotoPlayerEnd} 
      relPath={photoPath}
      title={photoDirTitle} 
    />
  ), [photoPath, photoDirTitle]);

  /*
  // This does not work. PhotoPlayerFull re-render and reinitialize player from 1st picture clicked.
  return (
    <>
      {showIntroduction ? (
        <AlbumIntro 
          onStart={handleIntroStart} 
          onFinish={handleIntroEnd} 
          title={photoDirTitle}
          introTimeout={5000} />
      ) : (
        <PhotoPlayerFull 
          onStart={handlePhotoPlayerStart} 
          onFinish={handlePhotoPlayerEnd} 
          relPath={photoPath}
          title={photoDirTitle} />
      )}
      <MuteButton />
    </>
  );
  */

  // This memoizedPhotoPlayerFull solved the problem of scroll / re-render, player restart from
  // 1st picture clicked.
  return (
    <>
      {showIntroduction ? (
        <AlbumIntro 
          onStart={handleIntroStart} 
          onFinish={handleIntroEnd} 
          title={photoDirTitle}
          introTimeout={5000} />
      ) : (
        memoizedPhotoPlayerFull
      )}
      <MuteButton />
    </>
  );
};

export default CinemaPhoto;
