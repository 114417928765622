import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LanguageContext } from '../Localization/LanguageContext/LanguageContext';

import $ from 'jquery';

// Discuss how to handle drag/drop on touch device. 1) Disable; 
// 2) Distinguish touch and scroll.
// https://chatgpt.com/c/5d3d31c8-7bbe-4f9c-a208-dc490dd0396a
import '../JQueryPlugins/JQueryDad.notouch.js';
import { AppContext } from '../../AppContext';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { IconMenuBar } from '../IconMenuBar/IconMenuBar.jsx';
import HeroIcon from './HeroIcon';
import { useAutoPlay } from '../System/AutoPlayContext';
import './SysIcons.css';

// Multi-lingual descriptions
const descriptions = {
  en: {
    'div-media': 'Media',
    'div-yt': 'Youtube Video',
    'div-document': 'Document',
    'div-tiny-theater': 'Tiny Theater',
    'div-channels': 'Channels',
    'div-settings': 'Settings'
  },
  zh: {
    'div-media': '视频，照片',
    'div-yt': 'Youtube 视频',
    'div-document': '文档',
    'div-tiny-theater': '小剧场',
    'div-channels': '频道',
    'div-settings': '设置'
  }
};

// Common icon data
const iconData = [
  {
    id: 'div-media',
    imgSrc: '/static/img/multimedia-png-3.png',
    logoSrc: '/static/img/my-media-6.png',
    backgroundClass: 'second'
  },
  {
    id: 'div-yt',
    imgSrc: '/static/img/youtube_bk_icon.webp',
    logoSrc: '/static/img/youtube-play-png.png',
    backgroundClass: 'seventh',
    imgClassName: 'hero-profile-img-center'
  },
  {
    id: 'div-document',
    imgSrc: '/static/img/icon-doc.png',
    logoSrc: '/static/img/icon-write.png',
    backgroundClass: 'third'
  },
  {
    id: 'div-channels',
    imgSrc: '/static/img/carousel.png',
    logoSrc: '/static/img/rotate.png',
    backgroundClass: 'fifth'
  },
  {
    id: 'div-tiny-theater',
    imgSrc: '/static/img/movie-theater.png',
    logoSrc: '/static/img/people-color.png',
    backgroundClass: 'fourth'
  },
  {
    id: 'div-settings',
    imgSrc: '/static/img/hill2.jpg',
    logoSrc: '/static/img/settings2.png',
    backgroundClass: 'sixth',
    imgClassName: 'hero-profile-img-center'
  }
];

const SysIcons = ({ size }) => {
  const navigate = useNavigate();
  const { autoPlaylist, loading, hasPlaylist } = useAutoPlay();
  const { language } = useContext(LanguageContext);
  const { loginUserName } = useContext(AppContext);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isProd, setIsProd] = useState(false);

  useEffect(() => {
    if (!loading) {
    }
  }, [autoPlaylist, loading]);

  useEffect(() => {
    $('#sysicon-container').dad();
    return () => {};
  }, []);

  const handlePhotoClick = (evt, id) => {
    evt.preventDefault();
    let url = null;
    switch (id) {
      case 'div-media':
        url = `/false/true`;
        break;
      case 'div-yt':
        url = `/youtubeview`;
        break;
      case 'div-document':
        url = `/articlelist`;
        break;
      case 'div-tiny-theater':
        url = `/theater`;
        break;
      case 'div-channels':
        url = `/playlist`;
        break;
      case 'div-settings':
        url = `/settings`;
        break;
      default:
        break;
    }
    if (url) {
      navigate(url, { replace: true });
    }
    console.log('Clicked icon with ID:', id);
  };

  const currentDescriptions = descriptions[language] || descriptions['en']; // Default to 'en' if language not found

  useEffect(() => {
    const api = new ApiFileX();
    api.isAdmin(loginUserName).then((ret) => {
      if (ret && ret.status) {
        setIsAdminUser(true);
      }
    });

    api.isProduction().then((ret) => {
      if (ret && ret.status) {
        setIsProd(ret.data);
      }
    });

  }, [loginUserName]);

  const handleBallClick = () => {
    // If auto playlist exist, go to root to trigger auto playlist.
    if (hasPlaylist) {
      let url = `/`;
      navigate(url, { replace: true });
    }
  }

  let filteredIconData = isAdminUser
    ? iconData
    : iconData.filter(icon => ['div-yt', 'div-media', 'div-channels', 'div-tiny-theater'].includes(icon.id));

  filteredIconData = !isProd
    ? filteredIconData
    : filteredIconData.filter(icon => !['div-document', 'div-tiny-theater'].includes(icon.id));

  return (
    <>
      <div id="sysicon-container" data-dad-active="true">
        {filteredIconData.map((icon, index) => (
          <HeroIcon
            key={index}
            size={size}
            imgSrc={icon.imgSrc}
            logoSrc={icon.logoSrc}
            imgClassName={icon.imgClassName}
            backgroundClass={icon.backgroundClass}
            description={currentDescriptions[icon.id]}
            onClick={(evt) => handlePhotoClick(evt, icon.id)}
          />
        ))}
      </div>
      <IconMenuBar
        onClickRotatingBall={handleBallClick} 
      />
    </>
  );
};

export default SysIcons;
