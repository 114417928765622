// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.carousel {
  max-width: 100%; /* Adjust the size of the carousel container as needed */
  max-height: 100%; /* Adjust the size of the carousel container as needed */
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}

.carousel-list {
  list-style: none;
  margin: 0;
  padding: 0;
}


.carousel-list img {
  object-fit: cover; /* This ensures the image retains its aspect ratio while covering the entire view */
  opacity: 1;
  transition: opacity 1s ease-in-out; 
}

.carousel-image-no-shift {
  width: 100%;
  height: 100%;
}

/* class to make img shift */
.carousel-img-shift {
  top: -5%;
  left: -5%;
  width: 110%;
  height: auto;
  animation: shiftAnimation 120s infinite linear; /* Adjust the animation duration for a slower effect */
}

@keyframes shiftAnimation {
  0%, 100% {
      transform: translateX(0) translateY(0);
  }
  20%, 50% {
      transform: translateX(-3%) translateY(-3%);
  }
  40%, 60% {
      transform: translateX(3%) translateY(3%);
  }
  70%, 90% {
      transform: translateX(-2%) translateY(2%);
  }
  80% {
      transform: translateX(2%) translateY(-2%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/ImageCarousel/ImageCarousel.css"],"names":[],"mappings":";;AAEA;EACE,eAAe,EAAE,wDAAwD;EACzE,gBAAgB,EAAE,wDAAwD;EAC1E,gBAAgB;EAChB,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;;AAGA;EACE,iBAAiB,EAAE,mFAAmF;EACtG,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA,4BAA4B;AAC5B;EACE,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,8CAA8C,EAAE,sDAAsD;AACxG;;AAEA;EACE;MACI,sCAAsC;EAC1C;EACA;MACI,0CAA0C;EAC9C;EACA;MACI,wCAAwC;EAC5C;EACA;MACI,yCAAyC;EAC7C;EACA;MACI,yCAAyC;EAC7C;AACF","sourcesContent":["\n\n.carousel {\n  max-width: 100%; /* Adjust the size of the carousel container as needed */\n  max-height: 100%; /* Adjust the size of the carousel container as needed */\n  overflow: hidden;\n  position: relative;\n  top: 0;\n  left: 0;\n}\n\n.carousel-list {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n\n.carousel-list img {\n  object-fit: cover; /* This ensures the image retains its aspect ratio while covering the entire view */\n  opacity: 1;\n  transition: opacity 1s ease-in-out; \n}\n\n.carousel-image-no-shift {\n  width: 100%;\n  height: 100%;\n}\n\n/* class to make img shift */\n.carousel-img-shift {\n  top: -5%;\n  left: -5%;\n  width: 110%;\n  height: auto;\n  animation: shiftAnimation 120s infinite linear; /* Adjust the animation duration for a slower effect */\n}\n\n@keyframes shiftAnimation {\n  0%, 100% {\n      transform: translateX(0) translateY(0);\n  }\n  20%, 50% {\n      transform: translateX(-3%) translateY(-3%);\n  }\n  40%, 60% {\n      transform: translateX(3%) translateY(3%);\n  }\n  70%, 90% {\n      transform: translateX(-2%) translateY(2%);\n  }\n  80% {\n      transform: translateX(2%) translateY(-2%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
