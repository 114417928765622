/**
 * This swipe detection hook is only applicable to non-iframe (youtube player)
 * as swipe handler is attached to document.
 * 
 * For youtube player, swipe needs to be based on attaching absolute positioned
 * div on top of iframe. Swipe handler needs to be attached not to document
 * as it does not work.
 */
import { useEffect } from 'react';
import useIsTouchDevice from './HookTouchDevice';

const useSwipeDetection = (onSwipeLeft = null, onSwipeRight = null) => {
  const isTouchDevice = useIsTouchDevice(); // Detect if the device supports touch

  useEffect(() => {
    let touchStartX = null;
    let touchEndX = null;
    let touchStartY = null; 
    let touchEndY = null;

    if (isTouchDevice) {
      console.log('Touch device detected');

      const handleTouchStart = (e) => {
        touchStartX = Math.floor(e.touches[0].clientX);
        touchStartY = Math.floor(e.touches[0].clientY);
        console.log(`touch start: x=${touchStartX}, y=${touchStartY}`);
      };

      const handleTouchMove = (e) => {
        touchEndX = Math.floor(e.touches[0].clientX);
        touchEndY = Math.floor(e.touches[0].clientY);
        console.log(`touch move: x=${touchStartX}-${touchEndX}, y=${touchStartY}-${touchEndY}`);
      };

      const handleTouchEnd = () => {
        // Determine if it's an up/down swipe.
        // This does not work for some reason. The y value is not correct.
        // if (Math.abs(touchEndY - touchStartY) > 34) {
        //   touchStartX = null;
        //   touchEndX = null;
        //   touchStartY = null;
        //   touchEndY = null;
        //   return;
        // }

        if (touchStartX !== null && touchEndX !== null) {
          if (touchStartX - touchEndX > 50) {
            // Swipe left
            if (onSwipeLeft) {
              console.log(`<<<<< onSwipeLeft()  <<<<<`);
              onSwipeLeft();
            }
          } else if (touchEndX - touchStartX > 50) {
            // Swipe right
            if (onSwipeRight) {
              console.log(`>>>>>> onSwipeRight() >>>>>`);
              onSwipeRight();
            }
          }
        }

        // Reset touch coordinates after processing the swipe
        touchStartX = null;
        touchEndX = null;
        touchStartY = null;
        touchEndY = null;
      };

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);

      return () => {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [isTouchDevice, onSwipeLeft, onSwipeRight]);
};

export default useSwipeDetection;
