/**
 * Design:
 * ProgramVideo's VideoPlayer has default intro. ProgramPhoto has intro component. Therefore, Channel's
 * intro should be removed.
 * 
 * 
 * 
 */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LanguageContext } from '../Localization/LanguageContext/LanguageContext';
import Program from './Program';
import ProgramVideo from './ProgramVideo';
import ProgramPhoto from './ProgramPhoto';
import AlbumIntro from './AlbumIntro';
import { AppContext } from '../../AppContext';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';


class ChannelsDataAPI {

  async getChannels() {
    let retData = null;

    try {
      const response = await fetch('/api_myfilex/startchannels');
      
      if (response.ok) {
        const data = await response.json();
        if (data.result.status) {
          retData = data.result;
        }
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }

  async playChannel(relChannelDir) {
    let retData = null;

    try {
      const response = await fetch('/api_myfilex/playerparam/' + relChannelDir);

      if (response.ok) {
        const data = await response.json();
        if (data.result.status) {
          retData = data.result;
        }
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }
    return retData;
  }
}


function Channels({
    autoChannelPlay=true,  // Use API to get auto play channels.
    playChannel=null,  // Read channel items by this specifyed channel file name. 
    onChannelDataReady=null,
    onPlayFolder,  // Each folder gets notified.
    onPlayVideoFile=null  // Since no video directory play is available, all video files are notfied?
  }) {
  // Parameters if invoked from url
  const { pachannelname: playChannelFromUrl } = useParams();

  const { language } = useContext(LanguageContext);
  const {musicState, updateMusicState} = useContext(AppContext);
  const labels = {
    en: { end: "End" },
    zh: { end: "结束" }
  };

  // Photo list
  const [channelList, setChannelList] = useState([]);

  // Video list
  const [channelVideos, setChannelVideos] = useState([]);
  const [progIndex, setProgIndex] = useState(-1);
  const [progTitle, setProgTitle] = useState(null);
  const [relPath, setRelPath] = useState(null);

  // Simple for now, 1 photo directory, 2 video file.
  const [mediaType, setMediaType] = useState(0);

  // Each program is started with 
  // 0: intro (0), then, 
  // 1: photo (or video) play (1),
  // 2: end (2),
  // then done or not started (-1)
  const [playProgram, setPlayProgram] = useState(-1);
  const [carouselActive, setCarouselActive] = useState(true);

  // Start to play after intro is done
  // TODO: combine these.
  const [startPlay, setStartPlay] = useState(false);
  const [forceRender, setForceRender] = useState(false);

  // Use show/hide, instead of re-render, to make sure video continuous play to work on iPhone (not iOS
  // of Desktop). Withtout it, <AlbumIntro> remount causes html structure to dynamically change, which
  // seems to prevent iPhone to work. Effort already made to VideoPlayer as well. Without both,
  // video list contineous plays works only on desktop, include iOS, but not work on iPhone.
  const [showIntro, setShowIntro] = useState(false);

  const [playlist, setPlaylist] = useState([]);
  const [plalistIndex, setPlaylistIndex] = useState(-1);
  const [curPlaylist, setCurPlaylist] = useState(null);
  const [curPlaylistRandom, setCurPlaylistRandom] = useState(false);
  const api = new ApiFileX();

  useEffect(() => {
    // 3 play cases:
    //
    // 1) Auto play channels;
    // 2) Single channel speicfied by url.
    // 3) Single channel specified by input parameter.
    let singleChannel = null;
    if (playChannelFromUrl) {
      autoChannelPlay = false;
      singleChannel = playChannelFromUrl;
    } else if (playChannel) {
      autoChannelPlay = false;
      singleChannel = playChannel;
    }
    
    if (autoChannelPlay) {
      api.getAutoPlaylists().then((ret) => {
        if (ret && ret.status) {
          setPlaylist(ret.data);
          // Start to play 1st auto channel.
          setPlaylistIndex(0);  

          if (onChannelDataReady) {
            onChannelDataReady(ret.data);
          }
        }
      });
    } else if (singleChannel) {
      api.getChannelPlaylist(singleChannel).then((ret) => {
        if (ret && ret.status) {
          setPlaylist(ret.data);
          setPlaylistIndex(0);  

          if (onChannelDataReady) {
            onChannelDataReady(ret.data);
          }
        }
      });
    } else {
      throw new Error('Please provide channel name!');
    }
  }, []);

  useEffect(() => {
    if (plalistIndex < 0) return;

    let mediaList = null;
    // Create video list
    Object.entries(playlist[plalistIndex]).forEach(([channel_filename, value]) => {
      mediaList = value.play_videos_encoded.files_relpath.map((prog) => {
        return prog;
      })
    });

    const channelFileNamekey = Object.keys(playlist[plalistIndex])[0]
    const randomPlay = playlist[plalistIndex][channelFileNamekey].random
    setCurPlaylistRandom(randomPlay);

    if (randomPlay) {
      mediaList = api.shuffleArray(mediaList);
    }
    setCurPlaylist(mediaList);

  }, [plalistIndex]);

  useEffect(() => {
    // Simulate fetching child data from the REST API
    // loadChannels().then(data => {
    //   if (data && data.status) {
    //     setChannelList(data.data.photo_channel_directories);
    //     setChannelVideos(data.data.video_channel_files);
    //   }
    // });

    if (!curPlaylist) return;

    setChannelVideos(curPlaylist);
    // setChannelList();

  }, [curPlaylist]);

  useEffect(() => {
    function preventScreenSaver() {
      if (carouselActive) {
        window.requestIdleCallback(preventScreenSaver);
      }
    }

    // Start preventing the screen saver when the component mounts
    // not work on iphone.
    // preventScreenSaver();

    // Clean up: Stop preventing the screen saver when the component unmounts
    return () => {
      setCarouselActive(false);
    };
  }, [carouselActive]);
  
  useEffect(() => {
    // Update context according to program status.
    if (playProgram == 0) {
      updateMusicState(0);
      // TODO: setShowIntro(true);
      setShowIntro(true);
    } else if (playProgram == 1) {
      updateMusicState(1);
      setShowIntro(false);
    } else if (playProgram == 2) {
      updateMusicState(2);
      setShowIntro(false);
    }
  }, [playProgram]);

  // This event is fired from CinemaVideo, where it detects device with touch
  // screen.
  const handleSwipeNextProgram = (swipeDirection) => {
    if (swipeDirection == 1) {
      // Right swipe
      if ((progIndex + 1) < channelList.length + channelVideos.length - 1) {
        nextProgramByIndex(progIndex + 1);
      } 
    }
    else if (swipeDirection == -1) {
      // Left swipe
      if ((progIndex - 1) >= 0) {
        nextProgramByIndex(progIndex - 1);
      }
    }
  }

  /**
   * id is program id, and is based on photo + video list.
   * 
   * @param {*} id 
   */
  const nextProgramByIndex = (id) => {
    if (id >= channelList.length + channelVideos.length) {
      return;
    }

    setProgIndex(id);

    let item = null;
    let title = null;
    let rel = null;

    // Assume to play video list first.
    if (id < channelVideos.length) {
      item = channelVideos[id];
      title = Object.values(item)[0];

      title = api.decodeBase64ToUrl(title);
      setMediaType(2);

      setProgTitle(title);
      const mediaRelpath = Object.keys(item)[0];
      setRelPath(mediaRelpath);
    } else {
      // Photo directory list.
      // id is program id.
      // Remove video id as video programs are played first.
      let idPhoto = id - channelVideos.length;
      // Also get the title.
      item = channelList[idPhoto]
      const value = Object.values(item)[0];
      title = value.title;
      setMediaType(1);

      setProgTitle(title);
      rel = Object.keys(item)[0];
      setRelPath(rel);

      // Notify 2nd page to show grid view. For video, no such directory is available.
      onPlayFolder(rel);
    }
  }

  useEffect(() => {
    setStartPlay(playProgram === 1 ? true : false);
  }, [playProgram]);

  useEffect(() => {
    // Update forceRender whenever startPlay changes
    setForceRender((prevForceRender) => !prevForceRender);
  }, [startPlay]);


  // Trigger channel to start play programs, if any of channelList, 
  // channelVideos is not empty.
  useEffect(() => {
    if (channelList.length > 0 || channelVideos.length > 0) {
      // Let's start 1st program with introduction.
      // TODO: no need of this.
      // setPlayProgram(0);  // State to display intro.

      // This is start.
      setPlayProgram(1); 
      nextProgramByIndex(0);
    }
  }, [channelList, channelVideos]);

  const handleIntroStart = (title=null) => {
    //console.log("handleIntroStart. Title: " + title);
  };

  const handleIntroFinish = () => {
    // This will hide intro screen, and photo player will be shown (started).
    setPlayProgram(1);
  };

  const programFinished = () => {

    if (progIndex < channelList.length + channelVideos.length - 1) {
      // Next program index.
      ////setProgIndex(progIndex + 1);
      nextProgramByIndex(progIndex + 1);


      // TODO: should make AlbumIntro to display
      // Cause intro to show.
      //setPlayProgram(0);

      // Next program intro will not show. 
      // TODO: temp solution

      // Video list is played first. At the end of each video, this function is called. If there
      // is photo list, it should display intro from Channel (as ProgramPhoto has no default intro).
      // if (progIndex + 1 > channelVideos.length &&   // Finished video list.
      //     channelList.length > 0) {
      //   setPlayProgram(0);
      // } else {
      //   setPlayProgram(1);
      // }

      setPlayProgram(1);
    } else {
      // All programs are finished.
      console.log("All programs are finished.");
      setPlayProgram(2);

      // For multiple auto play channels, goes to next channel's programs.
      // TODO: end program screen does not show.
      if (plalistIndex < playlist.length - 1) {
        setPlaylistIndex(prevIndex => prevIndex + 1);
      }
    }
  }

  // useEffect(() => {
  //   if (plalistIndex < playlist.length) {
  //     setPlaylistIndex(prevIndex => prevIndex + 1);
  //   }
  // }, [playProgram==2]);

  const handleProgramStart = (title=null) => {
    //console.log("handleProgramStart. Title: " + title);
  };

  /**
   * This is fired at the endo of each video in channel's video list. ProgramVideo uses
   * VideoPlayer, whic has intro by default. There is no need to use intro in Channel.
   * Therefore, setPlayProgram(1); is to keep Channel in play mode, which should go to next
   * program, which can be video or photo.
   */
  const handleProgramFinish = () => {
    programFinished();
  };

  const handleYtVideoStart = () => {

  }

  const handleYtVideoEnd = () => {
    programFinished();
  }

  const clickReloadHandler = () => {
      // Replay
      setPlaylistIndex(0);  // Start with 1st auto playlist of possible multiple playlists

      setPlayProgram(1);  // Control channel start finish.
      nextProgramByIndex(0);  // Set program in a channel.
  };

  // It assuming <ProgramVideo> is always mounted. If <AlbumIntro> is shown, <ProgramVideo> should be hidden.
  // Video start to play is also controlled by state in <Channels> which is after <AlbumIntro> is finished.
  //
  // When playing video list, <ProgramVideo> does not unmount (not controlled in the main if else switch).
  // Therefore, state change in <Channels> will not cause <ProgramVideo> to update. 
  // const [forceRender, setForceRender] = useState(false); is introduced to force re-render of
  // <ProgramVideo> is a controlled way. <ProgramVideo>  has to integrate this flag.
  // 
  // key={showIntro} is added to force <> rerender, so that it can process showAlbumIntro={showIntro}.
  // 
  // NOT work for iPhone, even hide AlbumIntro, not remount. 1/29/24
  //
  // TODO: we are assuming video list are played first in channel. If there is a mixed video and photo
  // in channel, behavior of if auto play video in a list is unknown.
  //
  // AlbumIntro:
  // 1) Intro tile for each list of multiple photo lists.
  // 2) Video. Assuming only 1 list now. At beginning, AlbumIntro display titile that's defined
  //    in json file. Each video's title uses VideoPlayer title intro component, which at present
  //    have 2 different code base with identical feature.
  return (
    <>
    {/* { <AlbumIntro
      key={showIntro}  
      onStart={handleIntroStart}
      onFinish={handleIntroFinish}
      title={progTitle}
      showAlbumIntro={showIntro}
    /> } */}

    {/* {playProgram === 1 && mediaType === 1 && (
      channelList && (
        <Program            
          onStart={handleProgramStart}
          onFinish={handleProgramFinish} 
          mediaPath={relPath}
          mediaType={mediaType}
        />
      )
    )} */}

    { playProgram === 0 && (
      <AlbumIntro
        key={showIntro}  
        onStart={handleIntroStart}
        onFinish={handleIntroFinish}
        title={progTitle}
        showAlbumIntro={showIntro}
      /> 
      )
    }

    {playProgram === 1 && mediaType === 1 && channelList && (
      (
        <ProgramPhoto
          key={relPath}          
          onStart={handleProgramStart}
          onFinish={handleProgramFinish} 
          mediaPath={relPath}
          title={progTitle}
          mediaType={mediaType}
        />
      )
    )}

    {playProgram === 1 && mediaType === 2 && channelVideos && (
      (
        <ProgramVideo
          key={relPath}      
          onStart={handleProgramStart}
          onFinish={handleProgramFinish} 
          onYtVideoStart={handleYtVideoStart}
          onYtVideoEnd={handleYtVideoEnd}
          mediaPath={relPath}
          paShowPlayer={true}
          paPlay={startPlay}
          forceRender={forceRender}
          onSwipeNextProgram={handleSwipeNextProgram}
        />
      )
    )}

    {playProgram === 2 && (
        <AlbumIntro
          title={labels[language].end}
          end={true}
          onClickReload={clickReloadHandler}
        />
    )}
    </>
  );
}

export default Channels;
