import React from 'react';
import './HeroIcon.css';

const HeroIcon = ({ 
    imgSrc, 
    logoSrc, 
    imgClassName = "hero-profile-img", 
    backgroundClass, 
    description,
    onClick 
  }) => {
  return (
    <div className="hero-container">
      <div className={`hero ${backgroundClass}`} onClick={onClick}>
        <div className="hero-profile-img-container">
          {imgSrc && <img className={imgClassName} src={imgSrc} alt="" />}
        </div>
        <div className="hero-description-bk"></div>
        {logoSrc && (
          <div className="hero-logo">
            <img src={logoSrc} alt="logo" />
          </div>
        )}
        <div className="hero-description">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default HeroIcon;
