import { useEffect, useState } from 'react';

const useIsAppleDevice = () => {
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isApple = /iphone|ipad|ipod|macintosh/.test(userAgent);
    setIsAppleDevice(isApple);
  }, []);

  // return true;
  return isAppleDevice;
};

export default useIsAppleDevice;
