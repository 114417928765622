import React, { useEffect, useImperativeHandle, forwardRef } from 'react';

const B = forwardRef(({ editMode }, ref) => {
  useImperativeHandle(ref, () => ({
    customMethod() {
      alert('Custom method called in B');
    }
  }));

  useEffect(() => {
    console.log('Edit mode changed:', editMode);
  }, [editMode]);

  return (
    <div>
      <p>Edit mode is {editMode ? 'ON' : 'OFF'}</p>
    </div>
  );
});

export default B;
