// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*   margin: auto; removed to make it work in some cases. 8/1/22 */
.pinto-grid {
  width: 100%;
  visibility: hidden;
}

.pinto-grid > div {
  box-shadow: 0 4px 15px -5px #555;
  background-color: #fff;
  width: 200px;
  padding: 0px;
  margin: 5px;
}

.pinto-grid > div img {
  padding: 0px;
  display: block;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/MediaGridView/PintoPlugin.css"],"names":[],"mappings":"AAAA,kEAAkE;AAClE;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EAEE,gCAAgC;EAChC,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;AACb","sourcesContent":["/*   margin: auto; removed to make it work in some cases. 8/1/22 */\n.pinto-grid {\n  width: 100%;\n  visibility: hidden;\n}\n\n.pinto-grid > div {\n  -webkit-box-shadow: 0 4px 15px -5px #555;\n  box-shadow: 0 4px 15px -5px #555;\n  background-color: #fff;\n  width: 200px;\n  padding: 0px;\n  margin: 5px;\n}\n\n.pinto-grid > div img {\n  padding: 0px;\n  display: block;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
