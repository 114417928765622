// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.online-users {
  display: flex;
  gap: 10px;
  align-items: center;
}

.online-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.user-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  top: -10px; /* Raise the avatar above its container */
}

.username {
  margin-bottom: 5px; /* Position the username above the avatar */
  text-align: center;
  font-size: 0.9em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Online/OnlineUsers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,UAAU,EAAE,yCAAyC;AACvD;;AAEA;EACE,kBAAkB,EAAE,2CAA2C;EAC/D,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".online-users {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.online-user {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}\n\n.user-icon {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n  position: relative;\n  top: -10px; /* Raise the avatar above its container */\n}\n\n.username {\n  margin-bottom: 5px; /* Position the username above the avatar */\n  text-align: center;\n  font-size: 0.9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
