class Manager {
  constructor(renderCallback) {
    this.renderCallback = renderCallback;
  }

  invokeRender(editMode) {
    if (this.renderCallback) {
      this.renderCallback(editMode);
    }
  }
}

export default Manager;
