/**
 * Chat + video
 */
import React, { useState, useEffect, useRef } from 'react';
import TestYtPlayer from '../YoutubeView/TestYtPlayer';
import TestVideoPlayer from '../CinemaVideo/TestVideoPlayer';
import SimplePeerManager from './SimplePeerManager';
import { useSimplePeerUsers } from './SimplePeerUserContext';
import './VideoChat.css';

const VideoChat = () => {
  const { loginUserName, onlineUsers = [], sendMessage, 
          connectionStatus, connectedPeerId } = useSimplePeerUsers();

  // const { loginUserName, onlineUsers = [], sendMessage } = useSimplePeerUsers();
  const [peerManager, setPeerManager] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [otherUsers, setOtherUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isInitiater, setIsInitiater] = useState(false);
  const [inRoom, setInRoom] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  useEffect(() => {
    const handleReceivedMessage = (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    };

    const handleReceivedStream = (stream) => {
      setRemoteStream(stream);
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = stream;
      }
    };

    const handleJoin = (data) => {
      console.log(`${data.user_name} joined room: ${data.room}`);
    };

    const handleLeave = (data) => {
      console.log(`${data.user_name} left room: ${data.room}`);
    };

    if (loginUserName) {
      const roomName = 'present_room';
      const manager = new SimplePeerManager(
        roomName,
        loginUserName,
        handleReceivedMessage,
        handleReceivedStream,
        handleJoin,
        handleLeave
      );
      setPeerManager(manager);
    }

    return () => {
      if (peerManager) {
        peerManager.cleanup();
      }
    };
  }, [loginUserName]);

  useEffect(() => {
    if (localVideoRef.current && localStream) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEffect(() => {
    const others = onlineUsers.filter((user) => user !== loginUserName);
    setOtherUsers(others);
  }, [onlineUsers, loginUserName]);

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message.trim() !== '') {
      sendMessage(message);
      setMessages((prevMessages) => [...prevMessages, message]);
      setMessage('');
    }
  };

  // const startVideoStream = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  //     setLocalStream(stream);
  //     if (peerManager) {
  //       peerManager.sendStream(stream);
  //     }
  //   } catch (err) {
  //     console.error('Error accessing media devices.', err);
  //   }
  // };
  const handleStreamReady = (stream) => {
    console.log('Stream from video player is ready');
    if (peerManager) {
      peerManager.sendStream(stream); // Send the video stream
    }
  };

  const startVideoStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setLocalStream(stream);
      if (peerManager) {
        peerManager.sendStream(stream);
      }
    } catch (err) {
      console.error('Error accessing media devices.', err);
    }
  };
  return (
    <div>
      <h1>Video Chat</h1>
      <div>
        <h3>Users in the Room</h3>
        <p>Login User: <strong>{loginUserName}</strong></p>
        <h5>Other Users:</h5>
        <ul className="users-list">
          {otherUsers.map((user, index) => (
            <li key={index} className="user-item">{user}</li>
          ))}
        </ul>
      </div>
      <div>
        <p>Connection Status: <strong>{connectionStatus}</strong></p>
        {connectionStatus === 'Connected' && (
          <p>Connected to Peer ID: <strong>{connectedPeerId}</strong></p>
        )}
      </div>
      <div className="video-player-box">
        {/* <TestYtPlayer onStreamReady={handleStreamReady} /> */}
        <TestVideoPlayer onStreamReady={handleStreamReady} />
      </div>
      <div>
        <button onClick={startVideoStream}>Start Video Chat</button>
        <video ref={localVideoRef} autoPlay muted className="video-container" />
        <video ref={remoteVideoRef} autoPlay className="video-container" />
      </div>
      <div className="chat-box">
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
      </div>
      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default VideoChat;
