import React from 'react';
import Playlist from './Playlist';
import PlaylistTitle from './PlaylistTitle';
import './PlaylistWithTitle.css';


const PlaylistWithTitle = ({ 
    playlist, 
    onUpdateVideos, 
    ytEditMode=false, 
    inEditFocus=false, 
    setInEditFocus=null
  }) => {

  return (
    <div className="playlist-with-title-container">
      <PlaylistTitle 
        title={playlist?.channel_name} 
        alignment={'middle'}
      />
      <Playlist 
        playlist={playlist} 
        onUpdateVideos={onUpdateVideos} 
        ytEditMode={ytEditMode} 
        inEditFocus={inEditFocus} 
        setInEditFocus={setInEditFocus} 
        forceDisablePlay={true}
      />
    </div>
  );
};

export default PlaylistWithTitle;
