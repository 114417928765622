import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { f2futil } from '../../comjs/utils/utils.js';
import './VideoPlayer.css'; // Import your CSS file


/**
 * How autoplay video list 2nd/next video is solved?
 * 
 * Play video list in iphone Sarafi is crucial. 
 * 
 * Play video list work in all mac browser, Chrome, Firefox, Safari, Brave. But not work in iPhone
 * Sarari and Chrome.
 * 
 * On tricky parameter is 'defaultMuted' in <video>. Is it possible to keep it in browser when
 * code is loaded.
 * 
 * Root problem: 
 * 
 * 1. React auto remove 'defaultMuted' <video> flag or change it to muted=false if defining <video>
 *    inside React component, such as VideoPlayer. This somehow makes iPhone not able to auto play
 *    video list in Safari. But mac Sarari works, able to play video list. 
 * 
 * 2. Even 'defaultMuted' is kept by creating <video> with JS inside React compnent, it seems Channel
 *    management also prevent it to work properly on iPhone (others are ok). For video item of video
 *    list to be played, channel create <AlbumIntro> component first (VideoPlayer not rendered.). Then
 *    <CinemaVideo> create <AlbumIntro> component again, this is because <CinemaVideo> is for
 *    showing each video, and title display is needed. Combination of these seems not able to
 *    auto play 2nd/next video on iPhone. 
 * 
 * 3. Both  <AlbumIntro> has to be removed from channel and CinemaVideo to make auto play to work.
 *    Not showing video title is not acceptable.
 * 
 * 4. In <CinemaVideo>, we hide <VideoPlayer> after 1st video is played, and not unload <VideoPlayer>. 
 *    This will not cause <video> to be reloaded. This is the solution.
 * 
 *    Since <AlbumIntro>, <Program> is children of <Channels>, <Program> should add hide feature for
 *    VideoPlayer
 * 
 * 5. Making sure "Replay" follows the same rule in 4).
 * 
 * All above is for iPhone. Without it, desktop browsers is very tricky, such as keep <video> not 
 * unloaded and re-rendered.
 * 
 * showPlayer: 
 * 
 * Show/hide player
 * 
 * play:
 * 
 * Add this parameter is to make unmounting VideoPlayer possible. This is needed for Channel
 * video playing mode. In this mode, VideoPlayer is not unmounted when new intro title component
 * is loaded. 
 * 
 * 
 * Above may not accurate. 1/30/24
 * 
 * new implementation is done:
 * 
 * We assume playWhenLoaded=false to be the mode to work. This component will display
 * title intro, and fake out. Video will start after fade out.
 * 
 * If playWhenLoaded=true, above should be disabled (TODO)
 * 
 * Why doing this. iPhone does not work for unknown reason. Many experiements are done.
 * By display intro div inside component, which is near <video>, it may work on iPhone. I suspect
 * any element css fade in/out change may trigger iphone to think new state of browser, which needs
 * user to interact. Hope this intro to make it work on iphone for continues video list play, with
 * title intro for each video.
 * 
 * 2/1/24
 * 
 * Basically, playing video list continuously is easy to implement, but iphone, ipad is spececial, 
 * iOS Safari on desktop.
 * 
 * So, have to treat iphone/ipad specially.
 * 
 * TODO:
 * 
 * What's a mess! Now, continuous playing video list worked. Not perfect until adding white video.
 * 
 * https://chat.openai.com/c/f1882e4d-2235-439b-92f9-af050f94bda2
 * 
 * 
 * @param {*} param0 
 * @returns 
 *     defaultMuted ${autoPlay ? 'autoPlay' : ''} playsInline controls 
 */
const tmplVideo = ({ isVisible, videoSrc, autoPlay }) => `
  <video
    defaultMuted autoPlay playsInline controls 
    class="fullscreen-video ${isVisible ? 'show' : ''}">
    <source src=${videoSrc} type="video/mp4"></source>
    Your browser does not support the video tag.
  </video>
`;

const VideoPlayer = ({ 
  videoPath,
  title,
  pageOneVisible,
  onVideoStart=null,
  onVideoEnd=null,
  showPlayer=true,
  playWhenLoaded=true,  // Add the play prop
  introTime=5500,
  onStreamReady = null
}) => {
  const api = new ApiFileX();
  const util = new f2futil();
  const [isMobile, setIsMobile] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    //window.matchMedia("(orientation: landscape)").matches
    window.innerWidth > window.innerHeight
  );
  //alert('isLandscape:' + isLandscape)
  const [isVisible, setIsVisible] = useState(false);

  const containerRef = useRef();

  // TODO: weird. dynamicVideoRef.current will be set to null in another useEffect. 
  // But, videoElementRef works
  const videoElementRef = useRef(null);
  let dynamicVideoRef = useRef();

  // This is to make sure hover works on touch screen as well. User needs to touch toggle.
  const toggleText = () => {
    setShowText(true);
    setTimeout(() => {
      setShowText(false);
    }, 5000); // Hide text after 5 seconds (adjust as needed)
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };
  
    window.addEventListener('orientationchange', handleOrientationChange);
  
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  const b64_to_utf8_urlsafe = (str) => {
    // Server side is base64 urlsafe encoding. 2 characters are treated specially.
    // https://stackoverflow.com/questions/28100601/decode-url-safe-base64-in-javascript-browser-side
    let data = str.replace(/_/g, '/').replace(/-/g, '+')
    return decodeURIComponent(escape(window.atob(data)));
  }

  // It will create <video> element according to videoPath. isVisible will be put in another
  // useEffect, as no new <video> needs to be created.
  useEffect(() => {
    // Make it compatible with old code.
    let newFormat = true;
    let urlInfo = null;
    try {
      urlInfo = util.parseMediaUrl(videoPath);
    } catch (error) {
      newFormat = false;
    }

    let videoSrc = null;
    let mediaRelpath = videoPath;
    if (newFormat) {

         // Figure out parameters that matches with old format.
      const path = urlInfo.relpath ? 
          `${api.decodeBase64ToUrl(urlInfo.relpath)}/${api.decodeBase64ToUrl(urlInfo.media_id)}` : 
          api.decodeBase64ToUrl(urlInfo.media_id);
      mediaRelpath = api.encodeUrlToBase64_UrlSafe(path);

      videoSrc = `/media_lv_video_root/${mediaRelpath}`;
    } else {
      videoSrc = `/media_video_root/${mediaRelpath}`;
    }

    const videoTemplate = tmplVideo({ 
      isVisible, 
      videoSrc: videoSrc,
      autoPlay: playWhenLoaded 
    });
  
    // Create a temporary div to hold the video element
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = videoTemplate;
  
    // Get the video element from the temporary div
    dynamicVideoRef = tempDiv.querySelector('.fullscreen-video');

    // Add event listeners as needed for the dynamically created video element
    dynamicVideoRef.addEventListener('play', handleVideoPlay);
    dynamicVideoRef.addEventListener('ended', handleVideoEnd);
    dynamicVideoRef.addEventListener('error', handleVideoError); // Add error event listener

    // Append the dynamically created video element to the container
    containerRef.current.appendChild(dynamicVideoRef);

    // won't persist in another useEffect
    dynamicVideoRef.current = containerRef.current.querySelector('.fullscreen-video');

    videoElementRef.current = containerRef.current.querySelector('.fullscreen-video');
 
    // Trigger the onStreamReady callback with the video stream
    if (onStreamReady && videoElementRef.current.captureStream) {
      const stream = videoElementRef.current.captureStream();
      onStreamReady(stream);
    }
    // Record played video.
    /*
    const api = new ApiFileX();
    api.trackPlayedVideo(videoPath).then(async (retData) => {
      const result = retData.data;
      if (result) {

      };
    });
    */

    return () => {
      // Remove event listeners and clean up dynamically created video element
      dynamicVideoRef.removeEventListener('play', handleVideoPlay);
      dynamicVideoRef.removeEventListener('ended', handleVideoEnd);
      dynamicVideoRef.removeEventListener('error', handleVideoError); // Remove error event listener

      // Stop video playback and perform any other cleanup
      if (dynamicVideoRef.current) {
        dynamicVideoRef.current.pause();
        dynamicVideoRef.current = null;
      }

      // Check if containerRef.current is not null before trying to remove the child
      if (containerRef.current && dynamicVideoRef.parentElement === containerRef.current) {
        containerRef.current.removeChild(dynamicVideoRef);
        containerRef.current = null;
      }
    };
  }, [videoPath]);
  
  useEffect(() => {
    if (videoElementRef.current === null) {
      return;
    }

    if (isVisible) {
      videoElementRef.current.classList.add('show');
    } else {
      videoElementRef.current.classList.remove('show');
    }    
  }, [isVisible]);

  // Make sure the order is after above useEffect()
  useEffect(() => {
    let timeOutStartAgain = null;
    const startAgain = () => {
      timeOutStartAgain = setTimeout(() => {
        const videoEle = document.querySelector('video');
        videoEle.play();      
      }, 100);
    };

    let timeOutIideIntro = null;
    const hideIntro = () => {
      if (containerRef.current == null) {
        return;
      }

      timeOutIideIntro = setTimeout(() => {
        const titleElement = containerRef.current.querySelector('.video-intro');
        if (titleElement) {
          titleElement.classList.add('video-intro-hide');
        }
        //const videoEle = document.querySelector('video');
        //document.querySelector('video').pause();

        //startAgain();
        ///document.querySelector('video').play();
        // Adjust so that user can see fade out, then the display:none kickes in without delay.
        // css opacity ease-out is 3s.
      }, 2000);   
    };

    // This is to wait according to introTime. Then start to play in hideIntro(). There is ease-out
    // period of the title in hideIntro() before video starts. The ease-out time of opacity 1-->0 is
    // defined in css, which is 3seconds. 2000 of hideIntro() is to accommodate with is 3s value,
    // to make display:none before fully fades out, which is an effect to achieve.
    let timeOutPlayVideoWithDelay = null;
    const playVideoWithDelay = () => {
      if (containerRef.current == null) {
        return;
      }

      timeOutPlayVideoWithDelay = setTimeout(() => {
        const titleElement = containerRef.current.querySelector('.video-intro');
        if (titleElement) {
          titleElement.classList.add('video-intro-fade');
        }

        hideIntro();

        // <video> is visible
        setIsVisible(true);
        /*
        dynamicVideoRef.play().then(() => {
          // Video started playing, add fade-out class to title.

          const titleElement = containerRef.current.querySelector('.video-intro');
          if (titleElement) {
            titleElement.classList.add('video-intro-fade');

            // In css, fade takes 1000. This delay function of 1000 is to set display: none, 
            // so that video is on top, which can be clicked. The 1000 delay is for user to 
            // see fade out, and after that, it's removed by display:none.
            hideIntro();
          }
        });
        */
      }, introTime); 
    };

    //dynamicVideoRef.addEventListener('loadeddata', playVideoWithDelay);
    playVideoWithDelay();

    return () => {
      //dynamicVideoRef.removeEventListener('loadeddata', playVideoWithDelay);
      if (timeOutPlayVideoWithDelay) {
        clearTimeout(timeOutPlayVideoWithDelay);
        timeOutPlayVideoWithDelay = null;
      }

      if (timeOutStartAgain) {
        clearTimeout(timeOutStartAgain);
        timeOutStartAgain = null;
      }

      if (timeOutIideIntro) {
        clearTimeout(timeOutIideIntro);
        timeOutIideIntro = null;
      }
    };
  }, [playWhenLoaded]);

  const handleVideoPlay = () => {
    if (onVideoStart) {
      onVideoStart();
    }
  };

  const handleVideoEnd = () => {
    if (onVideoEnd) {
      onVideoEnd();
    }
  };
  const handleVideoError = () => {
    if (onVideoEnd) {
      onVideoEnd('Video error');
    }
  };

  const toggleFullScreen = () => {
    const video = containerRef.current.querySelector('.fullscreen-video');
  
    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    // This is for iphone/ipad. It does not rorate, so it's to use js to do it.
    const videoElement = videoElementRef.current;

    // Without this code (not doing things?), <video> does not rotate correctly
    // on device.
    if (videoElement) {
      if (isLandscape) {
        videoElement.style.transform = 'rotate(0deg)';
      } else {
        videoElement.style.transform = 'rotate(0deg)';
      }
    }

    /*
    // video container also not rotate on iphone/ipad
    const containerElement = containerRef.current;
    if (containerElement) {
      if (isLandscape) {
        containerElement.style.transform = 'rotate(0deg)';
      } else {
        containerElement.style.transform = 'rotate(90deg)';
      }
    }
    */
  }, [isLandscape]);
  
  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;

      // Check for touch support
      const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;

      setIsMobile(isMobileDevice && isTouchDevice);
    };

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`video-player-container 
        ${showPlayer ? '' : 'hide-player'}
        ${isLandscape ? 'video-landscape' : 'video-portrait'
      }`}
      onMouseOver={() => setShowText(true)}
      onMouseOut={() => setShowText(false)}
      onTouchStart={() => toggleText()}
      onTouchEnd={() => setShowText(false)}
      ref={containerRef} // Reference to the container for appending the video element
    >
      <div className="video-intro">
        <div className="text multiline-text">
          {title}
        </div>
      </div>
      {showText && isVisible && <div className="video-title">{title}</div>}
      {showText && isVisible && !isMobile && (
        <button className="fullscreen-button" onClick={toggleFullScreen}>
          Full Screen（全屏）
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;
