/**
 * Abandon as it can be merged to VideoCard, which treats 2 type video the same except players.
 * If not using same code base, edit mode makes code more complicated.
 * 
 * This is to display local host video thumbnail, and small size of player if clicked to play.
 * VideoCard is for youtube video thumbnail and small size player.
 * 
 */
import React, { useState, useEffect, useRef } from 'react';
import { useVideoPlayPage } from '../System/VideoPlayPageContext.jsx';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { f2futil } from '../../comjs/utils/utils.js';
import './VideoPlayerSized.css';

const tmplVideo = ({ videoSrc, autoPlay }) => `
  <video className="video-player"
    ${autoPlay ? 'autoPlay' : ''}
    defaultMuted
    playsInline
    controls 
    class="sized-video show">
    <source src=${videoSrc} type="video/mp4"></source>
    Your browser does not support the video tag.
  </video>
`;

const VideoPlayerSized = ({ 
  videoPath,
  movie_title,
  thumbnail,
  duration,

  // Manage only 1 vide is plable in a playlist, or multiple playlist
  isPlaying,        // Movie id passed in from parent, which is used to compare 'movie_title' 
  setPlayingVideo,  // Parent state setter. This is how to notify parent that 
  
  onVideoClick = null,
  onVideoEnd = null,
  playWhenLoaded = false,
}) => {
  // APIs and utilities.
  const api = new ApiFileX();
  const util = new f2futil();

  // Single movie playing in multiple playlist.
  const { playingVideoPage, setPlayingVideoPage } = useVideoPlayPage(); 

  // Movie signature
  // const en_title_id = api.encodeUrlToBase64(movie_title);
  const isPlayingPageSig = `${videoPath}`;

  // This component activate playing movie.
  const [isPlayerActive, setIsPlayerActive] = useState(false);

  // Swtich between player and thumbnail.
  const containerRef = useRef();
  let dynamicVideoRef = useRef();


  useEffect(() => {
    if (isPlayerActive) {
      let newFormat = true;
      let urlInfo = null;
      try {
        urlInfo = util.parseMediaUrl(videoPath);
      } catch (error) {
        newFormat = false;
      }

      let videoSrc = null;
      let mediaRelpath = videoPath;
      if (newFormat) {
        const path = urlInfo.relpath ? 
          `${api.decodeBase64ToUrl(urlInfo.relpath)}/${api.decodeBase64ToUrl(urlInfo.media_id)}` : 
          api.decodeBase64ToUrl(urlInfo.media_id);
        mediaRelpath = api.encodeUrlToBase64_UrlSafe(path);
        videoSrc = `/media_lv_video_root/${mediaRelpath}`;
      } else {
        videoSrc = `/media_video_root/${mediaRelpath}`;
      }

      const videoTemplate = tmplVideo({ 
        videoSrc: videoSrc,
        autoPlay: true 
      });
    
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = videoTemplate;
      dynamicVideoRef = tempDiv.querySelector('.sized-video');

      dynamicVideoRef.addEventListener('play', handleVideoPlay);
      dynamicVideoRef.addEventListener('ended', handleVideoEnd);
      containerRef.current.appendChild(dynamicVideoRef);
      dynamicVideoRef.current = containerRef.current.querySelector('.sized-video');

      return () => {
        dynamicVideoRef.removeEventListener('play', handleVideoPlay);
        dynamicVideoRef.removeEventListener('ended', handleVideoEnd);
        if (dynamicVideoRef.current) {
          dynamicVideoRef.current.pause();
          dynamicVideoRef.current = null;
        }
        if (containerRef.current && dynamicVideoRef.parentElement === containerRef.current) {
          containerRef.current.removeChild(dynamicVideoRef);
          containerRef.current = null;
        }
      };
    }
  }, [isPlayerActive, videoPath]);

  useEffect(() => {
    // When to stop current VideoCard?
    // 1) If clicked other VideoCard within VideoGrid. 
    //    setPlayingVideo(null); will make !isPlaying = true. This happend when vide finishes.
    // 2) If clicked a VideoCard in another VideoGrid.
    if (!isPlaying) {
      setPlayingVideo(null);
    }
  }, [isPlaying, setPlayingVideo]);

  const handleVideoPlay = (evt) => {
    setPlayingVideo(movie_title);
    setPlayingVideoPage(isPlayingPageSig);

    if (onVideoClick) {
      const clickedVideoInfo = {
        title: movie_title,
        relpath: videoPath
      }
      onVideoClick(evt, clickedVideoInfo);
    }
  };

  const handleVideoEnd = (evt) => {
    setPlayingVideo(null);
    setPlayingVideoPage(null);
    if (onVideoEnd) {
      onVideoEnd();
    }
    setIsPlayerActive(false);
  };

  const handleClickPlay = (evt) => {
    evt.preventDefault();
    evt.stopPropagation(); 
    setIsPlayerActive(true);
  };

  return (
    <div
      className={`sized-video-player-container ${isPlaying ? 'playing' : ''}`}
      ref={containerRef}
    >
      {!isPlayerActive ? (
        <>
          <div className="video-cell">
            <div 
              className="movie" 
              onClick={handleClickPlay}
            >
              <a href="${playmovie_url}">
              <img className="img-thumbnail-video" src={`data:image/png;base64, ${thumbnail}`} />
              </a>
            </div>
            <div className="div-play-button">
              <a href="${playmovie_url}" onClick={handleClickPlay}>
                <div className="play-icon"></div>
              </a>
            </div>
            <div className="movie-contents">
              <a className="a-content" onClick={handleClickPlay}>
              ${movie_title}
              </a>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default VideoPlayerSized;

{/* <div className="movie">
  <a href="#" onClick={handleClickPlay}>
    <img className="img-thumbnail-video" src={`data:image/png;base64, ${thumbnail}`} />
    <div className="div-yt-play-button">
      <div className="play-icon"></div>
    </div>
  </a>
  <div className="time-label">{duration}</div>
</div>
<div className="video-title" onClick={handleClickPlay}>
  {title}
</div> */}