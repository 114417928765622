import { useState } from "react";
import files from './files.json';
import './DirectoryLab.css';


export const DirectoryLab = ({ files }) => {
    const [isExpanded, toggleExpanded] = useState(false);

    if (files.type === 'folder') {
        // Sort items so that folders come before files.
        // We are assuming files.json put files above folder items. This is to reverse that
        // so that folder in UI is on top of files (large number usually). This is to make
        // folder visible on top of large file list.
        const sortedItems = files.items.sort((a, b) => {
            if (a.type === 'folder' && b.type === 'file') {
                return -1; // Move folder above file
            } else if (a.type === 'file' && b.type === 'folder') {
                return 1; // Move file below folder
            }
            return 0; // Maintain the order for items of the same type
        });

        return (
            <div className="mf-folder">
                <div className="folder-title" 
                    onClick={() => toggleExpanded(!isExpanded)}>{files.name}</div>
                {
                    isExpanded && sortedItems.map((item, index) => 
                        <DirectoryLab key={index} files={item} />)
                }
            </div>
        )
    }

    return (
        <>
            <h3 className="file-name">{files.name}</h3>
        </>
    )
}


export const FileManagerLab = () => {
  return (
    <div className="file-manager-container">
      <div className="spacing">
          <DirectoryLab files={files} />
      </div>
    </div>
  );
};
