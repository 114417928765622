/**
 * It's grid view of one directory
 * 
 * YtDirectory has same feature as VideoGrid, which is inspired from YtDirectory.
 * Keep YtDirectory around for now, even it has duplicated feature.
 */

import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; 
import $ from 'jquery';
import {ApiFileX} from '../../../comjs/filex.api/filex-api.js';
import { ytDirectoryView } from '../../../comjs/youtube.view/youtube-view.js';
//import { useConfig } from '../System/ConfigContext.jsx';
//import { useAppContext } from '../../AppContext';

import { useConfig } from '../../System/ConfigContext.jsx';

// This is app root managed by RootContext
import YoutubeEditor from '../YoutubeEditor.jsx';
import DirectorySection from './SectionDirectory.jsx';
import ConfirmationDialog from '../../Util/ConfirmationDialog.jsx';
import './YtDirectory.css';

const YtDirectory = ({
  relpath=null,  // it contains dir itself. Dir name will be extracted.
  dirInfo=null,
  onClickVideo=null,
  onClickFolder=null,
  ytEditMode=false,
  onePageView=true
}) => {
  const api = new ApiFileX();
  const [oneDirView, setOneDirView] = useState(null);
  const [firstPageLoadDone, setFirstPageLoadDone] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);
  const [thumbnailToDelete, setThumbnailToDelete] = useState(null);
  const editContainerRef = useRef(null);
  const editRootRef = useRef(null);

  const [dirname, setDirName] = useState('');
  const [encodeDirName, setEncodeDirName] = useState('');

  // This is to figure out details of relpath, if it's not null.
  useEffect(() => {
    if (!relpath) return;

    const decodeRelPath = api.decodeBase64ToUrl(relpath);
    const dirname = api.getRightmostSubdir(decodeRelPath);
    const enDirName = api.encodeUrlToBase64_UrlSafe(dirname);

    setDirName(dirname);
    setEncodeDirName(enDirName);
  }, [relpath]);

  const handleEndLoadFirstPage = () => {
    setFirstPageLoadDone(true);
  };

  const handleOnClickVideo = (evt, title) => {
    const parentDiv = $(evt.target).closest('a').parent().parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');
    const ytId = href.attr('href');

    let newUrl = `/ytplayerdirview/${ytId}`;
    const enTitle = api.encodeUrlToBase64_UrlSafe(title);
    newUrl += `/${enTitle}`;

    const paramRelPath = relpath || '';

    if (paramRelPath) {
      newUrl += `/${paramRelPath}`;
    }

    // Root has no relpath and has no dir name.
    if (encodeDirName) {
      newUrl += `/${encodeDirName}`;
    }

    if (onClickVideo) {
      onClickVideo(ytId, title, dirInfo);
    }

    window.history.pushState(null, null, newUrl);
  };

  const handleOnClickFolder = (evt, dirName) => {
    const parentDiv = $(evt.target).closest('a').parent().parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');
    const folderRelPath = href.attr('href');

    onClickFolder(folderRelPath, dirInfo, dirName);
    window.history.pushState(null, null, `/folder/${folderRelPath}`);
  };

  const handleOnNewVideoCreated = (evt, newVideo) => {
    if (oneDirView) {
      const newItem = oneDirView.addItem(newVideo, false, true);
      const ytId = newItem.find('.movie a').attr('href');
      const deleteButton = $('<button class="delete-button">x</button>');
      newItem.append(deleteButton);
      deleteButton.on('click', () => confirmDelete(ytId, newItem));
    }
  };

  const handleOnCancel = () => {
    if (editRootRef && editRootRef.current) {
      editRootRef.current.unmount();
      editRootRef.current = null;
    }
    oneDirView.removeYtEdit();
  };

  const confirmDelete = (ytId, thumbnailElement) => {
    setVideoToDelete(ytId);
    setThumbnailToDelete(thumbnailElement);
    setShowModal(true);
  };

  const handleDelete = async () => {
    setShowModal(false);
    try {
      const retData = await api.deleteYtVideo(videoToDelete, relpath);
      if (retData && retData.status) {
        thumbnailToDelete.remove();
      } else {
        alert(`Failed to delete the video. Server returned an error: ${retData.error}`);
      }
    } catch (error) {
      alert(`Failed to delete the video: ${error}`);
    }
  };

  useEffect(() => {
    if (oneDirView && firstPageLoadDone) {
      if (ytEditMode) {
        const editContainer = oneDirView.addYtEdit();
        editContainerRef.current = editContainer[0];

        // Not sure why this is added. createRoot() will cause warning if called more than 
        // once.
        // I think the reason: if using this component without index.js to debug. index.js
        // is responsible to call createRoot().
        if (!editRootRef.current) {
          editRootRef.current = createRoot(editContainerRef.current);
        }

        editRootRef.current.render(
          <YoutubeEditor relpath={relpath} onNewVideoCreated={handleOnNewVideoCreated} />
        );
      } else {
        if (editRootRef.current) {
          editRootRef.current.unmount();
          editRootRef.current = null;
        }
        oneDirView.removeYtEdit();
      }
    }
  }, [ytEditMode, firstPageLoadDone]);

  useEffect(() => {
    return () => {
      if (editRootRef && editRootRef.current) {
        editRootRef.current.unmount();
        editRootRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (oneDirView && firstPageLoadDone && ytEditMode) {
      $('.movie').parent().each((index, element) => {
        const ytId = $(element).find('.movie a').attr('href');
        const deleteButton = $('<button class="delete-button">x</button>');
        $(element).append(deleteButton);
        deleteButton.on('click', () => confirmDelete(ytId, $(element)));
      });
    }
  }, [ytEditMode, firstPageLoadDone, oneDirView]);

  useEffect(() => {
    if (oneDirView) return;

    try {
      api.getDirInfo(relpath).then(async (retData) => {
        if (retData && retData.status) {
          const retValDirInfo = retData.data[0];
          const videoList = retValDirInfo.dir_files;
          const dirInfo = {
            dir_files: retValDirInfo.dir_files
          };

          const options = {
            onEndLoadFirstPage: handleEndLoadFirstPage,
            onClickVideo: handleOnClickVideo
          };

          const videoGrid = new ytDirectoryView(
            retValDirInfo,
            $('.yt-one-dir-view'),
            relpath,
            null,
            options,
            videoList,
            ytEditMode,
            onePageView
          );
          videoGrid.create();

          setOneDirView(videoGrid);
        } else {
          const err = 'getDirInfo failed: ' + retData.error;
          console.error(err);
        }
      });
    } catch (error) {
      console.error('Exception:', error);
    }

    return () => {
      if (oneDirView) {
        oneDirView.destroy();
      }
    };
  }, []);

  return (
    <>
      <div className="yt-one-dir-view"></div>
      <DirectorySection 
        relpath={relpath}
        secTitle={dirname}
        enablePlay={!ytEditMode}
      /> 
      <ConfirmationDialog
        show={showModal}
        title={'Delete Video'}
        message={'Are you sure you want to delete this video?'}
        onConfirm={handleDelete}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};

export default YtDirectory;
