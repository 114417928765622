// customAlert.js

import $ from 'jquery'; // Import jQuery

(function ($) {
  $.fn.customAlert = function (options) {
    var settings = $.extend(
      {
        message: 'This is a custom alert!',
        backgroundColor: '#f0f0f0',
        textColor: '#333',
        padding: '20px',
        borderRadius: '5px',
      },
      options
    );

    return this.each(function () {
      var $this = $(this);

      $this.on('click', function () {
        var $alert = $('<div>')
          .addClass('custom-alert')
          .text(settings.message)
          .css({
            backgroundColor: settings.backgroundColor,
            color: settings.textColor,
            padding: settings.padding,
            borderRadius: settings.borderRadius,
          });

        $('body').append($alert);

        $alert.fadeIn(300, function () {
          setTimeout(function () {
            $alert.fadeOut(300, function () {
              $alert.remove();
            });
          }, 2000);
        });
      });
    });
  };
})($); // Pass the jQuery alias as a parameter to the immediately-invoked function expression
