import SimplePeer from 'simple-peer';
import io from 'socket.io-client';

class PeerManager {
  constructor(room, userName, isInitiator, onMessage) {
    this.room = room;
    this.userName = userName;
    this.isInitiator = isInitiator;
    this.onMessage = onMessage;
    this.peers = {};
    this.socket = io('http://localhost:5000/signal', {
      transports: ['websocket', 'polling']
    });

    this.socket.on('connect', () => {
      console.log('WebSocket connection established');
      this.joinRoom();
    });

    this.socket.on('joined', (data) => {
      console.log(`${data.user_name} joined room: ${data.room}`);
      if (data.user_id && data.user_id !== this.socket.id) {
        const newPeer = this.createPeerConnection(data.user_id);
        this.peers[data.user_id] = newPeer;
      }
    });

    this.socket.on('signaling_message', ({ signal, from }) => {
      if (this.peers[from]) {
        this.peers[from].signal(signal);
      } else {
        const newPeer = this.createPeerConnection(from);
        newPeer.signal(signal);
        this.peers[from] = newPeer;
      }
    });

    this.socket.on('left', (data) => {
      if (this.peers[data.user_id]) {
        this.peers[data.user_id].destroy();
        delete this.peers[data.user_id];
      }
    });

    this.socket.on('error', (err) => {
      console.error(err.message);
    });
  }

  joinRoom() {
    this.socket.emit('join', {
      room: this.room,
      user_name: this.userName
    });
  }

  createPeerConnection(peerId) {
    const p = new SimplePeer({
      initiator: this.isInitiator,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' }
        ]
      }
    });

    p.on('error', err => console.error('Peer error:', err));

    p.on('signal', data => {
      console.log('SIGNAL', JSON.stringify(data));
      this.socket.emit('signal', { room: this.room, signal: data, to: peerId });
    });

    p.on('connect', () => {
      console.log('Peer connected');
    });

    p.on('data', data => {
      const textDecoder = new TextDecoder();
      const decodedData = textDecoder.decode(data);
      console.log('Data received:', decodedData);
      if (this.onMessage) {
        this.onMessage(decodedData);
      }
    });

    return p;
  }

  sendMessage(message) {
    Object.values(this.peers).forEach(peer => peer.send(message));
  }

  handleIncomingSignal(signalData) {
    Object.values(this.peers).forEach(peer => peer.signal(signalData));
  }
}

export default PeerManager;
