import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiFileX } from '../../comjs/filex.api/filex-api';
import { useConfig } from '../System/ConfigContext';
import { IconMenuBar } from '../IconMenuBar/IconMenuBar';
import ConfirmationDialog from '../Util/ConfirmationDialog';
import Playlist from './Playlist';
import Tooltip from '../Util/Tooltip';
import './PlaylistMan.css';

const PlaylistMan = () => {
  const navigate = useNavigate();
  const { config } = useConfig(); // Access context correctly
  const [ytEditMode, setYtEditMode] = useState(config.ytEditMode);
  const [playlists, setPlaylists] = useState([]);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [selFileName, setSelFileName] = useState(null);  // Channel json file name.
  const [errorMessage, setErrorMessage] = useState('');
  const [randomStatus, setRandomStatus] = useState({});
  const inputRef = useRef(null);
  const playlistRefs = useRef({});
  const [focusedChannel, setFocusedChannel] = useState(null);
  const [titleErrors, setTitleErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleteFileName, setDeleteFileName] = useState(null);

  useEffect(() => {
    if (null == selectedPlaylist) {
    }
  }, [selectedPlaylist]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const api = new ApiFileX();
        const retData = await api.listPlaylists();
        if (retData && retData.status) {
          const playlistsArray = Object.keys(retData.data).map(key => ({
            file_name: key,
            ...retData.data[key]
          }));
          setPlaylists(playlistsArray);

          const initialRandomStatus = playlistsArray.reduce((acc, playlist) => {
            acc[playlist.file_name] = playlist.random || false;
            return acc;
          }, {});
          setRandomStatus(initialRandomStatus);
        } else {
          console.error("Failed to fetch playlists.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPlaylists();
  }, []);

  const handleCreatePlaylist = async () => {
    const newName = newPlaylistName.trim();
    if (newName.length < 4) {
      setErrorMessage('Playlist name must be at least 4 characters long.');
      return;
    }
    setErrorMessage('');
    try {
      const api = new ApiFileX();
      const retData = await api.createPlaylist(api.encodeUrlToBase64_UrlSafe(newName));
      if (retData && retData.status) {
        const newPlaylist = retData.data;
        setPlaylists([newPlaylist, ...playlists]);
        setIsAddingNew(false);
        setNewPlaylistName('');
        setSelectedPlaylist(newPlaylist);
      } else {
        console.error("Failed to create playlist.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateVideos = (fileName, updatedVideos) => {
    const newPlaylists = playlists.map(playlist => 
      playlist.file_name === fileName 
        ? { 
            ...playlist, 
            play_videos_encoded: {
              ...playlist.play_videos_encoded,
              files_relpath: updatedVideos
            }
          }
        : playlist
    );

    setPlaylists(newPlaylists);
  };

  const handleDeletePlaylist = async (evt, fileName, channelName) => {
    evt.preventDefault();

    setDeleteFileName(fileName);
    setDeleteMessage('Are you sure you want to delete the channel "' +
        channelName + '"? (The videos are not affected. Only the channel is deleted.)');
    setShowModal(true);
  };

  const handleAddNewClick = () => {
    setIsAddingNew(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleClickEdit = (evt, playlist) => {
    evt.preventDefault();

    setFocusedChannel(playlist);

    setSelectedPlaylist(playlist);
    setSelFileName(playlist.file_name);
    document.addEventListener('click', handleClickOutside, true);
  };

  const handleBallClick = () => {
    let url = '/sysicon';
    navigate(url);
  }

  const handleRandomChange = async (evt, fileName, isRandom) => {
    evt.preventDefault();

    try {
      const api = new ApiFileX();
      const retData = await api.setChannelRandomState(fileName, isRandom);
      if (retData && retData.status) {
        setRandomStatus({
          ...randomStatus,
          [fileName]: isRandom
        });
      } else {
        console.error("Failed to update random status.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = async () => {
    setShowModal(false);

    const fileName = deleteFileName;
    try {
      const api = new ApiFileX();
      const retData = await api.deletePlaylist(fileName);
      if (retData && retData.status) {
        setPlaylists(playlists.filter(playlist => playlist.file_name !== fileName));
        if (selectedPlaylist && selectedPlaylist.file_name === fileName) {
          setSelectedPlaylist(null);
        }
      } else {
        setErrorMessage('Failed to delete playlist.');
      }
    } catch (error) {
      setErrorMessage(error);
    }

    setTimeout(() => {
      setErrorMessage('');
    }, 15000);
  };

  const cancelDelete = async () => {
    setDeleteFileName(null);
    setShowModal(false);
  };

  const handleSaveTitle = async (fileName, newTitle) => {
    const newName = newTitle.trim();
    if (newName.length < 4) {
      setTitleErrors(prev => ({ ...prev, [fileName]: 'Playlist name must be at least 4 characters long.' }));
      return;
    }
    setTitleErrors(prev => ({ ...prev, [fileName]: '' }));
  
    try {
      const api = new ApiFileX();
      const enNewTitle = api.encodeUrlToBase64_UrlSafe(newName);
      const retData = await api.updateChannelName(fileName, enNewTitle);
      if (retData && retData.status) {
        setPlaylists(playlists.map(playlist => 
          playlist.file_name === fileName 
            ? { ...playlist, channel_name: newTitle } 
            : playlist
        ));
      } else {
        console.error("Failed to update channel name.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlur = (e, fileName) => {
    e.preventDefault();

    const newTitle = e.target.innerText.trim();
    if (newTitle && newTitle !== playlists.find(pl => pl.file_name === fileName).channel_name) {
      handleSaveTitle(fileName, newTitle);
    } else {
    }
  };

  const handleClickOutside = (event) => {
    event.preventDefault();

    if (focusedChannel) {
      const ref = playlistRefs.current[focusedChannel.file_name];
      if (ref && !ref.contains(event.target)) {
        setFocusedChannel(null);  
      }
    }

    if (selectedPlaylist) {
      const ref = playlistRefs.current[selFileName];
      
      console.log(event.target);
      if (ref && !ref.contains(event.target)) {
        console.log('inside...');
        setSelFileName(null);
        setSelectedPlaylist(null);
        document.removeEventListener('click', handleClickOutside, true);
      } else {
        console.log('outside...');
      }
    }
  };

  const setPlaylistRef = (fileName, ref) => {
    playlistRefs.current[fileName] = ref;
  };

  const handleChange = (e) => {
    e.preventDefault();

    setNewPlaylistName(e.target.value)
  };

  // List all items in channel.
  const handleChannelTitleClick = (fileName) => {
    handlePlayButtonClick(fileName);
  };

  // Play channel.
  const handlePlayButtonClick = (fileName) => {
    const api = new ApiFileX();
    const channel = api.removeFileExtension(fileName);

    let url = `/playchannel/${channel}`;
    navigate(url);
  };

  return (
    <>
    <div className="playlist-manager">
      <h3>Channels</h3>
      {ytEditMode && (
        <div className="playlist-actions">
          {isAddingNew ? (
            <div className="new-playlist-input">
              <input
                ref={inputRef}
                type="text"
                value={newPlaylistName}
                onChange={handleChange}
                placeholder="Playlist name"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleCreatePlaylist();
                }}
              />
              <button onClick={handleCreatePlaylist}>OK</button>
              <button onClick={() => setIsAddingNew(false)}>Cancel</button>
            </div>
          ) : (
            <button className="add-playlist-button" onClick={handleAddNewClick}>+</button>
          )}
        </div>
      )}
  
      <div className="playlist-list">
        {playlists.map(playlist => (
          <div 
              key={playlist.file_name} 
              className="playlist-item"
              ref={(ref) => setPlaylistRef(playlist.file_name, ref)}
          >
            <div className="playlist-info">
              {selFileName === playlist.file_name ? (
                <div>
                  <div
                    contentEditable
                    suppressContentEditableWarning
                    className="editable-title editing"
                    onBlur={(e) => handleBlur(e, playlist.file_name)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleBlur(e, playlist.file_name);
                      }
                    }}
                    ref={inputRef}
                  >
                    {playlist.channel_name}
                  </div>
                  {titleErrors[playlist.file_name] && <span className="error-message">{titleErrors[playlist.file_name]}</span>}
                </div>
              ) : (
                <div className="non-edit-mode">
                  <span 
                    className="playlist-name"
                    onClick={() => handleChannelTitleClick(playlist.file_name)}
                  >
                    {playlist.channel_name}
                  </span>
                  <button 
                    className="play-button" 
                    onClick={() => handlePlayButtonClick(playlist.file_name)}
                  >
                    <img src="/static/img/channel-play.webp" alt="Play" />
                  </button>
                  <Tooltip text="Play following videos in random order.">
                    <div className="toggle-switch-container">
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          checked={randomStatus[playlist.file_name] === true}
                          onChange={(e) => handleRandomChange(e, playlist.file_name, !randomStatus[playlist.file_name])}
                        />
                        <span className="slider"></span>
                      </label>
                      <span className="random-text">Random</span>
                    </div>
                  </Tooltip>
                </div>
              )}
              {ytEditMode && selectedPlaylist !== playlist && (
                <div className="playlist-item-actions">

                  <button onClick={(e) => handleClickEdit(e, playlist)} className="edit-channel-button">
                    Edit
                  </button>
                  <button onClick={(e) => handleDeletePlaylist(e, playlist.file_name, playlist.channel_name)} className="delete-channel-button">
                    X
                  </button>
                </div>
              )}
            </div>
            <div className="playlist-detail">
              <Playlist 
                key={playlist.file_name}
                playlist={playlist} 
                onUpdateVideos={handleUpdateVideos}
                ytEditMode={ytEditMode} 
                inEditFocus={playlist === selectedPlaylist}
                // playButton2PagePlayer={true}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  
    <IconMenuBar
      onClickRotatingBall={handleBallClick} 
    /> 
    <ConfirmationDialog
      show={showModal}
      title={'Delete'}
      message={deleteMessage}
      onConfirm={confirmDelete}
      onCancel={cancelDelete}
    />
    </>
  );
};

export default PlaylistMan;
