/**
 * npm install react-helmet
 * npm install jquery jquery-ui for gridstack.js
 * 
 * problem to use gridstack.js. 
 * jquery-ui/data not found.
 * https://github.com/gridstack/gridstack.js/issues/524 
 * 
 * jquery-ui is packed with gridstack.???
 * 
 * 
 * Solved (1/16/24):
 * 
 * It's confusing configuration. I installed react-gridstack (not really working). 
 * Then installed gridstack. This process screwed up. I see both package in node_modules, but
 * only react-gridstack in package.json.
 * 
 * Problem starts here. I tried to make gridstack working, but it does not.
 * 
 * What make it work: npm uninstall both package. then, install gridstack. Now GridStack class
 * is accessible in React component.
 * 
 */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';
import './GridStackLab1.css';


const GridStackLab1 = () => {

  // useEffect(() => {
  //   // Initialize Gridstack
  //   $(function () {
  //     const grid = GridStack.init();
  //     // Your grid initialization code here
  //   });
  // }, []);
  useEffect(() => {
    const options = {
      cellHeight: '11rem',
      maxRow: 3
    }
      
    //GridStack.init(options);
    if (typeof GridStack !== 'undefined') {
      // Initialize GridStack or use it as needed
      const grid = GridStack.init(options);
    } else {
      // GridStack is not defined yet, you might want to handle this case
      console.error('GridStack is not defined. Make sure the script has loaded.');
    }
  }, []);

  return (
    <div>
      {/* Other components and content of your component */}
      <div className="body-content">
     <aside>
       <h1 className="app-name">Dashboard</h1>
       <ul>
         <li className="active">
           <i className="bx bxs-dashboard active"></i> Overview
         </li>
         <li><i className="bx bx-money"></i> Expenses</li>
         <li><i className="bx bxs-wallet"></i> Budget</li>
       </ul>
       <div className="logout-bar"><i className="bx bx-log-out"></i> Logout</div>
     </aside>
     <div className="overview">
       <header>
         <div><span className="avatar">A</span> Admin</div>
       </header>
       <main>
         <h2>Overview</h2>
         <div className="grid-stack">
           <div
             className="grid-stack-item"
             gs-x="0"
             gs-y="0"
             gs-w="4"
             gs-min-w="2"
           >
             <div className="grid-stack-item-content revenue">
               <p className="description">Revenue this month</p>
               <p className="metric-value">$10,018.00</p>
             </div>
           </div>
           <div
             className="grid-stack-item"
             gs-x="4"
             gs-y="0"
             gs-w="4"
             gs-min-w="2"
           >
             <div className="grid-stack-item-content customer-overview">
               <p className="description">Total Customers</p>
               <p className="metric-value">5,133</p>
             </div>
           </div>
           <div
             className="grid-stack-item"
             gs-x="8"
             gs-y="0"
             gs-w="4"
             gs-min-w="2"
           >
             <div className="grid-stack-item-content growth">
               <p className="description">Monthly Growth</p>
               <p className="metric-value profit">
                 <i className="bx bxs-up-arrow-circle"></i> 20.8%
               </p>
             </div>
           </div>
           <div
             className="grid-stack-item"
             gs-x="0"
             gs-y="1"
             gs-w="7"
             gs-h="2"
             gs-min-w="5"
             gs-min-h="2"
           >
             <div className="grid-stack-item-content transactions-overview">
               <p className="description">Latest Transactions</p>
               <table className="transaction-table">
                 <thead>
                   <tr>
                     <th>Name</th>
                     <th>Email</th>
                     <th>Amount</th>
                     <th>Time</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>Elijah Yusuf</td>
                     <td>placeholder@mail.com</td>
                     <td>$50</td>
                     <td>1 hour ago</td>
                   </tr>
                   <tr>
                     <td>Elijah Yusuf</td>
                     <td>placeholder@mail.com</td>
                     <td>$50</td>
                     <td>1 hour ago</td>
                   </tr>
                   <tr>
                     <td>Elijah Yusuf</td>
                     <td>placeholder@mail.com</td>
                     <td>$50</td>
                     <td>1 hour ago</td>
                   </tr>
                   <tr>
                     <td>Elijah Yusuf</td>
                     <td>placeholder@mail.com</td>
                     <td>$50</td>
                     <td>1 hour ago</td>
                   </tr>
                   <tr>
                     <td>Elijah Yusuf</td>
                     <td>placeholder@mail.com</td>
                     <td>$50</td>
                     <td>1 hour ago</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>
           <div
             className="grid-stack-item"
             gs-x="7"
             gs-y="1"
             gs-w="5"
             gs-min-w="2"
           >
             <div className="grid-stack-item-content top-customers">
               <p className="description">Top Paying Customers</p>
               <ul className="customers">
                 <li className="customer">
                   Majeed Johnson
                   <i className="bx bxs-badge-check"></i>
                 </li>
                 <li className="customer">
                   Uzo Onyekachi
                   <i className="bx bxs-badge-check"></i>
                 </li>
                 <li className="customer">
                   Liz Schmidt
                   <i className="bx bxs-badge-check"></i>
                 </li>
               </ul>
             </div>
           </div>
         </div>
       </main>
     </div>
   </div>
      {/* Add the following inside your component */}
      <Helmet>
        {/* <link href="/node_modules/gridstack/dist/gridstack.min.css" rel="stylesheet"/>
        <script src="/node_modules/gridstack/dist/gridstack-all.js"></script> */}
      </Helmet>
    </div>
  );
};

export default GridStackLab1;
