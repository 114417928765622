/**
 * Not really used??
 * 
 * Each program consists of:
 * 
 * 1. Introduction play. The title is from channel json, or original dir name if json does
 *    not define title.
 * 2. Image carousel play, which slides through all photos in a directory.
 */
import React from 'react';
import ProgramBase from './ProgramBase';
import PhotoPlayer from '../PhotoPlay/PhotoPlayer';
import PhotoPlayerUI from '../PhotoPlay/PhotoPlayerUI';
import CinemaVideo from '../CinemaVideo/CinemaVideo';

// Wrap the ImageCarousel component with the HOC
const PhotoPlayerFull = PhotoPlayer(PhotoPlayerUI);

const Program = ({ 
    onStart, 
    onFinish, 
    title, 
    mediaPath,   // video: full path, photo: 
    mediaType,
    hideVideoPlayer=false   // Use in-player intro (which is for video) is to avoid reload <video> 
                            // to lose contineous auto play videos in list. Because loading intro
                            // component and then VideoPlayer cause <video> to be reloaded and lose
                            // contineous play capability.
  }) => {  

  // If hideVideoPlayer=true, 
  return ( 
    <>
      {mediaType == 1 &&
        <PhotoPlayerFull 
          onStart={onStart} 
          onFinish={onFinish} 
          relPath={mediaPath}
          title={title} />
      }

      {mediaType == 2 &&
        <CinemaVideo 
          videoTitle={title} 
          videoPath={mediaPath} 
          onVideoStart={onStart}
          onVideoEnd={onFinish}
          showIntro={false} />
      }
    </>
  );
}

export default Program;
