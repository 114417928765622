import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// library.add(faArrowLeft);

import RotatingBall from '../MainPage/RotatingBall';
import styles from './NavControl.module.css';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';


/**
 * Backward/forward button needs to know the history state. The history text is not 
 * displayed in this component, but in MenuPanel component. Therefore, the container
 * should pass the url history into both types of nav controls.
 * 
 * @param {*} param0 
 * @returns 
 */
const NavControl = ({onClickHome, 
                     onEnterNavControl,
                     onLeaveNavControl,
                     onClickBackward=null, 
                     onClickBackwardEnter=null,
                     onClickBackwardLeave=null,
                     onClickForward=null,
                     onClickForwardEnter=null,
                     onClickForwardLeave=null}) => {
  const navigate = useNavigate();
  const [showControl, setShowControl] = useState(false);  // Hide this feature as not fully work
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isHomeButtonHovered, setIsHomeButtonHovered] = useState(false);
  const [timeoutIdHome, setTimeoutIdHome] = useState(null);
  const [isBFButtonHovered, setIsBFButtonHovered] = useState(false);
  const [timeoutIdBF, setTimeoutIdBF] = useState(null);
  //const [backwardUrl, setBackwardUrl] = useState(null);
  //const [forwardUrl, setForwardUrl] = useState(null);

  // Enter ball button, or left/right button.
  useEffect(() => {
    if (isHomeButtonHovered || isBFButtonHovered) {
      // insideControl = true;
      onEnterNavControl();
    } else {
      //insideControl = false;
      onLeaveNavControl();
    }
  }, [isHomeButtonHovered, isBFButtonHovered]);

  useEffect(() => {
    // Simulate browser history
    // In a real application, you would update the history based on actual user navigation
    const fakeHistory = ['/page1', '/page2', '/page3', '/home'];
    setHistory(fakeHistory);
    setCurrentIndex(fakeHistory.length - 1);

    return () => {
      if (timeoutIdHome) {
        clearTimeout(timeoutIdHome);
      }
    };
  }, [timeoutIdHome]);

  const handleBackward = (evt) => {
    if (onClickBackward) {
      onClickBackward(evt);
    }
    /*
    if (backwardUrl) {
      navigate(backwardUrl);
    }
    */
    /*
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      if (onClickBackward) {
        onClickBackward(evt);
      }
    }
    */
  };

  const handleForward = (evt) => {
    if (onClickForward) {
      onClickForward(evt);
    }
    /*
    if (currentIndex < history.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
    */
  };

  const handleHome = (evt) => {
    setCurrentIndex(history.length - 1);
    onClickHome(evt);
  };

  const handleHomeEnter = (evt) => {
    setIsHomeButtonHovered(true);
    // Clear the old timeout, if any
    if (timeoutIdHome) {
      clearTimeout(timeoutIdHome);
    }
  };

  const handleHomeLeave = (evt) => {
    const newTimeoutId = setTimeout(() => {
      setIsHomeButtonHovered(false);
    }, 5000);

    // Save the timeout ID for potential cleanup
    setTimeoutIdHome(newTimeoutId);
  };

  const handleBackwardEnter = (evt) => {
    handleBFwardEnter();

    if (onClickBackwardEnter) {
      onClickBackwardEnter(evt);
    }
  }

  const handleBackwardLeave = (evt) => {
    handleBFwardLeave();
    if (onClickBackwardLeave) {
      onClickBackwardLeave(evt);
    }
  }

  const handleForwardEnter = (evt) => {
    handleBFwardEnter();
    if (onClickForwardEnter) {
      onClickForwardEnter(evt);
    }
  }

  const handleForwardLeave = (evt) => {
    handleBFwardLeave();
    if (onClickForwardLeave) {
      onClickForwardLeave(evt);
    }
  } 
  
  const handleBFwardEnter = () => {
    setIsBFButtonHovered(true);

    // Clear the old timeout, if any
    if (timeoutIdBF) {
      clearTimeout(timeoutIdBF);
    }
  }

  const handleBFwardLeave = () => {
    const newTimeoutId = setTimeout(() => {
      setIsBFButtonHovered(false);
    }, 5000);

    setTimeoutIdBF(newTimeoutId);
  }

  const handleClickRotatingBall = (evt) => {
    handleHome();
  }

  const handleEnterBall = (evt) => {
    /* OBS
    // Get history.
    const api = new ApiFileX();
    api.getHistoryUrl().then(async (retData) => {
      const result = retData.data;
      if (result) {
        if (result.backward) {
          setBackwardUrl(result.backward.url);
        } else {
          setBackwardUrl(null);
        }

        if (result.forward) {
          setForwardUrl(result.forward.url);
        } else {
          setForwardUrl(null);
        }
      };
    });
    */
    handleHomeEnter();
  }  

  const handleLeaveBall = (evt) => {
    handleHomeLeave();
  }

  return (
    <div className={styles.nav_control}>
      <div className={styles.nav_inner_control}>
        {showControl && (<button
          onClick={handleBackward} 
          onMouseEnter={handleBackwardEnter} 
          onMouseLeave={handleBackwardLeave}
          disabled={!isHomeButtonHovered || currentIndex <= 0} 
          className={isHomeButtonHovered || isBFButtonHovered ? styles.bf_visible : styles.bf_hidden}>
            &#9665; {/* Unicode arrow left character */}
          {/* <FontAwesomeIcon icon={faArrowLeft} /> Font Awesome arrow left icon */}
        </button>
        )}
        <RotatingBall
          onClick={handleClickRotatingBall}
          onEnter={handleEnterBall}
          onLeave={handleLeaveBall}
          cssOption={styles.float_ball}
        />
        {showControl && (<button 
          onClick={handleForward} 
          onMouseEnter={handleForwardEnter} 
          onMouseLeave={handleForwardLeave}
          disabled={!isHomeButtonHovered || currentIndex >= history.length - 1} 
          className={isHomeButtonHovered || isBFButtonHovered ? styles.bf_visible : styles.bf_hidden}>
            &#9655; {/* Unicode arrow right character */}
        </button>
        )}
      </div>
    </div>
  );
};

export default NavControl;
