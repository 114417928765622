/**
 * https://chatgpt.com/c/a26f4657-8b19-46b1-b70d-8c3abff6842d
 * 
 * How to use React.memo to track parameters. Good to know.
 * 
 * TODO: it also explained how to partially update OneDirectory by separating similar videos.
 * 
 * It needs to dynamically update List1 which is fully list of video and scroll loaded.
 */
import React, {useEffect} from 'react';
import $ from 'jquery';
import './OneDirectory.css'
import { dirShowFiles } from '../../comjs/directory.view/directory-view';


const OneDirectory = ({
    relPath, 
    dirInfo, 
    onClickVideo,
    onClickPhoto,
    onClickFolder,
    isFirstRender,
    onePageView=true
  }) => {
  let oneDirView = null;

  const handleOnClickVideo = (evt, title) => {
    const parentDiv = $(evt.target).closest('a').parent().parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');  // Otherwise, evt.attr('href'); will get <img> only
    const videoRelPath = href.attr('href');

    onClickVideo(videoRelPath, title, dirInfo);

    // Update the URL without triggering navigation
    window.history.pushState(null, null, `/video/${videoRelPath}`);
  }
  
  const handleOnClickPhoto = (evt) => {
    const parentDiv = $(evt.target).closest('a').parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');  // Otherwise, evt.attr('href'); will get <img> only
    const photoRelPath = href.attr('href');

    // Get section title which is photo directory name.
    const section = parentDiv.parent().parent();
    const dirName = section.find('.div-section-title a').text();

    onClickPhoto(photoRelPath, dirInfo, dirName);

    // Update the URL without triggering navigation
    window.history.pushState(null, null, `/photo/${photoRelPath}`);
  }
  
  const handleOnClickFolder = (evt, dirName) => {
    // When folder icon is clicked, it will load one page (not 2 page view with player on top)
    // view which uses OneDirectory.

    const parentDiv = $(evt.target).closest('a').parent().parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');  // Otherwise, evt.attr('href'); will get <img> only
    const folderRelPath = href.attr('href');

    onClickFolder(folderRelPath, dirInfo, dirName);
    window.history.pushState(null, null, `/folder/${folderRelPath}`);
  }

  useEffect(() => {
    console.log('OneDirectory is mounted');

    const options = {
      onClickFolder: handleOnClickFolder,
      onClickVideo: handleOnClickVideo,
      onClickPhoto: handleOnClickPhoto
    }

    oneDirView = new dirShowFiles(dirInfo, $('.one-dir-view'), null, 
                                  options, relPath, false, onePageView);

    return () => {
      console.log('OneDirectory is unmounted');
      if (oneDirView) {
        oneDirView.destroy();
      }
    }
  }, [relPath]);

  return (
    <>
      <div className="one-dir-view"></div>
    </>
  );
};

export default React.memo(OneDirectory);
