import React, { createContext, useState, useEffect, useContext } from 'react';

export const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [loginUserName, setLoginUser] = useState(null);
  const [isActive, setIsActive] = useState(false);

  // Timer to track user activity
  let activityTimer = null;

  // TODO: remove this, as it's just for testing.
  const [config, setConfig] = useState(() => {
    const storedConfig = localStorage.getItem('appConfig');
    return storedConfig ? JSON.parse(storedConfig) : {
      theme: 'light',
      language: 'en',
      ytEditMode: false,
      // Add other default configuration parameters here
    };
  });

  const updateConfig = (newConfig) => {
    setConfig((prevConfig) => {
      const updatedConfig = { ...prevConfig, ...newConfig };
      localStorage.setItem('appConfig', JSON.stringify(updatedConfig));
      return updatedConfig;
    });
  };

  // -1: mute
  // 0: intro musc
  // 1: music for photo player
  // 2: program end music
  const [musicState, setMusicState] = useState(-1);

  const updateLoginUserName = (name) => {
    // Determine login, logout, and none of the above cases.
    if (name && !loginUserName) {
      // login
      setIsActive(true);
    } else if (!name && loginUserName) {
      // logout
      setIsActive(false);
    }
    setLoginUser(name);
  };

  const updateMusicState = (state) => {
    setMusicState(state);
  };

  const handleActivity = () => {
    setIsActive(true);
    clearTimeout(activityTimer);
    activityTimer = setTimeout(() => {
      setIsActive(false);
    }, 300 * 1000); // Set user as inactive after 5 minutes of inactivity
    //}, 300000); // Set user as inactive after 5 minutes of inactivity
  };

  useEffect(() => {
    function checkIfMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    setIsMobileDevice(checkIfMobile());

    // Add event listeners to detect user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      // Clean up event listeners on component unmount
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);

  return (
    <AppContext.Provider value={{
        loginUserName,
        isActive,
        updateLoginUserName,
        musicState,
        updateMusicState,
        config,
        updateConfig
    }}>
      {children}
    </AppContext.Provider>
  );
};
