/**
 * See simple-peer working in single page.
 */
import React, { useEffect } from 'react';
import SimplePeer from 'simple-peer';

const PeerCommunication = () => {
  useEffect(() => {
    const peer1 = new SimplePeer({ initiator: true });
    const peer2 = new SimplePeer();

    peer1.on('signal', data => {
      // when peer1 has signaling data, give it to peer2 somehow
      peer2.signal(data);
    });

    peer2.on('signal', data => {
      // when peer2 has signaling data, give it to peer1 somehow
      peer1.signal(data);
    });

    peer1.on('connect', () => {
      // wait for 'connect' event before using the data channel
      peer1.send('hey peer2, how is it going?');
    });

    peer2.on('data', data => {
      // got a data channel message
      console.log('got a message from peer1: ' + data);
    });
  }, []);

  return (
    <div>
      <h1>Peer-to-Peer Communication</h1>
      <p>Open the console to see the messages.</p>
    </div>
  );
};

export default PeerCommunication;
