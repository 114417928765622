import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { LanguageProvider } from '../Localization/LanguageContext/LanguageContext';
import './MainPage.css';
import TwoPages from '../TwoPages/TwoPages';
import {PlayerViews} from '../PlayerViews/PlayerViews';
//import IconMenuBar from './RotatingBall' 
import ShiningBall from '../SunMenu/ShiningBall';
import {IconMenuBar} from '../IconMenuBar/IconMenuBar';
import { ScrollProvider, useScroll } from '../TwoPages/ScrollContext';
import { CombinedProvider } from '../System/CombinedContext';


const MainPage = ({
      // These 2 parameters are mutually exclusive.
      //  
      // 1) Media Directory view
      //    paramShowTwoPage=false,
      //    paramShowPlayerView=true,
      //
      // 2) Channel view
      //    paramShowTwoPage=true,
      //    paramShowPlayerView=false,

      paramShowTwoPage=true,
      paramShowPlayerView=false,
    }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // app.js same name is used as paramShowTwoPage, destructuring is to rename it so
  // it does not confused with component input parameter.
  const { paramShowTwoPage: urlShowTwoPage, paramShowPlayerView: urlShowPlayerView } = useParams();

  // Check if both URL parameters are present and valid
  const isValidUrlParams = urlShowTwoPage !== undefined && urlShowPlayerView !== undefined;

  // Determine initial state based on URL parameters or default props
  const initialShowTwoPage = isValidUrlParams ? urlShowTwoPage === 'true' : paramShowTwoPage;
  const initialShowPlayerView = isValidUrlParams ? urlShowPlayerView === 'true' : paramShowPlayerView;

  // Show home button at bottom.
  let showHomeButton = false;  // Default
  if (isValidUrlParams) {
    showHomeButton = true;
  }

  // For calling goBack/goForward function inside child component PlayerView.
  const playerViewsRef = useRef();

  const { loginUserName, updateLoginUserName } = useContext(AppContext);

  // Scroll hook pass continuous scroll events from TwoPageBase to IconMenuBar.
  const { onScrollStart, onScrollEnd } = useScroll();
  
  // Get parameters from route.
  const { paphotoroot, paphotoid, 
          pavideoroot, pavideoid,
          pafolderroot, pafolderid } = useParams();

  const [inphotoroot, setInPhotoRoot] = useState(paphotoroot);
  const [inphotoid, setInPhotoId] = useState(paphotoid);
  const [invideoroot, setInVideoRoot] = useState(pavideoroot);
  const [invideoid, setInVideoId] = useState(pavideoid);
  const [infolderroot, setInFolderRoot] = useState(pafolderroot);
  const [infolderid, setInFolderId] = useState(pafolderid);

  // const navigate = useNavigate();
  // showTwoPage=true, media home. showTwoPage=false, channel home
  const [showTwoPage, setShowTwoPage] = useState(initialShowTwoPage);
  const [showPlayerView, setShowPlayerView] = useState(initialShowPlayerView);
  const [page2Visible, setPage2Visible] = useState(showHomeButton);
  //const [photoId, setPhotoId] = useState(null);
  //const [videoId, setVideoId] = useState(null);
  
  useEffect(() => {
    // console.log('pavideoroot:', pavideoroot);
    // console.log('pavideoid:', pavideoid);
    // console.log('Current location:', location);
    setInPhotoRoot(paphotoroot);
    setInPhotoId(paphotoid);
    setInVideoRoot(pavideoroot);
    setInVideoId(pavideoid);
    setInFolderRoot(pafolderroot);
    setInFolderId(pafolderid);

    if (paphotoroot && paphotoid || pavideoroot && pavideoid || pafolderroot && pafolderid) {
      setShowPlayerView(true);
      setShowTwoPage(false);
    }
  }, [paphotoroot, paphotoid, pavideoroot, pavideoid, pafolderroot, pafolderid, location]);

  // Debug back button.
  useEffect(() => {
    const handlePopState = (event) => {
      //console.log('popstate event:', event);
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    // This is not to show 2 page, which has player. It's 1 page that display just directory info.
    // setShowTwoPage(false);
    // setShowPlayerView(true);
  }, [infolderid, setInFolderId]);

  // Handle login event. The context value loginUserName is set with 2 scenarios:
  // 1) If not login yet, login is andled in TwoPages.
  // 2) If already login, the login name is obtained in AppControl (App.js)
  useEffect(() => {
    // This is to handle media visit url, which is not login yet. User enter url which fails,
    // and is asked to login. After login, the failed url will be visited.
    if (loginUserName) {
      const failedUrl = localStorage.getItem('failedUrl');

      if (failedUrl) {
        // This causes "Leaving site?" dialog show up.
        // window.location.href = failedUrl;

        // Open in another tab. This does not popup "Leave Site?" dialog which is build-in.
        window.open(failedUrl, '_blank');

        // Navigate without causing a full page reload
        //navigate(failedUrl);

        // Reset failed url as it's been visited now.
        localStorage.removeItem('failedUrl');
      }
    }
  }, [loginUserName]);

  useEffect(() => {
    if (paphotoroot && paphotoid || pavideoroot && pavideoid || pafolderroot && pafolderid ) {
      setShowPlayerView(true);
      setShowTwoPage(false);
    }
  }, [])

  const toggleTwoPage = () => {
    setShowTwoPage(true);
    setShowPlayerView(false);
  };

  const toggleDirectoryView = () => {
    setShowTwoPage(false);
    setShowPlayerView(true);
  };

  const handleTwoPagesBallMenuClick = () => {
    toggleDirectoryView();
  };

  const handlePlayerViewsBallMenuClick = () => {
    toggleTwoPage();
  };

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url, { replace: true });
  };

  const handleBallClick_back = () => {
    // Toggle
    if (showTwoPage) {
      // Main media page
      setShowTwoPage(false);
      setShowPlayerView(true);

      // This is to reset search bar parameter to null as well when navigate
      // to directory view: 
      // paphotoroot, paphotoid, pavideoroot, pavideoid
      // Otherwise, these values will trigger reloading video, photo, article view
      // which was on search bar.
      // Set the specific parameters to null

      const currentURL = new URL(window.location.href);
      currentURL.search = '';

      // Replace the current state with the new URL
      window.history.pushState(null, null, currentURL.toString());

      // If clicked the ball menu, we are sure it's not entered from browser search bar.
      setInPhotoRoot(null);
      setInPhotoId(null);
      setInVideoRoot(null);
      setInVideoId(null);
      setInFolderRoot(null);
      setInFolderId(null);
    } else {
      // Show channel
      setShowTwoPage(true);
      setShowPlayerView(false);

      window.history.pushState(null, null, `/`);
    }
  };

  const handlePage2Visible = (visible) => {
    setPage2Visible(visible);
  };

  // These 2 click handlers are called by backward/forward button click in 
  // IconMenuBar nav button. Shared functions in parent MainPages triggers
  // PlayerViews goBack, goForward from IconMenuBar.
  const handlBackwardClick = (evt) => {
    if (playerViewsRef.current) {
      playerViewsRef.current.notifyGoBack();
    }
  };

  const handlForwardClick = (evt) => {
    if (playerViewsRef.current) {
      playerViewsRef.current.notifyGoForward();
    }
  };

  // PlayersView has history info.
  const handleEnterNavControl = (evt) => {
    if (playerViewsRef.current) {
      return playerViewsRef.current.getNavInfo();
    }

    return null;
  }

  // Two page scroll events. Contineous scroll events are ignored. End scroll is fired
  // after default 5 seconds. This is to prevent menu bar flashing, as it will change opacity
  // after scroll is finished, which is default few seconds. Without these two events,
  // menu bar does not know when scroll is finished.

  // Use useMemo to memoize the MainPage component and prevent re-renders when 
  // page2Visible changes. This is to prevent undesired re-render. 
  // In following code, it's clear that 2 states are the trigger of re-render. But state
  // page2Visible which is passed from child component, also trigger re-rendered. This
  // is not what page2Visible is intended for. And, in this case, photo player will restart
  // when page2 is visible during scrolling.
  // NO, page2Visible is for IconMenuBar to behave correctly!!!
  // return useMemo(() => (
  //   <div>
  //     {showTwoPage && <TwoPages onPage2Visible={handlePage2Visible} />}
  //     {showPlayerView && 
  //         <PlayerViews 
  //           ref={playerViewsRef}
  //           onPage2Visible={handlePage2Visible} 
  //           photoRoot={inphotoroot}
  //           photoId={inphotoid}
  //           videoRoot={invideoroot}
  //           videoId={invideoid}
  //           folderRoot={infolderroot}
  //           folderId={infolderid}
  //         />
  //     }
  //     <IconMenuBar
  //         onClickRotatingBall={handleBallClick} 
  //         showIconMenuBar={page2Visible}
  //         onEnterNavControlParent={handleEnterNavControl}
  //         onBackwardClick={handlBackwardClick}
  //         onForwardClick={handlForwardClick}
  //         onScrollStart={onScrollStart}
  //         onScrollEnd={onScrollEnd} />
  //   </div>
  // ), [showTwoPage, showPlayerView]);

  // memoizedPlayerViews is a memoized version of the PlayerViews component. 
  // It will only re-render when the specific props (inphotoroot, inphotoid, etc.) change. 
  // This way, changes to page2Visible won't trigger unnecessary re-renders of PlayerViews.
  // Memoize the PlayerViews component to prevent unnecessary re-renders.
  // Not work. useMemo is for 'unneccesary' re-render. but what is it? it does not exist.
  // What's trying to prevent is NOT 'unceccesary'.
  // const memoizedPlayerViews = useMemo(() => (
  //   <PlayerViews 
  //     ref={playerViewsRef}
  //     onPage2Visible={handlePage2Visible} // 
  //     photoRoot={inphotoroot}
  //     photoId={inphotoid}
  //     videoRoot={invideoroot}
  //     videoId={invideoid}
  //     folderRoot={infolderroot}
  //     folderId={infolderid}
  //   />
  // ), [inphotoroot, inphotoid, invideoroot, invideoid, infolderroot, infolderid]);

  // return (
  //   <div>
  //     {showTwoPage && <TwoPages onPage2Visible={handlePage2Visible} />}
  //     {showPlayerView && memoizedPlayerViews}
  //     <IconMenuBar
  //       onClickRotatingBall={handleBallClick} 
  //       showIconMenuBar={page2Visible}
  //       onEnterNavControlParent={handleEnterNavControl}
  //       onBackwardClick={handlBackwardClick}
  //       onForwardClick={handlForwardClick}
  //       onScrollStart={onScrollStart}
  //       onScrollEnd={onScrollEnd} />
  //   </div>
  // );

  // Working version is in CininaPhoto.jsx


  return (
    <div>
      {showTwoPage && <TwoPages onPage2Visible={handlePage2Visible} />}
      {showPlayerView && <PlayerViews 
        ref={playerViewsRef}
        onPage2Visible={handlePage2Visible} 
        photoRoot={inphotoroot}
        photoId={inphotoid}
        videoRoot={invideoroot}
        videoId={invideoid}
        folderRoot={infolderroot}
        folderId={infolderid}
      />}
      <IconMenuBar
        onClickRotatingBall={handleBallClick} 
        showIconMenuBar={page2Visible}
        onEnterNavControlParent={handleEnterNavControl}
        onBackwardClick={handlBackwardClick}
        onForwardClick={handlForwardClick}
        onScrollStart={onScrollStart}
        onScrollEnd={onScrollEnd} />
    </div>
  );
};

export default MainPage;
