/**
 * Two page of top player and bottom grid is common feature, such as photo, video player.
 * This component is to abstract this behavior for multiple players.
 */
import React, { useEffect, useLayoutEffect, useContext, useState } from 'react'
import { useScroll } from './ScrollContext';
import OnlineUsers from '../Online/OnlineUsers';
import './TwoPageBase.css'


const TwoPageBase = (props) => {
  const {
    children,
    onPage1Visible=null,
    onPage2Visible=null,
    keyValue,
    scrollInterval=5000,
  } = props;
  // useScroll() is for parent component notification of scroll start/end.
  // Without using context, the 2 notifications has to be passed into nested components, which 
  // make code more clean. 
  // These 2 variable setters set 2 context values, which can be used for Provider (parent component)
  // for notification.
  const { setOnScrollStart, setOnScrollEnd } = useScroll();
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [showPageTwo, setShowPageTwo] = useState(false);

  // When loaded, always show page1. Since this component can be started by
  // an event which is originated in a page. And the scroll position could be in page2.
  // In this case, we need to force to show page1

  // Not able to make state to work.?
  //const [initPage, setInitPage] = useState(false);
  let initPageDone = false;

  // This flag is different from showPageTwo, and can't be replace by showPageTwo.
  // showPageTwo is React state, and will trigger rendering. isSecondPageVisible is not state.
  let isSecondPageVisible = false;
  
  // useState does not work.
  let scrollTimer = null;

  const handleScrollStartEndEvents = () => {
    if (!(setOnScrollStart && setOnScrollEnd)) {
      return;
    }

    // Scroll start event
    if (!scrollTimer) {
      setOnScrollStart(true);
      setOnScrollEnd(false);
    }

    // Clear previous timeout
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }

    // Set a new timeout for scroll end
    const timeout = setTimeout(() => {
      // Execute scroll end logic here
      setOnScrollStart(false);
      setOnScrollEnd(true);
      // Reset timeout variable
      //setScrollTimeout(null);
      scrollTimer = null;

    }, scrollInterval); // 5000 milliseconds (5 seconds) timeout

    scrollTimer = timeout;
  };

  /*
  useEffect(() => {
    // Simulate scroll start event after component mounts
    onScrollStart();

    // Simulate scroll end event after 5 seconds
    const timeout = setTimeout(() => {
      onScrollEnd();
    }, 5000);

    return () => {
      // Cleanup timeout on component unmount
      clearTimeout(timeout);
    };
  }, [onScrollStart, onScrollEnd]);
  */

  // Function to handle the scrolling effect
  const handleScroll = () => {
    handleScrollStartEndEvents();

    // Don't scroll until initialization is done.
    if (!initPageDone) {
      const scrollTop = window.scrollY;

      if (0 == scrollTop) {
        initPageDone = true;
        if (onPage1Visible) {
          onPage1Visible();
        }
      } else {
        return;
      }
    }

    const firstPage = document.querySelector('.page-1');
    const secondPage = document.querySelector('.page-2');
    const autoScrollThreshold = window.innerHeight / 2;
    //const autoScrollThreshold = window.innerHeight * 0.4;

    if (!secondPage) {
      return;
    }

    let scrollPosition = 0;

    // Already initialized.
    if (initPageDone) {
      scrollPosition = window.scrollY;
    }

    // Check if the user has scrolled past half a page
    if (scrollPosition >= autoScrollThreshold && !isSecondPageVisible) {
      // Show page2.
      isSecondPageVisible = true;
      animateScroll(secondPage.offsetTop); // Scroll to the second 

      firstPage.style.opacity = 0; // Hide the first page
      secondPage.style.opacity = 1; // Show the second page
      setShowPageTwo(true);

      if (onPage2Visible) {
        onPage2Visible();
      }
    } else if (scrollPosition <= autoScrollThreshold && isSecondPageVisible) {
      // Show page1.
      isSecondPageVisible = false;
      animateScroll(firstPage.offsetTop); // Scroll back to the first page

      firstPage.style.opacity = 1; // Show the first page
      secondPage.style.opacity = 0; // Hide the second page
      setShowPageTwo(false);

      if (onPage1Visible) {
        onPage1Visible();
      }
    }
  }

  // Function to animate the scroll
  const animateScroll = (destination) => {
    const duration = 800; // Animation duration in milliseconds
    const start = window.scrollY;
    const startTime = performance.now();

    function step(currentTime) {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easing = easeInOutQuad(progress);
      window.scrollTo(0, start + (destination - start) * easing);

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    }

    requestAnimationFrame(step);
  }

  // Easing function (quadratic ease-in-out)
  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

  useEffect(() => {  
    // Call the handleScroll function on initial load and whenever the user scrolls
    window.addEventListener('scroll', handleScroll);

    if (!initPageDone) {
      // This will trigger initialization process, and handleScroll() will receive few events.
      // Last event will window.scrollY = 0.
      window.scrollTo(0, 0);

      // After scrollTo(0, 0); handleScroll(); is NOT called. If not calling, it's not possible
      // to handle init done. We manually trigger scrolling, and it will cause few scroll event.
      // Last event will set scroll position to be 0, which is init done.
      handleScroll();

      // Don't set initPageDone = true; Init is not done yet!
      // The reason is scrollTo(0, 0); will trigger scroll, which does not know if 
      // init is done. So, initPageDone = true; should be performed there, when
      // init is done then.
    }

    return () => {
      // Cleanup: remove the scroll event listener when the component unmounts
      // Otherwise, when the component is unmounted, the scroll event is still processed
      // by this component.
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 


  // Add key is for triggering window.removeEventListener('scroll', handleScroll);
  // The key is passed from parent as dirInfo. dirInfo changes passed down here
  // trigger update.
  //
  // The reason to constrol class 'vertical-alignment' is that page 2 will take
  // whole viewport, and not a child of page-2. It interfere with Yt player control, and
  // you click phatom video which is on top page-2 inside page-1.
  //    showPageTwo ? 'vertical-alignment' : ''
  return (
    <>
      <div key={keyValue} data-scroll className="two-page-container">
        {children.map((child, index) => (
          <section
            key={index}
            // className={`page page-${index + 1} ${index === 1 && showPageTwo ? 'vertical-alignment' : ''}`}
            className={`page page-${index + 1} ${index === 1 ? 'vertical-alignment' : ''}`}
            // className={`page page-${index + 1}`}
            >
            {child}
          </section>
        ))}
      </div>
    </>
  );
};

export default TwoPageBase