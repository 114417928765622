import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { IconMenuBar } from '../IconMenuBar/IconMenuBar.jsx';
import './ArticleList.css'; // Assuming you put the CSS styles in a separate file


const ArticleList = ({
    relpath=null,
  }) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const api = new ApiFileX();
    api.getAllArticles(relpath).then(async (retData) => {
      if (retData && retData.status) {
        setArticles(retData.data);
      } else {
        console.error('getAllArticles failed!');
      }
      setLoading(false);
    });
  }, []);

  const handleClick = (file_name) => {
    let url = `/test/article/${file_name}`;
    if (relpath)
      url += `/${relpath}`;

    navigate(url);
    /*
    const api = new ApiFileX();
    api.getArticle(relpath, file_name).then(async (retData) => {
      if (retData && retData.status) {

        let url = `/test/article/:param_file_id/:param_relpath?`;
        if (relpath)
          url += `/${relpath}`;
    
        navigate(url);
      } else {
        console.error('getArticle failed!');
      }
    });
    */
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading articles: {error.message}</div>;
  }

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url);
  }

  return (
    <>
      <div className="list-wrapper">
        <ul>
          {articles.map((article) => (
            <li key={article.uuid} data-value={article.file_name} onClick={() => handleClick(article.file_name)}>
              <h3>{article.file_brief}</h3>
              <div className="article-info">
                <div className="publish-time">{article.createtime}</div>
                <div className="author-list">{article.file_owner}</div>
              </div>
              {/* <div className="doc-info">
                <div className="source-x">{article.source_x}</div>
                <div className="journal">{article.journal}</div>
                <a href={article.doi_link}>
                  <span className="article-link">(original doc)</span>
                </a>
              </div> */}
            </li>
          ))}
        </ul>
      </div>
      <IconMenuBar
        onClickRotatingBall={handleBallClick} 
      />
    </>
  );
};

export default ArticleList;
