import React, { useState, useContext } from 'react';
import { AppContext } from '../../AppContext';
import {ApiFileX} from '../../comjs/filex.api/filex-api';
import './NameTag.css'; // You can define your CSS for the NameTag component

const NameTag = ({ name }) => {
  const { loginUserName, updateLoginUserName } = useContext(AppContext);
  const [showMenu, setShowMenu] = useState(false);

  const handleNameClick = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = async () => {
    const api = new ApiFileX();
    let ret = await api.logOut();

    updateLoginUserName('');
    setShowMenu(false); // Close the menu after clicking Logout
  };

  /*
  const handleContextMenu = async (e) => {
    e.preventDefault(); // Prevent the default browser context menu
    
    const api = new ApiFileX();
    let ret = await api.logOut();

    let a = 0;
  };
  */
  return (
    // <div className="name-tag" onContextMenu={handleContextMenu}>
    //   <p>{name}</p>
    // </div>

    <div className="name-tag">
      <div className="name-container" onClick={handleNameClick}>
        <p>{name}</p>
      </div>
      {showMenu && (
        <div className="menu">
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>

  );
};

export default NameTag;
