import React, { useRef, useState, useEffect } from 'react';
import OriginalListComponent from './OriginalListComponent';
import DraggableListWrapper from './DraggableListWrapper';

const MuuriSandbox1 = () => {
  const items = ['Item 1', 'Item 2', 'Item 3'];
  const listRef = useRef(null);
  const [layoutDone, setLayoutDone] = useState(false);

  const handleListRef = (ref) => {
    // Save the listRef
    listRef.current = ref;
  };

  useEffect(() => {
    // Access listRef.current to get the reference to the root div
    if (listRef.current) {
      console.log(listRef.current);
    }
  }, [items]);

  const handleLayoutDone = () => {
    setLayoutDone(true);
  }

  return (
    <div>
      {/* Original List Component */}
      <OriginalListComponent 
        items={items} 
        notifyLayoutDone={layoutDone}
        onListRef={handleListRef} />

      {/* Draggable List Wrapper Component */}
      <DraggableListWrapper onLayoutDone={handleLayoutDone} listRef={listRef} />
    </div>
  );
};

export default MuuriSandbox1;

