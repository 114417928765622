import React, { useState, useEffect, useRef } from 'react';
import SimplePeer from 'simple-peer';
import io from 'socket.io-client';

const SimplePeerComponent = () => {
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [createP2P, setCreateP2P] = useState(false);
  const [peer, setPeer] = useState(null);
  const socketRef = useRef(null);
  const room = 'some-room-id'; // Use a unique room ID
  const peerRef = useRef(null);
  const peerDestroyedRef = useRef(false);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }

    socketRef.current.on('connect', () => {
      console.log('WebSocket connection established');

      const userN = `User_${Math.floor(Math.random() * 1000)}`;
      setUserName(userN);
      setUserId(socketRef.current.id);

      socketRef.current.emit('join', {
        room,
        user_id: socketRef.current.id,
        user_name: userN
      });
    });

    socketRef.current.on('joined', (data) => {
      console.log(`${data.user_name} joined room: ${data.room}`);
      if (peerRef.current) {
        alert('joined error');
        return; // Prevent creating multiple peers
      }
      
      // createPeer();
      setCreateP2P(true);
    });

    socketRef.current.on('error', (data) => {
      alert(data.message);
    });

    socketRef.current.on('signal', (data) => {
      console.log('Signal received from server:', data);
      if (peerRef.current && !peerDestroyedRef.current) {
        try {
          peerRef.current.signal(data.signal);
        } catch (error) {
          console.error('Error handling signal:', error);
        }
      }
    });

    socketRef.current.on('ice_candidate', (data) => {
      console.log('ICE candidate received from server:', data);
      if (peerRef.current && !peerDestroyedRef.current) {
        try {
          peerRef.current.signal(data.candidate);
        } catch (error) {
          console.error('Error handling ICE candidate:', error);
        }
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log('WebSocket connection closed');
      cleanup();
    });

    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (createP2P) {
      createPeer();
      setCreateP2P(false);
    }
  }, [createP2P]);

  const createPeer = () => {
    const isInitiator = window.location.hash === '#1';
    const p = new SimplePeer({
      initiator: isInitiator,
      trickle: true, // Enable trickle ICE
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' }, // STUN server
          // {
          //   urls: 'turn:your-turn-server.com:3478', // TURN server
          //   username: 'your-username', // Replace with your TURN server username
          //   credential: 'your-password' // Replace with your TURN server password
          // }
        ]
      }
    });

    p.on('error', err => console.log('error', err));

    p.on('signal', data => {
      if (peerDestroyedRef.current) return; // Prevent signaling if peer is destroyed
      console.log('SIGNAL', JSON.stringify(data));
      if (data.type === 'offer' || data.type === 'answer') {
        socketRef.current.emit('signal', { room, user_name: userName, user_id: userId, signal: data });
      } else {
        socketRef.current.emit('ice_candidate', { room, user_name: userName, user_id: userId, candidate: data });
      }
    });

    p.on('connect', () => {
      console.log('CONNECT');
      p.send('hey peer2, how is it going?');
    });

    p.on('data', data => {
      console.log('data: ' + data);
    });

    setPeer(p);
    peerRef.current = p;
    peerDestroyedRef.current = false; // Mark peer as active
  };

  const cleanup = () => {
    peerDestroyedRef.current = true; // Mark peer as destroyed
    if (peerRef.current) {
      peerRef.current.destroy();
      peerRef.current = null;
    }
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  };

  return (
    <div>
      <h1>Peer-to-Peer Communication</h1>
      <p>Open two browser windows/tabs:</p>
      <p>1. <strong>http://localhost:3000/test/simpeer/#1</strong></p>
      <p>2. <strong>http://localhost:3000/test/simpeer</strong></p>
      <p>The connection should be established automatically.</p>
    </div>
  );
};

export default SimplePeerComponent;
