// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.list-wrapper {
  margin: 0 20px;
}

div.list-wrapper > ul {
  list-style-type: none;
  margin: 0; /* To remove default bottom margin */
  padding: 0; /* To remove default left padding */
}

div.list-wrapper > ul > li > h3 {
  font: 20px/1.5 Helvetica, Verdana, sans-serif;
  color: #0d21ab;
  margin-block-start: 0;
  margin-block-end: 0;
}

div.list-wrapper > ul > li > h3:hover {
  cursor: pointer;
  text-decoration: none;
}

div.list-wrapper > ul > li > div {
  font: 200 14px/1.5 Helvetica, Verdana, sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  justify-content: left;
}

div.list-wrapper > ul > li > div > div {
  margin-right: 0.7em;
}

div.list-wrapper > ul > li > div > div.publish-time {
}

div.list-wrapper > ul > li > div.doc-info {
}

div.list-wrapper > ul > li > div > div.source-x:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #0d21ab;
}

div.list-wrapper > ul > li > div > div.journal:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #0d21ab;
}

div.list-wrapper > ul > li > div > a:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #0d21ab;
}

div.list-wrapper > ul > li {
  padding: 10px 0;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Article/ArticleList.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,SAAS,EAAE,oCAAoC;EAC/C,UAAU,EAAE,mCAAmC;AACjD;;AAEA;EACE,6CAA6C;EAC7C,cAAc;EACd,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,iDAAiD;EACjD,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;AACA;;AAEA;AACA;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":["div.list-wrapper {\n  margin: 0 20px;\n}\n\ndiv.list-wrapper > ul {\n  list-style-type: none;\n  margin: 0; /* To remove default bottom margin */\n  padding: 0; /* To remove default left padding */\n}\n\ndiv.list-wrapper > ul > li > h3 {\n  font: 20px/1.5 Helvetica, Verdana, sans-serif;\n  color: #0d21ab;\n  margin-block-start: 0;\n  margin-block-end: 0;\n}\n\ndiv.list-wrapper > ul > li > h3:hover {\n  cursor: pointer;\n  text-decoration: none;\n}\n\ndiv.list-wrapper > ul > li > div {\n  font: 200 14px/1.5 Helvetica, Verdana, sans-serif;\n  margin-block-start: 0;\n  margin-block-end: 0;\n  display: flex;\n  justify-content: left;\n}\n\ndiv.list-wrapper > ul > li > div > div {\n  margin-right: 0.7em;\n}\n\ndiv.list-wrapper > ul > li > div > div.publish-time {\n}\n\ndiv.list-wrapper > ul > li > div.doc-info {\n}\n\ndiv.list-wrapper > ul > li > div > div.source-x:hover {\n  cursor: pointer;\n  text-decoration: underline;\n  color: #0d21ab;\n}\n\ndiv.list-wrapper > ul > li > div > div.journal:hover {\n  cursor: pointer;\n  text-decoration: underline;\n  color: #0d21ab;\n}\n\ndiv.list-wrapper > ul > li > div > a:hover {\n  cursor: pointer;\n  text-decoration: underline;\n  color: #0d21ab;\n}\n\ndiv.list-wrapper > ul > li {\n  padding: 10px 0;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
