import React from 'react';
import { useParams } from 'react-router-dom';

const YouTubeEmbed = () => {
  const { videoId } = useParams();
  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  
  return (
    <div>
      {videoId ? (
        <iframe
          width="560"
          height="315"
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <p>No video ID provided!</p>
      )}
    </div>
  );
};

export default YouTubeEmbed;
