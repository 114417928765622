import $ from 'jquery';  // React only


export class f2fCss {
    constructor() {
        //this.linkElements = [];
        this.styleElements = {};
    }

    //loadCSS(href) {
        /*
        const linkElement = document.createElement('style');
        linkElement.appendChild(document.createTextNode(require(href).default));
        */
        /*
        const linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.type = 'text/css';
        linkElement.href = href;
    
        document.head.appendChild(linkElement);
        */


        /*
        const styleElement = document.createElement('style');
        styleElement.type = 'text/css';
  
        //const cssContent = require('../css/directory-view.css').default;
        const cssContent = require(href).default;
        styleElement.appendChild(document.createTextNode(cssContent));
  
        document.head.appendChild(styleElement);
        this.linkElements.push(styleElement);
        */
    //}

    extractFileNameAndRelativeDir(path) {
        const parts = path.split('/');
        const fileName = parts.pop();
        const relativeDir = parts.join('/');
      
        return { fileName, relativeDir };
    }
    
    /**
     * This is to make load css work for ES6 class in React environment.
     * 
     * @param {*} cssFileNameImport 
     */
    loadCSS(cssFileNameImport) {
        if (!this.styleElements[cssFileNameImport.name]) {
            const styleElement = document.createElement('style');
            styleElement.type = 'text/css';
    
            cssFileNameImport.import
            .then(cssModule => {
                styleElement.appendChild(document.createTextNode(cssModule.default));
                document.head.appendChild(styleElement);
                this.styleElements[cssFileNameImport.name] = styleElement;
            })
            .catch(error => {
                console.error(`Error loading CSS '${cssFileNameImport.name}':`, error);
            });
        }
    }

    unloadCSS(cssFileName) {
        if (this.styleElements[cssFileName.name]) {
          document.head.removeChild(this.styleElements[cssFileName.name]);
          delete this.styleElements[cssFileName.name];
        }
    }
    /*
    unloadAllCSS() {
        this.linkElements.forEach(linkElement => {
          document.head.removeChild(linkElement);
        });
        this.linkElements = [];
    }
    */
}

export class f2fWait {
    constructor(selector = 'body') {
        this.selector = selector;
    }

    startWait() {
        let waitStatus = $(this.selector).data('wait-status');

        if (!waitStatus) {
            let referenceCount = { referenceCount: 1 };
            $(this.selector).data('wait-status', referenceCount);

            this.setCursorStyle('progress');
        } else {
            let refCount = $(this.selector).data('wait-status');
            refCount.referenceCount += 1;
            $(this.selector).data('wait-status', refCount);
        }
    }

    stopWait() {
        let waitStatus = $(this.selector).data('wait-status');

        if (!waitStatus) {
            return;
        }

        waitStatus.referenceCount -= 1;

        // Update reference count.
        $(this.selector).data('wait-status', waitStatus);

        if (waitStatus.referenceCount <= 0) {
            $(this.selector).removeData('wait-status');
            this.setCursorStyle('default');
        }
    }

    setCursorStyle(cursorStyle) {
        $(this.selector).css('cursor', cursorStyle);
        $(`${this.selector} *`).css('cursor', cursorStyle); // Apply cursor style to all child elements
    }
}




// export class f2fWait {
//     constructor() {
//         this.waitStatus = false;
//     }

//     startWait() {
//         let waitStatus = $('body').data('wait-status');

//         if (!waitStatus) {
//             let referenceCount = {referenceCount: 1};
//             $('body').data('wait-status', referenceCount);

//             $('body').css('cursor', 'progress');
//         } else {
//             let refCount = $('body').data('wait-status');
//             refCount.referenceCount += 1;
//             $('body').data('wait-status', refCount);
//         }
//     }

//     stopWait() {
//         let waitStatus = $('body').data('wait-status');

//         if (!waitStatus) {
//           return;
//         }
    
//         waitStatus.referenceCount -= 1;

//         // Update reference count.
//         $('body').data('blocker-status', waitStatus); 
        
//         if (waitStatus.referenceCount <= 0) {        
//           $('body').removeData('wait-status');
//           $('body').css('cursor', 'default');
//         }
//     }
// }

export class f2fJs extends f2fCss {
    constructor() {
        super();
    }
}

export class f2fHtml extends f2fCss {

    constructor() {
        super();
    }

    appendTop() {

    }

    appendBefore($anchorItem, newItemHtml) {
        return $(newItemHtml).insertBefore($anchorItem);
    }

    appendAfter($anchorItem, newItem) {
        return $(newItem).insertAfter($anchorItem);
    }
    
    insertChildTop($anchorItem, newItem) {
        let $newItem = $(newItem);
        $anchorItem.prepend(newItem);

        // Return inserted jquery object.
        return $($anchorItem.children().first());
    }

    insertChildBottom($anchorItem, newItem) {
       return $(newItem).appendTo($anchorItem);
    }

    setDataToTmpl(dataObj, tmpl) {
        let arr = [];
        arr.push(dataObj);
    
        return arr.map(tmpl).join('');
    }
  
    isJqueryObj($obj) {
        if (!$obj) {
            return false;
        }
        
        return ($obj.length);
    }

    isThisJqueryObjByClass($obj, className) {
        return $obj.hasClass(className);
    }

    isThisJqueryObjById($obj, idName) {
        return $obj.attr('id') === idName;
    }

    equalJqueryObj($obj1, $obj2, checkCss=false) {
        // Check if both jQuery objects reference the same number of elements
        if ($obj1.length !== $obj2.length) {
            return false;
        }
    
        // Check if all elements in both jQuery objects are the same
        for (let i = 0; i < $obj1.length; i++) {
            if ($obj1[i] !== $obj2[i]) {
                return false;
            }
        }

        if (!checkCss) return true;

        // Check if all elements in both jQuery objects are the same based on their HTML, CSS class, and ID
        for (let i = 0; i < $obj1.length; i++) {
            const el1 = $obj1[i];
            const el2 = $obj2[i];
    
            if (el1.tagName !== el2.tagName) {
                return false;
            }
    
            if (el1.className !== el2.className) {
                return false;
            }
    
            if (el1.id !== el2.id) {
                return false;
            }
        }

        return true;
    }
}


export class f2fBase extends f2fHtml {

    constructor(options=null, 
        elementDivId=null) {  // To make some old code to work.
        super();

        if (elementDivId) {
            this.initContainerDiv(elementDivId);
        }

        let defaultOptions = {
            base_class: null,
            notify_destroy: null,
            notify_create: null,
        }

        // Set options
        this.options = $.extend(defaultOptions, options);

        this.$header = $('.rtu-header');
        this.$userCap = $('#caption-user');
        this.$user = this.$userCap.find('span');
        this.$welcome = this.$userCap.find('#welcome');
        this.$logout = this.$userCap.find('#logout');

        this.wait = new f2fWait();

        // Stylesheet management. Track stylesheet already loaded into html.
        // Stylesheet hash string. 
        ///this.loadedCss = [];??? which to use??
        if (window.loadedCss == undefined) 
            window.loadedCss = [];
    }

    // Legacy code --- start
    initContainerDiv(elementDivId) {
        this.elementDivId = elementDivId;

        if (this.elementDivId) {
            this.$elementDiv = $('#' + elementDivId);
        } else {
            this.$elementDiv = null;
        }
        
        
        // Create event handlers
        this.$elementDiv.click($.proxy(this.event_click, this));
    }
    get $div() {
        return this.$elementDiv;
    }

    get divId() {
        return this.elementDivId;
    }
    // Legacy code --- end

    isElementInViewport(element) {
        let rect = element.getBoundingClientRect();
        return rect.bottom <= $(window).height();
        /*
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= $(window).height() &&
            rect.right <= $(window).width()
        );
        */
    }

    setOptions(options) {
        this.options = $.extend(this.options, options);
    }

    b64_to_utf8_urlsafe(str) {
        // Server side is base64 urlsafe encoding. 2 characters are treated specially.
        // https://stackoverflow.com/questions/28100601/decode-url-safe-base64-in-javascript-browser-side
        let data = str.replace(/_/g, '/').replace(/-/g, '+')
        return decodeURIComponent(escape(window.atob(data)));
    }
    
    utf8_to_b64(str) {
        return window.btoa(unescape(encodeURIComponent( str )));
    }
    
    b64_to_utf8(str) {
        return decodeURIComponent(escape(window.atob( str )));
    }

    makeCall(handler, param) {
        if (handler && handler.base && handler.func) {
          const base = handler.base;
          const func = handler.func;
          return func.call(base, param); 
        } else {
          return null;
        }
    }

    hashCode(str) {
        return str.split('').reduce((prevHash, currVal) =>
          (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
    }
    
    /**
     * It needs to handle mulitple load/unload to avoid unload prematurely.
     * 
     * [{cssId1: ref1}, {cssId2: ref2}]
     */
    loadCssFile(cssFile) {
        if (!cssFile)
            return null;
        
        let cssId = this.hashCode(cssFile).toString();  // this.uniqueId();
        if (!document.getElementById(cssId)) {
            // Add new.
            let head  = document.getElementsByTagName('head')[0];

            // Non react
            /*
            let link  = document.createElement('link');
            link.id   = cssId;
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = cssFile;
            link.media = 'all';
            head.appendChild(link);
            */
            // React. Load the CSS using the require syntax
            const link = document.createElement('style');

            // link.type = 'text/css';
            link.appendChild(document.createTextNode(require(cssFile).default));

            window.loadedCss.push({cssId: cssId, ref: 1});
        } else {
            // If already loaded, just add ref count.
            let loadedCss = window.loadedCss.find(o => o.cssId === cssId);
            loadedCss.ref += 1;
        }
        return cssId;
    }
    
    unloadCssFile(cssFileId) {
        return this.deleteCss(cssFileId);
        
        /*
        if (!cssFileId)
        return false;
        
        let firstLink = document.getElementsByTagName(cssFileId)[0];
        firstLink.parentNode.removeChild(firstLink);
        
        return true;
        */
    }
 
    /**
     * Handle multiple load/unload of same css string. Reference count is needed
     * to avoid unload prematurely.
     */
    addCss(styles) {
        if (!styles)
            return null;
        
        let hashId = this.hashCode(styles).toString();  // this.uniqueId();

        let loadedCss = window.loadedCss.find(o => o.cssId === hashId);
        //if (!window.loadedCss.includes(hashId)) {
        if (!loadedCss) {
            let styleSheet = document.createElement("style");
            styleSheet.type = 'text/css';
            styleSheet.innerText = styles;
            //styleSheet.id = 'injected-css';  // Used for delete.
            styleSheet.setAttribute('id', hashId);
            document.head.appendChild(styleSheet);
            window.loadedCss.push({cssId: hashId, ref: 1});
        } else {
            // If already loaded, just add ref count.
            loadedCss.ref += 1;
        }

        return hashId;
    }

    deleteCss(styleId) {
        if (!styleId)
            return false;

        let stylesheet = document.getElementById(styleId);
        if (stylesheet) {      
        let loadedCss = window.loadedCss.find(o => o.cssId === styleId);
        if (!loadedCss) {
            console.log('Unload css inconsistency!');
            return false;
        }
        loadedCss.ref -= 1;

        if (0 == loadedCss.ref) {
            stylesheet.parentNode.removeChild(stylesheet);
            // Remove the item with ref count = 0;
            window.loadedCss = window.loadedCss.filter(function( obj ) {
            return obj.cssId !== styleId;
            });
        }
        /*
        let index = window.loadedCss.indexOf(styleId);
        if (index >= 0) {
            window.loadedCss.splice(index, 1);
        }
        */
        }
        
        return true;
    }

    /*
    readHtmlFile(htmlFile) {
        if (!htmlFile)
          return null;
    
        // return fs.readFileSync(htmlFile, 'utf8');
        let fileReader = new FileReader(); 
        fileReader.readAsText(htmlFile); 

        fileReader.onload = function() {
            console.log(reader.result);
        };
    
        fileReader.onerror = function() {
        console.log(reader.error);
        };
    }
    */

    /**
     * https://stackoverflow.com/questions/36921947/read-a-server-side-file-using-javascript
     * 
     * Synchronously read a text file from the web server with Ajax
     * The filePath is relative to the web page folder.
     * Example:   myStuff = loadFile("Chuuk_data.txt");
     * 
     * Read file as web server resource. It's different from Electron which is file protocal which
     * requires local file directory.
     * 
     * @param {*} filePath 
     * @returns 
     */
    readHtmlFileUrl(filePath) {
        var result = null;
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", filePath, false);
        xmlhttp.send();
        if (xmlhttp.status==200) {
          result = xmlhttp.responseText;
        }
        return result;
    }

    /**
     * Load html file and append at bottom of an anchor.
     */
    loadHtmlFileAppendBottom($anchor, htmlFile) {
        throw new Error("loadHtmlFileAppendBottom is not implemented.");
        /*
        const htmlFilePath = path.resolve(__dirname, htmlFile);
        const html = this.readHtmlFileUrl(htmlFilePath);
        return this.appendBottom($anchor, html);
        */
    }
     
    loadHtmlFileChildTop($container, htmlFile) {
        //const htmlFilePath = path.resolve(__dirname, htmlFile);
        let loc = window.location.pathname;
        let dir = loc.substring(0, loc.lastIndexOf('/'));

        const htmlFilePath = dir + '/' + htmlFile;
        const html = this.readHtmlFileUrl(htmlFilePath);
        return this.insertChildTop($container, html);
    }

    loadJsFile(jsFiles, callback=null) {
        if (!Array.isArray(jsFiles)) {
          throw 'jsFiles is not array';
        }
        /*
        idList = [];
        
        if (++i < jsFiles.length) {
          _loadJsFiles(jsFiles[i], callback)
        } 
        
        return idList;
        */
        let i = 0;
        let idList = [];
        let recursiveCallback = function() {
          if (++i < jsFiles.length) {
            let jsId = this._loadJsFiles(jsFiles[i], recursiveCallback);
            idList.push(jsId);
          } else {
            callback(idList);
          }
        }.bind(this);
        
        // Loaded Js id list is passed in callback. This is 1st JS item in array 
        // get loaded. Rest of them is loaded in recursive call.
        let jsId = this._loadJsFiles(jsFiles[0], recursiveCallback);
        idList.push(jsId);
    }
    
    _loadJsFiles(jsFile, callback=null) {
        if (!jsFile)
          return null;
    
        let jsId = this.hashCode(jsFile).toString();  
        if (!document.getElementById(jsId)) {
          let head  = document.getElementsByTagName('head')[0];
          let script = document.createElement('script');
          script.setAttribute('type', 'text/javascript');
          
          if (callback) {
            if (script.readyState) { // IE
              script.onreadystatechange = function() {
                if (script.readyState == "loaded" ||
                  script.readyState == "complete") {
                  script.onreadystatechange = null;
                  callback();
                }
              };
            } else { //Others
              script.onload = function() {
                callback();
              };
            }
          }
        
          script.setAttribute('src', jsFile);
          script.setAttribute('id', jsId);
          head.appendChild(script);
    
          // simplify it by adding jsId to css array.
          window.loadedCss.push({cssId: jsId, ref: 1});
          //window.loadedCss.push(jsId);
        } else {
          // If already loaded, add ref count.
          let loadedJs = window.loadedCss.find(o => o.cssId === jsId);
          loadedJs.ref += 1;
        }
        
        return jsId;
    }
      
    unloadJsFile(jsFileIdList) {
        for (let i = 0; i < jsFileIdList.length; i++) {
            this.deleteCss(jsFileIdList[i]);
        }
    }
  
    showHeader(state) {
        if (state) {
            this.$header.show();
        } else {
            this.$header.hide();
        }
    }

    regCall(baseClass, func) {
        return {base: baseClass,
                func: func};
    }

    funcCall(handler, params) {
        if (handler && handler.base && handler.func) {
            return handler.func.call(handler.base, params);
        } else {
            return null;
        }
    }
}
