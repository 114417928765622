// Not work. OBS
import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import { useAppContext } from '../../AppContext';  // Adjust the import path as necessary

const OnlineUsersContext = createContext();

export const useOnlineUsers = () => {
  return useContext(OnlineUsersContext);
};

export const OnlineUsersProvider = ({ children }) => {
  const { loginUserName, isActive } = useAppContext();
  const [peers, setPeers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [selfUserId, setSelfUserId] = useState(null);
  const [messages, setMessages] = useState([]);
  const socketRef = useRef(null);
  const prevLoginUserNameRef = useRef(null);
  const activeConnections = useRef({});  // Track active user connections

  const stunServers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' }
    ],
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }

    const socket = socketRef.current;
    const room = 'presence_room';

    const handleUserConnected = ({ user_id }) => {
      if (loginUserName && user_id !== loginUserName && !activeConnections.current[user_id]) {
        console.log('Create peer initiator... #22222... user connected.')
        const peer = new Peer({ 
          initiator: true, 
          trickle: false, 
          config: stunServers 
        });
        peer.on('signal', (data) => {
          console.log(`Sending signal to ${user_id}. Signal: ${data}`);
          socket.emit('signal', { to: user_id, signal: data });
        });
        peer.on('connect', () => {
          console.log(`Peer connected with ${user_id}`);
        });
        peer.on('data', (data) => {
          const message = JSON.parse(data.toString());
          setMessages((prevMessages) => [...prevMessages, message]);
        });
        peer.on('iceStateChange', (state) => {
          console.log(`ICE state change for ${user_id}: ${state}`);
        });
        peer.on('iceCandidate', (candidate) => {
          if (candidate) {
            console.log(`Sending ICE candidate to ${user_id}`);
            socket.emit('ice_candidate', { to: user_id, candidate });
          }
        });

        activeConnections.current[user_id] = peer;
        setPeers((peers) => [...peers, { peer, user_id }]);
        setOnlineUsers((users) => {
          const newUsers = new Set(users);
          newUsers.add(user_id);
          return newUsers;
        });
      }
    };

    const handleUserDisconnected = ({ user_id }) => {
      if (activeConnections.current[user_id]) {
        activeConnections.current[user_id].destroy();
        delete activeConnections.current[user_id];
      }
      setPeers((peers) => peers.filter(p => p.user_id !== user_id));
      setOnlineUsers((users) => {
        const newUsers = new Set(users);
        newUsers.delete(user_id);
        return newUsers;
      });
    };

    const handleSignal = ({ from, signal }) => {
      console.log(`Received signal from ${from}`);
      if (activeConnections.current[from]) {
        console.log(`Signaling existing peer with ${from}`);
        activeConnections.current[from].signal(signal);
      } else {
        console.log(`Creating new peer for signal from ${from}`);
        const newPeer = new Peer({ initiator: false, trickle: false, config: stunServers });
        newPeer.on('signal', (data) => {
          console.log(`Sending return signal to ${from}`);
          socket.emit('signal', { to: from, signal: data });
        });
        newPeer.on('connect', () => {
          console.log(`Peer connected with ${from}`);
        });
        newPeer.on('data', (data) => {
          const message = JSON.parse(data.toString());
          setMessages((prevMessages) => [...prevMessages, message]);
        });
        newPeer.on('iceStateChange', (state) => {
          console.log(`ICE state change for ${from}: ${state}`);
        });
        newPeer.on('iceCandidate', (candidate) => {
          if (candidate) {
            console.log(`Sending ICE candidate to ${from}`);
            socket.emit('ice_candidate', { to: from, candidate });
          }
        });
        newPeer.on('close', () => {
          console.log(`Peer connection with ${from} closed`);
          delete activeConnections.current[from];
          setPeers((peers) => peers.filter(p => p.peer !== newPeer));
          setOnlineUsers((users) => {
            const newUsers = new Set(users);
            newUsers.delete(from);
            return newUsers;
          });
        });

        newPeer.signal(signal);
        activeConnections.current[from] = newPeer;
        setPeers((peers) => [...peers, { peer: newPeer, user_id: from }]);
        setOnlineUsers((users) => new Set([...users, from]));
      }
    };

    const handleIceCandidate = ({ from, candidate }) => {
      console.log(`Received ICE candidate from ${from}`);
      if (activeConnections.current[from]) {
        activeConnections.current[from].signal(candidate);
      }
    };

    const handleSelfJoined = ({ room, existing_users }) => {
      console.log(`Joined room: ${room}, socket id: ${socket.id}`);
      setSelfUserId(socket.id);
      const newUsers = new Set(existing_users);
      setOnlineUsers(newUsers);
      existing_users.forEach((user_id) => {
        if (loginUserName && user_id !== loginUserName && !activeConnections.current[user_id]) {
          console.log('Create peer initiator... #2... self join.')
          const peer = new Peer({ 
            initiator: true, 
            trickle: false, 
            config: stunServers 
          });
          peer.on('signal', (data) => {
            console.log(`Sending signal to ${user_id}`);
            socket.emit('signal', { to: user_id, signal: data });
          });
          peer.on('connect', () => {
            console.log(`Peer connected with ${user_id}`);
          });
          peer.on('data', (data) => {
            const message = JSON.parse(data.toString());
            setMessages((prevMessages) => [...prevMessages, message]);
          });
          peer.on('iceStateChange', (state) => {
            console.log(`ICE state change for ${user_id}: ${state}`);
          });
          peer.on('iceCandidate', (candidate) => {
            if (candidate) {
              console.log(`Sending ICE candidate to ${user_id}`);
              socket.emit('ice_candidate', { to: user_id, candidate });
            }
          });

          activeConnections.current[user_id] = peer;
          setPeers((peers) => [...peers, { peer, user_id }]);
        }
      });
    };

    socket.on('user_connected', handleUserConnected);
    socket.on('user_disconnected', handleUserDisconnected);
    socket.on('signal', handleSignal);
    socket.on('self_joined', handleSelfJoined);
    socket.on('ice_candidate', handleIceCandidate);

    return () => {
      socket.off('user_connected', handleUserConnected);
      socket.off('user_disconnected', handleUserDisconnected);
      socket.off('signal', handleSignal);
      socket.off('self_joined', handleSelfJoined);
      socket.off('ice_candidate', handleIceCandidate);
    };
  }, [selfUserId, peers]);

  useEffect(() => {
    const socket = socketRef.current;
    const room = 'presence_room';
    const prevLoginUserName = prevLoginUserNameRef.current;

    // isActive = user login already.
    if (isActive && loginUserName && loginUserName !== prevLoginUserName) {
      console.log('Join room..... #1');
      socket.emit('join', { room, user_id: loginUserName });
      sessionStorage.setItem('loginUserName', loginUserName);
      prevLoginUserNameRef.current = loginUserName;
    }

    if ((!isActive || !loginUserName) && prevLoginUserName) {
      socket.emit('leave', { room, user_id: prevLoginUserName });
      sessionStorage.removeItem('loginUserName');
      prevLoginUserNameRef.current = null;
    }
  }, [isActive, loginUserName]);

  const sendMessage = (to, message) => {
    console.log(`Sending message from ${selfUserId} to ${to}: ${message}`);
    const peer = activeConnections.current[to];
    if (peer) {
      if (peer._channel && peer._channel.readyState === 'open') {
        const msg = { from: selfUserId, message };
        peer.send(JSON.stringify(msg));
        setMessages((prevMessages) => [...prevMessages, msg]);
      } else {
        console.log(`Connection to ${to} is not open`);
      }
    } else {
      console.log(`No active connection found for user ${to}`);
    }
  };

  return (
    <OnlineUsersContext.Provider value={{ onlineUsers: Array.from(onlineUsers), selfUserId, messages, sendMessage }}>
      {children}
    </OnlineUsersContext.Provider>
  );
};

export default OnlineUsersProvider;
