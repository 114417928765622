import React, { useRef, useState, useEffect } from 'react';

import Muuri from 'muuri';
import './MuuriStockList.css';


export const MuuriStockList = ({
  title = 'Stocks',
  titleCss = 'source',
  items = ['item1', 'item2'],
  notifyLayoutDone = true,
  onListRef = null,
  isStockList = true,
}) => {
  const [listCount, setListCount] = useState(1);
  const boardColumnRefs = useRef([]);
  const boardGridRef = useRef(null);

  const addNewList = () => {
    setListCount((prevCount) => prevCount + 1);
  };

  const renderStockList = (index) => (
    <div key={index} className={`board-column
        ref={boardColumnRef} 
        ${titleCss} 
        ${notifyLayoutDone ? 'show-muuri-tems' : 'hide-muuri-tems'}`}>
      <div className="board-column-container">
        <div className="board-column-header">{index === 1 ? title : `Stocks ${index}`}</div>
        <div className="board-column-content-wrapper">
          <div className="board-column-content">
            {/* Editable item. Not draggable. */}
            {isStockList ? (
              <>
                <div key={`special-${index}`} className="board-item">
                  <div className="board-item-content">
                    <input
                      type="text"
                      value={''}
                      placeholder={index === 1 ? 'Enter special item' : `Enter special item for Stocks ${index}`}
                      autoFocus
                    />
                    <button type="button">+</button>
                  </div>
                </div>
              </>
            ) : null}

            {/* List Items */}
            {items.map((item, itemIndex) => (
              <div key={`${index}-${itemIndex}`} className="board-item">
                <div className="board-item-content">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );


  useEffect(() => {
    // Initialize Muuri grid for the stock list
    const board = new Muuri(`.board-column.${titleCss}`, {
      items: '.board-item',
      dragEnabled: true,
      dragStartPredicate: function (item, event) {
        // Prevent first item from being dragged.
        if (board.getItems().indexOf(item) === 0) {
          return false;
        }
        // For other items use the default drag start predicate.
        return Muuri.ItemDrag.defaultStartPredicate(item, event);
      },
      // ... other Muuri options
    })
    .on('dragInit', function (item) {
      item.getElement().style.width = item.getWidth() + 'px';
      item.getElement().style.height = item.getHeight() + 'px';
    })
    .on('dragReleaseEnd', function (item) {
      item.getElement().style.width = '';
      item.getElement().style.height = '';
      item.getGrid().refreshItems([item]);
    })
    .on('layoutStart', function () {
      boardGridRef.current.refreshItems().layout();
    });

    // Add the grid instance to columnGrids (assuming columnGrids is defined somewhere)
    // columnGrids.push(gridRef.current);

    return () => {
      // Cleanup Muuri grid when component unmounts
      board.destroy();
    };
  }, [titleCss]); // Make sure to include dependencies for useEffect

  useEffect(() => {
    // Init board grid so we can drag those columns around.
    boardGridRef.current = new Muuri('.board', {
      dragEnabled: false,
      dragHandle: '.board-column-header',
    });

    return () => {
      // Cleanup board grid when component unmounts
      boardGridRef.current.destroy();
    };
  }, []);

  return (
  <>
    {/* Add Stock List Button */}
    <button onClick={addNewList}>Add Stock List</button>
    <div className="drag-container"></div>
    <div className="board">
      {/* Default Stock List */}
      {renderStockList(1)}

      {/* Additional Stock Lists */}
      {[...Array(listCount - 1)].map((_, index) => renderStockList(index + 2))}
    </div>
  </>
  );
};
