import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { f2futil } from '../../../comjs/utils/utils.js';
import VideoCard from './VideoCard';
import VideoPlayerSized from '../../CinemaVideo/VideoPlayerSized';
import styles from './VideoGridBase.module.css';

const VideoGridBase = forwardRef(({
    initGrid,
    addNewItemGrid,
    onClickVideo,
    onClickFolder,
    ytEditMode,
    editStatus,
    setErrorMessage,
    deleteVideoApiCall,
    saveVideosOrderToServer,
    items = [],
    forceDisablePlay=false
}, ref) => {
    const [videos, setVideos] = useState([]);
    const [playingVideo, setPlayingVideo] = useState(null);
    const isInitialized = useRef(false);
    const [isDragging, setIsDragging] = useState(false);
    const [pasteUrl, setPasteUrl] = useState('');
    const pasteInputRef = useRef(null);
    const [pasteError, setPasteError] = useState('');

    useEffect(() => {
        if (!isInitialized.current) {
            initGrid().then((retData) => {
                if (retData && retData.status) {
                    // const videoList = retData.data.filter(item => item.file_type == 2  // 2 is yt video
                    //        || item.file_type == 3);  // 3 is local video ??? 


                    // Check if retData.data is an array and filter for videos with file_type 2 or 3
                    const videoList = Array.isArray(retData.data) ? 
                            retData.data.filter(item => item.file_type === 2        // 2 is yt video
                                                     || item.file_type === 3) : []; // 3 is local video ??? 

                    // Distinguish local and yt vides, so that different players can be applied
                    const extVideoList = videoList.map((video, index) => {
                        let valid = true;
                        let urlInfo = null;
                        try {
                          const util = new f2futil();
                          urlInfo = util.parseMediaUrl(video.channel_media_path);
                        } catch (error) {
                          valid = false;
                        }

                        return {
                            ...video, // Spread the existing properties of the video object
                            relpath: urlInfo.relpath,
                            channel_video_type: valid ? urlInfo.type : '' // Add the new field
                        };
                    });

                    setVideos(extVideoList);
                } else {
                    console.error("API call failed due to server return null.");
                }
            }).catch(error => {
                console.error('Error during API call:', error);
                alert('Failed to fetch data.');
            });
            isInitialized.current = true;
        }
    }, [initGrid]);

    // useEffect(() => {
    //     if (items.length > 0) {
    //         setVideos(items);
    //     }
    // }, [items]);

    const handleOnClickVideo = (evt, title) => {
        if (!isDragging && onClickVideo) onClickVideo(evt, title);
    };

    const handleOnVideoEnd = (title) => {
        setPlayingVideo(null);
    };

    const onDragEnd = (result) => {
        setIsDragging(false);
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        const reorderedVideos = Array.from(videos);
        const [movedVideo] = reorderedVideos.splice(sourceIndex, 1);
        reorderedVideos.splice(destinationIndex, 0, movedVideo);

        setVideos(reorderedVideos);
        saveVideosOrderToServer(reorderedVideos);
    };

    const handlePasteUrlChange = (e) => {
        setPasteUrl(e.target.value);
        setPasteError('');
    };

    const processAddNewItem = async (pasteUrl) => {
        if (!pasteUrl) return;

        try {
            addNewItemGrid(pasteUrl).then((retData) => {
                if (retData && retData.status) {
                    const newItem = retData.data;

                    if (newItem.paste_type == 'yv') {
                        setVideos((prevVideos) => {
                            const exists = prevVideos.some(video => video.channel_media_path === newItem.channel_media_path);
                            if (exists) {
                                const msg = 'This youtube is already in the list!';
                                console.warn(msg);
                                setErrorMessage(msg);
                                return prevVideos;
                            }
                            return [newItem, ...prevVideos];
                        });
                    } else if (newItem.paste_type == 'lv') {
                        setVideos((prevVideos) => {
                            const exists = prevVideos.some(video => 
                                    video.file_name === newItem.file_name);
                            if (exists) {
                                const msg = 'This video is already in the list!';
                                console.warn(msg);
                                setErrorMessage(msg);
                                return prevVideos;
                            }
                            return [newItem, ...prevVideos];
                        });
                    }
                } else if (retData && !retData.status) {
                    setPasteError(retData.error);
                }
            }).catch((error) => {
                let err = `Failed to add video: ${error}`;
                // console.error(err);
                setPasteError(err);
            })
            .finally(() => {
                setPasteUrl('');
            });
        } catch (error) {
            let err = `Failed to add video: ${error}`;
            // console.error(err);
            setPasteError(err);
        }
    };

    const handlePasteUrlKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            processAddNewItem(pasteUrl.trim());
        }
    };

    const handlePasteUrlButtonClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        processAddNewItem(pasteUrl.trim());
    };

    useImperativeHandle(ref, () => ({
        addItem: (url) => {
            addNewItemGrid(url).then((retData) => {
                if (retData && retData.status) {
                    const newItem = retData.data;

                    if (newItem.paste_type == 'yv') {
                        setVideos((prevVideos) => {
                            const exists = prevVideos.some(video => video.channel_media_path === newItem.channel_media_path);
                            if (exists) {
                                const err = 'This youtube is already in the list!';
                                console.warn(err);
                                setErrorMessage(err);
                                return prevVideos;
                            }
                            return [...prevVideos, newItem];
                        });
                    } else if (newItem.paste_type == 'lv') {
                        setVideos((prevVideos) => {
                            const exists = prevVideos.some(video => 
                                    video.file_name === newItem.file_name);
                            if (exists) {
                                const err = 'This video is already in the list!';
                                console.warn(err);
                                setErrorMessage(err);
                                return prevVideos;
                            }
                            return [...prevVideos, newItem];
                        });
                    }
                } else {
                    console.error("Add item API call failed.");
                    if (setErrorMessage) setErrorMessage('Add item API call failed.');
                }
            }).catch(error => {
                console.error('Error during add item API call:', error);
                if (setErrorMessage) setErrorMessage('Failed to add item.');
            });
        }
    }));

    const handleDeleteVideoCard = async (media_id) => {
        const ret = await deleteVideoApiCall(media_id);
        if (ret.status) {
            setVideos((prevVideos) => prevVideos.filter(video => video.channel_media_path !== media_id));

            // Error is under input editbox. Not good position.
            setPasteError('');
        } else {
            setPasteError(ret.error);
        }
    };

    return (
        <div>
            {editStatus ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="video-grid" direction="vertical">
                        {(provided) => (
                            <div className={`${styles['grid-container']} ${styles['droppable-container']}`} {...provided.droppableProps} ref={provided.innerRef}>
                                <div className={styles['paste-input-container']}>
                                    <input
                                        type="text"
                                        ref={pasteInputRef}
                                        value={pasteUrl}
                                        onChange={handlePasteUrlChange}
                                        onKeyPress={handlePasteUrlKeyPress}
                                        placeholder="Paste channel link here"
                                        className={styles['paste-input']}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                    {pasteError && <div className={styles['error-message']}>{pasteError}</div>}
                                    <button onClick={handlePasteUrlButtonClick} className={styles['paste-button']}>Add Channel Video</button>
                                </div>
                                {videos.map((video, index) => (
                                    <Draggable key={video.media_id} draggableId={video.media_id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                className={`${styles['video-card-container']} ${snapshot.isDragging ? styles['dragging'] : ''}`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {editStatus && (
                                                    <button
                                                        className={styles['delete-button']}
                                                        onClick={() => handleDeleteVideoCard(video.channel_media_path)}
                                                    >
                                                        x
                                                    </button>
                                                )}
                                                <VideoCard
                                                    key={index}
                                                    channelMediaPath={video.channel_media_path}
                                                    yvVideoUrl={video.youtube_url}
                                                    movie_title={video.file_name}
                                                    clipthumbnail={video.thumbnail}
                                                    duration={video.duration}
                                                    onVideoClick={handleOnClickVideo}
                                                    onVideoEnd={handleOnVideoEnd}
                                                    isPlaying={playingVideo}
                                                    setPlayingVideo={setPlayingVideo}
                                                    uuid={video.uuid}
                                                    relpath={video.relpath}
                                                    media_id={video.media_id}
                                                    playAllowed={!editStatus}
                                                    channelVideoType={video.channel_video_type}
                                                    enablePlay={false}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <div className={styles['grid-container']}>
                    {videos.map((video, index) => (
                        <div key={index} className={styles['video-card-container']}>
                            {video.channel_video_type=='yv' ? (
                                <VideoCard
                                    key={index}
                                    channelMediaPath={video.channel_media_path}
                                    yvVideoUrl={video.youtube_url}
                                    movie_title={video.file_name}
                                    clipthumbnail={video.thumbnail}
                                    duration={video.duration}
                                    onVideoClick={handleOnClickVideo}
                                    onVideoEnd={handleOnVideoEnd}
                                    isPlaying={playingVideo}
                                    setPlayingVideo={setPlayingVideo}
                                    uuid={video.uuid}
                                    relpath={video.relpath}
                                    media_id={video.media_id}
                                    playAllowed={!editStatus}
                                    channelVideoType={video.channel_video_type}
                                    forceDisablePlay={forceDisablePlay}
                                />
                            ) : video.channel_video_type=='lv' ? (
                                <VideoCard
                                    key={index}
                                    channelMediaPath={video.channel_media_path}
                                    lvVideoUrl={video.video_src}
                                    movie_title={video.file_title}
                                    clipthumbnail={video.thumbnail}
                                    duration={video.duration}
                                    onVideoClick={handleOnClickVideo}
                                    onVideoEnd={handleOnVideoEnd}
                                    isPlaying={playingVideo}
                                    setPlayingVideo={setPlayingVideo}
                                    playAllowed={!editStatus}
                                    channelVideoType={video.channel_video_type}
                                    forceDisablePlay={forceDisablePlay}
                                />
                            ) : null}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default VideoGridBase;
