/*

This is based on:
https://docs.muuri.dev/examples.html

Need to learn from:

https://codesandbox.io/p/sandbox/muuri-react-kanban-zmypd?file=%2Findex.js%3A7%2C73

to make it react components.


*/

import React, { useEffect } from 'react';

import Muuri from 'muuri';
import './MuuriLab3Kanban.css';


export const MuuriLab3Kanban = () => {

  useEffect(() => {
    var dragContainer = document.querySelector('.drag-container');
    var itemContainers = [].slice.call(document.querySelectorAll('.board-column-content'));
    var columnGrids = [];
    var boardGrid;
    
    // Init the column grids so we can drag those items around.
    itemContainers.forEach(function (container) {
      var grid = new Muuri(container, {
        items: '.board-item',
        dragEnabled: true,
        dragSort: function () {
          return columnGrids;
        },
        dragContainer: dragContainer,
        dragAutoScroll: {
          targets: (item) => {
            return [
              { element: window, priority: 0 },
              { element: item.getGrid().getElement().parentNode, priority: 1 },
            ];
          }
        },
      })
      .on('dragInit', function (item) {
        item.getElement().style.width = item.getWidth() + 'px';
        item.getElement().style.height = item.getHeight() + 'px';
      })
      .on('dragReleaseEnd', function (item) {
        item.getElement().style.width = '';
        item.getElement().style.height = '';
        item.getGrid().refreshItems([item]);
      })
      .on('layoutStart', function () {
        boardGrid.refreshItems().layout();
      });
    
      columnGrids.push(grid);
    });
    
    // Init board grid so we can drag those columns around.
    boardGrid = new Muuri('.board', {
      dragEnabled: true,
      dragHandle: '.board-column-header'
    });
  }, []);

  return (
    <>
      <div className="drag-container"></div>
      <div className="board">
        <div className="board-column todo">
          <div className="board-column-container">
            <div className="board-column-header">Todo</div>
            <div className="board-column-content-wrapper">
              <div className="board-column-content">
                <div className="board-item"><div className="board-item-content"><span>Item #</span>1</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>2</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>3</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>4</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>5</div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="board-column working">
          <div className="board-column-container">
            <div className="board-column-header">Working</div>
            <div className="board-column-content-wrapper">
              <div className="board-column-content">
                <div className="board-item"><div className="board-item-content"><span>Item #</span>6</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>7</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>8</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>9</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>10</div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="board-column done">
          <div className="board-column-container">
            <div className="board-column-header">Done</div>
            <div className="board-column-content-wrapper">
              <div className="board-column-content">
                <div className="board-item"><div className="board-item-content"><span>Item #</span>11</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>12</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>13</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>14</div></div>
                <div className="board-item"><div className="board-item-content"><span>Item #</span>15</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}