import React from 'react';

// To persist status for all children components, local storage is used. The settings are
// often set by user when app is running.
import { ConfigProvider } from './ConfigContext';

// Language setting is read from browser. No local storage is needed. It's like
// to provide global settings to all components.
import { LanguageProvider } from '../Localization/LanguageContext/LanguageContext';
import { VideoPlayPageProvider } from './VideoPlayPageContext';
import { AutoPlayContextProvider } from './AutoPlayContext';
import SimplePeerUserProvider from '../Online/SimplePeerUserContext';
// import OnlineUsersProvider from '../Online/OnlineUsersContext';
// import PeerJsUserProvider from '../Online/PeerJsUserContext';


export const CombinedProvider = ({ children }) => {
  return (
    <ConfigProvider>
      <LanguageProvider>
        <VideoPlayPageProvider>
          <AutoPlayContextProvider>
            <SimplePeerUserProvider>
              {children}
            </SimplePeerUserProvider>
          </AutoPlayContextProvider>
        </VideoPlayPageProvider>
      </LanguageProvider>
    </ConfigProvider>
  );
};