import React, { useState, useEffect } from 'react';
import './PhotoPlayer.css'


class PhotoPlayerAPI {
  redirectToCurrentDomain() {
    const currentDomain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    window.location.href = currentDomain;
  }

  async initPlayer(relPath=null) {
    let retData = null;

    try {
      let url = '/api_myfilex/playerparam';

      if (relPath) {
        url += '/' + relPath;
      }
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.result.status) {
          retData = data.result;
        }
      } else if (!response.ok && response.statusText == "UNAUTHORIZED") {
        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }

  async nextPlayerPhoto(relImageFile) {
    let retData = null;

    try {
      const response = await fetch('/api_myfilex/playerfile/' + relImageFile, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.result.status) {
          retData = data.result;
        }
      } else if (!response.ok && response.statusText == "UNAUTHORIZED") {
        // Login session error (such as server reboot)
        this.redirectToCurrentDomain();
        return null;
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }
    return retData;
  }
}

/**
 * It uses HOC (high order of component), which takes one component and return another component
 * that is similar to inheritance.
 * 
 * The whole point of using HOC here is to define APIs outside of PlayerUI to make code more abstract.
 * 
 * @param {*} PlayerUI 
 * @returns 
 */
const PhotoPlayer = (PlayerUI) => {
  const PhotoPlayerData = (props) => {
    // const { onStart, onFinish, relPath, title } = props;

    const initImage = async (relPath) => {
      const dataFetcher = new PhotoPlayerAPI();
      const result = await dataFetcher.initPlayer(relPath);
      return result;
    };

    const nextImage = async (relImageFile) => {
      const dataFetcher = new PhotoPlayerAPI();
      const result = await dataFetcher.nextPlayerPhoto(relImageFile);

      return result;
    };

    return <PlayerUI 
      initImage={initImage}
      nextImage={nextImage} 
      {...props}  // Make sure to pass down all other props
    />;
  };

  return PhotoPlayerData;
};

export default PhotoPlayer;

/*
In this code snippet, PhotoPlayer is a higher-order component (HOC) that takes
 a component PlayerUI as its argument and returns a new component PhotoPlayerData. 
 Let's break down the structure and understand the flow.

PhotoPlayer Function:

Takes a component PlayerUI as its argument.
Logs a message to the console indicating that it's the PhotoPlayer.
PhotoPlayerData Function:

Takes props as its argument, which likely includes properties like onStart, 
onFinish, relPath, and title.
Logs a message to the console that includes the value of relPath.
initImage Function:

Asynchronously initializes the player by fetching data using the PhotoPlayerAPI.
Returns the result of the initialization.
nextImage Function:

Asynchronously fetches the next player photo using the PhotoPlayerAPI based on the 
provided relative image file path.
Returns the result of fetching the next image.
Returning PlayerUI:

Returns the PlayerUI component and passes down the initImage and nextImage functions as props.
Also, spreads the rest of the props (like onStart, onFinish, relPath, title, etc.) 
to ensure that all the original props are passed down.
Returning PhotoPlayerData:

The PhotoPlayerData function is returned from the PhotoPlayer function.
So, when you use PhotoPlayer with a specific PlayerUI component, you get a new 
component (PhotoPlayerData) that includes additional functionalities (initImage and 
  nextImage) and passes down the original props to the PlayerUI component. 
  The original props likely contain information needed for the player, such as paths, 
  titles, and event handlers.
*/