/*
npm install dompurify
import DOMPurify from 'dompurify';

const sanitizedHTML = DOMPurify.sanitize(articleContent);

return (
  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
);
*/

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { f2fBase } from '../../comjs/js/base.js';
import { articleEditor, articleEditToggle } from '../../comjs/article.view/article-editor'; 
import {IconMenuBar, MenuModes} from '../IconMenuBar/IconMenuBar';
import DroppableVideoGrid from '../YoutubeView/YtPlaylistDnD/DroppableVideoGrid';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import './Article.css';


export const Article = ({ title, author, content }) => {
  const navigate = useNavigate();
  const { param_file_id, param_relpath } = useParams();
  const [articleContent, setArticleContent] = useState('');
  const [loading, setLoading] = useState(true);

  const [articleFile, setArticleFile] = useState(null);

  // Toggle class to set 'contenteditable="true"/"false" to article elements.
  const [editMode, setEditMode] = useState(null);

  // Store edit class.
  const [editor, setEditor] = useState(null);

  const articleRef = useRef(null);
  const [clipboardContent, setClipboardContent] = useState('');
  const [supported, setSupported] = useState(true);
  const [dropMedias, setDropMedias] = useState([]);
  const [currentEditItem, setCurrentEditItem] = useState($());
  
  const [editStatus, setEditStatus] = useState(false);
  // Use for event handler of ES6 class, which needs to access this state. Otherwise,
  // it's not able to get updated value when calling setEditStatus (weird react!)
  const editStatusRef = useRef(editStatus);  // Ref to store the latest editStatus

  // This is container created after toolbar insert media button is clicked, which
  // contains DroppableVideoGrid that is created is this component upon notification.
  const dropVideoGridRef = useRef(null);
  useEffect(() => {
    if (param_file_id) {
      try {
        const api = new ApiFileX();
  
        api.getArticle(param_relpath, param_file_id).then(async (retData) => {
          if (retData && retData.status) {
            setArticleContent(retData.data.content);
  
            // Once the content is set, process the media anchors
            setTimeout(() => {
              retData.data.anchor_media.forEach(anchor => {
                const mediaContainer = document.getElementById(anchor.anchor_name);
                if (mediaContainer) {
                  ReactDOM.render(
                    <DroppableVideoGrid
                      ref={(ref) => {
                        mediaContainer.gridRef = ref;
                      }}
                      ytEditMode={editStatus}
                      editStatus={editStatusRef.current}
                      items={anchor.media_list} // Pass the media list to DroppableVideoGrid
                    />,
                    mediaContainer
                  );
                }
              });
            }, 0); // Timeout to ensure the content is rendered before processing
          }
        });
      } catch (err) {
        console.error('Failed to fetch article:', err);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [param_file_id, param_relpath]);
  
  useEffect(() => {
    if (!loading && articleRef.current) {
      // Get vanilla div id for ES6 code.
      const articleId = articleRef.current.id;

      const editToggle = new articleEditToggle('article-container', articleId);
      setEditMode(editToggle);

      const options = {
        onChangeEditItem: handleOnChangeEditItem,
        onSave: handleOnSave,
        onDropMediaContainer: handleOnDropMediaContainer,
        onClickPasteMedia: handleClickPasteMedia
      }
      const editorTmp = new articleEditor(articleId, options);
      setEditor(editorTmp);
    }

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [loading]);

  useEffect(() => {
    if (!clipboardContent) return;

  }, [clipboardContent]);

  const editState = (editState) => {
    dropMedias.forEach(media => {
      if (editState) {
        media.removeClass('hidden');
      } else {
        media.addClass('hidden');
      }
    });
  };

  const handleOnChangeEditItem = (evt, newItem) => {
    const util = new f2fBase();

    // Editable object changed.
    if (!util.equalJqueryObj(newItem, currentEditItem)) {
      setCurrentEditItem(newItem);
    }
  }


  // 6/5/24. Put this callback inside useEffect() does not make different to the problem
  // of editMode change not causing rerender of DroppableVideoGrid.
  // 
  // Each article can have multiple droppable video grid. Each video grid should have
  // unique anchor provided by this handler. 
  const handleOnDropMediaContainer = (evt, $mediaContainer, url) => {
    setDropMedias(prevDropMedias => [...prevDropMedias, $mediaContainer]);
    const mediaContainer = $mediaContainer[0];
    dropVideoGridRef.current = mediaContainer;

    // Render react component DroppableVideoGrid into DOM component $mediaContainer[0]
    // which is ul element.
    ReactDOM.render(
      <DroppableVideoGrid
        ref={(ref) => {
          // Define the gridRef property on the mediaContainer DOM element.
          // gridRef is dynamically added here.
          // Why add? Without it, how to find it? It's used by useImperativeHandle
          // in VideoGridBase which handles grid videos.
          //
          // Callback Function:
          //   The callback function receives the instance of DroppableVideoGrid as 
          //   its argument, which we named ref in the function parameter.
          //
          //   Inside the callback, you dynamically add a new property gridRef to the 
          //   DOM element represented by $mediaContainer[0].
          //
          // Dynamic Property Addition:
          //
          //  $mediaContainer[0].gridRef = ref;:
          //
          //  $mediaContainer is a jQuery object. $mediaContainer[0] accesses 
          //  the underlying DOM element.
          //
          //  ref is the instance of the DroppableVideoGrid component.
          //
          //  This line dynamically adds a gridRef property to the DOM element
          //  and assigns it the DroppableVideoGrid component instance.
          // https://chatgpt.com/c/eaa09cff-a2d7-454d-a676-0d0a844a126b
          $mediaContainer[0].gridRef = ref;
        }}
        ytEditMode={editStatus}
        editStatus={editStatusRef.current}  // Use the ref to get the latest editStatus due to 
                                            // ES6 class event handler handleOnDropMediaContainer. 
      />,
      $mediaContainer[0]
    );
  }

  const handleOnSave = (evt) => {
    if (articleRef.current) {
      const articleContainer = articleRef.current.closest('#article-container');
      if (articleContainer) {
        // Clone the article container
        const clonedArticleContainer = articleContainer.cloneNode(true);
  
        // Remove the toolbar from the cloned container
        const toolbar = clonedArticleContainer.querySelector('.wrapper-toolbar');
        if (toolbar) {
          toolbar.remove();
        }

        // Remove all toolbars from the cloned container
        const toolbars = clonedArticleContainer.querySelectorAll('.wrapper-toolbar');
        toolbars.forEach(toolbar => {
          toolbar.remove();
        });

        // Extract <ul> elements with IDs starting with 'dropbox_'
        const dropboxElements = clonedArticleContainer.querySelectorAll('ul[id^="dropbox_"]');
        const medias = Array.from(dropboxElements).map(ul => {
          // Extract VideoCard information from each ul
          const videoCards = ul.querySelectorAll('[class*="video-cell"]'); // Replace with the actual class prefix if needed
          const videoCardData = Array.from(videoCards).map(videoCard => {
            const dataInfo = videoCard.getAttribute('data-info');
            return dataInfo ? JSON.parse(dataInfo) : null;
          }).filter(data => data !== null);
          
          // Remove all children from the ul element
          ul.innerHTML = '';

          return {
            ul_id: ul.id,
            videocard_data: videoCardData
          };
        });

        // Log extracted data
        console.log('Extracted Data:', medias);

        // Get the inner HTML of the cloned container
        const articleContent = clonedArticleContainer.querySelector('article').innerHTML; // Updated to get inner content
        const articleText = clonedArticleContainer.textContent;
        //const articleContent = clonedArticleContainer.innerHTML;
        //console.log('Article content to save:', articleContent);

        //const articleText = clonedArticleContainer.textContent;

        let articleBrief = '';
        if (!articleText) {
          articleBrief = 'Untitled';
        } else {
          articleBrief =  articleText.substring(0, 60);
        }

        // Extract dropbox videos.


        // Here you can make an API call to save the content or handle it as required
        // For example:
        // saveArticleContent(articleContent);
        const api = new ApiFileX();

        if (articleFile) {
          api.updateArticle(
              articleFile['file_id'], 
              articleFile['relpath'], 
              articleContent, 
              medias).then(async (retData) => {
            if (retData && retData.status) {
              const retValDirInfo = retData.data;
              articleFile = retData.data;
            } else {
              console.error('saveNewArticle failed!');
            }
          });
        } else {
          api.saveNewArticle(null, articleBrief, articleContent, medias).then(async (retData) => {
            if (retData && retData.status) {
              const retValDirInfo = retData.data;
              setArticleFile(retData.data);
            } else {
              console.error('saveNewArticle failed!');
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    // Update the ref whenever editStatus changes
    editStatusRef.current = editStatus;
  }, [editStatus]);

  
  const handleClickPasteMedia = async (event) => {
    // If not in edit mode, can't paste.
    // editStatus does not work, as handleClickPasteMedia is fired not by react component,
    // but es6 class.
    if (!editStatusRef.current) {
      return;
    }

    event.target.focus(); // Ensure the triggering element is focused
    if (navigator.clipboard) {
      try {
        const text = await navigator.clipboard.readText();
  
        // New value triggers loading of the video thumbnails.
        setClipboardContent(text);
        console.log('Insert video pasted: ' + text);
  
        // Use the ref to get the DroppableVideoGrid
        if (dropVideoGridRef.current && dropVideoGridRef.current.gridRef) {
          dropVideoGridRef.current.gridRef.addItem(text);
        }
      } catch (err) {
        // In tool debugger, it will fail but this is not bug.
        console.error('Failed to read clipboard contents: ', err);
      }
    } else {
      setSupported(false);
    }
  };

  const handleBallClick = (evt) => {
    let url = `/sysicon`;
    navigate(url);
  }

  const handleEditModeChange = (evt) => {
    const newCheckedStatus = evt.target.checked;
    setEditStatus(newCheckedStatus);

    if (editMode) {
      editMode.enableEdit(newCheckedStatus);

      if (newCheckedStatus) {
        editor.show();   // Show toolbar
      } else {
        editor.hide();   // Hide toolbar
      }
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {param_file_id ? (
        <div id="article-container">
          <article
            id="article"
            className="sky-article sky-editable"
            ref={articleRef}
            dangerouslySetInnerHTML={{ __html: articleContent }}
          />
        </div>
      ) : (
        <div id="article-container">
          <article id='article' className="sky-article sky-editable" ref={articleRef}>
            <h1 className="align-left">
              How to use Flexbox to create a modern CSS card design layout
            </h1>
            <h3>Running any NPM pacakge in the browser locally</h3>
            <p>JavaScript1 has never had any official solution for distributing packages...</p>
            <p>"喜"迎降雪,南方朋友们进来"云"赏雪啦!</p>
            <p className="align-justify">JavaScript2 </p>
            <p className="align-justify">JavaScript has never had any official solution for distributing packages...</p>
          </article>
          <div className="article">
            <h2 className="article-title">{title}</h2>
            <p className="article-author">Author: {author}</p>
            <div className="article-content">{content}</div>
          </div>
        </div>
      )}

      <IconMenuBar onClickRotatingBall={handleBallClick}
                     menuMode={MenuModes.EDIT_ARTICLE}
                     onEditModeChange={handleEditModeChange} />
    </>
  );
};


