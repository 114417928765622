// ImageCarousel.js (Presentation Component)

import React, {useEffect, useRef, useState} from 'react';
import './PhotoPlayerUI.css'

const displayTime = 6000;

/**
 * initImage and nextImage API functions are defined outside of PhotoPlayerUI.
 * 
 * @param {*} param0 
 * @returns 
 */
const PhotoPlayerUI = ({
  initImage,
  nextImage,
  ...props
}) => {
  // These parameters are from CinemaPhoto. relPath (photo relative path), 
  // title (photo dir) are for player to get started to play.
  const { 
    onStart,  // Optional. Parent does not need notification of this event.
    onFinish, // Optional
    relPath, 
    title     
  } = props;
  
  const [showText, setShowText] = useState(false);

  // photoLabel = dir + file name. dir is title passed in. file name is obtained from server
  // after image is loaded. Therefore, for each loaded photo file, photoLabel is created.
  const [photoLabel, setPhotoLabel] = useState(null);
  const [dirLabel, setDirLabel] = useState(null);
  
  const [renderCount, setRenderCount] = useState(0);

  // Effect to update render count
  useEffect(() => {
    setRenderCount(prev => prev + 1);  // This increments the count each render
    console.log('===========' + renderCount);
  }, []);
  
  // This is to make sure hover works on touch screen as well. User needs to touch toggle.
  const toggleText = () => {
    setShowText(true);
    setTimeout(() => {
      setShowText(false);
    }, 3000); // Hide text after 3 seconds (adjust as needed)
  };

  // Can't use state to store curIndex. The reason is that looping all image
  // is performed within useEffect once play is initialized.
  let curIndex = 0;
  const [imageList, setImageList] = useState([]);
  //const [currentIndex, setCurrentIndex] = useState(-1);

  // When loaded, it assumes player is started.
  const [isPlaying, setIsPlaying] = useState(false); 

  // https://chat.openai.com/c/708e4e8b-573e-4a9d-a8d9-0050a10e362a
  // 
  const imageListRef = useRef([]);
  const [initPlayer, setInitPlayer] = useState(false);  

  //let imageList = [];

  //console.log("currentIndex = " + currentIndex);
  useEffect(() => {
    const playPauseBtn = document.querySelector(".play-pause-btn");
    playPauseBtn.textContent = !isPlaying ? "▶" : "||";

  }, [isPlaying]);

  useEffect(() => {
    let timeoutId;
    
    const showPhoto = async (enRelfile) => {
      const slideImage = document.querySelector('.slide');
      const imgElement = slideImage.querySelector('img');

      const imageData = await nextImage(enRelfile);
      
      if (imageData && imageData.status) {
        await new Promise(resolve => {
          imgElement.onload = () => resolve(); // Wait for the image to load
          imgElement.src = `data:image/png;base64, ${imageData.data.img64}`;
          imgElement.classList.add('loaded');

          setDirLabel(title)
          setPhotoLabel(
            <>
              {imageData.data.create_time_original && (
                <>
                  Taken: {imageData.data.create_time_original} <br />
                </>
              )}
              File: {imageData.data.create_time} <br />
              {imageData.data.file_name} 
            </>
          );
        });
      } else {
        console.log("No image is received!")
      }
    }

    const nextPhoto = (idx) => {
      if (!isPlaying && imageList.length > 0) {
        return;
      }

      const image = imageList[idx];
      showPhoto(image.relfile_name).then(() => {
        //console.log("nextPhoto() is called: {" + idx + "} " + image.relfile_name)
      });
    }

    const startTimer = (idx) => {
      nextPhoto(idx);
      timeoutId = setTimeout(() => {
        moveToNextSlide(); // Move to the next slide when display time is finished
      }, displayTime);
    };

    const moveToNextSlide = () => {
      //setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      if (curIndex === (imageList.length - 1)) {
        handleProgramFinished(); 
      } else {   
        curIndex = (curIndex + 1) % imageList.length;
        startTimer(curIndex); // Start the timer for the next slide
      }
    };

    const handleProgramFinished = () => {
      console.log('Stop photo player... Total photos: ', curIndex + 1)

      setIsPlaying(false);
      // Notify parent.
      if (!(onFinish === undefined)) {
        onFinish();
      }
    }

    if (isPlaying) {
      startTimer(0); // Start the timer when playing
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear the timer when paused
      }
    }

    const slidesContainer = document.querySelector(".slides-container");
    const prevBtn = document.querySelector(".prev-btn");
    const nextBtn = document.querySelector(".next-btn");
    const playPauseBtn = document.querySelector(".play-pause-btn");

    const nextSlide = () => {
      const currentImageList = imageListRef.current;  // Access the latest imageList via the ref
      curIndex = (curIndex + 1) % currentImageList.length;

      const image = currentImageList[curIndex];
      showPhoto(image.relfile_name).then(() => {
        //console.log("next image is called: {" + curIndex + "} " + image.relfile_name)
      });
    };

    // Attach the method to the window object (or any global scope)
    window.nextSlide = nextSlide;

    const prevSlide = () => {
      const currentImageList = imageListRef.current;  // Access the latest imageList via the ref

      curIndex = (curIndex - 1 + currentImageList.length) % currentImageList.length;

      const image = currentImageList[curIndex];
      showPhoto(image.relfile_name).then(() => {
        //console.log("previous image is called: {" + curIndex + "} " + image.relfile_name)
      });
    };
    window.prevSlide = prevSlide;

    const playPauseCarousel = () => {
      console.log("isPlaying=" + isPlaying);
      setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };
    window.playPauseCarousel = playPauseCarousel;

    return () => {
      // clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [isPlaying]);  // Empty invoking parameters. All play login in userEffect block and 
  // execute only once in component mount. No state update invoke this block


  useEffect(() => {

    // Call the initImage function in PhotoPlayer to initialize player.
    const initPhotoPlayer = async (relPath) => {
      console.log("PhotoPlayerUI::initPhotoPlayer: " + relPath);
      const playerParam = await initImage(relPath);
      
      if (playerParam.status && playerParam.data.media_list.length > 0) {
        // If there is photo, start with 1st one in list, which contains full file directory
        // info on server, and request full image from server.
        // imageList = playerParam.data.media_list;
        const sortedList = sortImageList(playerParam.data.media_list, playerParam.data.file_name);
        setImageList(sortedList);
        imageListRef.current = sortedList;  // Update the ref with the latest imageList

        //console.log("Photo player is initialized.");

        // Start to show 1st image by calling nextImage of PhotoPlayer component.
        //setCurrentIndex(0);
        curIndex = 0;

        // Send notification to parent.
        if (!(onStart === undefined)) {
          onStart(title);
        }

        // Trigger player to play.
        setIsPlaying(true)
      } else if (playerParam.status) {
        curIndex = -1;
        
        console.log("No file is found!");
      } else {
        curIndex = -1;
        console.log("Error initPhotoPlayer!");
      }
    };

    if (!initPlayer) {
      setInitPlayer(true);
      initPhotoPlayer(relPath);

      console.log('Start photo player...')
    }
  }, [relPath, initPlayer]); 
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        window.prevSlide();
      } else if (event.key === 'ArrowRight') {
        window.nextSlide();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const sortImageList = (imageList, file) => {
    // Remove video image (TODO: improve server?)
    let filterImages = []
    for (const item of imageList) {
      if (item.file_type == 2) {
        filterImages.push(item)
      }
    }

    imageList = filterImages;

    let retImageList = [];

    let index = 0
    for (const item of imageList) {
      if (item.file_name === file) {
        const srcList = imageList.slice(index, imageList.length);
        retImageList = srcList.concat(retImageList);
        break;
      } else {
        retImageList.push(item)
      }
      index += 1;
    }

    return retImageList;
  }

  return (
    <div className="photo-carousel">
      <div className="slides-container"
        onMouseOver={() => setShowText(true)}
        onMouseOut={() => setShowText(false)}
        onTouchStart={() => toggleText()}
        onTouchEnd={() => setShowText(false)}
      >
        {showText && (dirLabel !== null && dirLabel !== undefined) && 
            <div className="photo-title photo-title-up">{dirLabel}</div>
        } 
        <div className="slide active">
          <img  /> 
        </div>
        {showText && <div className="photo-title photo-title-down">{photoLabel}</div>} 
      </div>
      <div className="controls">
        <button className="prev-btn" onClick={window.prevSlide}>&lt;</button>
        <button className="play-pause-btn" onClick={window.playPauseCarousel}></button>
        <button className="next-btn" onClick={window.nextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default PhotoPlayerUI;
