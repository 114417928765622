/**
 * 1) p 2 p connect.
 * 2) send chat msg to each other.
 */
import React, { useState, useEffect } from 'react';
import PeerManager from './PeerManager';

const PeerManagerRoom = (roomName='media_room') => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [peerManager, setPeerManager] = useState(null);

  useEffect(() => {
    const isInitiator = window.location.hash === '#1';
    const userName = isInitiator ? 'user_host' : `user_guest_${Math.floor(Math.random() * 1000)}`;

    const manager = new PeerManager(roomName, userName, isInitiator, handleReceivedMessage);
    setPeerManager(manager);

    return () => {
      if (manager && manager.socket) {
        manager.socket.close(); // Clean up the socket connection
      }
    };
  }, []);

  const handleReceivedMessage = (data) => {
    setMessages((prevMessages) => [...prevMessages, data]);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message.trim() !== '' && peerManager) {
      peerManager.sendMessage(message);
      setMessages((prevMessages) => [...prevMessages, message]);
      setMessage('');
    }
  };

  return (
    <div>
      <h1>Peer-to-Peer Communication</h1>
      <p>First, open <strong>http://localhost:3000/test/simpeer/#1</strong> in one browser tab/window.</p>
      <p>Then, open <strong>http://localhost:3000/test/simpeer</strong> in another browser tab/window.</p>

      <div style={{ border: '1px solid black', height: '300px', overflowY: 'scroll', marginBottom: '10px' }}>
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
      </div>

      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default PeerManagerRoom;
