import React, { useState, useEffect, forwardRef } from 'react';
import VideoGridBase from './VideoGridBase';
import { ApiFileX } from '../../../comjs/filex.api/filex-api.js';
import { f2futil } from '../../../comjs/utils/utils.js';
import styles from './DroppableVideoGrid.module.css';

const DroppableVideoGrid = forwardRef(({
    onClickVideo=null,
    onAddVideo=null,
    onDeleteVideoApiCall=null,
    onClickFolder=null,
    saveVideosOrderToServer=null, // Add this prop
    forceDisablePlay=false,
    ...restProps
  }, ref) => {
  const [errorMessage, setErrorMessage] = useState('');
  const api = new ApiFileX();

  const handleSetErrorMessage = (message) => {
    setErrorMessage(message);
    clearErrorMessageAfterTimeout();
  };

  const validateAndParseUrl = (url) => {
    let valid = true;
    let urlInfo = null;
    try {
      const util = new f2futil();
      // urlInfo = util.parseYtUrl(url);
      urlInfo = util.parseMediaUrl(url);
    } catch (error) {
      valid = false;
      const errMsg = `Invalid video link "${url}"! Link must be copied from this site by right clicking the video. Please open another tab to browse video and copy the link by right click.`;
      setErrorMessage(errMsg);
      clearErrorMessageAfterTimeout();
      return { valid, urlInfo };
    }
    return { valid, urlInfo };
  };
  
  // This is intialization that loads existing list.
  const initGrid = async () => {
    const initialVideos = restProps.videos.map((item) => {
      /*
      Sample of urlInfo:

      media_id: "dfaip-fd",
      relpath: "WV5bCN5LiN5Y-q5p",
      type: "lv"
      */
      
      const entries = Object.entries(item);
      const processedEntries = entries.map(([key, value]) => {
        const { valid, urlInfo } = validateAndParseUrl(key);
    
        if (!valid) {
          console.error('Invalid URL:', item);
          return null;
        }
  
        // Each valid item is urlInfo.
        return urlInfo;
      });
      return processedEntries[0];
      

      /*
      const { valid, urlInfo } = validateAndParseUrl(item);

      if (!valid) {
        console.error('Invalid URL:', item);
        return null;
      }

      // Each valid item is urlInfo.
      return urlInfo;
      */
    }).filter(item => item !== null); // Filter out invalid items

    // Group videos by media type
    const groupedByType = initialVideos.reduce((acc, video) => {
      const key = video.type;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(video);
      return acc;
    }, {});

    // Group videos by relpath within each type
    const grouped = Object.keys(groupedByType).reduce((acc, type) => {
      acc[type] = groupedByType[type].reduce((typeAcc, video) => {
        const key = video.relpath || '_root_null_';
        if (!typeAcc[key]) {
          typeAcc[key] = [];
        }
        typeAcc[key].push(video);
        return typeAcc;
      }, {});
      return acc;
    }, {});

    // Get video info for each item from server.
    let response_yv = null;
    let response_lv = null;
    let response_ret = null;

    if (isEmpty(grouped)) {
      // Match server's API response.
      response_ret = { status: true, data: [] }
    } else {
      // 2 calls are sychronous. TODO: async.
      if ('yv' in grouped) {
        // Group by relpath to decrease index file access.
        response_yv = await api.ytVideoList(grouped.yv);

        // Add channel id for each video, which is used to delete. This matches what is stored
        // in channel file.
        if (response_yv.status) {
          // It's categorized by relpath.
          Object.entries(response_yv.data).forEach(([relpath, videos]) => {
            videos = videos.map(video => {
              if (relpath && relpath != '_root_null_') {
                video['channel_media_path'] = `/yv/${video.media_id}/${relpath}`;
              } else {
                video['channel_media_path'] = `/yv/${video.media_id}`;
              }
              return video;
            });
          });
        } else {
          // TODO:  ignore invalid item in channel file.
          console.log('Media in channel files are ignored!'); 
        }
      } 
      
      if ('lv' in grouped) {
        response_lv = await api.lvVideoList(grouped.lv);

        if (response_lv.status) {
          // It's categorized by relpath.
          Object.entries(response_lv.data).forEach(([relpath, videos]) => {
            videos = videos.map(video => {
              if (relpath) {
                video['channel_media_path'] = `/lv/${video.media_id}/${relpath}`;
              } else {
                video['channel_media_path'] = `/lv/${video.media_id}`;
              }
              video['video_src'] = `${api.decodeBase64ToUrl(relpath)}/${video['file_name']}`;
              video['video_src'] = api.encodeUrlToBase64_UrlSafe(video['video_src']);
              return video;
            });
          });
        } else {
          // TODO:  ignore invalid item in channel file.
          console.log('Media in channel files are ignored!'); 
        }
      }
      
      // Combine response_yv and response_lv.
      const combinedData = [];
      if (response_lv && response_lv.status && response_lv.data) {
        Object.entries(response_lv.data).forEach(([relpath, videos]) => {
          combinedData.push(...videos);
        });
      }

      if (response_yv && response_yv.status && response_yv.data) {
        Object.entries(response_yv.data).forEach(([relpath, videos]) => {
          combinedData.push(...videos);
        });
      }

      // Response is grouped by medity type + relpath, which does not match channel file order, which
      // is display order.
      const combinedDataMap = new Map(combinedData.map(video => [video.channel_media_path, video]));
      
      // const displayData = restProps.videos.map(video => {
      // initialVideos filtered out invalid items from restProps.videos.
      const displayData = initialVideos.map(video => {
        let key = `/${video.type}/${video.media_id}`;

        if (video.relpath) {
          key += `/${video.relpath}`
        }
        
        // Due to inconsistent channel media could exist in channel file, due to
        // design (TODO improve), such as yt index directory change but channel file is not
        // updated properly, we ignore the channel media item at present if it happens.
        // The yt directory update requires updating all related media items in all channel files.
        // This design needs improve!
        // User experience: just find missing channel item added previously.
        // TODO: besides yt media, local media directory name change and updating related
        // channel file is not implemented.  
        return combinedDataMap.get(key) ? combinedDataMap.get(key) : null;
        
        // const { urlInfo } = validateAndParseUrl(video);
        // if (urlInfo) {
        //   const path = urlInfo.type === 'yv'
        //     ? urlInfo.relpath && urlInfo.relpath !== '_root_null_' 
        //       ? `/yv/${urlInfo.media_id}/${urlInfo.relpath}` 
        //       : `/yv/${urlInfo.media_id}`
        //     : `/lv/${urlInfo.media_id}${urlInfo.relpath ? `/${urlInfo.relpath}` : ''}`;
        //   return combinedDataMap.get(path);
        // }
        // return null;
      })
      .filter(item => item !== null); // Remove any invalid entries

      response_ret = {
        status: true,
        data: displayData
      };
    }

    return response_ret;
  };

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  // This is pasted new item.
  const addNewItemGrid = async (pastedUrl) => {
    setErrorMessage(''); // Clear any existing error message

    // The following is to construct key: value pair to be stored in channel json file
    // for each media item.
    //
    // Let's understand. The grid view uses media item passed from db to display thumbnail, etc.
    // Only field 'channel_json_id' related to channel is dynamically added, such as
    // '/lv/<media_id>/<relpath>', which is not the format stored in channel json file.
    // Each media item in channel file is format like followings: it's an object in JS
    // {'/lv/<media_id>/<relpath>': <media_id>}. The reason to have value is for easy decoding
    // to get file name in UI.
    //
    // Therefore, it's not possible to get entry value that can be used to store directly into
    // channel file from grid. It should be derived from each items 'channel_json_id'.
    let valid = true;
    let urlInfo = null;
    try {
      const util = new f2futil();
      urlInfo = util.parseMediaUrl(pastedUrl);
    } catch (error) {
      valid = false;
      const errMsg = `Invalid video link "${pastedUrl}"! Link must be copied from this site by 
          right clicking a video. Please open another browser to show videos. Then you can right 
          clicka video to show "Copy channel link" menu and to copy the link by clicking it.`;
      setErrorMessage(errMsg);
      clearErrorMessageAfterTimeout();
      return Promise.reject(errMsg);
    }

    if (valid) {
      let response = null;
      if ('yv' == urlInfo.type) {
        const relpath = urlInfo.relpath;
        const ytId = urlInfo.media_id;
        response = await api.getPastedYtVideoInfo(
            'article_uuid_hello', 
            pastedUrl, 
            relpath, 
            ytId);
        
        if (response.status) {
          response.data['paste_type'] = 'yv';

          let key = null;
          if (relpath) {
            key = `/yv/${ytId}/${relpath}`;
          } else {
            key = `/yv/${ytId}`;
          }
          if (!response.data['channel_media_path']) {
            response.data['channel_media_path'] = {};
          }
          
          response.data['channel_media_path'][key] = 
              api.encodeUrlToBase64_UrlSafe(response.data['file_name']);
        }
      } else if ('lv' == urlInfo.type) {
        const relpath = urlInfo.relpath;
        const file_name = urlInfo.media_id;
        response = await api.getPasteVideoInfo(file_name, relpath);
        if (response.status) {
          response.data['paste_type'] = 'lv';

          let key = null;
          if (relpath) {
            key = `/lv/${file_name}/${relpath}`;
          } else {
            key = `/lv/${file_name}`;
          }

          if (!response.data['channel_media_path']) {
            response.data['channel_media_path'] = {};
          }
          
          response.data['channel_media_path'][key] = 
              api.encodeUrlToBase64_UrlSafe(response.data['file_name']);
        }
      }

      // Response is paste video detail
      if (response) {
        if (onAddVideo && response.status) {
          return onAddVideo(response).then((respUpdateChannel) => {
            if (!respUpdateChannel.status) {
              // In case of adding channel failure, paste video details will be ignored.
              // This will return to VideoGridBase, that will display error message under 
              // paste editbox which is right place to display.
              return respUpdateChannel;
            }

            return response;
          });
        }

        return response;
      }
      // try {
      //   const relpath = urlInfo.relpath;
      //   const ytId = urlInfo.ytid;
      //   let response = await api.getPastedYtVideoInfo(
      //       'article_uuid_hello', 
      //       pastedUrl, 
      //       relpath, 
      //       ytId);
        
      //   return response;
      // } catch (error) {
      //   setErrorMessage('Failed to fetch video info');
      //   clearErrorMessageAfterTimeout();
      //   return Promise.reject('Failed to fetch video info');
      // }
    }
  };

  const clearErrorMessageAfterTimeout = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 15000);
  };

  const deleteVideoApiCall = async (uuid) => {
    if (onDeleteVideoApiCall) {
      return onDeleteVideoApiCall(uuid);
    }

    return true;
  };
  
  useEffect(() => {
    if (restProps.editStatus) {
      // Handle edit mode actions
    } else {
      // Handle non-edit mode actions
    }
  }, [restProps, restProps.editStatus, restProps.ytEditMode]);
  
  return (
    <div className={styles['div-video-grid']}>
      <VideoGridBase
        ref={ref}
        initGrid={initGrid}
        addNewItemGrid={addNewItemGrid}
        onClickVideo={onClickVideo}
        onClickFolder={onClickFolder}
        ytEditMode={restProps.ytEditMode}
        editStatus={restProps.editStatus}
        setErrorMessage={handleSetErrorMessage}
        deleteVideoApiCall={deleteVideoApiCall}
        saveVideosOrderToServer={saveVideosOrderToServer}
        items={restProps.videos}
        forceDisablePlay={forceDisablePlay}
      />
      {errorMessage && <div className={styles['error-message']}>{errorMessage}</div>}
    </div>
  );
});

export default DroppableVideoGrid;
