// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `bodyStyles {
  background: #fff;
  font: 13px 'Open Sans', sans-serif;
  color: #222;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Article/CookingRecipeBody.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,WAAW;AACb","sourcesContent":["bodyStyles {\n  background: #fff;\n  font: 13px 'Open Sans', sans-serif;\n  color: #222;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
