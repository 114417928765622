// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.original-list-style {
  position: relative;
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 20px;
}

.hide {
  display: none;
}

.list-item-style {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  padding: 0px;
  margin: 0;
  width: 100%;
}

.item-content-style {
  position: relative;
  width: 100%;
  height: 100%;
}

.list-item-style.muuri-item-dragging {
  z-index: 3;
}
.list-item-style.muuri-item-releasing {
  z-index: 2;
}
.list-item-style.muuri-item-hidden {
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Sandbox/OriginalListComponent.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,sBAAsB;EACtB,YAAY;EACZ,SAAS;EACT,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".original-list-style {\n  position: relative;\n  background-color: #f0f0f0;\n  padding: 10px;\n  margin-bottom: 20px;\n}\n\n.hide {\n  display: none;\n}\n\n.list-item-style {\n  position: absolute;\n  z-index: 1;\n  border: 1px solid #ccc;\n  padding: 0px;\n  margin: 0;\n  width: 100%;\n}\n\n.item-content-style {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.list-item-style.muuri-item-dragging {\n  z-index: 3;\n}\n.list-item-style.muuri-item-releasing {\n  z-index: 2;\n}\n.list-item-style.muuri-item-hidden {\n  z-index: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
