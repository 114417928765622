import React, { useState } from 'react';
import { usePeerJsUsers } from './PeerJsUserContext';  // Adjust the import path as necessary

const ChatPeerJS = () => {
  const { onlineUsers, selfUserId, messages, sendMessage } = usePeerJsUsers();
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    if (selectedUser && message.trim()) {
      sendMessage(selectedUser[0], message);
      setMessage('');
    }
  };

  return (
    <div>
      <h2>Chat ({selfUserId})</h2>
      <div>
        <h3>Online Users</h3>
        <ul>
          {onlineUsers
            .filter(user => user[0] !== selfUserId)
            .map(([userId, userName]) => (
              <li key={userId} onClick={() => setSelectedUser([userId, userName])}>
                {userName}
              </li>
            ))}
        </ul>
      </div>
      <div>
        <h3>Messages</h3>
        <div>
          {messages.map((msg, index) => (
            <div key={index}>
              <strong>{msg.from === selfUserId ? 'You' : msg.from}:</strong> {msg.message}
            </div>
          ))}
        </div>
      </div>
      <div>
        <h3>Send Message</h3>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message"
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatPeerJS;
