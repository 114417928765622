import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TwoPageBase from '../TwoPages/TwoPageBase';
import TwoSectionsPage from '../TwoSectionsPage/TwoSectionsPage';
import Channels from './Channels';
import PlaylistWithTitle from '../Playlist/PlaylistWithTitle';
import { IconMenuBar } from '../IconMenuBar/IconMenuBar';


const ChannelPlayer2P = ({
    playChannelName,
    onPage2VisibleVideo=null
  }) => {
  const navigate = useNavigate();
  const [curPlaylist, setCurPlaylist] = useState(null);
  const [pageOneVisible, setPageOneVisible] = useState(false);
  
  useEffect(() => {
  
  }, []);

  // Handle item clicked in OneDirectory. No need to be handled by parent component.
  // const handleOneClickVideo = (videoRelPath, title, dirInfo) => {
  //   //onClickVideo(videoRelPath, title, dirInfo);
  //   setVideoPath(videoRelPath);
  //   setVideoTitle(title);
  //   setDirInfo(dirInfo);
  // };
  // const handleOneClickVideo = useCallback((videoRelPath, title, dirInfo) => {
  //   setVideoPath(videoRelPath);
  //   setVideoTitle(title);
  //   setDirInfo(dirInfo);
  // }, []);

  // When photo is clicked, it needs to pass the event to parent of VideoPlayer2P
  // as VideoPlayer2P is video player. Parent should invoke PhotoPlayer2P
  // const handleOneClickPhoto = (photoRelPath, dirInfo) => {
  //   onClickPhoto(photoRelPath, dirInfo);
  // };
  // const handleOneClickPhoto = useCallback((photoRelPath, dirInfo) => {
  //   onClickPhoto(photoRelPath, dirInfo);
  // }, [onClickPhoto]);
  
  // const handleOneClickFolder = (folderRelPath, dirInfo, dirName) => {
  //   onClickFolder(folderRelPath, dirInfo, dirName);
  // }
  // const handleOneClickFolder = useCallback((folderRelPath, dirInfo, dirName) => {
  //   onClickFolder(folderRelPath, dirInfo, dirName);
  // }, [onClickFolder]);

  // const handleOnPage1Visible = () => {
  //   setPageOneVisible(true);
  //   onPage2VisibleVideo(false);
  // };

  // const handleOnPage2Visible = () => {
  //   setPageOneVisible(false);
  //   onPage2VisibleVideo(true);
  // };

  const handleOnPage1Visible = useCallback(() => {
    setPageOneVisible(true);

    if (onPage2VisibleVideo) {
      onPage2VisibleVideo(false);
    }
    
  }, [onPage2VisibleVideo]);

  const handleOnPage2Visible = useCallback(() => {
    setPageOneVisible(false);
    if (onPage2VisibleVideo) {
      onPage2VisibleVideo(true);
    }
  }, [onPage2VisibleVideo]);

  const hanelOnChannelDataReady = (channelFileContent) => {
    if (channelFileContent) {
      const key = Object.keys(channelFileContent[0])[0];
      const val = channelFileContent[0][key];
      setCurPlaylist(val);
    }
  }

  const handleBallClick = () => {
    let url = `/playlist`;
    navigate(url);
  }

  // key is to making sure scrolling to OneDirectory to work. Without it, it does not scroll to
  // page2, which is OneDirectory.
  return (
    <>
    <div key={playChannelName}>
      <TwoSectionsPage
          keyValue={playChannelName} 
          onScroll0={handleOnPage1Visible} 
          onScroll30={handleOnPage2Visible} >
        <Channels 
          autoChannelPlay={false}
          playChannel={playChannelName}
          onChannelDataReady={hanelOnChannelDataReady}
        />
        <PlaylistWithTitle 
          playlist={curPlaylist} 
        />
      </TwoSectionsPage>
    </div>
    <IconMenuBar
      onClickRotatingBall={handleBallClick} 
    /> 
    </>
  );
};

export default ChannelPlayer2P;