import React,  { useState, useRef } from 'react';
import AlbumIntro from '../../Channels/AlbumIntro';
// import YouTubePlayerYtApi from '../YoutubePlayerYtApi';
import YouTubePlayerAdv from '../YoutubePlayerAdv';
// import useSwipeDetection from '../../Util/HookSwipeDetection';
import YtSwipeDetection from '../../Util/YtSwipeDetection';
import useIsTouchDevice from '../../Util/HookTouchDevice.jsx';


const CinemaYt = ({
    videoTitle,
    ytId, 
    relpath=null, 
    dirInfo=null,   
    pageOneVisible=null,
    onVideoStart=null,
    onVideoEnd=null,
    showIntro=true,
    onSwipeNextProgram=null
  }) => {
  const [showIntroduction, setShowIntroduction] = useState(showIntro);
  const isTouchDevice = useIsTouchDevice();
  // To implement singleton of new window.YT.Player in YouTubePlayerAdv
  // so that yt video list playing could start automatically for each
  // video.
  const playerRef = useRef(null); 
  
  // Track whether the video being played is the first video
  const isFirstVideo = useRef(true);

  // useSwipeDetection(
  //   onSwipeNextProgram ? () => onSwipeNextProgram(-1) : null, // onSwipeLeft
  //   onSwipeNextProgram ? () => onSwipeNextProgram(1) : null   // onSwipeRight
  // );
  
  const handleIntroStart = () => {
    setShowIntroduction(true);
  }

  const handleIntroEnd = () => {
    setShowIntroduction(false);
  }

  const handleVideoEnd = () => {
    isFirstVideo.current = false;
    if (onVideoEnd) {
      onVideoEnd();
    }
  }

  return (
    <>
    {showIntroduction ? (
      <AlbumIntro 
        onStart={handleIntroStart} 
        onFinish={handleIntroEnd} 
        title={videoTitle}
        introTimeout={5000} />
    ) : (
      <>
        <YouTubePlayerAdv
          ytId={ytId}
          relpath={relpath}
          playerRef={playerRef}  
          onVideoStart={onVideoStart}
          onVideoEnd={handleVideoEnd}
          onSwipeNextProgram={onSwipeNextProgram} 
          isFirstVideo={isFirstVideo.current}  
        />
        {isTouchDevice && onSwipeNextProgram && (
          <YtSwipeDetection
            onSwipeLeft={() => onSwipeNextProgram(-1)}
            onSwipeRight={() => onSwipeNextProgram(1)}
          />
        )}
      </>
    )}
    </>
  );
};

export default CinemaYt;