import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

const ScrollProvider = ({ children }) => {
  const [onScrollStart, setOnScrollStart] = useState(null);
  const [onScrollEnd, setOnScrollEnd] = useState(null);

  const handleScrollStart = () => {
    console.log('Scroll started');
    if (onScrollStart) {
      onScrollStart();
    }
  };

  const handleScrollEnd = () => {
    console.log('Scroll ended');
    if (onScrollEnd) {
      onScrollEnd();
    }
  };

  const contextValue = {
    onScrollStart,
    onScrollEnd,
    setOnScrollStart,
    setOnScrollEnd,
  };

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};

/**
 * Scroll hook
 * 
 * @returns 
 */
const useScroll = () => {
  const contextValue = useContext(ScrollContext);
  if (!contextValue) {
    // throw new Error('useScroll must be used within a ScrollProvider');
    // Not forcing parent component to use ScrollProvider.
    console.warn('ScrollProvider not found. Using default values.');
    return {
      onScrollStart: null,
      onScrollEnd: null,
      setOnScrollStart: null,
      setOnScrollEnd: null,
    };
  }
  return contextValue;
};

export { ScrollProvider, useScroll };
