/*
https://docs.muuri.dev/examples.html


*/

import React, { useEffect } from 'react';

import Muuri from 'muuri';
import './MuuriLab2.css';


export const MuuriLab2 = () => {

  useEffect(() => {
    //const grid = new Muuri('.grid');
    // To make the items draggable
    // try this next...
    const grid = new Muuri('.grid', {
      items: '.item',  
      dragEnabled: true});
  }, []);

  return (
    <>
    <div className="grid">

      <div className="item">
        <div className="item-content">
          
          This can be anything.
          
        </div>
      </div>

      <div className="item">
        <div className="item-content">
         
          <div className="my-custom-content">
            Yippee!
          </div>
          
        </div>
      </div>

      </div>
    </>
  );
}