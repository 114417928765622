// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000; /* Optional: Set a background color */
  overflow: hidden;
  touch-action: pan-y; /* Allow vertical touch scrolling */
}

#youtube-player-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  /* pointer-events: none; Prevent iframe from capturing touch events, why? */
}

#youtube-player {
  width: 100%;
  height: 100%;
  pointer-events: auto; /* Allow touch events on the iframe */
}

.player-container .touch-overlay {
  position: absolute;
  bottom: 22%;
  left: 10%;
  width: 80%;
  height: 20%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 400;
}
`, "",{"version":3,"sources":["webpack://./src/Components/YoutubeView/YoutubePlayerAdv.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB,EAAE,qCAAqC;EAC7D,gBAAgB;EAChB,mBAAmB,EAAE,mCAAmC;AAC1D;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,2EAA2E;AAC7E;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB,EAAE,qCAAqC;AAC7D;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;EACV,WAAW;EACX,0CAA0C;EAC1C,YAAY;AACd","sourcesContent":[".player-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background-color: #000; /* Optional: Set a background color */\n  overflow: hidden;\n  touch-action: pan-y; /* Allow vertical touch scrolling */\n}\n\n#youtube-player-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: auto;\n  /* pointer-events: none; Prevent iframe from capturing touch events, why? */\n}\n\n#youtube-player {\n  width: 100%;\n  height: 100%;\n  pointer-events: auto; /* Allow touch events on the iframe */\n}\n\n.player-container .touch-overlay {\n  position: absolute;\n  bottom: 22%;\n  left: 10%;\n  width: 80%;\n  height: 20%;\n  background-color: rgba(255, 255, 255, 0.6);\n  z-index: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
