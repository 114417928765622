/**
 * It displays nested directories, unlike dirShowFiles.
 * 
 * A lot of dupicated code. It's to display Media file category. It can be replaced by
 * components that handles yt video. Not migrating at present.
 * 
 * My media directories main page. This page is loaded after clicking my media icon
 * on Flask home page.
 * 
 * It loads media files in following directories:
 * 
 * 1. Receive dir.
 * 2. All media directories.
 */
//import { env } from 'shelljs';

// not working if mixing with fetch. Session issue.
//import axios from 'axios';

import $ from 'jquery';    // React only
import '../../3rdparty/pinto/pinto.css'
import '../../3rdparty/pinto/pinto.js'

import {f2fBase} from '../js/base.js'
//import {iFrameClause} from '../app-common/iframe-constant.js'
import {dlgOk} from '../utils/dialog.js'
import {ApiFileX} from '../filex.api/filex-api';

var gMyMain = null;



export class myMediaMain extends f2fBase {
  static get CSS() {
    return {
      name: './my-media-main.css',
      import: import('./my-media-main.css')
    };
  }
  static get CSS_SKY() {
    return {
      name: './sky-list-media.css',
      import: import('./sky-list-media.css')
    };
  }
  static get CSS_DIR_VIEW() {
    return {
      name: '../directory.view/directory-view.css',
      import: import('../directory.view/directory-view.css')
    };
  }
  static get polaroidCSS() {
    return {
      name: '../directory.view/polaroidalbum.css',
      import: import('../directory.view/polaroidalbum.css')
    };
  }
  //static get HTML() {return 'ui.comp/my.media.main/my-media-main.html';}

  constructor($container, $SCRIPT_ROOT=null, relDirInfo=null, 
              options=null, onePageView=true) {
    // The handler can be passed from React component, so that react handler
    // is able to handle the thumbnail click to start playing video or phone carousel.
    let defaultOptions = {
      onClickVideo: null,
      onClickPhoto: null
    }
    const current_options = $.extend(defaultOptions, options);
    super(current_options);

    // TODO:
    this.$SCRIPT_ROOT = $SCRIPT_ROOT;

    this.mediaRoots = [];
  
    this.$container = $container;
    this.onePageView = onePageView;
    // div-my-media-container div is in the base html loaded.
    //this.$container = $('#div-my-media-container');

    // this.loadHtmlFileChildTop(this.$container, myMediaMain.HTML);

    /*
    this.myMediaCss = this.loadCssFile(myMediaMain.CSS);
    this.skyCss = this.loadCssFile(myMediaMain.CSS_SKY);
    this.dirViewCss = this.loadCssFile(myMediaMain.CSS_DIR_VIEW);
    */
    this.loadCSS(myMediaMain.CSS);
    this.loadCSS(myMediaMain.CSS_SKY);
    this.loadCSS(myMediaMain.CSS_DIR_VIEW);
    this.loadCSS(myMediaMain.polaroidCSS);

    this.tmplMediaSection = ({sec_title, sec_title_url}) => `
    <section class="section-media">
      <div class="div-section-title div-section-seperator">
        <a href="${sec_title_url}">${sec_title}</a>
      </div>
      <div class="div-video-grid"></div>
      <div class="div-image-grid pinto-grid"></div>
      <div class="div-folder-grid"></div>
    </section>
    `;

    this.tmpl_Folder = ({album_url, album_name, album_image}) => `
    <div class='album-cell'>
      <div class="album polaroids-album-cell">
        <a href="${album_url}" title="${album_name}">
          <img src="/${album_image}">
        </a>
      </div>
    </div>   
    `;

    // This is youtube css, which is for studying.
    // Not used.
    this.tmplVideoItem = ({dir_name, dir_id}) => `
    <div class="thumbnail-row">
        <img class="thumbnail" src="thumbnails/thumbnail-1.webp">
        <div class="video-time">14:20</div>
      </div>
      <div class="video-info-grid">
        <div class="channel-picture">
          <img class="profile-picture" src="channel-pictures/channel-1.jpeg">
        </div>
        <div class="video-info">
          <p class="video-title">
            Talking Tech and AI with Google CEO Sundar Pichai!
          </p>
          <p class="video-author">
            Marques Brownlee
          </p>
          <p class="video-stats">
            3.4M views &#183; 6 months ago
          </p>
        </div>
      </div>
    </div>
    `;

    this.tmpl_Image = ({albumgrid_url, thumbnail}) => `
    <div class="div-thumbnail">
      <a href="${albumgrid_url}">
        <img src="data:image/png;base64, ${thumbnail}">
      </a>
    </div>
    `;
    // <img src="data:image/png;base64, ${thumbnail}" width="${width}" height="${height}">

    this.tmpl_Video = ({playmovie_url, movie_title, clipthumbnail}) => `
    <div class="video-cell">
      <div class="movie">
        <a href="${playmovie_url}">
          <img src="data:image/png;base64, ${clipthumbnail}">
        </a>
      </div>
      <div class="div-play-button">
        <a href="${playmovie_url}">
          <div class="play-icon"></div>
        </a>
      </div>
      <div class="movie-contents">
        <a href="${playmovie_url}">
        ${movie_title}
        </a>
      </div>
    </div>
    `;

    /*
    this.iframeDispatch = this.handlersIFrameToFlask.bind(this);

    //window.addEventListener('message', this.handlersIFrameToFlask.bind(this), false);
    window.addEventListener('message', this.iframeDispatch, false);
    this.getMediaDirsFromRender();
    */

    this.isFirstSection = true;

    if (relDirInfo) {
      this.loadDirViews(relDirInfo);
    } else {
      // Load media root
      this.loadMediaRoot();
    }

    // Append context menu to the container
    $container.append(`
      <div id="contextMenu" class="context-menu">
        <ul>
          <li id="getVideoUrl">Copy channel link</li>
        </ul>
      </div>
    `);

    // Initialize context menu
    this.initContextMenu($container);
  }

  destroy() {
    if (this.$container) {
      this.$container.empty();
    }

    this.unloadCSS(myMediaMain.CSS);
    this.unloadCSS(myMediaMain.CSS_SKY);
    this.unloadCSS(myMediaMain.CSS_DIR_VIEW);
    this.unloadCSS(myMediaMain.polaroidCSS);
    /*
    this.unloadCssFile(this.myMediaCss);
    this.unloadCssFile(this.skyCss);
    this.unloadCssFile(this.dirViewCss);
    */

    window.removeEventListener('message', this.iframeDispatch);
  }

  /**
   * Post message to iframe parent which is render, to get full configuration that contains
   * list of directories.
   */
  getMediaDirsFromRender() {
    console.log('call IFrame_get_config');

    /*
    // alert('call get_config.')
    window.parent.postMessage(
        {funcname: iFrameClause.IFrame_get_config,
         data: ''}, 
         '*');   // Any origin.

    */
  }

  /**
   * Handler iframe (render) response.
   */
  handlersIFrameToFlask(evt) {
    /*
    if (!evt || (evt && !evt.data) || (evt && evt.data && !evt.data.funcname )) {
      return;
    }
    */

    console.log(evt.data);
    if(true) {
    //if(evt.data.funcname == iFrameClause.IFrame_get_config_resp) {
        console.log('answer IFrame_get_config_resp');
      let medisDirs = evt.data.data.media_dirs;
      //let receiveDir = new Array(path.join(evt.data.data.dir_f2f, evt.data.data.dir_f2f_receive));
      let receiveDir = new Array(`${evt.data.data.dir_f2f}/${evt.data.data.dir_f2f_receive}`);
      medisDirs = receiveDir.concat(medisDirs);
      
      if (medisDirs.length == 0) {
        // Display no directory to display.
        let dlg = new dlgOk('Message',
            "You don't have media directories specified. Please click Setting button to define media directories!");
        dlg.open().then((ret) => {})
        
        return;
      }

      // Retrieve root media dir info
      let isFirstSection = true
      medisDirs.forEach(dir => {
        this.loadMediaDir(dir, isFirstSection);
        if (isFirstSection) {
          isFirstSection = false;
        }
      })
    }

    window.removeEventListener('message', this.handlersIFrameToFlask);
  }


  createSectionTitle(secTitle, isFirstSection) {
    // const files = rootDirData.dir_files;
    const files = secTitle.dir_files;

    /*
    if (files.length == 0) {
      return;
    }
    */

    //let dirInfo = rootDirData.dir_info;
    //let dirInfo = secTitle.dir_info;

    // Add new section
    let insertItem = null;
    let item = [];
    item.push({
      sec_title: secTitle['file_title'],
      sec_title_url: secTitle['file_name_url_only']
    });
    insertItem = item.map(this.tmplMediaSection).join('');

    const $sec = this.insertChildBottom(this.$container, insertItem);
    const $secTitle = $sec.find('.div-section-title');

    if (isFirstSection) {
      // Remove separator
      $secTitle.removeClass('div-section-seperator');
    }

    // Add click handler.
    const $ele = $sec;
    if (this.options.onClickFolder) {
      const $link = $ele.find('a');
      //$link.attr('href', files['file_name_url_only']);
      $link.attr('href', secTitle['file_name_url_only']);

      // Store the object, so that click even is able to retrieve it
      // which is used to get files under the directory.
      let fileObj = {
        dir_files: []
      };


      // This view lists all directories in 1st layer. To make directory name to be clickable,
      // which will load OneDirectory, we need to modify this fileObj.dir_files which has 
      // only 1 items. This is used for clicking a video or photo item, which starts video/photo
      // player and loads OneDirectory view.
      // To make folder name clickable, which uses same code as clicked video/photo item, it needs to
      // make modify 'files' which represents folder dirInfo, that is folder parent folder info.
      let fakeClickFolder = {
        //media_root: dirInfo['encode_media_root'],
        //dir_url_only: dirInfo['dir_name_url_only']
        media_root: secTitle['media_root'],
        dir_url_only: secTitle['file_name_url_only']
      }

      fileObj.dir_files.push(fakeClickFolder);
      // fileObj.dir_files.push(files);

      // file.dir_url_only will be used to access folder's media file in fetch() call.
      // But in get_file_thumbnails(), this parameter is for video and photo type,
      // which contains clicked video, photo file parant relative (relative to media_root dir)
      // directory. 
      // In order for this class to work for access folder, which is different from clicked
      // photo, video file, it changed to folder, which contains relative path and folder name.
      //files.dir_url_only = files.file_name_url_only;
      secTitle.dir_url_only = secTitle.file_name_url_only;

      $ele.data('dir-info', fileObj);

      $link.unbind('click');  
      $link.on('click', (evt) => {
          evt.preventDefault();

          // Call React handler
          if (typeof this.options.onClickFolder=== 'function') {
            // Get title
            const $parent = $(evt.target).parent();
            const title = $parent.find('a').text();

            // Fire the event to parent.
            this.options.onClickFolder(evt, title);
          }
      })
    }

    const $video = $sec.find('.div-video-grid');
    const $image = $sec.find('.div-image-grid');
    const $folder = $sec.find('.div-folder-grid');
    this.$folder = $folder;

    this.mediaRoots.push({
      section: $sec,
      folder: $folder,
      video: $video,
      image: $image
    });

    return {
      folder: $folder,
      video: $video,
      image: $image
    }
  }

  getMediaCpUri(mediaInfo, $this) {
    // Identify what kind of resource. local video - lv; youtube - yv.
    let uri = `/lv/`;
    uri += mediaInfo.dir_files[0].media_cp_uri;
    return uri;
  }

  // Initialize context menu
  initContextMenu($container) {
    const self = this; // Store reference to the class instance

    $(document).ready(function() {
      let currentVideoUrl = '';
    
      // Handle right-click on video thumbnails
      $container.on('contextmenu', '.video-cell', function(event) {
        event.preventDefault();

        // 'dir-info' is dynamically added to <div class="video-cell">
        const mediaInfo = $(this).data('dir-info');
    
        // If clicked on edit cell.
        if (!mediaInfo) return false;

        currentVideoUrl = self.getMediaCpUri(mediaInfo, $(this));

        // Calculate the position of the context menu
        const menuWidth = $('#contextMenu').outerWidth();
        const menuHeight = $('#contextMenu').outerHeight();
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
    
        //let left = event.clientX + $(document).scrollLeft(); // Adjust for horizontal scroll
        //let top = event.clientY + $(document).scrollTop(); // Adjust for vertical scroll
    
        // let left = event.clientX; // Adjust for horizontal scroll
        // let top = event.clientY; // Adjust for vertical scroll

        // Use event.pageX and event.pageY to get the coordinates relative to the document
        let left = event.pageX;
        let top = event.pageY;
        
        // Deduct the height of the first page (100vh) if it's 2-page view. Player on top that
        // takes 100vh.
        if (!self.onePageView) {
          top -= windowHeight;
        }

        // Adjust position if menu goes beyond window boundaries
        if (left + menuWidth > windowWidth) {
          left -= menuWidth;
        }
        if (top + menuHeight > windowHeight) {
          top -= menuHeight;
        }
    
        // Show the context menu at the calculated position
        $('#contextMenu').css({
          display: 'block', // Make the menu visible
          left: left, // Set horizontal position
          top: top // Set vertical position
        });
        // alert(`top: ${top}, left: ${left}`);
        return false; // Prevent the default context menu
      });
    
      // Hide the context menu on click outside
      $(document).on('click', function(event) {
        if (!$(event.target).closest('#contextMenu').length) {
          $('#contextMenu').hide(); // Hide the menu
        }
      });
    
      // Handle click on "Get Video URL" menu item
      $('#getVideoUrl').on('click', function() {
        // Clear the clipboard by writing an empty string first
        navigator.clipboard.writeText('').then(function() {
          // Now write the new video URL to the clipboard
          navigator.clipboard.writeText(currentVideoUrl).then(function() {
            // alert('Video URL copied to clipboard: ' + currentVideoUrl);
            $('#contextMenu').hide(); // Hide the context menu
            currentVideoUrl = '';
          }).catch(function(err) {
            console.error('Could not copy text: ', err);
          });
        }).catch(function(err) {
          console.error('Could not copy text: ', err);
        });
      });
    });
  }

  createSectionContent(secContent, $image, $video, $folder) {
    $(document).ready(function() {
      // pinto needs to calculate each image item size to figure out container height.
      // This is to make sure all images is loaded. Otherwise, the container height may
      // not be correct.
      let picWidth = 120;
      $image.pinto({
        itemWidth: picWidth,
        marginX: 6,
        marginY: 6,
        align: 'center',
        animate: true,
        autoResize: true,
        resizeDelay: 50,
        invisibleUntilLayoutDone: true,
        eventLayoutDone: () => {
          $('#image-gallery').css('visibility', 'visible'); // Show the gallery after layout.
        },
      });
    })

    let files = secContent.dir_files;
    // This media root page. No folder is displayed only if files has no other types of media file.
    // For one directory view, all types of media files are displayed, including folders.
    let emptyFolder = true;
    for (let i = 0; i < files.length; i++) {
      if (files[i].file_type == 2 || files[i].file_type == 3) {
        emptyFolder = false;
        break; 
      }
    }
    let item = [];
    let insertItem = null
    // No photo or video
    if (emptyFolder) {
      for (let file of files) {
        item = [];
        // Folder
        item.push({
          album_url: file['file_name_url'],
          album_name: file['file_title'],
          album_image: file['thumbnail']
        });
        insertItem = item.map(this.tmpl_Folder).join('');
        const $ele =  this.insertChildBottom($folder, insertItem);

        //const $ele = this.$lastFolder;
        if (this.options.onClickFolder) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // Store the object, so that click even is able to retrieve it
          // which is used to get files under the directory.
          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);

          // file.dir_url_only will be used to access folder's media file in fetch() call.
          // But in get_file_thumbnails(), this parameter is for video and photo type,
          // which contains clicked video, photo file parant relative (relative to media_root dir)
          // directory. 
          // In order for this class to work for access folder, which is different from clicked
          // photo, video file, it changed to folder, which contains relative path and folder name.
          file.dir_url_only = file.file_name_url_only;

          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickFolder=== 'function') {
                // Get title
                const $parent = $(evt.target).parent().parent().parent();
                const title = $parent.find('.album a').attr('title');

                // Fire the event to parent.
                this.options.onClickFolder(evt, title);
              }
          })
        }
      }
    }

    for (let file of files) {
      if (!(file.file_type == 2 || file.file_type ==3)) {
        continue;
      }

      let insertItem = null;
      let item = [];
      // Photo
      if (file.file_type == 2 ) {
        item.push({
          //width: file['width'],
          //height: file['height'],
          //albumgrid_url: file['file_name_url'],
          albumgrid_url: file['file_name_url_only'],
          thumbnail: file['thumbnail']
        });

        insertItem = item.map(this.tmpl_Image).join('');
        const $ele = this.insertChildBottom($image, insertItem);

        if (this.options.onClickPhoto) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);
          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
            // Prevent the default behavior of the link to prevent navigation
            evt.preventDefault();

            // Call React handler
            if (typeof this.options.onClickPhoto=== 'function') {
              this.options.onClickPhoto(evt);
            }
          })
        }
      } else if (file.file_type == 3) {
        // Video
        item.push({
          playmovie_url: file['file_name_url'],
          movie_title: file['file_title'],
          clipthumbnail: file['thumbnail']
        });

        insertItem = item.map(this.tmpl_Video).join('');
        const $ele = this.insertChildBottom($video, insertItem);

        // Add thumbnail handler if defined in option parameter, which
        // is passed from parent.
        if (this.options.onClickVideo) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // Store the object, so that click even is able to retrieve it
          // which is used to get files under the directory.
          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);
          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickVideo=== 'function') {
                // Get title
                const $parent = $(evt.target).parent().parent().parent();
                const title = $parent.find('.movie-contents a').html();

                // Fire the event to parent.
                this.options.onClickVideo(evt, title);
              }
          })
        }
      }
    }
  }

  /**
   * Create new section for a media root.
   * 
   * @param {*} rootDir 
   */
  createMediaSections(rootDirData, isFirstSection) {
    const files = rootDirData.dir_files;

    if (files.length == 0) {
      return;
    }

    let dirInfo = rootDirData.dir_info;

    // Add new section
    let insertItem = null;
    let item = [];
    item.push({
      sec_title: dirInfo['dir_name_orig'],
      sec_title_url: dirInfo['dir_name_url_only']
    });
    insertItem = item.map(this.tmplMediaSection).join('');

    const $sec = this.insertChildBottom(this.$container, insertItem);
    const $secTitle = $sec.find('.div-section-title');

    if (isFirstSection) {
      // Remove separator
      $secTitle.removeClass('div-section-seperator');
    }

    // Add click handler.
    const $ele = $sec;
    if (this.options.onClickFolder) {
      const $link = $ele.find('a');
      $link.attr('href', files['file_name_url_only']);

      // Store the object, so that click even is able to retrieve it
      // which is used to get files under the directory.
      let fileObj = {
        dir_files: []
      };


      // This view lists all directories in 1st layer. To make directory name to be clickable,
      // which will load OneDirectory, we need to modify this fileObj.dir_files which has 
      // only 1 items. This is used for clicking a video or photo item, which starts video/photo
      // player and loads OneDirectory view.
      // To make folder name clickable, which uses same code as clicked video/photo item, it needs to
      // make modify 'files' which represents folder dirInfo, that is folder parent folder info.
      let fakeClickFolder = {
        media_root: dirInfo['encode_media_root'],
        dir_url_only: dirInfo['dir_name_url_only']
      }

      fileObj.dir_files.push(fakeClickFolder);
      // fileObj.dir_files.push(files);

      // file.dir_url_only will be used to access folder's media file in fetch() call.
      // But in get_file_thumbnails(), this parameter is for video and photo type,
      // which contains clicked video, photo file parant relative (relative to media_root dir)
      // directory. 
      // In order for this class to work for access folder, which is different from clicked
      // photo, video file, it changed to folder, which contains relative path and folder name.
      files.dir_url_only = files.file_name_url_only;

      $ele.data('dir-info', fileObj);

      $link.unbind('click');  
      $link.on('click', (evt) => {
          evt.preventDefault();

          // Call React handler
          if (typeof this.options.onClickFolder=== 'function') {
            // Get title
            const $parent = $(evt.target).parent();
            const title = $parent.find('a').text();

            // Fire the event to parent.
            this.options.onClickFolder(evt, title);
          }
      })
    }

    const $video = $sec.find('.div-video-grid');
    const $image = $sec.find('.div-image-grid');
    const $folder = $sec.find('.div-folder-grid');
    this.$folder = $folder;

    this.mediaRoots.push({
      section: $sec,
      folder: $folder,
      video: $video,
      image: $image
    });

    // This media root page. No folder is displayed only if files has no other types of media file.
    // For one directory view, all types of media files are displayed, including folders.
    let emptyFolder = true;
    for (let i = 0; i < files.length; i++) {
      if (files[i].file_type == 2 || files[i].file_type == 3) {
        emptyFolder = false;
        break; 
      }
    }
 
    // No photo or video
    if (emptyFolder) {
      for (let file of files) {
        item = [];
        // Folder
        item.push({
          album_url: file['file_name_url'],
          album_name: file['file_title'],
          album_image: file['thumbnail']
        });
        insertItem = item.map(this.tmpl_Folder).join('');
        this.$lastFolder = this.insertChildBottom(this.$folder, insertItem);

        const $ele = this.$lastFolder;
        if (this.options.onClickFolder) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // Store the object, so that click even is able to retrieve it
          // which is used to get files under the directory.
          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);

          // file.dir_url_only will be used to access folder's media file in fetch() call.
          // But in get_file_thumbnails(), this parameter is for video and photo type,
          // which contains clicked video, photo file parant relative (relative to media_root dir)
          // directory. 
          // In order for this class to work for access folder, which is different from clicked
          // photo, video file, it changed to folder, which contains relative path and folder name.
          file.dir_url_only = file.file_name_url_only;

          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickFolder=== 'function') {
                // Get title
                const $parent = $(evt.target).parent().parent().parent();
                const title = $parent.find('.album a').attr('title');

                // Fire the event to parent.
                this.options.onClickFolder(evt, title);
              }
          })
        }
      }
    }

    for (let file of files) {
      if (!(file.file_type == 2 || file.file_type ==3)) {
        continue;
      }

      let insertItem = null;
      let item = [];
      // Photo
      if (file.file_type == 2 ) {
        item.push({
          //width: file['width'],
          //height: file['height'],
          //albumgrid_url: file['file_name_url'],
          albumgrid_url: file['file_name_url_only'],
          thumbnail: file['thumbnail']
        });

        insertItem = item.map(this.tmpl_Image).join('');
        const $ele = this.insertChildBottom($image, insertItem);

        if (this.options.onClickPhoto) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);
          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
            // Prevent the default behavior of the link to prevent navigation
            evt.preventDefault();

            // Call React handler
            if (typeof this.options.onClickPhoto=== 'function') {
              this.options.onClickPhoto(evt);
            }
          })
        }
      } else if (file.file_type == 3) {
        // Video
        item.push({
          playmovie_url: file['file_name_url'],
          movie_title: file['file_title'],
          clipthumbnail: file['thumbnail']
        });

        insertItem = item.map(this.tmpl_Video).join('');
        const $ele = this.insertChildBottom($video, insertItem);

        // Add thumbnail handler if defined in option parameter, which
        // is passed from parent.
        if (this.options.onClickVideo) {
          const $link = $ele.find('a');
          $link.attr('href', file['file_name_url_only']);

          // Store the object, so that click even is able to retrieve it
          // which is used to get files under the directory.
          let fileObj = {
            dir_files: []
          };
          fileObj.dir_files.push(file);
          $ele.data('dir-info', fileObj);

          $link.unbind('click');  
          $link.on('click', (evt) => {
              evt.preventDefault();

              // Call React handler
              if (typeof this.options.onClickVideo=== 'function') {
                // Get title
                const $parent = $(evt.target).parent().parent().parent();
                const title = $parent.find('.movie-contents a').html();

                // Fire the event to parent.
                this.options.onClickVideo(evt, title);
              }
          })
        }
      }
    }

    /*
    let picWidth = 120;
    $image.pinto({
      //itemSelector: '.block',  # child selector to hold image.
      itemWidth: picWidth,
      marginX: 6,
      marginY: 6,
      align: 'center',
      animate: true,
      //fitWidth: true,
      autoResize: true,
      resizeDelay: 50,
      invisibleUntilLayoutDone: true
    });
    */

    $(document).ready(function() {
      // pinto needs to calculate each image item size to figure out container height.
      // This is to make sure all images is loaded. Otherwise, the container height may
      // not be correct.
      let picWidth = 120;
      $image.pinto({
        itemWidth: picWidth,
        marginX: 6,
        marginY: 6,
        align: 'center',
        animate: true,
        autoResize: true,
        resizeDelay: 50,
        invisibleUntilLayoutDone: true,
        eventLayoutDone: () => {
          $('#image-gallery').css('visibility', 'visible'); // Show the gallery after layout.
        },
      });
    })
  }

  /*
  loadMediaRoot() {
    this.wait.startWait();
    let url = '/api_myfilex/dirfilestest';
    axios.get(url, {
      method: 'GET',
      credentials: 'include', // Include cookies with the request
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
    })
    .then(async (response) =>  {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let resp = await response.json();

      let files = null;
      if (resp.result.status) {
        let data = JSON.parse(resp.result.data);
        this.curPage = data.page;
        if (data.page != -1) {

          // The return results consists of all directories under media root.
          // Here is to extends all the directories.
          if (this.loadDirViews(data)) {
            // Load next page 1.
            //this.appendNextPage(1);
          }
        }
      } else {
        console.log(resp.result.error);
        let dlg = new dlgOk('Error',
            'Load media files loadFiles() failed! /api/dirfiles/ ' + 'Error: ' + response.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      console.error('Error:', error);
    }).finally(() => {
      this.wait.stopWait();
    }); 
  }
  */

  loadMediaRoot() {
    const api = new ApiFileX();
    api.loadMediaRoot().then(async (retData) => {
      if (retData.status) {
        this.curPage = retData.data.page;
        if (retData.data.page != -1) {

          // The return results consists of all directories under media root.
          // Here is to extends all the directories.
          if (this.loadDirViews(retData.data)) {
            // Load next page 1.
            //this.appendNextPage(1);
          }
        }
      }
    })
  }
  

  loadMediaRoot_back() {
    this.wait.startWait();
    //let url = '/api_myfilex/dirfiles';
    let url = '/api_myfilex/dirfilesroot';

    // Issue: this url login_required decorator fails. Why this API worked: forgot to add
    // the decorator.
    //
    // Not sure why on this api fails on decorator login_required. It's found that session does not 
    // have login credential which is already verified.
    // 
    // Few things:
    // 1. fetch add credentials: 'include', on React
    // 2. Added 2nd parameter: CORS(app, supports_credentials=True) on Flask server.
    //
    // Now it works.  9/13/23
    //
    // ChatGPT also suggests following on server. Not done it.
    // data = {"message": "Your response data here"}
    // response = jsonify(data)
    // response.headers.add('Access-Control-Allow-Origin', 'http://localhost:3000')
    // response.headers.add('Access-Control-Allow-Credentials', 'true')  # Set to 'true'

    // return response
    //
    // https://stackoverflow.com/questions/57419527/why-flask-session-didnt-store-the-user-info-when-making-different-posts-to-it-f
    fetch(url, {
      method: 'GET',
      credentials: 'include', // Include cookies with the request
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
    })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let resp = await response.json();

      let files = null;
      if (resp.result.status) {
        let data = JSON.parse(resp.result.data);
        this.curPage = data.page;
        if (data.page != -1) {

          // The return results consists of all directories under media root.
          // Here is to extends all the directories.
          if (this.loadDirViews(data)) {
            // Load next page 1.
            //this.appendNextPage(1);
          }
        }
      } else {
        console.log(resp.result.error);
        let dlg = new dlgOk('Error',
            'Load media files loadFiles() failed! /api/dirfiles/ ' + 'Error: ' + response.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      console.error('Error:', error);
    })
    .finally(() => {
      this.wait.stopWait();
    });  
  }

  loadDirViews(dirInfo) {
    // Load thumbnails for each directory.
    dirInfo.dir_files.forEach(async dir => {
      let retResult = this.createSectionTitle(dir, this.isFirstSection);

      if (this.isFirstSection) {
        this.isFirstSection = false;
      }
    
      // let url = '/api_myfilex/dirfiles/' + dir.file_name_url_only;
      let url = '/api_myfilex/dirfilesbrief/' + dir.file_name_url_only;
      fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let resp = await response.json();
  
        let files = null;
        if (resp.result.status) {
          let data = JSON.parse(resp.result.data);
          this.createSectionContent(data, retResult.image, retResult.video, retResult.folder)
        } else {
          console.log(resp.result.error);
          let dlg = new dlgOk('Error',
              'Load loadDirViews() failed! /api/dirfiles/ ' + 'Error: ' + response.result.error);
          dlg.open().then((ret) => {})
        }
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
      });
    });

    //this.wait.stopWait();
  }

  loadMediaDir(dir, isFirstSection) {
    this.wait.startWait();

    let url = this.$SCRIPT_ROOT + '/api/mediaroot/' + this.utf8_to_b64(dir);
    $.ajax({
      url: url,
      success: (response) => {
        let files = null;
        if (response.result.status) {
          let data = JSON.parse(response.result.data);
          this.createMediaSections(data, isFirstSection);
        } else {
          let dlg = new dlgOk('Error',
              'Load media files failed! /api/mediaroot/ ' + 'Error: ' + response.result.error);
          dlg.open().then((ret) => {})

        }
      },
      error: (response) => {
        let a = response;
      },
      complete: (data) => {
        this.wait.stopWait();
      }
    });
  }
}

/*
$(document).ready(function() {
  gMyMain = new myMediaMain();
})
*/

/*
// Useless
$(window).unload(function() {
  // Otherwise, the section to list media is not deleted. This is evident that switching from
  // setting view to my media view.

  if (gMyMain) {
    gMyMain.destroy();
    gMyMain = null
  }
});
*/