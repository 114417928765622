/**
 * It display multiple sections. Each section uses grid to display videos.
 * It's different from one section component, which display only one section. The title is
 * in middle with its grid videos underneath.
 * 
 */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiFileX } from '../../../comjs/filex.api/filex-api';
import { useConfig } from '../../System/ConfigContext.jsx';
import VideoGrid from '../YtPlaylistDnD/VideoGrid.jsx';
import ConfirmationDialog from '../../Util/ConfirmationDialog.jsx';
import './SectionDirectory.css';

const DirectorySection = ({
    relpath=null,   // Including dir self.
    secTitle=null,
    enablePlay=true,
    onNewDirectory = null
}) => {
    const { config } = useConfig(); // Access context
    const editableDivRef = useRef(null);
    const navigate = useNavigate();

    const [ytEditMode, setYtEditMode] = useState(config.ytEditMode);
    const [directoryName, setDirectoryName] = useState();
    const [sections, setSections] = useState([]);
    const [error, setError] = useState('');
    const [sectionError, setSectionError] = useState({ id: null, message: '' });
    const [loading, setLoading] = useState(false); // Added loading state
    const [showModal, setShowModal] = useState(false);
    const [sectionToDelete, setSectionToDelete] = useState(null);
    const [addingNewSection, setAddingNewSection] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [editingSection, setEditingSection] = useState(null);
    const editableRefs = useRef({});

    const placeholderText = 'Add new category...';

    const fetchSections = async () => {
        try {
            const api = new ApiFileX();
            const retData = await api.getAllSections(relpath);
            if (retData && retData.status) {
                setSections(retData.data);
            } else {
                console.error('Failed to fetch sections from the server.');
            }
        } catch (error) {
            console.error('Error fetching sections:', error);
        }
    };

    useEffect(() => {
        fetchSections();
    }, [relpath]);

    useEffect(() => {
        if (addingNewSection && editableDivRef.current) {
            editableDivRef.current.focus();
        }
    }, [addingNewSection]);

    const validateSectionName = (name) => {
        if (!name || name.trim().length < 2) {
            return 'Name must be at least 2 characters long.';
        }
        if (name.length > 64) {
            return 'Name must be 64 characters or less.';
        }
        const invalidCharsPattern = /[<>:"/\\|?*]/;
        if (invalidCharsPattern.test(name)) {
            return 'Sorry. These characters are not allowed: <>:"/\\|?*';
        }
        return null;
    };

    const handleInput = (event) => {
        const currentText = editableDivRef.current.innerText;

        if (currentText.length > 64) {
            setError('Name must be 64 characters or less.');
            editableDivRef.current.innerText = currentText.slice(0, 64);
            setTimeout(() => {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(editableDivRef.current);
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);
            }, 0);
        } else {
            setError('');
        }

        if (currentText && currentText !== placeholderText) {
            editableDivRef.current.classList.remove('placeholder');
        }
    };

    const handleFocus = () => {
        if (editableDivRef.current.innerText === placeholderText) {
            editableDivRef.current.innerText = '';
            editableDivRef.current.classList.remove('placeholder');
        }
    };

    const handleBlur = () => {
        if (!editableDivRef.current.innerText.trim()) {
            editableDivRef.current.innerText = placeholderText;
            editableDivRef.current.classList.add('placeholder');
            setError('');
        } else {
            handleSubmit();
        }
    };

    const handleKeyDown = (event) => {
        if (editableDivRef.current.innerText === placeholderText) {
            editableDivRef.current.innerText = '';
            editableDivRef.current.classList.remove('placeholder');
        }

        const currentText = editableDivRef.current.innerText;

        if (currentText === placeholderText && (event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }

        if (event.key === ' ' && !currentText.trim()) {
            event.preventDefault();
        }

        if (event.key === 'Enter') {
            // Prevent wrap so that 2nd line appear
            event.preventDefault();
            editableDivRef.current.blur();
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const currentText = editableDivRef.current.innerText.trim();

        const validationError = validateSectionName(currentText);
        if (validationError) {
            setError(validationError);
            return;
        }

        setLoading(true); // Start loading
        setError(null);

        try {
            const api = new ApiFileX();

            // Create new directory under current relpath.
            const retData = await api.saveYtDirectory(relpath, currentText);
            if (retData && retData.status) {
                editableDivRef.current.innerText = placeholderText; // Clear the content of the editable div
                editableDivRef.current.classList.add('placeholder');
                setError(''); // Clear any previous error

                if (onNewDirectory) {
                    onNewDirectory(currentText);
                }
                // Refresh the component data instead of reloading the page
                await fetchSections();
                setAddingNewSection(false); // Hide the editable box and show the + button
            } else if (retData && !retData.status && retData.message) {
                setError(retData.message);
            } else if (retData && !retData.status && retData.error) {
                setError(retData.error);
            } else {
                setError('Failed to save the directory. Please try again.');
            }
        } catch (error) {
            setError('Failed to submit directory. ' + error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleSectionClick = (folderUrl, dirName) => {
        let url = `/ytdirview/${folderUrl}`;
        if (dirName)
            url += `/${dirName}`;

        const api = new ApiFileX();
        const encodeDirName = api.encodeUrlToBase64_UrlSafe(dirName);

        // navigate(`/ytdirview/${folderUrl}/${encodeDirName}`, 
        //    { replace: true, state: { timestamp: new Date().getTime() } });

        navigate(`/ytdirview/${folderUrl}/${encodeDirName}`,
            { replace: true });    // Use replace to avoid nested instances. It creates YoutubeView again.
    };

    const handleDeleteSection = async (sectionId, categoryName) => {
        setSectionToDelete(sectionId);
        setDeleteMessage('Are you sure you want to delete the category "' + categoryName + '"? All YouTube video links in this category will be deleted.');
        setShowModal(true);
    };

    const confirmDelete = async () => {
        setShowModal(false);

        try {
            const api = new ApiFileX();
            const retData = await api.deleteYtDirectory(sectionToDelete);
            if (retData && retData.status) {
                setSections(sections.filter(section => section.id !== sectionToDelete));
                // Clear the error for the section
                setSectionError({ id: null, message: '' });
            } else {
                const errorMessage = !retData.status && retData.message ? retData.message : 'Failed to delete the section. Please try again.';
                setSectionError({ id: sectionToDelete, message: errorMessage });
            }
        } catch (error) {
            setSectionError({ id: sectionToDelete, message: 'Failed to delete the section. ' + error });
        }

        fetchSections();

        // Clear the error after 5 seconds
        setTimeout(() => {
            setSectionError({ id: null, message: '' });
        }, 15000); // Adjust the duration as needed
    };

    const cancelDelete = async () => {
        setShowModal(false);
        setSectionToDelete(null);
    };

    const handleAddNewSectionClick = () => {
        setAddingNewSection(true);
    };

    const handleEditSectionClick = (sectionId) => {
      setEditingSection(sectionId);
      
      // Add cursor to editable section which is just entered section title editable mode after
      // clicking 'Edit'.
      setTimeout(() => {
          if (editableRefs.current[sectionId]) {
              editableRefs.current[sectionId].focus();
          }
      }, 0);
    };

    const handleSaveEditSection = async (section) => {
        const sectionId = section.folder_url
        const editedText = document.getElementById(`editable-${sectionId}`).innerText.trim();

        // No changes.
        if (editedText === section.file_name) {
          // Clear error message.
          setSectionError({ id: null, message: '' });  

          // Remove edit mode dashed border.
          // setLoading(false);
          setEditingSection(null);
          return;
        }

        const validationError = validateSectionName(editedText);
        if (validationError) {
            setSectionError({ id: sectionId, message: validationError });
            return;
        }

        setLoading(true);
        setSectionError({ id: null, message: '' });

        try {
            const api = new ApiFileX();
            const retData = await api.updateYtDirectory(sectionId, editedText);

            if (retData && retData.status) {
                setSections(sections.map(section => section.id === sectionId ? { ...section, file_name: editedText } : section));
                setEditingSection(null);

                section.file_name = retData.data.file_name;
                section.file_title = retData.data.file_name;
                section.folder_url = retData.data.folder_url
            } else {
                const errorMessage = retData.message || 'Failed to update the section. Please try again.';
                setSectionError({ id: sectionId, message: errorMessage });
            }
        } catch (error) {
            setSectionError({ id: sectionId, message: 'Failed to update the section. ' + error });
        } finally {
            setLoading(false);
        }
    };

    const handleCancelEditSection = () => {
        setEditingSection(null);
    };

    return (
        <>
            <div className="yt-sections-container">
                {ytEditMode && (
                    <section className="yt-section section-media">
                        <div className="div-section-title div-section-seperator">
                            <div className="editable-container">
                                {!addingNewSection ? (
                                    <button className="button add-button" onClick={handleAddNewSectionClick}>+</button>
                                ) : (
                                    <div
                                        contentEditable
                                        ref={editableDivRef}
                                        suppressContentEditableWarning
                                        onInput={handleInput}
                                        onKeyDown={handleKeyDown}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        className={`editable-div edit-mode ${!directoryName ? 'placeholder' : ''}`}
                                        data-placeholder={placeholderText}
                                    >
                                        {directoryName || placeholderText}
                                    </div>
                                )}
                                {error && <div className="error-message">{error}</div>}
                            </div>
                        </div>
                    </section>
                )}
                {sections.map((section, index) => (
                    <section key={index} className="yt-section section-media">
                        <div className="div-section-title">
                            {editingSection === section.folder_url ? (
                                <div
                                    id={`editable-${section.folder_url}`}
                                    ref={(el) => (editableRefs.current[section.folder_url] = el)}
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={() => handleSaveEditSection(section)}
                                    onKeyDown={(e) => e.key === 'Enter' && handleSaveEditSection(section)}
                                    className="editable-div edit-mode"
                                >
                                    {section.file_name}
                                </div>
                            ) : (
                                <>
                                    <span
                                        className="title-link"
                                        onClick={(e) => {
                                            // e.preventDefault();
                                            handleSectionClick(section.folder_url, section.file_name);
                                        }}
                                    >
                                        {section.file_name}
                                        <span className="more-indicator"> </span>
                                    </span>
                                    {ytEditMode && (
                                        <>
                                            <button
                                                className="button-edit-bar button-section-edit"
                                                onClick={() => handleEditSectionClick(section.folder_url)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="button-edit-bar button-section-delete"
                                                onClick={() => handleDeleteSection(section.folder_url, section.file_name)}
                                            >
                                                X
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        {sectionError.id === section.folder_url && (
                            <div className="error-message">{sectionError.message}</div>
                        )}
                        <VideoGrid
                            relpath={section.folder_url}
                            briefView={true}
                            ytEditMode={ytEditMode}
                        />
                    </section>
                ))}
            </div>
            <ConfirmationDialog
                show={showModal}
                title={'Delete'}
                message={deleteMessage}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />
        </>
    );
};

export default DirectorySection;
