// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.two-page-container {
  height: 200vh; /* Set the height to make the page scrollable */
}

.two-page-container .page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 1.5s ease; /* Add opacity transition */
}

.two-page-container .page-2 {
  position: absolute;
  top: 100vh;
  left: 0;
  width: 100%;
  opacity: 0; /* Hide the second page initially */
}
`, "",{"version":3,"sources":["webpack://./src/Components/TwoPages/TwoPages.css"],"names":[],"mappings":";AACA;EACE,aAAa,EAAE,+CAA+C;AAChE;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,6BAA6B,EAAE,2BAA2B;AAC5D;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,OAAO;EACP,WAAW;EACX,UAAU,EAAE,mCAAmC;AACjD","sourcesContent":["\n.two-page-container {\n  height: 200vh; /* Set the height to make the page scrollable */\n}\n\n.two-page-container .page {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  transition: opacity 1.5s ease; /* Add opacity transition */\n}\n\n.two-page-container .page-2 {\n  position: absolute;\n  top: 100vh;\n  left: 0;\n  width: 100%;\n  opacity: 0; /* Hide the second page initially */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
