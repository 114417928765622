import React, { useState, useEffect } from 'react';
import TwoSectionsPage from '../TwoSectionsPage/TwoSectionsPage';
import CinemaPhoto from '../CinemaPhoto/CinemaPhoto';
import OneDirectory from '../DirectoryView/OneDirectory';


/**
 * When a photo/video is clicked. How PageOne player displays at top is achieved with 
 * event lister of triggerPage1Visible in TwoPageBase. 
 * 
 * As how triggerPage1Visible is used at ...Player2P, clicedPhoto (clickedVideo) file
 * name change will trigger useEffect() inside TwoPageBase. 
 * 
 * Also, clicedPhoto (clickedVideo) file changes in ...Player2P will re-render itself. Therefore
 * TwoPageBase will be re-rendered as well. 
 * 
 * If window.scrollTo(0, 0); is to responde  triggerPage1Visible variable changes, which is photo/video
 * file name changes, it will not work for TwoPageBase to scroll properly. Because after window.scrollTo(0, 0);
 * TwoPageBase will be re-rendered.
 * 
 * Therefore, TwoPageBase should take a variable which is to force page 1 to be displayed.
 * 
 * So, triggerPage1Visible is not useful in this case, unless ...Player2P only re-render top player, not
 * the grid display at bottom.
 * 
 * onPage2VisiblePhoto is needed by the menu at bottom to show up if page2 is visible.
 * 
 * @param {*} props 
 * @returns 
 */
const PhotoPlayer2P = (props) => {
  const {
    photoTitleInput,
    photoPathInput,
    dirInfoInput,
    onClickVideo,
    onClickFolder,
    onPage2VisiblePhoto
  } = props;
  // Scroll cause re-render (needs to improve). This is to prevent reinitialize OneDirectory
  // component during scroll.
  const [isFirstRender, setIsFirstRender] = useState(false);

  const [pageOneVisible, setPageOneVisible] = useState(false);
  const [photoPath, setPhotoPath] = useState(photoPathInput);
  const [dirInfo, setDirInfo] = useState(dirInfoInput);
  const [dirName, setDirName] = useState(photoTitleInput);
  const [clicedPhoto, setClickedPhoto] = useState(photoTitleInput);

  useEffect(() => {
    setIsFirstRender(true);
  }, [photoPathInput, dirInfoInput]); 

  useEffect(() => {
    return () => {
      console.log("PhotoPlayer2P unmounted.");
    };
  }, []); 

  // Handle item clicked in OneDirectory. No need to be handled by parent component as it needs to load
  // video player component. Current view is photo player view.
  const handleOnClickVideo = (photoRelPath, title, dirInfo) => {
    //onClickVideo(videoRelPath, title, dirInfo);
    setPhotoPath(photoRelPath);
    // setPhotoTitle(title);
    setDirInfo(dirInfo);

    onClickVideo(photoRelPath, title, dirInfo);
  };

  // OneDirectory view photo click will start new photo play with 1st picture to be
  // the clicked photo.
  const handleOnClickPhoto = (photoRelPath, dirInfo, dirName) => {
    setPhotoPath(photoRelPath);
    setDirInfo(dirInfo);
    setDirName(dirName);

    // This state variable is to trigger page1 to be displayed. It assumes file name to be
    // different in a folder.
    setClickedPhoto(dirName);
  };
  
  const handleOnClickFolder = (folderRelPath, dirInfo, dirName) => {
    onClickFolder(folderRelPath, dirInfo, dirName);
  }

  /**
   * These 2 events are triggered by scrolling.
   */
  const handleOnPage1Visible = () => {
    setPageOneVisible(true);
    onPage2VisiblePhoto(false);
  };

  const handleOnPage2Visible = () => {
    setPageOneVisible(false);
    onPage2VisiblePhoto(true);
  };

  // key is to making sure scrolling to OneDirectory to work. Without it, it does not scroll to
  // page2, which is OneDirectory.
  return (
    <div key={photoPath}>
      <TwoSectionsPage
          keyValue={photoPath} 
          onScroll0={handleOnPage1Visible} 
          onScroll30={handleOnPage2Visible} >
        <CinemaPhoto 
            photoDirTitle={dirName} 
            photoPath={photoPath} 
            pageOneVisible={pageOneVisible} />
        <OneDirectory 
            relPath={photoPath} 
            dirInfo={dirInfo}
            onClickVideo={handleOnClickVideo} 
            onClickPhoto={handleOnClickPhoto}
            onClickFolder={handleOnClickFolder}
            onPageView={false}
        />
        </TwoSectionsPage>
    </div>
  );
};

export default PhotoPlayer2P;