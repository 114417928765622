import React, { useState, useEffect, useContext, Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './App.css';
import { AppContext, AppContextProvider } from './AppContext';
import MainPage from './Components/MainPage/MainPage';
import ResponsiveDiv from './TestCom/ResponsiveDiv/ResponsiveDiv'; 
import PhotoGridView from './Components/MediaGridView/PhotoGrid';
import CinemaVideo from './Components/CinemaVideo/CinemaVideo';
import MediaPage from './TestCom/MediaPage/MediaPage';
import { Article } from './Components/Article/Article';
import GridIcons from './Components/GridIcons/GridIcons';
import ShareScreen from './Components/ShareScreen/ShareScreen';
import CookingRecipe from './Components/Article/CookingRecipe';
import GridStackLab1 from './Components/Channels/GridStackLab1';
// import { MuuriLab1 } from './Components/Channels/MuuriLab1';
import { MuuriLab2 } from './Components/Channels/MuuriLab2';
import { MuuriLab3Kanban } from './Components/Channels/MuuriLab3Kanban';
import { FileManagerLab } from './Components/FileExplorer/DirectoryLab';
import { FileExplorer } from './Components/FileExplorer/FileExplorer';
import ChannelFileEdit from './Components/ChannelEdit/ChannelEdit';
import MuuriSandbox1 from './Components/Sandbox/MuuriSandbox1';
import { MuuriEditKanban } from './Components/ChannelEdit/MuuriEditKanban';
import { StockMonitor } from './Components/StockMonitor/StockMonitor';
import { MuuriStockList } from './Components/StockMonitor/MuuriStockList';
import MuuriGrid from './Components/StockMonitor/LabMuuriList';
import YouTubeEmbed from './Components/Sandbox/YoutubeEmbed';
import YoutubeEditor from './Components/YoutubeView/YoutubeEditor';

import RecipeCard from './Components/Article/RecipeCard';
import NotifyBell from './Components/Notification/NotifyBell';
import { CombinedProvider } from './Components/System/CombinedContext';
import SystemSettings from './Components/System/SystemSettings';
import { RootProvider } from './RootContext';
import DirectorySection from './Components/YoutubeView/Section/SectionDirectory';
import CustomModal from './Components/Util/CustomModal';
import ConfirmationDialog from './Components/Util/ConfirmationDialog';
import VideoGrid from './Components/YoutubeView/YtPlaylistDnD/VideoGrid';
import ArticleList from './Components/Article/ArticleList';
import SysIcons from './Components/HeroIcon/SysIcons';
import PlaylistMan from './Components/Playlist/PlaylistMan';
import AutoPlaylists from './Components/Playlist/AutoPlaylists';
import UserProfilePanel from './Components/IconMenuBar/UserProfilePanel';
import ChannelPlayer2P from './Components/Channels/ChannelPlayer2P';
import useScrollToTop from './Components/Util/ScrollTop';

// ----- sandbox
import TestVideoPlayer from './Components/CinemaVideo/TestVideoPlayer';
import TestYtPlayer from './Components/YoutubeView/TestYtPlayer';
import ChatSPRoom from './Components/Online/ChatSPRoom';
import VideoChatRoom from './Components/Online/VideoChatRoom';
import PeerCommunication from './Components/Sandbox/PeerCommunication';
import SimPeerComponent from './Components/Sandbox/SimPeerComponent';
import SimplePeerComponent from './Components/Online/SimplePeerComponent';
import PeerManagerRoom from './Components/Sandbox/PeerManagerRoom';
import SimPeerComponentRoom from './Components/Sandbox/SimPeerComponentRoom';
import A from './Components/Sandbox/Problem1/A';
import TwoPageAlign from './Components/Sandbox/TwoPageAlign';
import Chat from './Components/Online/Chat';
import ChatPeerJS from './Components/Online/ChatPeerJS';
import TestTwoPageOverlay from './Components/TwoPageOverlay/TestTwoPageOverlay';
import TwoSectionsOverlay from './Components/TwoPageOverlay/TwoSectionsOverlay';
import TestTwoSectionsPage from './Components/TwoSectionsPage/TestTwoSectionsPage';

// Lazy load the YoutubeView component
import YoutubeView from './Components/YoutubeView/YoutubeView';
import Channels from './Components/Channels/Channels';

// 6/9/24. This causes weird problem when invoke YoutubeView from button click.
// https://stackoverflow.com/questions/72167518/a-component-suspended-while-responding-to-synchronous-input
//const YoutubeView = lazy(() => import('./Components/YoutubeView/YoutubeView'));


//function App() {
const App = () => {
  const [appTitle, setAppTitle] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <AppContextProvider>
      <AppControl />
    </AppContextProvider>
  );
}

// const App = () => {
//   const [appTitle, setAppTitle] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   return (
//     <AppContextProvider>
//       <AppControl />
//     </AppContextProvider>
//   );
// };

// const container = document.getElementById('root');
// const root = createRoot(container);

// root.render(
//   <RootProvider>
//     <App />
//   </RootProvider>
// );

  /*
  // To remove "Leave site?" dialog for new url entered in browser address.
  // TODO: not work.
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Fetch the title from the Flask API
    fetch('/title')
        .then(response => response.json())
        .then(data => {
            // Set the document title with the fetched title
            document.title = `${data.title}`;
            setAppTitle(data.title);
        })
        .catch(error => console.error('Error fetching title:', error));
  }, []);

  useEffect(() => {
    // Add a beforeunload event listener
    const handleBeforeUnload = async (event) => {
      event.preventDefault();

      // Send a logout request or any other necessary action here
      event.returnValue = ""; // Some browsers require a return value to display the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect only runs once
  */
  // Wrap ScrollProvider (context) on all components is for MainPage to get 
  // processed scroll events in TwoPageBase to be passed to MainPage. 
  // MainPage could control IconeMenu raise/hide. ScrollProvider has to be outside 
  // os MainPage (consumer) for it to use useScroll hook. If putting in MainPage 
  // and use useScroll in MainPage, it does not work.
  //
  // By wrapping your entire application or the relevant part with DndProvider, 
  // you provide the necessary context for drag-and-drop operations to work properly. 
  // TODO: DndProvider needed? 1/26/24

  // <React.StrictMode> 
  // As mentioned earlier, wrap your entire application or relevant parts with 
  // React.StrictMode to catch potential issues during development. This might help identify 
  // any unsafe lifecycle methods or side effects.
  // 1/25/24. This causes to identical content loaded
  //<React.StrictMode>  
  //</React.StrictMode>

  //TODO: 1/24/24
  // WHy this is added?
  //    <DndProvider backend={HTML5Backend}>
  // </DndProvider>
  // It has no effect with/without it.

  // <ScrollProvider>
  // 1/26/24. Remove  <ScrollProvider> as HOC wrapper for <AppContextProvider>. Not sure why add it.
  // It caused problem: when scroll, photo player will replay. The reason is that the scroll will
  // trigger AppControl to re-render the photo player.
  // ScrollContext has implemented useScroll hook for MainPage to use so that is can process
  // the continues scroll event. The main purpose is to fire less scroll event so that bottom
  // menu bar does not flash.


// 5/20/24. These 2 lines are added. not sure if it makes diff in solving the issue.
// https://chatgpt.com/c/15336d74-fc6a-4ff4-ba5b-9177f195d3d0
//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);

// TODO: try to disable "Reload site" dialog. Seems not fully working.
// Even after break here, the dislog still shows up.
window.onbeforeunload = (event) => {
  const e = event || window.event;
  // Cancel the event
  e.preventDefault();
  if (e) {
    e.returnValue = ''; // Legacy method for cross browser support
  }
  return ''; // Legacy method for cross browser support
};

const DisableBeforeUnload = () => {
  useEffect(() => {
    const disableBeforeUnload = (event) => {
      // Prevent the default behavior of the beforeunload event
      // event.preventDefault();
      // Check if the event is triggered by the browser's reload button
      if (event.currentTarget.performance.navigation.type === 1) {
        event.preventDefault();
        event.returnValue = '';
      }
      // Customize the message if needed
      // const message = "Are you sure you want to leave?";
      // event.returnValue = message;
      // return message;
    };

    window.addEventListener('beforeunload', disableBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', disableBeforeUnload);
    };
  }, []);

  return null;
};


const ScrollToTopWrapper = ({ children }) => {
  // Ensure that the page scrolls to the top whenever the route changes, 
  // without needing to add scroll handling code in multiple places
  // useScrollToTop();
  return <>{children}</>;
};

/**
 * Create AppControl is to make AppContext to work. Without wrapping it by
 * App() with AppContextProvider, AppContext is not accessible. In other words,
 * accessing AppContext is not possible in App().
 * 
 * @returns 
 */
const AppControl = () => {
  const { loginUserName, updateLoginUserName } = useContext(AppContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/check_login');
        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setIsLoggedIn(true);
            updateLoginUserName(data.data);
          } else {
            setIsLoggedIn(false);
            updateLoginUserName('');
          }
        } else {
          setIsLoggedIn(false);
          updateLoginUserName('');
        }
      } catch (error) {
        setIsLoggedIn(false);
        updateLoginUserName('');
      }
    };

    fetchData();
  }, []);

  // 404 Not Found component
  const NotFound = () => <div>404 Not Found</div>;

  // 6/12/24
  // Adding this weird wrapper is possibly due to React bug. As discussed here:
  // 1) It's nested render YoutubeView + DirectorySection. But 'replace' is used
  // 2) YoutubeView url input parameters does changes its state
  //
  // Give above, when clicking section title, YoutubeView is not unmounted when 
  // in 2nd layer of directory (when clicking section name).
  // By adding wrapper, it works as expected.
  // 8/10/24. OBS. Yt video player unmount automatically without reason
  // after 5-10 min
  const YoutubeViewWrapper = () => {
    const { param_ytid, param_ytpath, param_dirname } = useParams();
    const key = `${param_ytid}-${param_ytpath}-${param_dirname}`;
    return <YoutubeView key={key} />;
  };

  return (
    <>
     {/* <DisableBeforeUnload /> */}
    <CombinedProvider>
    <Router>
      <ScrollToTopWrapper>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route exact path="/" element={<MainPage />} />
          <Route
            path="/:paramShowTwoPage/:paramShowPlayerView"
            element={<MainPage />}
          />
          <Route exact path="/autoplaylist" element={
              <AutoPlaylists />
            } 
          />
          <Route exact path="/video/:pavideoroot/:pavideoid" element={<MainPage />} />
          <Route exact path="/photo/:paphotoroot/:paphotoid" element={<MainPage />} />
          <Route exact path="/folder/:pafolderroot/:pafolderid" element={<MainPage />} />
          <Route exact path="/playchannel/:pachannelname" element={<ChannelPlayer2P />} />
          <Route exact path="/gridicon" element={<GridIcons />} />
          <Route exact path="/sysicon" element={<SysIcons size={180} />} />
          <Route exact path="/home" element={<MainPage />} />
          <Route exact path="/articlelist" element={<ArticleList />} />
          <Route exact path="/article" element={<Article />} />
          {/* <Route exact path="/theater" element={<ChatSPRoom />} /> */}
          <Route exact path="/theater" element={<VideoChatRoom />} />
          <Route exact
            path="/test/article/:param_file_id/:param_relpath?" 
            element={<Article />} 
          />
          <Route exact 
            path="/getarticle/:param_articalid/:param_relpath?/" 
            element={
              <Article />
            } 
          />
          <Route exact path="/playlist" element={
              <PlaylistMan />
            } 
          />
          <Route exact path="/youtubeview" element={
              <YoutubeView />
            }
          />
          <Route exact 
            // path="/ytdirview/:ytpath" 
            // element={<YoutubeView />} 
            // If dirname is empty, it means to display 
            path="/ytdirview/:param_ytpath/:param_dirname?"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                {/* <YoutubeViewWrapper /> Don't use, it auto remount 5-10 min.*/}
                <YoutubeView />
              </Suspense>
            }
          />
          <Route exact 
            path="/ytplayerdirview/:param_ytid/:param_title/:param_ytpath?/:param_dirname?"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                {/* <YoutubeViewWrapper /> */}
                <YoutubeView />
              </Suspense>
            }
          />
          <Route exact path="/settings" element={
              <SystemSettings />
            } 
          />
          <Route exact path="/test/ytplayer" element={
              <TestYtPlayer />
            } 
          />
          <Route exact path="/test/videoplayer" element={
              <TestVideoPlayer />
            } 
          />
          <Route exact path="/test/section" element={
              <DirectorySection />
            } 
          />
          <Route exact path="/test/chat" element={
              // <Chat />
              <ChatSPRoom />
            } 
          />
          <Route exact path="/test/simpeer" element={
              // <SimplePeerComponent />
              // <SimPeerComponent />
              // <SimPeerComponentRoom />
              <PeerManagerRoom />
            } 
          />
          <Route exact path="/test/peercomm" element={
              <PeerCommunication />
            } 
          />

          <Route exact path="/test/userprofile" element={
              <UserProfilePanel />
            } 
          />
          <Route exact 
            path="/test_videogrid/:param_relpath?"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <VideoGrid />
              </Suspense>
            }
          />
          <Route exact path="/test/notify" element={<NotifyBell size="large" />} />
          <Route exact path="/recipe" element={<CookingRecipe />} />
          <Route exact path="/recipe1" element={<RecipeCard />} />
          <Route exact path="/test/muurigrid" element={<MuuriGrid />} />
          <Route exact path="/test/murri1" element={<MuuriSandbox1 />} />
          {/* <Route exact path="/test/murri1" element={<MuuriLab2 />} /> */}
          {/* MuuriLab3Kanban ChannelFileEdit MuuriEditKanban*/}
          <Route exact path="/test/channeledit" element={<MuuriEditKanban />} />
          <Route exact path="/test/stockmonitor" element={<MuuriStockList />} />
          <Route exact path="/test/filemanagerlab" element={<FileManagerLab />} />
          <Route exact path="/test/filemanager" element={<FileExplorer />} />
          <Route exact path="/test/channeleditor" element={<MuuriLab3Kanban />} />
          <Route exact path="/test/channeleditor1" element={<GridStackLab1 />} />
          <Route exact path="/test/responsive" element={<ResponsiveDiv />} />
          <Route exact path="/test/mediapage" element={<MediaPage />} />
          <Route exact path="/test/sharescreen" element={<ShareScreen />} />
          <Route exact path="/test/youtube/:videoId" element={<YouTubeEmbed />} />
          <Route exact path="/test/youtubeeditor" element={<YoutubeEditor />} />
          <Route exact path="/test/twopageoverlay" element={<TestTwoPageOverlay />} />
          <Route exact path="/test/twosectionsoverlay" element={<TwoSectionsOverlay />} />
          <Route exact path="/test/twosectionspage" element={<TestTwoSectionsPage />} />
          
          <Route exact path="/sandbox/problem1" element={<A />} />
          <Route exact path="/sandbox/twopagealign" element={<TwoPageAlign />} />
          <Route exact path="/test/confirm" element={
            <ConfirmationDialog
              show={true}
            />
          } />
          <Route exact path="/test/dialog" element={
            <CustomModal 
              show={true}
              title="Delete Section"
              message="Are you sure you want to delete this section? All YouTube video links will be deleted."
              onConfirm={() => alert('Confirmed!')}
              onCancel={() => alert('Cancelled!')}
            />
          } />
  
        </Routes>
      </ScrollToTopWrapper>
    </Router>
    </CombinedProvider>
    </>
  );
}

export default App;
