import React, { useEffect } from 'react';
import './ImageCarousel.css'
import image1 from '../../assets/img/bg1.jpeg'; 
import image2 from '../../assets/img/bg13.jpeg'; 
import image3 from '../../assets/img/bg7.jpeg'; 
import image4 from '../../assets/img/bg10.webp';


//const ImageCarousel = () => {
class ImageCarousel  extends React.Component {
  
  constructor(props) {
    super(props);

    this.shiftAmount = 0;
    this.shiftImage = this.shiftImage.bind(this);
    this.intervalId = null; 
  }

  componentDidMount() {
    const imageList = [
      image1, image2, image4
    ];

    const carouselList = document.querySelector('.carousel-list');
    let currentIndex = 0;
    
    function showImage(index) {
        const img = carouselList.querySelector('img');
        img.src = imageList[index];
    }
    
    function nextImage() {
        currentIndex = (currentIndex + 1) % imageList.length;
        showImage(currentIndex);
    }
    
    // Change image every 3 seconds (adjust as needed)
    setInterval(nextImage, 13000);
    
    // Show the first image initially
    showImage(currentIndex);
    this.intervalId = setInterval(this.shiftImage, 200);
  }

  componentWillUnmount() {
    // Clear the interval when the component is about to unmount. Otherwise, shiftImage() will continue
    // to execute when component is unloaded. 
    if (this.intervalId) {
        clearInterval(this.intervalId);
    }
  }

  /**
   * Not sure if this works.
   */
  shiftImage = () => {
    const image = document.querySelector('.carousel-list img');
    this.shiftAmount += 50; // Adjust the value based on the desired shifting amount
    image.style.transform = `translateX(${this.shiftAmount}px)`;
    
    // Reset the shiftAmount when the image goes beyond the container's width
    if (this.shiftAmount >= image.width) {
      this.shiftAmount = -image.width;
    }
  }
  /*
  useEffect(() => {
    const carouselList = document.querySelector('.carousel-list');
    const images = carouselList.getElementsByTagName('li');
    let currentIndex = 0;
    
    function showImage(index) {
        for (let i = 0; i < images.length; i++) {
            images[i].style.opacity = i === index ? '1' : '0';
        }
    }
    
    function nextImage() {
        currentIndex = (currentIndex + 1) % images.length;
        showImage(currentIndex);
    }
    
    // Change image every 3 seconds (adjust as needed)
    setInterval(nextImage, 9000);
    
    // Show the first image initially
    showImage(currentIndex);
  }, []);
  */
 
  render () {
    return (
      <>
        <div className="carousel">
          <ul className="carousel-list">
            <li><img className="carousel-img-shift" src="" alt="Image" /></li>
          </ul>
        </div>
      </>
    )
  }
};

export default ImageCarousel