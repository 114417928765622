import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SimplePeerUserProvider from './SimplePeerUserContext';
import { IconMenuBar, MenuModes } from '../IconMenuBar/IconMenuBar';
import ChatSP from './ChatSP';

const ChatSPRoom = () => {
  const navigate = useNavigate();

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url);
  }

  return (
    <>
      <SimplePeerUserProvider>
        <ChatSP />
      </SimplePeerUserProvider>

      <IconMenuBar
        onClickRotatingBall={handleBallClick} 
        menuMode={MenuModes.TINY_THEATER}
      /> 
    </>
  );
};

export default ChatSPRoom;
