import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // default language

  useEffect(() => {
    const browserLang = navigator.language || navigator.userLanguage;
    setLanguage(browserLang.startsWith('zh') ? 'zh' : 'en');
    console.log(browserLang);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
