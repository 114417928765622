/**
 * Basic muuri
 */
import React, { useEffect, useRef } from 'react';
//import 'muuri/dist/muuri.min.css';
import Muuri from 'muuri';
import './LabMuuriList.css'


const MuuriGrid = () => {
  const items = ['item1', 'item2', 'item3'];
  const gridRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // const fullGrid = new Muuri('.lab-container', {
    //   dragEnabled: true,
    // });

    const grid = new Muuri('.grid', {
      dragEnabled: true,
    });

    // Cleanup Muuri instance on component unmount
    return () => {
      grid.destroy();
      //fullGrid.destroy();
    };
  }, []);

  return (

    <div className="lab-container" ref={containerRef}>
      <div className="title">
        <div className="content">List Title</div>
      </div>
      <div className="grid" ref={gridRef}>

        {items.map((item, itemIndex) => (
          <div key={`${itemIndex}`} className="item">
            <div className="content">{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MuuriGrid;

/*
import React, { useEffect } from 'react';
import Muuri from 'muuri';
import Draggable from 'draggable';
import './LabMuuriList.css'


const MuuriGrid = () => {
  useEffect(() => {
    // Initialize Muuri grid
    const grid = new Muuri('.grid', {
      dragEnabled: true,
    });

    // Initialize Draggable for captions
    const captions = document.querySelectorAll('.caption');
    const draggableCaptions = new Draggable(captions, {
      draggable: '.grid-item',
      mirror: {
        constrainDimensions: true,
      },
    });

    // Handle Muuri layout on window resize
    const handleResize = () => {
      grid.refreshItems().layout();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      draggableCaptions.destroy();
      grid.destroy();
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div className="grid">
      <div className="grid-item">
        <div className="caption">Item 1</div>
   
      </div>
      <div className="grid-item">
        <div className="caption">Item 2</div>
   
      </div>
    
    </div>
  );
};

export default MuuriGrid;
*/