// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-manager-container {
  text-align: left;
  min-height: 100vh;
  /* display: flex; */
}

.file-manager-container .spacing {
  flex: 1 1;
  margin-left: 50%;
  margin-top: 0.1vh;
}

.file-manager-container .mf-folder {
  border: 1px solid #dcdcdc;
  border-bottom: none; /* Remove bottom border */
  border-right: none; /* Remove right border */
  padding: 4px;
  margin-left: 14px;
  margin-bottom: 0; /* Set margin-bottom to 0 */
}

.file-manager-container .folder-title {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.file-manager-container .file-name {
  margin: 0;
  padding: 0;
  margin-left: 14px;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/FileExplorer/DirectoryLab.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB,EAAE,yBAAyB;EAC9C,kBAAkB,EAAE,wBAAwB;EAC5C,YAAY;EACZ,iBAAiB;EACjB,gBAAgB,EAAE,2BAA2B;AAC/C;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".file-manager-container {\n  text-align: left;\n  min-height: 100vh;\n  /* display: flex; */\n}\n\n.file-manager-container .spacing {\n  flex: 1;\n  margin-left: 50%;\n  margin-top: 0.1vh;\n}\n\n.file-manager-container .mf-folder {\n  border: 1px solid #dcdcdc;\n  border-bottom: none; /* Remove bottom border */\n  border-right: none; /* Remove right border */\n  padding: 4px;\n  margin-left: 14px;\n  margin-bottom: 0; /* Set margin-bottom to 0 */\n}\n\n.file-manager-container .folder-title {\n  cursor: pointer;\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.file-manager-container .file-name {\n  margin: 0;\n  padding: 0;\n  margin-left: 14px;\n  margin-top: 6px;\n  margin-bottom: 6px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
