/**
 * VideoGrid is container of VideoCard.
 */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiFileX } from '../../../comjs/filex.api/filex-api.js';
import VideoCard from './VideoCard';
import styles from './VideoGrid.module.css';


const VideoGrid = ({ 
    relpath=null, 
    briefView=false,  // Limit items displayed 
    onClickVideo=null, 
    onVideoEnd=null,
    onClickFolder=null, 
    ytEditMode=false
  }) => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState(null);

  // Handle single play within VideoGrid. It tracks currently playing video which is set
  // by child VideoCard. This is achieved by passing setter function setPlayingVideo to
  // child VideoCard when clicking. 
  // Therefore, all children VideoCard knows payingVideo. It can use it to compared its own
  // video. If not matching, it should not in playing mode. The clicked VideoCard, of cause, is
  // playing comparing this value to its owns video passed in as input parameter.
  const [playingVideo, setPlayingVideo] = useState(null);

  // This is to make sure correct columns of videos are displayed by knowing that grid is
  // loaded, and then measure its size. 
  const [columns, setColumns] = useState(4);
  const gridRef = useRef(null);

  const handleOnClickVideo = (evt, clickedVideoInfo) => {
    //console.log('Video clicked:', clickedVideoInfo);

    if (onClickVideo) {
      onClickVideo(evt, clickedVideoInfo);
    }
  };

  const handleOnVideoEnd = (title) => {
    setPlayingVideo(null);
  };

  useEffect(() => {
    try {
      const api = new ApiFileX();
      api.getYtVideo(relpath).then(async (retData) => {
        if (retData && retData.status) {
          const retValDirInfo = retData.data[0];
          const videoList = retValDirInfo.dir_files;
          const videosOnly = videoList.filter(item => item.file_type === 2);
          setVideos(videosOnly);
        } else {
          console.error("initDirView failed due to server return null.");
        }
      });
    } catch (error) {
      console.error('Error submitting URL:', error);
      alert('Failed to submit URL.');
    }
  }, [relpath]);

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url);
  }

  useEffect(() => {
    const updateColumns = () => {
      if (gridRef.current) {
        const gridContainer = gridRef.current;
        const columnCount = getComputedStyle(gridContainer).getPropertyValue('grid-template-columns').split(' ').length;
        setColumns(columnCount);
      }
    };

    const handleResize = () => {
      updateColumns();
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Update columns when gridRef is set
    if (gridRef.current) {
      updateColumns();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [videos]);


  const isMobile = window.innerWidth <= 430;
  const maxVideos = briefView ? (isMobile ? columns * 3 : columns) : videos.length;
  const briefViewClass = briefView ? (isMobile ? styles['brief-view'] + ' ' + styles['mobile'] : styles['brief-view'] + ' ' + styles['desktop']) : '';

  return (
    <>
      {videos && (
        <div ref={gridRef} className={`${styles['div-video-grid']} ${briefViewClass}`}>
          {videos.slice(0, maxVideos).map((video, index) => (
            <VideoCard
              key={index}
              channelMediaPath={video.channel_media_path}
              yvVideoUrl={video.youtube_url}
              movie_title={video.file_title}
              clipthumbnail={video.thumbnail}
              duration={video.duration}
              media_cp_uri={video.media_cp_uri}
              channelVideoType={'yv'}   // Play icon to be red
              onVideoClick={handleOnClickVideo}
              onVideoEnd={handleOnVideoEnd}
              isPlaying={playingVideo}
              setPlayingVideo={setPlayingVideo}
              playAllowed={true}
              relpath={relpath}
              media_id={video.youtube_id}
              editMode={ytEditMode}
              playButton2PageYoutbubePlayer={true}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default VideoGrid;
