import React, { useState } from 'react';
import { f2fWait } from '../../comjs/js/base.js';
import { ApiFileX } from '../../comjs/filex.api/filex-api';
import './YoutubeEditor.css';

const YouTubeEditor = ({
  relpath = null,
  onNewVideoCreated = () => {},
}) => {
  const [url, setUrl] = useState('');
  const [ytId, setYtId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (event) => {
    setUrl(event.target.value.trim());
  };
  
  const isYouTubeUrl = (url) => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return pattern.test(url);
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!url) {
      setError('Please enter a youtube link!');
      return;;
    }

    if (!isYouTubeUrl(url)) {
      setError('This is not a youtube link!');
      return;
    }

    const wait = new f2fWait('.youtube-editor'); // Use the optional selector
    wait.startWait(); // Start the wait cursor
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const api = new ApiFileX();
      const retData = await api.saveYtVideo(url, relpath);
      if (retData && retData.status) {
        setSuccess(true);

        if (onNewVideoCreated) {
          onNewVideoCreated(evt, retData.data);
        }
      } else if (retData && !retData.status && retData.message) {
        setError(retData.message);
      } else {
        setError("Failed to save the YouTube link. Please try again.");
      }
    } catch (error) {
      setError('Failed to submit URL. ' + error);
    } finally {
      setLoading(false);
      wait.stopWait(); // Stop the wait cursor
    }
  };

  const handleAddNew = () => {
    setUrl('');
    setError(null);
    setSuccess(false);
  };

  return (
    <div className="youtube-editor">
      {loading && <div className="overlay">Please wait...</div>}
      {!success && (
        <>
          <textarea
            value={url}
            onChange={handleInputChange}
            placeholder="Paste YouTube link here..."
            className="youtube-editor-textarea"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          />
          <div className="button-container">
            <button onClick={handleSubmit} disabled={loading} className="button">Add Youtube Video</button>
          </div>
        </>
      )}
      {error && <div className="error-message">{error}</div>}
      {success && (
        <div className="success-message">
          Video added successfully!
          <button onClick={handleAddNew} className="button add-button">+</button>
        </div>
      )}
    </div>
  );
};

export default YouTubeEditor;
