import React from 'react';
import ImageCarousel from './ImageCarousel';
import './LoginBg.css'
import LoginName from '../LoginName/LoginName';
import LoginKey from '../LoginKey/LoginKey';
import { UserInfoContextProvider } from './UserInfoContext';
import { LanguageProvider } from '../Localization/LanguageContext/LanguageContext';
import { AppContext } from '../../AppContext';
import { CombinedProvider } from '../System/CombinedContext';


class LoginBg extends ImageCarousel {
  static contextType = AppContext;

  /*
    This is background page that login panel (name, password key) are on top of it.
    This page is derived from ImageCarousel, which has nothing to do with login.
  */
  constructor(props) {
    super(props);
    this.state = {
      isLoginName: false,
      isLoginKey: false,
      name: null
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.showLoginName(false);
  }

  showLoginName = (loginStatus, name) => {
    console.log(`isLoginName:`, loginStatus);

    this.setState({
      isLoginName: loginStatus,
      name: name
    });

    const { isLoginName, isLoginKey, newname } = this.state;
    console.log(`isLoginKey:`, isLoginKey);
    console.log(`isLoginName:`, isLoginName);
  };

  
  handleLoginName = (status, data) => {
    console.log(`Event received from LoginName:`, data);
    
    if (status) {
      this.showLoginName(true, data.data);
    }
  };

  handleLoginKey = (status, data) => {
    console.log(`Event received from LoginKey:`, data);

    if (status) {
      this.setState({
        isLoginKey: status
      });
      const { isLoginName, isLoginKey, name } = this.state;
      // After key login is successful, user is fully login.
      // It uses AppContext to update parent TwoPages.
      this.props.onLoginSuccess(name);
    }
  };

  render() {
    const { isLoginName, isLoginKey, name } = this.state;
 
    return (
      <div>
        {/* Call the parent's render method using super */}
        {super.render()}
        
        <CombinedProvider>
          <UserInfoContextProvider>
            {!isLoginName && <LoginName parentHandleLoginName={this.handleLoginName} />}
            {isLoginName && !isLoginKey && <LoginKey parentHandleLoginKey={this.handleLoginKey} />}
            {/* Both login are performed */}
            {isLoginName && isLoginKey && (
              <div className="curved-text-container">
                {name}
              </div>
            )}
          </UserInfoContextProvider>
        </CombinedProvider>
      </div>
    );
  }
}

export default LoginBg;
