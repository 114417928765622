/*
 * https://fontawesome.com/v4.7.0/icons/
 * 
 * <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
 */
import $ from 'jquery';    // React only
import {f2fBase} from '../js/base.js'

export class floatToolbar extends f2fBase {
  static get CSS() {
    return {
      name: './float-toolbar.css',
      import: import('./float-toolbar.css')
    };
  }

  static get FONTAWESOME_470_CSS() {
    return {
      name: '../../3rdparty/font-awesome-4.7.0/css/font-awesome.min.css',
      import: import('../../3rdparty/font-awesome-4.7.0/css/font-awesome.min.css')
    };
  }
  
  constructor(divId, options=null) {
    // awosome css is used for template class, so that it displayed properly.
    const TMPL_BUTTON = ({iconId, iconName, buttonSize, iconSize, iconShape, iconShow}) => `
      <a id="${iconId}" class="${buttonSize} ${iconShape} ${iconShow}" href="#">
        <i class="fa fa-${iconName} ${iconSize}"></i>
      </a>
    `;
    
    const TMPL_DROPDOWN_MENU = `
      <div class="dropdown-menu">
        <a href="#">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
      </div>
    `;
    
    const TMPL_MENU_ITEM = `
        <a href="#">Link 1</a>
    `;

    super(options, divId);

    //this.restApi = new loi.Class.LoiBase();
    this.restApi = null;

    this.tmpl_DROPDOWN_MENU = TMPL_DROPDOWN_MENU;
    
    // Font size and button size are independent.
    // Font size: normal (undefined), small, large.
    // Button size: normal (undefined), large.
    var defaultSettings = {
        size: 'normal',
        shape: null,
        iconList: [
            {iconId: 'add',
             iconName: 'plus',
             iconShow: true,
            },
            {iconId: 'add',
             iconName: 'plus',
             iconSize: 'small',
            }],
        event_positionChange: null,
        event_click: null,
      };

    this.settings = $.extend(defaultSettings, options);
    
    this.loadCSS(floatToolbar.CSS);
    this.loadCSS(floatToolbar.FONTAWESOME_470_CSS);

    // Create buttons defined in settings.
    let iconListDef = this.settings.iconList;
    let buttonList = [];

    let buttonSize = 'normal';
    if (!(this.settings.size === undefined))
      buttonSize = this.settings.size;


    for (let i = 0; i < iconListDef.length; i++) {
      let iconSize = 'normal';
      if (!(iconListDef[i].iconSize === undefined)) {
        iconSize = iconListDef[i].iconSize;
      } 
      
      // Create dropdown menu.
      buttonList.push({iconId: iconListDef[i].iconId,
        iconName: iconListDef[i].iconName,
        buttonSize: buttonSize,
        iconSize: iconSize,
        iconShape: this.settings.shape,
        iconShow:  (iconListDef[i].iconShow === undefined || iconListDef[i].iconShow === true) ? 'show' : 'hide'});
    }
    
    // Create buttons
    let insertButtons = buttonList.map(TMPL_BUTTON).join('');
    this.$div.html(insertButtons);

    // Create wrapper div
    this.$div.wrap( "<div class='wrapper-toolbar'></div>" );
    this.$wrapDiv = $(this.$div.parent());
    
    /*
    // After button is created, create submenu.
    for (let i = 0; i < iconListDef.length; i++) {
      // Create and attach submenu under main menu.
      // Add sub menu next to block toolbar container div.
      let dropdown = false;
      let dropdown_html = '';
      if (!(iconListDef[i].dropdown === undefined)) {
        dropdown = iconListDef[i].dropdown;
        
        if (dropdown) {
          dropdown_html = TMPL_DROPDOWN_MENU;
        }
      }
      
      if (dropdown) {
        //$(dropdown_html).insertAfter(this.$div);
        this.$div.after(dropdown_html);
      }
      
    }
    */
    //if (iconListDef[i].event_click)
    //  this.$elementDiv.click($.proxy(iconListDef[i].event_click, this));
    
    // Add this class which defines toolbar appearance.
    this.$div.addClass('icon-bar');
    
    // This class not compatible with other css.
    //this.$div.addClass('icon-bar-dropdown-pos-parent');
    
    this.hide();
    this.initHoverMenu();
    
    this.submenu_created = false;
  }
  
  destroy() {
    this.$toolbarDiv.remove();
    this.unloadCSS(floatToolbar.CSS);
    this.unloadCSS(floatToolbar.FONTAWESOME_470_CSS);
  }
  
  get $toolbarDiv() {
    return this.$wrapDiv;
  }
  /**
   * Dropdown menu button hover to show sub menu.
   * The reason not create in constructor is that css hover will show
   * menu if hover wrap div. But popup menu only hovering on dropdown menu
   * is needed.
   */
  initHoverMenu() {
    // Initialize toolbar button dropdown menu. Submenu is not created until
    // hovering.
    let buttons = this.settings.iconList;
    
    for (let i = 0; i < buttons.length; i++) {
      if (!(buttons[i].dropdown === undefined) && buttons[i].dropdown)  {
        let menuButton = this.$div.find('a#' + buttons[i].iconId);
        
        // Create hover handler for dropdown buttons
        $(menuButton).hover(this.dropdownHoverIn.bind(this),
            this.dropdownHoverOut.bind(this));
      }
    }
  }
  
  /**
   * Submenu is created next to toolbar container div.
   */
  dropdownHoverIn(evt) {
    //evt.preventDefault();
    
    // Get hovering button.
    // target is anchor tag <a>.
    let anchor = $(evt.target);
    let iconId = $(anchor).attr('id');
    
    let subMenuDef = this._getSubMenuDef(iconId);
    
    if (subMenuDef && !this.submenu_created) {
      // From submenu definition to create submenu.

      this.$div.after(this.tmpl_DROPDOWN_MENU);
      this.submenu_created = true;
      /*
      // Create submenu on hover in. The destruction is NOT hover out of button.
      // Destruction should be hover out of submenu container div.
      let submenuDiv = $(evt.target).parent().nextAll('div.dropdown-menu:first');
      $(submenuDiv).css('display','block');
      
      $(submenuDiv).hover(this.submenuHoverIn.bind(this),
          this.submenuHoverOut.bind(this));
      */
    }
    // let button = $(evt.target).parent();
    //let submenuDiv = $(evt.target).parent().find('div.dropdown-menu');
    //$(submenuDiv).css('display','block');
    
    // Figure out if it's submenu.
    

  }
  
  submenuHoverOut(evt) {
    
  }
  
  /**
   * Since submenu is next to toolbar container div, remove it when hover out.
   */
  dropdownHoverOut(evt) {
    //evt.preventDefault();
    //console.log('out');

    // let submenuDiv = $(evt.target).parent().find('div.dropdown-menu');
    // $(submenuDiv).css('display','none');
    let anchor = $(evt.target);
    let iconId = $(anchor).attr('id');
    
    let subMenuDef = this._getSubMenuDef(iconId);
    
    if (subMenuDef) {
      // If hover in creates a submenu, it will next element.
      //let submenu = this.$div.nextAll('.dropdown-menu:first')
      //$(submenu).remove();
    }

  }
  
  initButtonClickHandler(handlerList) {
    let status = true;
    
    // Merge handlers
    let buttonList = this.settings.iconList;
    for (let i = 0; i < buttonList.length; i++) {
      let button = handlerList.find(x => x.iconId === buttonList[i].iconId);
      
      if (button) {
        buttonList[i].clickHandler = button.clickHandler;
      } else {
        status = false;
      }
    }
    
    // Create event handler for child class defined buttons.
    // this.$div.children() is div container of <a> and sub menu <div>
    let buttons = this.$div.children();
    let clickHandlers = this.settings.iconList;
    
    for (let i = 0; i < buttons.length; i++) {
      let key = $(buttons[i]).attr('id');
      //let anchor = $(buttons[i]).children('a');
      //let key = $(anchor).attr('id');
      let obj = clickHandlers.find(x => x.iconId === key);
      if (obj) {
        $(buttons[i]).click($.proxy(obj.clickHandler, 
            this));   // Bind to button's parent div, not button itself $(buttons[i]), so that this
                      // class method can be accessed.
      }
    }
    
    return status;
  }
  
  _getSubMenuDef(id) {
    let buttons = this.settings.iconList;
    let buttonDef = null;
    
    for (let i = 0; i < buttons.length; i++) {
      if (!(buttons[i].dropdown === undefined) && buttons[i].dropdown &&
          buttons[i].iconId == id)  {
        buttonDef = buttons[i];
        break;
        //let menuButton = this.$div.find('div.button > a#' + buttons[i].iconId);
        //$(menuButton).hover(this.dropdownHoverIn, this.dropdownHoverOut);
      }
    }
    
    return buttonDef;
  }
  
  _findButton(id) {
    return this.$div.find('#' + id);
  }
  
  get $toolbarDiv() {
    // Toolbar has parent <div class='wrapper-toolbar'> to handle submenu.
    return this.$div.parent();
  }
  
  get $prevElement() {
    return this.$toolbarDiv.prev();
  }
  
  get $nextElement() {
    return this.$toolbarDiv.next();
  }
  
  insertNextElement(ele) {
    let next = this.$nextElement;
    
    if (next) {
      ele.insertAfter(next);
    }
  }
  
  show() {
    this.$div.show();
  }
  
  hide() {
    this.$div.hide();
  }
  
  /**
   * Active means visible, including disable status.
   */
  getActiveStatus(id) {
    let $button = this._findButton(id);
    
    let activeStatus = false;
    if ($button) {
      if (!$button.hasClass('hide')) {
        activeStatus = true;
      }
    } else {
      throw 'Invalid button id!'
    }
    
    return activeStatus;
  }
  
  toggleButton(id, pushDown) {
    
  }
  
  enableButton(id, enable) {
    let $button = this._findButton(id);
    
    if ($button) {
      if (enable) {
        if ($button.hasClass('disable')) {
          $button.removeClass('disable');
        }
      } else {
        if (!$button.hasClass('disable')) {
          $button.addClass('disable');
        }
      }
    } else {
      throw 'Invalid button id!'
    }
  }
  
  showButton(id, show) {
    let $button = this._findButton(id);
    
    if ($button) {
      if (show) {
        let cls = $button.attr('class');
        if ($button.hasClass('hide')) {
          $button.removeClass('hide');
        }
      } else {
        if (!$button.hasClass('hide')) {
          $button.addClass('hide');
        }
      }
    } else {
      throw 'Invalid button id!'
    }
  }
  
  
  hideAllButtons() {
    let buttonList = this.settings.iconList;
    for (let i = 0; i < buttonList.length; i++) {
      this.showButton(buttonList[i].iconId, false);
    }
  }
  
  event_click(evt) {
    evt.preventDefault();
    /*
    this.checkStatus = this.$inputCheck.is(':checked');
    
    // Process channels
    if (typeof window.channel_list !== 'undefined' && 
        window.channel_list.length > 0) {
      // Enable edit for the channels.
      for (let i = 0; i < window.channel_list.length; i++) {
        let channel = window.channel_list[i];
        channel.enableEdit(this.checkStatus);
      }
    }
    */
    // Youtube. Add + button.
    //this.settings.event_click;
  }
}
