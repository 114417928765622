import React,  { useState } from 'react';
import AlbumIntro from '../Channels/AlbumIntro';
import YouTubePlayerAdv from './YoutubePlayerAdv';
import './TestYtPlayer.css';


const TestYtPlayer = ({ onStreamReady }) => {
  const [showIntroduction, setShowIntroduction] = useState(true);

  // Hardcoded parameters for testing
  const ytId = 'n152tgh2yMk'; // Example YouTube video ID
  const relpath = ''; // Example relative path

  const handleVideoStart = () => {
    console.log('Video started');
  };

  const handleVideoEnd = () => {
    console.log('Video ended');
  };


  const handleIntroStart = () => {
    // console.log('start');
    setShowIntroduction(true);
  }

  const handleIntroEnd = () => {
    // console.log('end');
    setShowIntroduction(false);
  }

  return (
    <>
    {showIntroduction ? (
      <AlbumIntro 
        onStart={handleIntroStart} 
        onFinish={handleIntroEnd} 
        title={"videoTitle"}
        introTimeout={5000} />
    ) : (
      <div className="test-yt-player-container">
        <YouTubePlayerAdv
          ytId={ytId}
          relpath={relpath}
          onVideoStart={handleVideoStart}
          onVideoEnd={handleVideoEnd}
          onStreamReady={onStreamReady}
        />
      </div>
    )}
    </>
  );
};

export default TestYtPlayer;
