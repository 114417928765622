// ChannelSource.js
import React, { useEffect, useRef } from 'react';
import Muuri from 'muuri';
import './MuuriList.css';


const ChannelSource = ({ children }) => {
  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refreshItems().layout();
    }
  }, [children]);

  return (
    <div className="channel-source">
      {React.cloneElement(children, {
        className: 'muuri-item', // Add Muuri-specific class
        ref: gridRef,
      })}
    </div>
  );
};

export default ChannelSource;
