import React, { useEffect, useState } from 'react';

function ResponsiveDiv() {
  const [orientation, setOrientation] = useState('');

  const handleOrientationChange = () => {
    if (window.orientation === 0 || window.orientation === 180) {
      setOrientation('Portrait');
    } else if (window.orientation === 90 || window.orientation === -90) {
      setOrientation('Landscape');
    }
  };

  useEffect(() => {
    // Initial orientation check
    handleOrientationChange();

    // Listen for orientation changes
    window.addEventListener('orientationchange', handleOrientationChange);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <div className="orientation-container">
      <h1>Device Orientation</h1>
      <p>Current orientation: {orientation}</p>
    </div>
  );
}

export default ResponsiveDiv;
