// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DroppableVideoGrid_div-video-grid__T2M\\+A {
  position: relative;
}

.DroppableVideoGrid_paste-input-container__\\+xlCN {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ddd;
  z-index: 10;
}

.DroppableVideoGrid_paste-input__BX4Vz {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}

.DroppableVideoGrid_ok-button__up2VS {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.DroppableVideoGrid_ok-button__up2VS:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/Components/YoutubeView/YtPlaylistDnD/DroppableVideoGrid.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".div-video-grid {\n  position: relative;\n}\n\n.paste-input-container {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  padding: 10px;\n  background-color: #f1f1f1;\n  border-bottom: 1px solid #ddd;\n  z-index: 10;\n}\n\n.paste-input {\n  flex-grow: 1;\n  padding: 5px;\n  margin-right: 10px;\n}\n\n.ok-button {\n  padding: 5px 10px;\n  background-color: #4CAF50;\n  color: white;\n  border: none;\n  cursor: pointer;\n}\n\n.ok-button:hover {\n  background-color: #45a049;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div-video-grid": `DroppableVideoGrid_div-video-grid__T2M+A`,
	"paste-input-container": `DroppableVideoGrid_paste-input-container__+xlCN`,
	"paste-input": `DroppableVideoGrid_paste-input__BX4Vz`,
	"ok-button": `DroppableVideoGrid_ok-button__up2VS`
};
export default ___CSS_LOADER_EXPORT___;
