/**
 * It is able to play yt and local host video inside card with same size of the card.
 * 
 * 1) Identify different types of video. 'yvVideoUrl' starts with '/yv/...' or /lv/...'.
 * 2) By default clicking 3 parts of VideoCard will display small video size.
 * 3) Trigger external player is possible. If enabled, 
 * 3.1) Clicking video contens will not trigger external player.
 * 3.2) External player is will be trigger by clicking thumbnail and play button.
 * 
 */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ApiFileX } from '../../../comjs/filex.api/filex-api.js';
import { f2futil } from '../../../comjs/utils/utils.js';

// This hood function from VideoPlayContext is to handle single video play on page across multiple
// VideoGrid by tracking playing video in global context VideoPlayContext.
//
// For single video play within VideoGrid, it uses different approach. VidoeCard has access to
// a state in VideoGrid, which stores playing vidoe title.
import { useVideoPlayPage } from '../../System/VideoPlayPageContext';
import styles from './VideoCard.module.css';



const VideoCard = ({ 
    channelMediaPath,
    yvVideoUrl=null,   // For Yt only
    lvVideoUrl=null,
    movie_title, 
    clipthumbnail, 
    duration, 
    onVideoClick, 
    onVideoEnd, 

    media_cp_uri=null,

    // Player: lv, yv.
    channelVideoType,
    playButton2PageYoutbubePlayer=false,  // Trigger player page. Otherwise, player button is same as clicking
                                  // thumbnail and content, to trigger local small player.
                                  // Youtube grid view only. VideoGrid.
                                  // Channel manager ChannelMan does not use it as channel
                                  // playlist video playering buttong clicking does not have 2 page
                                  // player. In this case, user has to click channge title play button
                                  // to start channel play. This is different from youtube grid view.

    // It manages 2-page player playing outside of VideoCard. Borders is highlighted.
    is2PagePlayerPlaying=false,

    // It manages player playing inside VideoCard.
    // The following is for both single play within VideoGrid, and across multiple VideoGrids
    isPlaying,        // Movie id passed in from parent, which is used to compare 'movie_title' 
    setPlayingVideo,  // Parent state setter. This is how to notify parent that 
                      // this card started to play a new video with title so that other card can stop.
    
                      // The following is passed in parameter to setup VideoCard, and these info will be used
    // when this item is clicked, which contains info of which one is clicked.
    uuid=null, 
    relpath=null, 
    media_id=null,
    playAllowed = false,
    editMode=false,
    forceDisablePlay=false   // Regardless of edit mode, hide play button and disable play handlers.
  }) => {
  let enablePlay = !editMode;

  if (forceDisablePlay) {
    enablePlay = false;
  }

  const navigate = useNavigate();
  
  // APIs and utilities.
  const api = new ApiFileX();
  const util = new f2futil();

  // Handle single video play across multiple VideoGrid.
  // useVideoPlayPage's parameters playingVideoPage, setPlayingVideoPage need exactly the same information
  // as: isPlaying, which is currently playing video, but in different format.
  //
  // So, isPlaying is tracked in parent VideoGrid. playingVideoPage is stracked in global context which
  // coordinates multiple VideoGrid objects.
  //
  // If looking VideoGrid, the value isPlaying inside VideoGrid is set by VideoCard, after clicking. 
  //
  // Therefore, global context should be the same as isPlaying mechanism to set playingVideoPage with
  // setPlayingVideoPage. playingVideoPage exists in global video play context, which changes according
  // to VideoCard click.
  const { playingVideoPage, setPlayingVideoPage,
          playing2PagePlayer, setPlaying2PagePlayer } = useVideoPlayPage();
  const isPlayingPageSig = `${media_id}-${relpath}`;

  const [autoPlay, setAutoPlay] = useState('true');
  const [videoSrc, setVideoSrc] = useState('');

  const [isTruncated, setIsTruncated] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const textRef = useRef();
  const cardRef = useRef();
  const contextMenuRef = useRef(null);

  // If 2-page player is playing, it highlight which video is play in grid.
  const [isHighlighted, setIsHighlighted] = useState(is2PagePlayerPlaying);

  // Handle single vidle playing across page, multiple VideoGrid.
  /*
  useEffect(() => {
    if (playingVideoPage !== isPlayingPageSig) {
      setPlayingVideoPage(null);
    }
  }, [playingVideoPage, setPlayingVideoPage, relpath, media_id]);
  */
  useEffect(() => {
    // This video is 2-page player playing.
    if (playing2PagePlayer && playingVideoPage === isPlayingPageSig) {
      setIsHighlighted(true);
    } else {
      setIsHighlighted(false);
    }
  }, [playing2PagePlayer, setPlaying2PagePlayer]);

  // Figure out video type
  useEffect(() => {
    // if (!isPlaying) return;

    // If VideoCard is used for displaying channel video
    if (!channelMediaPath) return;

    let newFormat = true;
    let urlInfo = null;
    try {
      urlInfo = util.parseMediaUrl(channelMediaPath);
    } catch (error) {
      newFormat = false;
    }

    let videoSource = null;
    let mediaRelpath = channelMediaPath;
    if (newFormat && urlInfo.type === 'lv') {
      videoSource = `/media_lv_video_root/${lvVideoUrl}`;
      setVideoSrc(videoSource);
    } else if (urlInfo.type === 'lv') {
      // ??
      videoSource = `/media_video_root/${urlInfo.relpath}`;
      setVideoSrc(videoSource);
    } // 'yv' has no videoSrc.
  }, []);


  // Single video play in VideoGrid.
  useEffect(() => {
    // When to stop current VideoCard?
    // 1) If clicked other VideoCard within VideoGrid. 
    //    setPlayingVideo(null); will make !isPlaying = true. This happend when vide finishes.
    // 2) If clicked a VideoCard in another VideoGrid.
    if (!isPlaying) {
      setPlayingVideo(null);
    }
  }, [isPlaying, setPlayingVideo]);

  useEffect(() => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [movie_title]);

  const handleClickPlayButton = (evt) => {
    if (!enablePlay || !playAllowed || editMode) return;

    if (!playButton2PageYoutbubePlayer) {
      handleClickPlay(evt);
    } else {
      // Trigger two-page player.
      let newUrl = `/ytplayerdirview/${media_id}`;
      const enTitle = api.encodeUrlToBase64_UrlSafe(movie_title);
      newUrl += `/${enTitle}`;

      const paramRelPath = relpath || '';
      if (paramRelPath) {
        newUrl += `/${paramRelPath}`;
      }

      const decodeRelPath = api.decodeBase64ToUrl(relpath);
      const dirname = api.getRightmostSubdir(decodeRelPath);
      const enDirName = api.encodeUrlToBase64_UrlSafe(dirname);

      // Root has no relpath and has no dir name.
      if (enDirName) {
        newUrl += `/${enDirName}`;
      }
  
      // Update browser url, so that it can be refreshed.
      window.history.pushState(null, null, newUrl);
      navigate(newUrl);
    }
  };

  const handleClickPlay = (evt) => {
    if (!enablePlay || !playAllowed || editMode) return;

    evt.preventDefault();
    evt.stopPropagation(); 
    
    // The click will 'broadcast' clicked video to all VideoCard in VideoGrid.
    setPlayingVideo(movie_title);
    
    // The click will 'broadcast' clicked video to global context's VideoCard.
    // isPlaying === movie_title && playingVideoPage === isPlayingPageSig will determine if a VideoCard
    // should play across multiple VideoGrid.
    setPlayingVideoPage(isPlayingPageSig);

    if (onVideoClick) {
      const clickedVideoInfo = {
        title: movie_title,
        relpath: relpath,
        yt_id: media_id
      }
      onVideoClick(evt, clickedVideoInfo);
    }
  };

  const getEmbedUrl = (url) => {
    const videoId = new URL(url).searchParams.get('v');
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  };

  const handleVideoEnd = () => {
    setPlayingVideo(null);
    setPlayingVideoPage(null);
    if (onVideoEnd) {
      onVideoEnd(movie_title);
    }
  };

  const handleVideoError = () => {
    setPlayingVideo(null);
    setPlayingVideoPage(null);
    if (onVideoEnd) {
      onVideoEnd(movie_title);
    }

    // if (onVideoError) {
    //   onVideoError();
    // }
  };
  // const handleContextMenu = (evt) => {
  //   evt.preventDefault();
  //   navigator.clipboard.writeText(`${relpath}, ${media_id}`);
  //   alert('Video link copied to clipboard!');
  // };

  const dataInfo = uuid && media_id ? 
      JSON.stringify({ uuid, relpath: relpath || "", media_id }) : null;

  const handleContextMenu = (evt) => {
    // enablePlay = !editMode
    // enablePlay=true means not in edit mode. Edit mode disables play.
    if (enablePlay) return; // Disable context menu when enablePlay is false.
                             // forceDisablePlay = true when playing channel items.

    // Context menu shows up only in edit mode
    evt.preventDefault();

    if (contextMenuRef.current) {
      contextMenuRef.current.focus();
    }
    
    const clickX = evt.clientX;
    const clickY = evt.clientY;
    setContextMenu({ visible: false, x: clickX, y: clickY }); // First, hide any existing menu
    
    setTimeout(() => {
      if (contextMenuRef.current) {
        contextMenuRef.current.focus();
      }

      setContextMenu({ visible: true, x: clickX, y: clickY }); // Then, show the new menu
    }, 0);
  };
    
  const handleCopyLink = () => {
    let copyLink = null;

    if (yvVideoUrl) {
      copyLink = `/yv/${media_cp_uri}`;
    } else if (lvVideoUrl) {
      copyLink = `/lv/${media_cp_uri}`;
    }
    // navigator.clipboard.writeText(`${relpath}, ${media_id}`);
    navigator.clipboard.writeText(copyLink);
    alert('Video link copied to clipboard!' + copyLink);
    closeContextMenu();
  };

  // const handleContextMenu = (event) => {
  //   event.preventDefault();
  //   if (contextMenuRef.current) {
  //     contextMenuRef.current.focus();
  //   }
  // };

  const closeContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  useEffect(() => {
    // Close the context menu when clicking outside
    const handleClickOutside = (event) => {
      if (contextMenu.visible && cardRef.current && !cardRef.current.contains(event.target)) {
        closeContextMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu]);

  /* ============== Notes ============
  The meaning of isPlaying == movie_title && isPlayingPage !== isPlayingPageSig 

  1) Video playing happends in current VideoGrid: isPlaying == movie_title. 
  2) If video starts to play in another VideGrid, isPlaying == movie_title does not change in this VideoCard
  3) How current video stops is another video starts in anther VideoGrid? For this VideoCard, 
     anoterh VideoCard will set isPlayingPage to be false for this VideoCard which will
     end this VideoCard.
  */
  return (
    <div
    className={`${styles['video-cell']} ${isHighlighted ? styles['highlighted'] : ''} ${editMode ? styles['edit-mode'] : ''}`}
      {...(dataInfo ? { 'data-info': dataInfo } : {})}
      onContextMenu={handleContextMenu}
      ref={cardRef}
    >
      <input type="hidden" className={styles['video-url']} value={yvVideoUrl} />
      {isPlaying === movie_title && playingVideoPage === isPlayingPageSig ? (
        <div className={styles['movie']}>
          {channelVideoType === 'lv' ? (
            <video
              className={`${styles['sized-video']} ${styles['show']}`}
              autoPlay={autoPlay}
              defaultMuted
              playsInline
              controls
              onEnded={handleVideoEnd}
              onError={handleVideoError}
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : channelVideoType === 'yv' ? (
            <iframe
              width="100%"
              height="100%"
              src={getEmbedUrl(yvVideoUrl)}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={movie_title}
              onEnded={handleVideoEnd}
            ></iframe>
          ) : null}
        </div>
      ) : (
        <>
          <div className={styles['movie']}>
            <a onClick={handleClickPlay}>
              <img className={styles['img-thumbnail-video']} src={`data:image/png;base64, ${clipthumbnail}`} alt="Video Thumbnail" />
            </a>
            <div className={styles['time-label']}>{duration}</div>
          </div>
          {enablePlay && (
            <div className={`${styles['div-yt-play-button']} ${channelVideoType === 'yv' ? styles['div-button-color-yv'] : styles['div-button-color-lv']}`}>
              <a onClick={handleClickPlayButton}>
                <div className={styles['play-icon']}></div>
              </a>
            </div>
          )}
        </>
      )}
      <div
        className={styles['movie-contents']}
        ref={textRef}
        data-tooltip-id={isTruncated ? `tooltip-${uuid || yvVideoUrl}` : undefined}
        data-tooltip-content={isTruncated ? movie_title : undefined}
      >
        <a className={styles['a-content']} href={yvVideoUrl} onClick={handleClickPlay}>
          {movie_title}
        </a>
      </div>
      {isTruncated && (
        <Tooltip
          id={`tooltip-${uuid || yvVideoUrl}`}
          className={styles['tooltip-custom']}
          place="top"
          delayShow={1500}
        />
      )}
      {contextMenu.visible && (
        <div
          className={styles.contextMenu}
          style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px`, display: 'block' }}
        >
          <div
            className={styles.contextMenuItem}
            ref={contextMenuRef}
            onContextMenu={handleContextMenu}
            onClick={handleCopyLink}
            tabIndex={0}
          >
            Copy channel link
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoCard;
