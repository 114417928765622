import React from 'react';
import { useOnlineUsers } from './OnlineUsersContext'; // Adjust the import path as necessary
import './OnlineUsers.css'; // Import the CSS file

const OnlineUsers = ({ excludeSelf = false }) => {
  const { onlineUsers, selfUserId } = useOnlineUsers();

  const filteredUsers = excludeSelf
    ? onlineUsers.filter(user => user !== selfUserId)
    : onlineUsers;

  return (
    <div className="online-users">
      {filteredUsers.map((user, index) => (
        <div key={`${user}-${index}`} className="online-user">
          <span className="username">{user}</span>
          <img 
            src={`https://avatars.dicebear.com/api/human/${user}.svg`} 
            alt={user} 
            className="user-icon" 
          />
        </div>
      ))}
    </div>
  );
};

export default OnlineUsers;
