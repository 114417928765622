// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yt-one-dir-view {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.delete-button {
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent gray background */
  border: none; /* No border */
  color: white; /* White color for the 'x' */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold; /* Make 'x' thicker */
  cursor: pointer; /* Pointer cursor on hover */
  width: 24px; /* Fixed width */
  height: 24px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove default padding */
  border-radius: 50%; /* Make the button rounded */
}

.delete-button:hover {
  background-color: rgba(0, 0, 0, 0.45); /* Darker gray background on hover */
}`, "",{"version":3,"sources":["webpack://./src/Components/YoutubeView/Section/YtDirectory.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,0CAA0C,EAAE,qCAAqC;EACjF,YAAY,EAAE,cAAc;EAC5B,YAAY,EAAE,4BAA4B;EAC1C,eAAe,EAAE,+BAA+B;EAChD,iBAAiB,EAAE,qBAAqB;EACxC,eAAe,EAAE,4BAA4B;EAC7C,WAAW,EAAE,gBAAgB;EAC7B,YAAY,EAAE,iBAAiB;EAC/B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU,EAAE,2BAA2B;EACvC,kBAAkB,EAAE,4BAA4B;AAClD;;AAEA;EACE,qCAAqC,EAAE,oCAAoC;AAC7E","sourcesContent":[".yt-one-dir-view {\n  position: relative;\n  width: 100%;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.delete-button {\n  position: absolute;\n  top: 4px;\n  left: 4px;\n  background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent gray background */\n  border: none; /* No border */\n  color: white; /* White color for the 'x' */\n  font-size: 16px; /* Adjust font size as needed */\n  font-weight: bold; /* Make 'x' thicker */\n  cursor: pointer; /* Pointer cursor on hover */\n  width: 24px; /* Fixed width */\n  height: 24px; /* Fixed height */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0; /* Remove default padding */\n  border-radius: 50%; /* Make the button rounded */\n}\n\n.delete-button:hover {\n  background-color: rgba(0, 0, 0, 0.45); /* Darker gray background on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
