// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.dialog-actions {
  margin-top: 20px;
}

.dialog-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px; /* Adjusted padding to make the button smaller */
  margin: 0 5px; /* Adjusted margin to reduce space between buttons */
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem; /* Reduced font size */
  transition: background 0.3s;
}

.dialog-button:hover {
  background: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Util/ConfirmationDialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB,EAAE,gDAAgD;EACnE,aAAa,EAAE,oDAAoD;EACnE,kBAAkB;EAClB,eAAe;EACf,mBAAmB,EAAE,sBAAsB;EAC3C,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".dialog-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.dialog {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  width: 300px;\n  text-align: center;\n}\n\n.dialog-actions {\n  margin-top: 20px;\n}\n\n.dialog-button {\n  background: #007bff;\n  color: white;\n  border: none;\n  padding: 5px 10px; /* Adjusted padding to make the button smaller */\n  margin: 0 5px; /* Adjusted margin to reduce space between buttons */\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 0.875rem; /* Reduced font size */\n  transition: background 0.3s;\n}\n\n.dialog-button:hover {\n  background: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
