import React from 'react';
import TwoPageOverlay from './TwoPageOverlay';
import './TestTwoPageOverlay.css';

const TestTwoPageOverlay = () => {
  return (
    <TwoPageOverlay
      keyValue="test"
      onPage1Visible={() => console.log("Page 1 is visible")}
      onPage2Visible={() => console.log("Page 2 is visible")}
      onOverlayStart={() => console.log("Overlay started")}
      onTransparencyStart={() => console.log("Transparency started")}
      onTransparencyEnd={() => console.log("Transparency ended")}
      onAutoScrollStart={() => console.log("Auto-scroll started")}
    >
      <div className="page1Content">
        <h1>Page 1</h1>
        <p>This is the content of Page 1. It should always take the full viewport.</p>
      </div>
      <div className="page2Content">
        <h1>Page 2</h1>
        <p>This is the content of Page 2. Scroll up to see the effects.</p>
        <p>Keep scrolling to see the transparency and auto-scroll effects.</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
        <p>More content to enable scrolling...</p>
      </div>
    </TwoPageOverlay>
  );
};

export default TestTwoPageOverlay;
