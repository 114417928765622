// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* YtSwipeDetection.css */

.swipe-left,
.swipe-right {
  position: fixed;
  top: 10%;
  bottom: 12%;
  width: 30%; /* Adjust width as needed */
  z-index: 10000; /* Ensure it's above other elements */
  pointer-events: auto; /* Allow touch events */
  pointer-events: none; /* Allow events to pass through , but it*/
}

/* Debugging colors */
.debug-left {
  background-color: rgba(255, 0, 0, 0.3); /* Semi-transparent red */
}

.debug-right {
  background-color: rgba(0, 0, 255, 0.3); /* Semi-transparent blue */
}

.swipe-left {
  left: 0;
}

.swipe-right {
  right: 0;
}

/* New swipe-bottom div for below the YouTube play button */
.swipe-bottom {
  position: fixed;
  left: 30%;
  width: 40%;
  bottom: 12%; /* Position it just above the video control bar */
  height: 22%; /* Adjust height as needed */
  z-index: 10000; /* Ensure it's above other elements */
  pointer-events: auto; /* Allow touch events */
}

.debug-bottom {
  background-color: rgba(0, 255, 0, 0.3); /* Semi-transparent green for debugging */
}`, "",{"version":3,"sources":["webpack://./src/Components/Util/YtSwipeDetection.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;;EAEE,eAAe;EACf,QAAQ;EACR,WAAW;EACX,UAAU,EAAE,2BAA2B;EACvC,cAAc,EAAE,qCAAqC;EACrD,oBAAoB,EAAE,uBAAuB;EAC7C,oBAAoB,EAAE,yCAAyC;AACjE;;AAEA,qBAAqB;AACrB;EACE,sCAAsC,EAAE,yBAAyB;AACnE;;AAEA;EACE,sCAAsC,EAAE,0BAA0B;AACpE;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,QAAQ;AACV;;AAEA,2DAA2D;AAC3D;EACE,eAAe;EACf,SAAS;EACT,UAAU;EACV,WAAW,EAAE,iDAAiD;EAC9D,WAAW,EAAE,4BAA4B;EACzC,cAAc,EAAE,qCAAqC;EACrD,oBAAoB,EAAE,uBAAuB;AAC/C;;AAEA;EACE,sCAAsC,EAAE,yCAAyC;AACnF","sourcesContent":["/* YtSwipeDetection.css */\n\n.swipe-left,\n.swipe-right {\n  position: fixed;\n  top: 10%;\n  bottom: 12%;\n  width: 30%; /* Adjust width as needed */\n  z-index: 10000; /* Ensure it's above other elements */\n  pointer-events: auto; /* Allow touch events */\n  pointer-events: none; /* Allow events to pass through , but it*/\n}\n\n/* Debugging colors */\n.debug-left {\n  background-color: rgba(255, 0, 0, 0.3); /* Semi-transparent red */\n}\n\n.debug-right {\n  background-color: rgba(0, 0, 255, 0.3); /* Semi-transparent blue */\n}\n\n.swipe-left {\n  left: 0;\n}\n\n.swipe-right {\n  right: 0;\n}\n\n/* New swipe-bottom div for below the YouTube play button */\n.swipe-bottom {\n  position: fixed;\n  left: 30%;\n  width: 40%;\n  bottom: 12%; /* Position it just above the video control bar */\n  height: 22%; /* Adjust height as needed */\n  z-index: 10000; /* Ensure it's above other elements */\n  pointer-events: auto; /* Allow touch events */\n}\n\n.debug-bottom {\n  background-color: rgba(0, 255, 0, 0.3); /* Semi-transparent green for debugging */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
