import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useAppContext } from '../../AppContext';  // Adjust the import path as necessary
import SimplePeerManager from './SimplePeerManager';

const SimplePeerUserContext = createContext();

export const useSimplePeerUsers = () => {
  return useContext(SimplePeerUserContext);
};

export const SimplePeerUserProvider = ({ 
    children, 
    onUserJoin=null, 
    onUserLeave=null
  }) => {
  const { loginUserName, isActive } = useAppContext();

  // socketio connection to signal room server.
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selfUserId, setSelfUserId] = useState(null);

  // WebRTC peer to peer connection. 
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [connectedPeerId, setConnectedPeerId] = useState(null);
  const [messages, setMessages] = useState([]);
  const peerManagerRef = useRef(null);

  const handleReceivedMessage = (data) => {
    setMessages((prevMessages) => [...prevMessages, data]);
  };

  const handleJoin = (data) => {
    setSelfUserId(data.user_id);
    setOnlineUsers(data.room_user_names); // Assuming data.users contains the list of online users
    if (onUserJoin) {
      onUserJoin(data.user_name);
    }
  };

  const handleLeave = (data) => {
    const userLeft = data.user_name;
    setOnlineUsers(prevUsers => prevUsers.filter(user => user !== userLeft));
    if (onUserLeave) {
      onUserLeave(data.user_name);
    }
  };

  const handleP2PConnected = (peerId) => {
    setConnectionStatus('Connected');
    setConnectedPeerId(peerId);
  };

  const handleP2PDisconnected = (peerId) => {
    setConnectionStatus('Disconnected');
    setConnectedPeerId(null);
  };

  const handleStream = (data) => {

  };

  const sendMessage = (message) => {
    if (message.trim() !== '' && peerManagerRef.current) {
      peerManagerRef.current.sendMessage(message);
      setMessages((prevMessages) => [...prevMessages, message]);
    }
  };

  useEffect(() => {
    if (isActive && loginUserName && !peerManagerRef.current) {
      console.log('new SimplePeerManager');
      const roomName = 'present_room';  
      peerManagerRef.current = new SimplePeerManager(roomName, 
          loginUserName, 
          handleReceivedMessage, 
          handleStream,
          handleJoin,
          handleLeave,
          handleP2PConnected,
          handleP2PDisconnected
      );
    }

    return () => {
      if (peerManagerRef.current) {
        peerManagerRef.current.cleanup();
        peerManagerRef.current = null;
      }
    };
  }, [loginUserName]);

  return (
    <SimplePeerUserContext.Provider value={{ 
        onlineUsers, 
        loginUserName, 
        selfUserId, 
        connectionStatus, 
        connectedPeerId, 
        messages, 
        sendMessage 
    }}>
      {children}
    </SimplePeerUserContext.Provider>
  );
};

export default SimplePeerUserProvider;
