// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playlist-title {
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.playlist-title.left {
  justify-content: flex-start;
}

.playlist-title.middle {
  justify-content: center;
}

.playlist-title .play-icon {
  margin-left: 13px;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Playlist/PlaylistTitle.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,WAAW,EAAE,0BAA0B;EACvC,YAAY,EAAE,0BAA0B;AAC1C","sourcesContent":[".playlist-title {\n  font-weight: bold;\n  font-size: 18px;\n  padding: 10px;\n  display: flex;\n  align-items: center;\n}\n\n.playlist-title.left {\n  justify-content: flex-start;\n}\n\n.playlist-title.middle {\n  justify-content: center;\n}\n\n.playlist-title .play-icon {\n  margin-left: 13px;\n  width: 24px; /* Adjust size as needed */\n  height: 24px; /* Adjust size as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
