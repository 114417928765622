import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimplePeerUserProvider from './SimplePeerUserContext';
import { IconMenuBar, MenuModes } from '../IconMenuBar/IconMenuBar';
import VideoChat from './VideoChat';


const VideoChatRoom = () => {
  const navigate = useNavigate();

  // The join/leave handlers can be handled with hook useSimplePeerUsers as
  // well.
  const handleUserJoin = (userName) => {
    console.log(`${userName} has joined the room.`);
    
  };

  const handleUserLeave = (userName) => {
    console.log(`${userName} has left the room.`);
    
  };
  const handleP2PConnected = (peerId) => {
    console.log(`${peerId} is connected with me.`);
    
  };
  const handleP2PDisconnected = (peerId) => {
    console.log(`${peerId} is disconnected with me.`);
    
  };

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url);
  }

  return (
    <>
      <SimplePeerUserProvider 
        onUserJoin={handleUserJoin} 
        onUserLeave={handleUserLeave}
      >
        <VideoChat 
          onP2PConnected={handleP2PConnected}
          onP2PDisconnected={handleP2PDisconnected}
        />
      </SimplePeerUserProvider>

      <IconMenuBar
        onClickRotatingBall={handleBallClick} 
        menuMode={MenuModes.TINY_THEATER}
      /> 
    </>
  );
};

export default VideoChatRoom;
