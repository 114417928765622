/**
 * Abandoned. 8/4/24
 * 
 * React makes signal process hard to work.
 * 
 * Use ES6 class to handle simple-peer.
 */
import React, { useState, useEffect, useRef } from 'react';
import SimplePeer from 'simple-peer';
import io from 'socket.io-client';
io();
// const socket = io('http://localhost:5000/signal', {
const socket = io(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/signal`, {
    transports: ['websocket', 'polling']
});

const SimPeerComponentRoom = () => {
  const [incomingSignal, setIncomingSignal] = useState('');
  const [outgoingSignal, setOutgoingSignal] = useState('');
  const [peers, setPeers] = useState({}); // Multiple peer connections
  const userNameRef = useRef(null);

  useEffect(() => {
    const room_name = 'present_room';
    const isInitiator = window.location.hash === '#1';
    const user_name = isInitiator ? 'user_host' : `user_guest_${Math.floor(Math.random() * 1000)}`;
    userNameRef.current = user_name;

    // Automatically join the fixed room once when the component mounts
    socket.emit('join', { room: room_name, user_name });

    socket.on('joined', (data) => {
      console.log(`${data.user_name} joined room: ${data.room}`);
      // When someone joins the room, create a peer connection with them if they are not the current user
      if (data.user_id && data.user_id !== socket.id) {
        const newPeer = createPeerConnection(isInitiator, data.user_id);
        setPeers(prevPeers => ({ ...prevPeers, [data.user_id]: newPeer }));
      }
    });

    socket.on('error', (err) => {
      console.log(err.message);
    });

    socket.on('left', (data) => {
      console.log(data.msg);
      if (peers[data.user_id]) {
        peers[data.user_id].destroy();
        setPeers(prevPeers => {
          const { [data.user_id]: _, ...rest } = prevPeers;
          return rest;
        });
      }
    });

    socket.on('signaling_message', ({ signal, from }) => {
      if (peers[from]) {
        try {
          peers[from].signal(signal);
        } catch (error) {
          console.error('Error handling signal:', error);
        }
      } else {
        const newPeer = createPeerConnection(false, from);
        setPeers(prevPeers => {
          const updatedPeers = { ...prevPeers, [from]: newPeer };
          // Signal the new peer directly here
          try {
            newPeer.signal(signal);
          } catch (error) {
            console.error('Error handling signal:', error);
          }
          return updatedPeers;
        });
      }
    });

    return () => {
      socket.off('joined');
      socket.off('left');
      socket.off('signaling_message');
      cleanupPeers();
    };
  }, []); // Empty dependency array ensures this useEffect runs once

  const createPeerConnection = (isInitiator, peerId) => {
    const p = new SimplePeer({
      initiator: isInitiator,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' }
        ]
      }
    });

    p.on('error', err => console.log('Peer error:', err));

    p.on('signal', data => {
      console.log('SIGNAL', JSON.stringify(data));
      setOutgoingSignal(JSON.stringify(data));
      socket.emit('signal', { room: 'present_room', signal: data, to: peerId });
    });

    p.on('connect', () => {
      console.log('Peer connected');
      p.send('hello peer');
    });

    p.on('data', data => {
      console.log('Data received:', data);
    });

    return p; // Return the newly created peer connection
  };

  const cleanupPeers = () => {
    Object.values(peers).forEach(p => p.destroy());
    setPeers({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (incomingSignal) {
      try {
        const signalData = JSON.parse(incomingSignal);
        console.log('Received signal data:', signalData);
        Object.values(peers).forEach(peer => peer.signal(signalData));
      } catch (error) {
        console.error('Invalid signal data', error);
      }
    }
  };

  return (
    <div>
      <h1>Peer-to-Peer Communication</h1>
      <p>First, open <strong>http://localhost:3000/test/simpeer/#1</strong> in one browser tab/window.</p>
      <p>Then, open <strong>http://localhost:3000/test/simpeer</strong> in another browser tab/window.</p>
      <form onSubmit={handleSubmit}>
        <textarea
          id="incoming"
          value={incomingSignal}
          onChange={(e) => setIncomingSignal(e.target.value)}
          placeholder="Paste signal data here"
        />
        <button type="submit">Submit Signal</button>
      </form>
      <pre id="outgoing" style={{ width: '600px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
        {outgoingSignal}
      </pre>
    </div>
  );
};

export default SimPeerComponentRoom;
