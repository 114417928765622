import SimplePeer from 'simple-peer';
import io from 'socket.io-client';

class SimplePeerManager {
  constructor(room, userName, onMessage, onStream = null, 
      onJoin=null, onLeave=null,  // socketio connection to Flask single server.
      onP2PConnected=null, onP2PDisconnected=null   // webrtc p2p connection
  ) {
    this.room = room;

    // User name is determined by Flask server user context, and has nothing to do with socket server.
    this.userName = userName;

    // User id is socket session to Flask socketio server. It is not simple-peer connection session id.
    // This id is determined after join room and connected to room server.
    this.userId = null;

    // Initiator is determined by room manager. The user with 0 index is initiator due to 
    // dynamics of user in/out of room.
    this.isInitiator = false;
    this.onMessage = onMessage;
    this.onStream = onStream;
    this.onP2PConnected = onP2PConnected;
    this.onP2PDisconnected = onP2PDisconnected;
    this.onJoin = onJoin;
    this.onLeave = onLeave;
    this.peers = {};

    this.socket = io('http://localhost:5000/signal', {
      transports: ['websocket', 'polling']
    });

    this.socket.on('connect', () => {
      console.log('WebSocket connection established');
      this.joinRoom();
    });

    this.socket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
      // Handle connection error here, e.g., by showing a notification to the user
    });

    // Respond to any user's join. So, this is not the place to determine if it's user itself.
    this.socket.on('joined', (data) => {
      if (this.onJoin) {
        // Passing room user raw data.
        this.onJoin(data);
      }

      console.log(`${data.user_name} joined room: ${data.room}`);
      console.log(`Room details: ${JSON.stringify(data)}`);

      // When any user, including this user, join room, 2 things are determined for current user:
      // 
      // 1) How to determine initiator? 
      // 2) If current user is initiator, if the join triggers p2p object creation.

      if (data.user_id === this.socket.id) {
        // I joined.
        if (data.initiator === this.socket.id) {
          // I am initiator.
          this.isInitiator = true;
        } else {
          // I am not initiator.
          this.isInitiator = false;
        }
      } else {
        // Other joined.
        // I don't care, but, I may become initiator, due to the case, I am last one in room,
        // and new user joins. In this case, I am index = 0 in room, even my index in room > 0 
        // before.
        // Otherwise, I could not think of anything that affect initiator status.
        if (data.initiator === this.socket.id) {
          // I become initiator due to count = 1 in room. I am the only one left in room.
          this.isInitiator = true;
        }
      }
      console.log(`Set this.isInitiator: ${this.isInitiator}`);

      const triggerInitiate = data.user_count > 1;

      // Why? When a new user joins, there is a need to make a connection.
      if (triggerInitiate && this.isInitiator) {
        console.log('+++++++++++++++=');

        this.createPeerConnection(data.user_id, true)
          .then(newPeer => {
            this.peers[data.user_id] = newPeer;
            console.log(`Make connection to newly joined user: ${data.user_name}`);
          })
          .catch(error => {
            console.error('non-initiator peer creation error:', error);
          });
      }
    });

    this.socket.on('signaling_message', async ({ signal, from }) => {
      if (this.peers[from]) {
        this.peers[from].signal(signal);
      } else {
        console.log('=====================');

        this.createPeerConnection(from, false)
          .then(newPeer => {
            newPeer.signal(signal);
            this.peers[from] = newPeer;
            console.log(`Make non-initiator "${from}" connection after signal from initiator`);
          })
          .catch(error => {
            console.error('non-initiator peer creation error:', error);
          });
      }
    });

    this.socket.on('left', (data) => {
      if (this.peers[data.user_id]) {
        this.peers[data.user_id].destroy();
        delete this.peers[data.user_id];
      }

      console.log(`left::user left. ${JSON.stringify(data)}`);
    });

    // Fired by socketio by default. When my connection is disconnected.
    this.socket.on('disconnect', () => {
      console.warn('Socket disconnected');
      // Handle disconnection, e.g., by attempting to reconnect or informing the user
      this.cleanup();
    });

    // Custom event.
    this.socket.on('disconnected', (data) => {
      if (this.peers[data.user_id]) {
        this.peers[data.user_id].destroy();
        delete this.peers[data.user_id];
      }

      if (this.onLeave) {
        this.onLeave(data);
      }
      console.log(`disconnected::data= ${JSON.stringify(data)}`)
    });

    this.socket.on('error', (err) => {
      console.error(err.message);
    });

    // By using the transport event on the client side, you can 
    // track when the transport method is upgraded and log the changes accordingly. 
    // This will give you more insight into why multiple connect and disconnect 
    // events are being triggered.
    // No need to know the details of this.
    this.socket.io.on('transport', (transport) => {
      console.log('Transport changed to:', transport.name);
    });
  }

  joinRoom() {
    this.socket.emit('join', {
      room: this.room,
      user_name: this.userName
    });
  }

  leaveRoom() {
    this.socket.emit('leave', {
      room: this.room,
      user_name: this.userName
    });
    this.cleanup();
  }

  async createPeerConnection(peerId, isInitiator) {
    const self = this;
    const p = new SimplePeer({
      initiator: isInitiator,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' }
        ]
      },
      stream: this.localStream   // Add the local stream to the peer connection
    });

    p.on('error', err => console.error('Peer error:', err));

    p.on('signal', data => {
      console.log('SIGNAL', JSON.stringify(data));
      this.socket.emit('signal', { room: this.room, signal: data, to: peerId });
    });

    p.on('connect', () => {
      console.log(`Peer connected with user: ${peerId}`);
      if (self.onP2PConnected) {
        self.onP2PConnected(peerId);
      }
    });

    p.on('close', () => {
      console.log(`Peer connection with user ${peerId} has been closed`);
      if (self.onP2PDisconnected) {
        self.onP2PDisconnected(peerId);
      }
    });

    p.on('data', data => {
      const textDecoder = new TextDecoder();
      const decodedData = textDecoder.decode(data);
      console.log('Data received:', decodedData);
      if (this.onMessage) {
        this.onMessage(decodedData);
      }
    });

    p.on('stream', stream => {
      console.log(`Stream received: `);
      if (this.onStream) {
        this.onStream(stream);
      }
    });

    return p;
  }
  
  sendStream(stream) {
    this.localStream = stream;
    Object.values(this.peers).forEach(peer => {
      // console.log(`sendStream(stream) ${JSON.stringify(this.peers, null, 2)}`);
      console.log(`sendStream(stream)`);
      peer.addStream(stream); // Ensure the stream is added to all peer connections
      peer.negotiate(); // Manually trigger negotiation to handle the added stream
    });
  }

  sendMessage(message) {
    Object.entries(this.peers).forEach(([userId, peer]) => {
      // Somehow, connection that's not connected is stored in the array. For example, 
      // 2 users are connected, but there is a 3rd connection.
      // TODO: 3rd object should be removed.
      if (peer.connected) { // Ensure the peer is connected before sending
        peer.send(message);
        console.log(`Send message to: ${userId}. message: ${message}`);
      } else {
        console.warn('Attempted to send message to a peer that is not connected');
      }
    });
  }

  handleIncomingSignal(signalData) {
    Object.values(this.peers).forEach(peer => {
      if (peer) {
        peer.signal(signalData);
      }
    });
  }

  cleanup() {
    Object.values(this.peers).forEach(peer => {
      if (peer) {
        peer.destroy();
      }
    });
    this.peers = {};
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

export default SimplePeerManager;
