/*

Create basic Muuri list according to Muuri list structure. Muuri object is not created
in this component.

It only responsible to create list. Muuri layout is not achieved here.

*/

import React, { useRef, useState, useEffect } from 'react';

import Muuri from 'muuri';
import './MuuriListBase.css';


export const MuuriList = ({
  title = 'Title',
  titleCss = 'source',
  items = [],
  notifyLayoutDone = false,
  onListRef = null,
}) => {
  const listRef = useRef(null);
  const [layoutDone, setLayoutDone] = useState(true);
  const [specialItem, setSpecialItem] = useState('');

  useEffect(() => {
    if (onListRef && typeof onListRef === 'function') {
      onListRef(listRef);
    }
  }, []);

  const handleSpecialItemChange = (e) => {
    setSpecialItem(e.target.value);
  };

  const handleAddSpecialItem = () => {
    if (specialItem.trim() !== '') {
      setSpecialItem(specialItem);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior of the Enter key
      handleAddSpecialItem();
    }
  };

  return (
    <div className={`board-column ${titleCss} ${layoutDone ? 'show-muuri-tems' : 'hide-muuri-tems'}`} ref={listRef}>
      <div className="board-column-container">
        <div className="board-column-header">{title}</div>
        <div className="board-column-content-wrapper">
          <div className="board-column-content" ref={listRef}>
            {/* Special Item Input */}
            <div key="special" className="board-item">
              <div className="board-item-content">
                <input
                  type="text"
                  value={specialItem}
                  onChange={handleSpecialItemChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter special item"
                  autoFocus  // This attribute will automatically focus the input box
                />
                <button type="button" onClick={handleAddSpecialItem}>
                  +
                </button>
              </div>
            </div>

            {/* List Items */}
            {items.map((item, index) => (
              <div key={index} className="board-item">
                <div className="board-item-content">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

  // return (
  //   <>
  //     <div className="drag-container"></div>
  //     <div className="board">
  //       <div className="board-column todo">
  //         <div className="board-column-container">
  //           <div className="board-column-header">Todo</div>
  //           <div className="board-column-content-wrapper">
  //             <div className="board-column-content">
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>1</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>2</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>3</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>4</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>5</div></div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="board-column working">
  //         <div className="board-column-container">
  //           <div className="board-column-header">Working</div>
  //           <div className="board-column-content-wrapper">
  //             <div className="board-column-content">
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>6</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>7</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>8</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>9</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>10</div></div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="board-column done">
  //         <div className="board-column-container">
  //           <div className="board-column-header">Done</div>
  //           <div className="board-column-content-wrapper">
  //             <div className="board-column-content">
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>11</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>12</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>13</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>14</div></div>
  //               <div className="board-item"><div className="board-item-content"><span>Item #</span>15</div></div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );