// NextProgramComponent.js
import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../Localization/LanguageContext/LanguageContext';
import { useNavigate } from 'react-router-dom';
import './AlbumIntro.css';
import ProgramBase from './ProgramBase';

import IntroMusicClip1 from '../../assets/music/short-intro/use-mixkit-air-woosh-1489.wav'; 
import IntroMusicClip2 from '../../assets/music/short-intro/use-mixkit-angelic-swell-presentation-2672.wav'; 
import IntroMusicClip3 from '../../assets/music/short-intro/use-mixkit-intro-transition-1146.wav'; 
import IntroMusicClip4 from '../../assets/music/short-intro/windows11-intro.mp3'; 
import IntroMusicClip5 from '../../assets/music/short-intro/announcement-sound-21466.mp3'; 
import IntroMusicClip6 from '../../assets/music/short-intro/cinematic-intro-3-40041.mp3'; 
import IntroMusicClip7 from '../../assets/music/short-intro/cinematic-intro-6097.mp3'; 
import IntroMusicClip8 from '../../assets/music/short-intro/short-logo-108964.mp3'; 
import IntroMusicClip9 from '../../assets/music/short-intro/positive-logo-opener-13622.mp3'; 
import IntroMusicClip10 from '../../assets/music/short-intro/modern-ambient-logo-157545.mp3'; 

const musicIntro = [
    //IntroMusicClip1,//n
    //IntroMusicClip2,////n
    IntroMusicClip3,//ok
    IntroMusicClip4,//ok
    //IntroMusicClip5,//n
    IntroMusicClip6,//ok
    //IntroMusicClip7,//n
    IntroMusicClip8,//ok
    //IntroMusicClip9,//n
    IntroMusicClip10
]

/**
 * onClickReload: It's used to inform Channel to reset its status, so that
 *      it can be rerendered. Otherwise, Channel component is call due to naviagate('/'). but
 *      no re-render is performed since its state is not changed.
 * 
 * @param {*} param0 
 * @returns 
 */
const AlbumIntro = ({
        onStart=null, 
        onFinish=null, 
        title=null, 
        description=null, 
        end=false, 
        introTimeout=15000, 
        showAlbumIntro=true,   // Flag for parent to control component visibility.
        onClickReload=null
    }) => {
    const { language } = useContext(LanguageContext);
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showIntro, setShowIntro] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    const [audioMuted, setAudioMuted] = useState(true); // New state for audio mute
    const labels = {
        en: { replay: "Replay" },
        zh: { replay: "重放" }
      };
    
    useEffect(() => {
        if (!showAlbumIntro) {
            return;
        }

        // Notify parent channel.
        instroStart();
        setShowIntro(true);
        playMusic();

        let timeoutId = null;
        
        if (!end) {
            setTimeout(() => {
                // Notify channel container that intro is done, so that program can start.
                instroFinish();
            }, introTimeout);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [currentIndex, showIntro]);

    const toggleMute = () => {
        if (audioContext) {
            audioContext.resume().then(() => {
                audioContext.suspend().then(() => {
                    setAudioMuted(!audioMuted);
                });
            });
        } else {
            const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            setAudioContext(newAudioContext);
        }
    };

    const getIntroMusicRand = () => {
        const idx = Math.floor(Math.random() * (musicIntro.length));
        const audio = musicIntro[idx];

        console.log('Rand intro index: ' + idx)
        return audio
    }

    const playMusic = () => {
        if (audioContext && audioContext.state === 'suspended') {
            audioContext.resume().then(() => {
                console.log('Audio context resumed.');
            });
        }
        if (!audioMuted && audioContext) {
            const audio = new Audio(getIntroMusicRand());
            audio.play();
        }
    };

    const handleClick = () => {
        if (!audioContext) {
            const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            setAudioContext(newAudioContext);
        }
    }

    const instroStart = () => {
        if (onStart) {
            onStart(title);
        }
    }
    
    const instroFinish = () => {
        if (onFinish) {
            onFinish();
        }
    }
    
    const handleReplay = () => {
        // navigate('/', { replace: true }); 
        navigate('/', { replace: true });  

        // Since Channel is loaded already, it needs to changes its status to re-render.
        if (onClickReload) {
            onClickReload();
        }

        // Reload the root URL.
        // This causes reload page dialog showing up: on desktop. But iPhone is ok.
        ///window.location.href = '/';

        // Not show Reload site dialog. Not work, as no state is available. It stays in current state.
        // // Use the history object to navigate to the home page
        //window.history.pushState(null, null, '/');  // not work

        // // Dispatch a popstate event to simulate a navigation event
        //window.dispatchEvent(new Event('popstate'));
    }

    // Inherit from ProgramBase, so that onStart, and onFinish will be 
    // fired automatically during component mount/unmount.
    return (
        <>
            <div className={`
                next-program-container   
                ${showAlbumIntro ? '' : 'hide-intro'}`
            }>
                <div className="next-program">
                    <div className="text multiline-text">
                    {title}
                    </div>
                    {end && (
                        <button className="replay-button" onClick={handleReplay}>{labels[language].replay}</button>
                    )}
                </div>
            </div>
        </>
    );
};

export default AlbumIntro;
