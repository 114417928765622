import React, { useState, useEffect, useRef } from 'react';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { f2futil } from '../../comjs/utils/utils.js';
import DroppableVideoGrid from '../YoutubeView/YtPlaylistDnD/DroppableVideoGrid';
import './Playlist.css';

const Playlist = ({ 
    playlist,    // Channel file video item list.
    onUpdateVideos, 
    ytEditMode=false, 
    inEditFocus=false, 
    setInEditFocus=null,
    forceDisablePlay=false
}) => {
  // const [videos, setVideos] = useState(playlist?.play_videos_encoded?.files_relpath ?? []);
  const [videos, setVideos] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const playlistContainerRef = useRef(null);
  const isInitialized = useRef(false); // Ref to track if videos are set initially

  useEffect(() => {
    if (playlist) {
      setVideos(playlist.play_videos_encoded.files_relpath);
    } else {
      setVideos([]);
    }
  }, [playlist]);

  // useEffect(() => {
  //   if (!isInitialized.current) {
  //     setVideos(playlist.play_videos_encoded.files_relpath || []);
  //     isInitialized.current = true;
  //   }
  // }, [playlist]);

  // useEffect(() => {
  //   if (playlist && !videos.length) {
  //     setVideos(playlist.play_videos_encoded.files_relpath || []);
  //   }
  // }, [playlist.file_name]);  // Use playlist.file_name to uniquely identify each playlist

  const clearErrorMessageAfterTimeout = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 15000);
  };

  const handleAddVideo = async (respAdd) => {
    // This is pasted media info obj from server.
    if (!respAdd.status) return;

    const pastedUrl = respAdd.data.channel_media_path;
    const api = new ApiFileX();
    const retData = await api.addPlaylistVideo(playlist.file_name, pastedUrl);
    if (retData && retData.status) {
      setVideos([ pastedUrl, ...videos]);
      // onUpdateVideos(playlist.file_name, [...videos, pastedUrl]);
      return {status: true, data: null}
    } else {
      //setErrorMessage("Failed to add video to playlist.");
      return {status: false, error: retData.error}
    }
  };

  const handleDeleteVideo = async (youtube_id) => {
    const api = new ApiFileX();
    const retData = await api.deletePlaylistVideo(playlist.file_name, youtube_id);
    if (retData && retData.status) {
      // Compare key.
      // Sample: {"/yv/my4lChG4Gjg": "VS5TLiB3YXJzaGlwcyBkZXBsb3llZCB0byBtb25pdG9yIFJ1c3NpYW4gZmxlZXQ="}
      const updatedVideos = videos.filter(v => !Object.keys(v).includes(youtube_id));
      setVideos(updatedVideos);
      onUpdateVideos(playlist.file_name, updatedVideos);

      return {status: true, data: null}
    } else {
      // setErrorMessage("Failed to delete video from playlist.");
      return {status: false, error: 'Failed to delete video from playlist!'}
    }
  };

  const handlePaste = async () => {
    if (!inEditFocus) {
      return;
    }

    try {
      const text = await navigator.clipboard.readText();
      handleAddVideo(text);
    } catch (err) {
      setErrorMessage('Failed to read clipboard contents: ', err);
    }
  };

  const handleClick = async (event) => {
    if (inEditFocus) {
      event.stopPropagation();
      playlistContainerRef.current.focus();
      handlePaste();
    }
  };

  const handleClickOutside = (event) => {
    if (
      playlistContainerRef.current && 
      !playlistContainerRef.current.contains(event.target) &&
      !event.target.classList.contains('edit-button') &&
      !event.target.classList.contains('paste-input') &&  // Prevent unfocus if clicking inside paste input
      !event.target.classList.contains('paste-button')   // Prevent unfocus if clicking paste button
    ) {
      if (setInEditFocus) {
        setInEditFocus(null);
      }

      setErrorMessage('');
    }
  };

  useEffect(() => {
    if (inEditFocus) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inEditFocus]);

  const saveVideosOrderToServer = (reorderedVideos) => {
    // reorderedVideos is passed from VideoGridBase that display video grid with
    // video db info. It does not have exact format to be saved into channel file.
    // Here we need to construct it from video['channel_media_path'].
    //
    // In JavaScript, when you want to use a variable as a key in an object literal, 
    // you need to wrap the variable name in square brackets.
    // [video['channel_media_path']] will use the value of video['channel_media_path'] 
    // as the key for the object.
    let newVideos = reorderedVideos.map(video => {
      const api = new ApiFileX();
      return {
        [video['channel_media_path']]: api.encodeUrlToBase64_UrlSafe(video['file_name'])
      }
      // return video['channel_media_path'];
    });

    // Drag end to update reordered playlist. This is to save to channel json file.
    const api = new ApiFileX();
    const retData = api.updatePlaylist(playlist.file_name, newVideos);

    if (retData.status) {
      setErrorMessage("Failed to update playlist.");
    }
  };

  return (
    <div 
      className={`playlist playlist-container ${inEditFocus ? 'edit-focus' : ''}`} 
      ref={playlistContainerRef} 
      onClick={handleClick}
      tabIndex={0}  // Make the div focusable
    >
      {inEditFocus && errorMessage && <div className="error-message">{errorMessage}</div>}
      <DroppableVideoGrid
        key={videos.length} // Force re-render by using a unique key. 
                            // The reason is Playlist may be called 2 times. 1st time videos is [].
                            // 2nd time videos is not empty and this is the channel items to be displayed. 
                            // This component is used in few different scenarios.
                            // Without adding this key, react is not able to detect the difference of 2nd time
                            // the videos list update.
        videos={videos}
        onAddVideo={handleAddVideo}
        onDeleteVideoApiCall={handleDeleteVideo}  // Fired from VideoGridBase
        ytEditMode={ytEditMode}
        editStatus={inEditFocus}
        saveVideosOrderToServer={saveVideosOrderToServer}
        forceDisablePlay={forceDisablePlay}
      />

    </div>
  );
};

export default Playlist;
