import React, { useEffect, useRef } from 'react';
import './YtSwipeDetection.css';

const YtSwipeDetection = ({ onSwipeLeft = null, onSwipeRight = null, onSwipeUp = null, onSwipeDown = null }) => {
  const leftSwipeRef = useRef(null);
  const rightSwipeRef = useRef(null);
  const bottomSwipeRef = useRef(null);

  useEffect(() => {
    let touchStartX = null;
    let touchEndX = null;
    let touchStartY = null;
    let touchEndY = null;

    const handleTouchStart = (e) => {
      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].clientY;
      console.log(`YT touch start: x=${touchStartX}, y=${touchStartY}`);
    };

    const handleTouchMove = (e) => {
      touchEndX = Math.round(e.touches[0].clientX); // Convert to the nearest integer
      touchEndY = Math.round(e.touches[0].clientY); // Convert to the nearest integer
      console.log(`YT touch move: x=${touchStartX}-${touchEndX}, y=${touchStartY}-${touchEndY}`);
    };

    const handleTouchEnd = () => {
      if (Math.abs(touchEndY - touchStartY) > 34) {
        if (touchStartY - touchEndY > 50 && onSwipeUp) {
          onSwipeUp(); // Swipe up
        } else if (touchEndY - touchStartY > 50 && onSwipeDown) {
          onSwipeDown(); // Swipe down
        }

        touchStartX = null;
        touchEndX = null;
        touchStartY = null;
        touchEndY = null;
        return;
      }

      if (touchStartX !== null && touchEndX !== null) {
        if (touchStartX - touchEndX > 50) {
          if (onSwipeLeft) onSwipeLeft();
        } else if (touchEndX - touchStartX > 50) {
          if (onSwipeRight) onSwipeRight();
        }
      }

      touchStartX = null;
      touchEndX = null;
      touchStartY = null;
      touchEndY = null;
    };

    const leftSwipe = leftSwipeRef.current;
    const rightSwipe = rightSwipeRef.current;
    const bottomSwipe = bottomSwipeRef.current;

    if (leftSwipe && rightSwipe && bottomSwipe) {
      leftSwipe.addEventListener('touchstart', handleTouchStart);
      leftSwipe.addEventListener('touchmove', handleTouchMove);
      leftSwipe.addEventListener('touchend', handleTouchEnd);

      rightSwipe.addEventListener('touchstart', handleTouchStart);
      rightSwipe.addEventListener('touchmove', handleTouchMove);
      rightSwipe.addEventListener('touchend', handleTouchEnd);

      bottomSwipe.addEventListener('touchstart', handleTouchStart);
      bottomSwipe.addEventListener('touchmove', handleTouchMove);
      bottomSwipe.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (leftSwipe && rightSwipe && bottomSwipe) {
        leftSwipe.removeEventListener('touchstart', handleTouchStart);
        leftSwipe.removeEventListener('touchmove', handleTouchMove);
        leftSwipe.removeEventListener('touchend', handleTouchEnd);

        rightSwipe.removeEventListener('touchstart', handleTouchStart);
        rightSwipe.removeEventListener('touchmove', handleTouchMove);
        rightSwipe.removeEventListener('touchend', handleTouchEnd);

        bottomSwipe.removeEventListener('touchstart', handleTouchStart);
        bottomSwipe.removeEventListener('touchmove', handleTouchMove);
        bottomSwipe.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown]);

  return (
    <>
      <div ref={leftSwipeRef} className="swipe-left debug-left"></div>
      <div ref={rightSwipeRef} className="swipe-right debug-right"></div>
      <div ref={bottomSwipeRef} className="swipe-bottom debug-bottom"></div>
    </>
  );
};

export default YtSwipeDetection;
