import React, { useEffect, useState } from 'react';
import styles from './TwoPageOverlay.module.css';

const TwoPageOverlay = (props) => {
  const {
    children,
    onPage1Visible = null,
    onPage2Visible = null,
    onOverlayStart = null,
    onTransparencyStart = null,
    onTransparencyEnd = null,
    onAutoScrollStart = null,
    keyValue,
    overlayThreshold = 0.06,
    transparencyStartThreshold = 0.16,
    transparencyEndThreshold = 0.3,
    autoScrollThreshold = 0.5,
  } = props;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [initPageDone, setInitPageDone] = useState(false);
  const [isSecondPageVisible, setIsSecondPageVisible] = useState(false);

  const handleScroll = () => {
    const firstPage = document.querySelector(`.${styles.page1}`);
    const secondPage = document.querySelector(`.${styles.page2}`);
    const scrollY = window.scrollY;

    if (!initPageDone) {
      if (scrollY === 0) {
        setInitPageDone(true);
        if (onPage1Visible) {
          onPage1Visible();
        }
      } else {
        return;
      }
    }

    setScrollPosition(scrollY);

    if (scrollY < window.innerHeight * overlayThreshold) {
      firstPage.style.opacity = 1 - scrollY / (window.innerHeight * overlayThreshold);
      secondPage.style.opacity = scrollY / (window.innerHeight * overlayThreshold);
      if (onOverlayStart) onOverlayStart();
    } else if (scrollY >= window.innerHeight * transparencyStartThreshold && scrollY < window.innerHeight * transparencyEndThreshold) {
      firstPage.style.opacity = 0.8;
      secondPage.style.opacity = 1;
      if (onTransparencyStart) onTransparencyStart();
    } else if (scrollY >= window.innerHeight * transparencyEndThreshold && scrollY < window.innerHeight * autoScrollThreshold) {
      firstPage.style.opacity = 1 - (scrollY - window.innerHeight * transparencyEndThreshold) / (window.innerHeight * (autoScrollThreshold - transparencyEndThreshold));
      if (onTransparencyEnd) onTransparencyEnd();
    } else if (scrollY >= window.innerHeight * autoScrollThreshold && !isSecondPageVisible) {
      setIsSecondPageVisible(true);
      animateScroll(secondPage.offsetTop);
      if (onAutoScrollStart) onAutoScrollStart();
    }
  };

  const animateScroll = (destination) => {
    const duration = 800;
    const start = window.scrollY;
    const startTime = performance.now();

    function step(currentTime) {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easing = easeInOutQuad(progress);
      window.scrollTo(0, start + (destination - start) * easing);

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    }

    requestAnimationFrame(step);
  };

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if (!initPageDone) {
      window.scrollTo(0, 0);
      handleScroll();
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [initPageDone]);

  return (
    <div key={keyValue} className={styles.twoPageOverlayContainer}>
      {children.map((child, index) => (
        <section key={index} className={`${styles.page} ${index === 0 ? styles.page1 : styles.page2}`}>
          {child}
        </section>
      ))}
    </div>
  );
};

export default TwoPageOverlay;
