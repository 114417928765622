import $ from 'jquery';    // React only

import adapter from 'webrtc-adapter';  // npm install webrtc-adapter
import {f2fBase} from '../js/base.js'



export class shareMedia extends f2fBase {
  static get CSS() {
    return {
      name: './main.css',
      import: import('./main.css')
    };
  }
  // static get JS_ADAPTER() {return './adapter.js';}

  constructor() {

    super();
    this.loadCSS(shareMedia.CSS);
    //this.loadJsFile([shareMedia.JS_ADAPTER], this.jsReady.bind(this));


    this.preferredDisplaySurface = document.getElementById('displaySurface');
    this.startButton = document.getElementById('startButton');

    if (adapter.browserDetails.browser === 'chrome' &&
        adapter.browserDetails.version >= 107) {
      // See https://developer.chrome.com/docs/web-platform/screen-sharing-controls/
      document.getElementById('options').style.display = 'block';
    } else if (adapter.browserDetails.browser === 'firefox') {
      // Polyfill in Firefox.
      // See https://blog.mozilla.org/webrtc/getdisplaymedia-now-available-in-adapter-js/
      adapter.browserShim.shimGetDisplayMedia(window, 'screen');
    }

    this.startButton.addEventListener('click', () => this.startSharing());
    
    if ((navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices)) {
      this.startButton.disabled = false;
    } else {
      this.errorMsg('getDisplayMedia is not supported');
    }
  }

  jsReady(loadeJsIdList) {
    this.jsIdList = loadeJsIdList;

  }

  handleSuccess(stream) {
    this.startButton.disabled = true;
    this.preferredDisplaySurface.disabled = true;
    const video = document.querySelector('video');
    video.srcObject = stream;

    // demonstrates how to detect that the user has stopped
    // sharing the screen via the browser UI.
    stream.getVideoTracks()[0].addEventListener('ended', () => {
      this.errorMsg('The user has ended sharing the screen');
      this.startButton.disabled = false;
      this.preferredDisplaySurface.disabled = false;
    });
  }

  handleError(error) {
    this.errorMsg(`getDisplayMedia error: ${error.name}`, error);
  }

  errorMsg(msg, error) {
    const errorElement = document.querySelector('#errorMsg');
    errorElement.innerHTML += `<p>${msg}</p>`;
    if (typeof error !== 'undefined') {
      console.error(error);
    }
  }

  startSharing() {
    const options = { audio: true, video: true };
    const displaySurface = this.preferredDisplaySurface.options[this.preferredDisplaySurface.selectedIndex].value;
    
    if (displaySurface !== 'default') {
      options.video = { displaySurface };
    }

    navigator.mediaDevices.getDisplayMedia(options)
        .then((stream) => this.handleSuccess(stream))
        .catch((error) => this.handleError(error));
  }
}
