/**
 * Mute component controls:
 * 
 * 1) Intro music if intro component is present.
 * 2) Random music from pool for photo player.
 * 
 * Design of system music start/stop:
 * 
 * 1) User click to start.
 * 2) After that, user could navigate to any page. The music is mainly for photo player, 
 *    and channel's intro, channel's photo player.
 * 3) Once started, the mute state does not belong to Mute component, as user could go to any page.
 *    The best behavior is that started music state is store in global context. If Intro or photo 
 *    player page shows up again, Mute button should take over the state.
 * 4) Define enter/leave playing music state:
 * 
 * 4.1) Enter
 * 4.2) Leave
 */

import React, { useState, useContext, useEffect } from 'react';
import './Mute.css'; // You can define your CSS for the MuteButton component
import {ApiFileX} from '../../comjs/filex.api/filex-api';
import { AppContext } from '../../AppContext';

//import IntroMusicClip1 from '../../assets/music/short-intro/use-mixkit-air-woosh-1489.wav'; 
//import IntroMusicClip2 from '../../assets/music/short-intro/use-mixkit-angelic-swell-presentation-2672.wav'; 
import IntroMusicClip3 from '../../assets/music/short-intro/use-mixkit-intro-transition-1146.wav'; 
import IntroMusicClip4 from '../../assets/music/short-intro/windows11-intro.mp3'; 
//import IntroMusicClip5 from '../../assets/music/short-intro/announcement-sound-21466.mp3'; 
import IntroMusicClip6 from '../../assets/music/short-intro/cinematic-intro-3-40041.mp3'; 
//import IntroMusicClip7 from '../../assets/music/short-intro/cinematic-intro-6097.mp3'; 
import IntroMusicClip8 from '../../assets/music/short-intro/short-logo-108964.mp3'; 
//import IntroMusicClip9 from '../../assets/music/short-intro/positive-logo-opener-13622.mp3'; 
import IntroMusicClip10 from '../../assets/music/short-intro/modern-ambient-logo-157545.mp3'; 

const initVol = 0.3;
const musicIntro = [
    //IntroMusicClip1,//n
    //IntroMusicClip2,////n
    IntroMusicClip3,//ok
    IntroMusicClip4,//ok
    //IntroMusicClip5,//n
    IntroMusicClip6,//ok
    //IntroMusicClip7,//n
    IntroMusicClip8,//ok
    //IntroMusicClip9,//n
    IntroMusicClip10
]

const MuteButton = ({onClickUnmute=null}) => {
    // What music to play, intro or others.
    const {musicState, updateMusicState} = useContext(AppContext);

    // Mute state triggered by user, which kept in this component. It's independent of musicState.
    const [audioMuted, setAudioMuted] = useState(true); 
    //const [audioContext, setAudioContext] = useState(null);

    const [isMuted, setIsMuted] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [volume, setVolume] = useState(initVol); // Initial volume set to maximum (1.0)


    useEffect(() => {
        /*
        if (!audioMuted) {
            playMusic();
        } else {
            // If already playing, it should stop music.
            if (currentAudio) {
                currentAudio.pause();
            }
        }
        */
        return () => {
            if (currentAudio) {
                currentAudio.pause();
            }
        }; 
    }, []);

    const handleMuteToggle = () => {
        setIsMuted(prevMuted => !prevMuted);
        // You can implement the actual mute/unmute functionality here
    };

    // Called when clicking toggle button. Only parent knows what parent panel state is,
    // such as if in intro mode, musicState should be 0. If in photo playing mode, musicState
    // should be 1 (background music to play)
    //const onClickUnmute = () => {

    //};
    
    const toggleMute = () => {
        // It will be unmuted.
        if (audioMuted && onClickUnmute) {
            if (onClickUnmute) {
                onClickUnmute();
            }
        }

        if (audioMuted) {
            playMusic();
        } else {
            // If already playing, it should stop music.
            if (currentAudio) {
                currentAudio.pause();
            }
        }

        setAudioMuted(!audioMuted);

        console.log('Toggle mute: ' + !audioMuted);
        /*
        if (audioContext) {
            audioContext.resume().then(() => {
                audioContext.suspend().then(() => {
                    // It will be unmuted.
                    if (audioMuted && onClickUnmute) {
                        onClickUnmute();
                    }
                    setAudioMuted(!audioMuted);
                });
            });
        } else {
            const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            setAudioContext(newAudioContext);
        }
        */
    };

    const getIntroMusicRand = () => {
        const idx = Math.floor(Math.random() * (musicIntro.length));
        const audio = musicIntro[idx];

        console.log('Rand intro index: ' + idx)
        return audio
    };

    const playMusic = (toPlay=true) => {
        /*
        if (audioContext && audioContext.state === 'suspended') {
            audioContext.resume().then(() => {
                console.log('Audio context resumed.');
            });
        }
        */
        //if (!audioMuted && audioContext) {
        if (!toPlay) {
            return;
        }
            // 0. Intro
        if (0 == musicState) {
            if (currentAudio) {
                currentAudio.pause();
            }
            const newAudio = new Audio(getIntroMusicRand());
            newAudio.volume = volume;
            newAudio.play();
            setCurrentAudio(newAudio);
        } else if (1 == musicState || 2 == musicState ) {
            
            // 1. Random music.
            const api = new ApiFileX();
            api.getRandMusic().then(async (retData) => {
                if (retData) {
                    let mData = retData.data;

                    if (currentAudio) {
                        currentAudio.pause();
                    }
                    const newAudio = new Audio(mData);
                    newAudio.volume = volume;

                    newAudio.addEventListener('loadedmetadata', () => {
                        console.log('Audio duration:', newAudio.duration);
                        const audioDuration = newAudio.duration; // Get the total duration of the audio in seconds
                        const randomStartingTime = Math.random() * audioDuration;

                        // Set the currentTime to the random starting point
                        newAudio.currentTime = randomStartingTime;

                        newAudio.play();
                        setCurrentAudio(newAudio);
                    });
                } 
            });
        }
    };

    const handleVolumeChange = (increment) => {
        const newVolume = Math.min(1.0, Math.max(0, volume + increment));
        setVolume(newVolume);
    
        if (currentAudio) {
            currentAudio.volume = newVolume;
        }
    };

    return (
        <div className="mute-wrapper">
            <div className={`mute-box mute-button ${audioMuted ? 'muted' : ''}`} onClick={toggleMute}>
                {audioMuted ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M6 9v6h4l5 5V4L10 9H6z" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M11 5v14l-4-4H2V9h5L11 5zm8.54 8.54a9.917 9.917 0 0 0-1.469-1.677l2.785-2.785a15.078 15.078 0 0 1 1.47 1.678l-2.786 2.784zm-2.793-2.793a6.975 6.975 0 0 0-1.07-1.313l2.786-2.785a8.98 8.98 0 0 1 1.07 1.313l-2.786 2.785z" />
                    </svg>
                )}
            </div>
            {!audioMuted && (
            <div className="mute-box audio-player">
                <div className="volume-controls">
                    <button onClick={() => handleVolumeChange(0.1)}>+</button>
                    <button onClick={() => handleVolumeChange(-0.1)}>-</button>
                </div>
            </div>
            )}
        </div>
    );
};

export default MuteButton;
