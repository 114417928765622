// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* playlist-container for DroppableVideoGrid */
.playlist-container {
  position: relative;
  text-align: center;
  margin-top: 20px;
  width: 100%;
  padding: 5px;
  min-height: 100px;
  height: auto;
  outline: none; /* Remove the outline by default */
}

.playlist-container.edit-focus {
  outline: 4px dashed #afcedc; /* Apply the dashed border only when inEditFocus is true */
}

.playlist-container.edit-focus:hover {
  outline: 6px dashed #afcedc; /* Change the outline on hover when inEditFocus is true */
  /* background-color: lightgray !important; */
}

.playlist-container.hidden {
  display: none;
}



.playlist {
  margin-top: 10px;
  margin-bottom: 10px;
}

.playlist-edit {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playlist-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.video-actions {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


`, "",{"version":3,"sources":["webpack://./src/Components/Playlist/Playlist.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,aAAa,EAAE,kCAAkC;AACnD;;AAEA;EACE,2BAA2B,EAAE,0DAA0D;AACzF;;AAEA;EACE,2BAA2B,EAAE,yDAAyD;EACtF,4CAA4C;AAC9C;;AAEA;EACE,aAAa;AACf;;;;AAIA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* playlist-container for DroppableVideoGrid */\n.playlist-container {\n  position: relative;\n  text-align: center;\n  margin-top: 20px;\n  width: 100%;\n  padding: 5px;\n  min-height: 100px;\n  height: auto;\n  outline: none; /* Remove the outline by default */\n}\n\n.playlist-container.edit-focus {\n  outline: 4px dashed #afcedc; /* Apply the dashed border only when inEditFocus is true */\n}\n\n.playlist-container.edit-focus:hover {\n  outline: 6px dashed #afcedc; /* Change the outline on hover when inEditFocus is true */\n  /* background-color: lightgray !important; */\n}\n\n.playlist-container.hidden {\n  display: none;\n}\n\n\n\n.playlist {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.playlist-edit {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.playlist-actions {\n  margin-top: 10px;\n  display: flex;\n  gap: 10px;\n}\n\n.video-actions {\n  margin-top: 20px;\n  display: flex;\n  gap: 10px;\n}\n\ninput[type=\"text\"] {\n  padding: 10px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\nbutton {\n  padding: 10px;\n  font-size: 16px;\n  color: white;\n  background-color: #007bff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
