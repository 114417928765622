// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* VideoChat.css */

.video-container {
  width: 300px;
  height: 300px;
  background-color: black;
}

.chat-box {
  border: 1px solid black;
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.users-list {
  list-style-type: none;
  padding-left: 0;
}

.user-item {
  margin-bottom: 5px;
}

.video-player-box {
  width: 480px;
  height: 320px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Online/VideoChat.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":["/* VideoChat.css */\n\n.video-container {\n  width: 300px;\n  height: 300px;\n  background-color: black;\n}\n\n.chat-box {\n  border: 1px solid black;\n  height: 300px;\n  overflow-y: scroll;\n  margin-bottom: 10px;\n}\n\n.users-list {\n  list-style-type: none;\n  padding-left: 0;\n}\n\n.user-item {\n  margin-bottom: 5px;\n}\n\n.video-player-box {\n  width: 480px;\n  height: 320px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
