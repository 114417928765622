import React, { createContext, useContext, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

const RootContext = createContext(null);

export const RootProvider = ({ children }) => {
  const rootRef = useRef(null);

  // useEffect(() => {
  //   const container = document.getElementById('root');
  //   if (!rootRef.current && container) {
  //     rootRef.current = createRoot(container);
  //   }
  // }, []);

  return (
    <RootContext.Provider value={rootRef.current}>
      {children}
    </RootContext.Provider>
  );
};

export const useRoot = () => useContext(RootContext);
