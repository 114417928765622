/**
 * Not work. OBS
 * ChatGPT can't make it work. OBS.
 */
import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import Peer from 'peerjs';
import { useAppContext } from '../../AppContext';  // Adjust the import path as necessary
import io from 'socket.io-client';

const PeerJsUserContext = createContext();

export const usePeerJsUsers = () => {
  return useContext(PeerJsUserContext);
};

export const PeerJsUserProvider = ({ children }) => {
  const { loginUserName, isActive } = useAppContext();
  const [peers, setPeers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(new Map());
  const [selfUserId, setSelfUserId] = useState(null);
  const [messages, setMessages] = useState([]);
  const socketRef = useRef(null);
  const peerInstance = useRef(null);
  const activeConnections = useRef({});  // Track active user connections

  const peerConfig = {
    config: {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
        {
          urls: 'turn:your.turn.server:3478',
          username: 'your-username',
          credential: 'your-credential'
        }
      ]
    }
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }

    const socket = socketRef.current;

    if (!peerInstance.current) {
      peerInstance.current = new Peer(peerConfig);
    }

    const peer = peerInstance.current;

    peer.on('open', id => {
      console.log('My peer ID is: ' + id);
      setSelfUserId(id);
      if (isActive && loginUserName) {
        joinRoom(id);
      }
    });

    peer.on('connection', conn => {
      console.log(`New connection from ${conn.peer}`);
      handleConnection(conn);
    });

    const joinRoom = (id) => {
      socket.emit('join', { room: 'presence_room', user_id: id, user_name: loginUserName });
    };


    socket.on('self_joined', ({ room, existing_users }) => {
      console.log(`Joined room: ${room}, socket id: ${socket.id}`);
      const newUsers = new Map(existing_users.map(user => [user.user_id, user.user_name]));
      setOnlineUsers(newUsers);
      existing_users.forEach((user) => {
        if (loginUserName && user.user_id !== loginUserName && !activeConnections.current[user.user_id]) {
          connectToPeer(user.user_id, user.user_name);
        }
      });
    });

    socket.on('user_connected', ({ user_id, user_name }) => {
      setOnlineUsers(users => {
        const newUsers = new Map(users);
        newUsers.set(user_id, user_name);
        return newUsers;
      });
      if (loginUserName && user_id !== loginUserName && !activeConnections.current[user_id]) {
        connectToPeer(user_id, user_name);
      }
    });

    socket.on('user_disconnected', ({ user_id }) => {
      if (activeConnections.current[user_id]) {
        activeConnections.current[user_id].close();
        delete activeConnections.current[user_id];
      }
      setPeers(peers => peers.filter(p => p.peer !== user_id));
      setOnlineUsers(users => {
        const newUsers = new Map(users);
        newUsers.delete(user_id);
        return newUsers;
      });
    });

    socket.on('signal', ({ from, signal }) => {
      if (activeConnections.current[from]) {
        activeConnections.current[from].signal(signal);
      }
    });

    socket.on('ice_candidate', ({ from, candidate }) => {
      if (activeConnections.current[from]) {
        activeConnections.current[from].signal(candidate);
      }
    });

    return () => {
      socket.off('self_joined');
      socket.off('user_connected');
      socket.off('user_disconnected');
      socket.off('signal');
      socket.off('ice_candidate');
    };
  }, [isActive, loginUserName]);

  const handleConnection = (conn) => {
    conn.on('open', () => {
      console.log(`Connection open with ${conn.peer}`);
      activeConnections.current[conn.peer] = conn;
      setPeers(peers => [...peers, conn]);
      setOnlineUsers(users => {
        const newUsers = new Map(users);
        newUsers.set(conn.peer, conn.metadata);
        return newUsers;
      });
    });

    conn.on('data', data => {
      console.log('Received:', data);
      setMessages(prevMessages => [...prevMessages, data]);
    });

    conn.on('close', () => {
      console.log('Connection closed');
      delete activeConnections.current[conn.peer];
      setPeers(peers => peers.filter(p => p.peer !== conn.peer));
      setOnlineUsers(users => {
        const newUsers = new Map(users);
        newUsers.delete(conn.peer);
        return newUsers;
      });
    });

    conn.on('error', (err) => {
      console.error(`Connection error with ${conn.peer}:`, err);
    });
  };

  const connectToPeer = (peerId, peerName) => {
    if (!activeConnections.current[peerId]) {
      const conn = peerInstance.current.connect(peerId, { metadata: peerName });
      console.log(`Connecting to ${peerId}`);
      handleConnection(conn);
    }
  };

  const sendMessage = (to, message) => {
    console.log(`Sending message to ${to}: ${message}`);
    const conn = activeConnections.current[to];
    if (conn && conn.open) {
      conn.send(message);
      setMessages(prevMessages => [...prevMessages, { from: selfUserId, message }]);
    } else {
      console.log(`Connection to ${to} is not open. Retrying...`);
      // Retry connecting
      if (onlineUsers.has(to)) {
        connectToPeer(to, onlineUsers.get(to));
      }
    }
  };

  return (
    <PeerJsUserContext.Provider value={{ onlineUsers: Array.from(onlineUsers.entries()), selfUserId, messages, sendMessage }}>
      {children}
    </PeerJsUserContext.Provider>
  );
};

export default PeerJsUserProvider;
