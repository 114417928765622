import React, { useState } from 'react';
import './ConfirmationDialog.css'


const ConfirmationDialog = ({ 
    show=true, 
    title = 'Confirm Action', 
    message = 'Are you sure you want to proceed with this action?', 
    onCancel = null, 
    onConfirm = null
  }) => {
  if (!show) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog">
        <h4>{title}</h4>
        <p>{message}</p>
        <div className="dialog-actions">
          <button onClick={onCancel} className="dialog-button">Cancel</button>
          <button onClick={onConfirm} className="dialog-button">Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;