// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwoPageOverlay_twoPageOverlayContainer__MIw2B {
  position: relative;
  height: 200vh; /* Ensure container allows full scroll of both pages */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100vw; /* Ensure full width */
}

.TwoPageOverlay_page__TDEuZ {
  width: 100%;
  height: 100vh; /* Ensure each page takes full viewport height */
  transition: opacity 0.5s ease, top 0.5s ease;
}

.TwoPageOverlay_page1__8G7TD {
  z-index: 1;
  opacity: 1;
  position: fixed; /* Fix Page 1 in place */
  top: 0;
  left: 0;
  right: 0;
}

.TwoPageOverlay_page2__7CMmn {
  z-index: 2;
  top: 100vh; /* Position Page 2 below the viewport initially */
  opacity: 0;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TwoPageOverlay/TwoPageOverlay.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa,EAAE,sDAAsD;EACrE,gBAAgB,EAAE,8BAA8B;EAChD,YAAY,EAAE,sBAAsB;AACtC;;AAEA;EACE,WAAW;EACX,aAAa,EAAE,gDAAgD;EAC/D,4CAA4C;AAC9C;;AAEA;EACE,UAAU;EACV,UAAU;EACV,eAAe,EAAE,wBAAwB;EACzC,MAAM;EACN,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,UAAU;EACV,UAAU,EAAE,iDAAiD;EAC7D,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".twoPageOverlayContainer {\n  position: relative;\n  height: 200vh; /* Ensure container allows full scroll of both pages */\n  overflow-y: auto; /* Enable vertical scrolling */\n  width: 100vw; /* Ensure full width */\n}\n\n.page {\n  width: 100%;\n  height: 100vh; /* Ensure each page takes full viewport height */\n  transition: opacity 0.5s ease, top 0.5s ease;\n}\n\n.page1 {\n  z-index: 1;\n  opacity: 1;\n  position: fixed; /* Fix Page 1 in place */\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n.page2 {\n  z-index: 2;\n  top: 100vh; /* Position Page 2 below the viewport initially */\n  opacity: 0;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twoPageOverlayContainer": `TwoPageOverlay_twoPageOverlayContainer__MIw2B`,
	"page": `TwoPageOverlay_page__TDEuZ`,
	"page1": `TwoPageOverlay_page1__8G7TD`,
	"page2": `TwoPageOverlay_page2__7CMmn`
};
export default ___CSS_LOADER_EXPORT___;
