// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Basic styling for items */
.item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.video-thumbnail img {
  width: 100px;
  height: 100px;
}

.photo-thumbnail img {
  width: 100px;
  height: 100px;
}

/* Styling for folders */
.folder {
  border: 1px solid #aaa;
  padding: 10px;
  margin: 10px;
}

.folder-name {
  font-weight: bold;
}

/* Add more specific CSS styles as needed for your design */
`, "",{"version":3,"sources":["webpack://./src/TestCom/MediaPage/MediaPage.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA,wBAAwB;AACxB;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA,2DAA2D","sourcesContent":["/* Basic styling for items */\n.item {\n  border: 1px solid #ccc;\n  padding: 10px;\n  margin: 10px;\n  text-align: center;\n}\n\n.video-thumbnail img {\n  width: 100px;\n  height: 100px;\n}\n\n.photo-thumbnail img {\n  width: 100px;\n  height: 100px;\n}\n\n/* Styling for folders */\n.folder {\n  border: 1px solid #aaa;\n  padding: 10px;\n  margin: 10px;\n}\n\n.folder-name {\n  font-weight: bold;\n}\n\n/* Add more specific CSS styles as needed for your design */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
