// EditCheckbox.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS


const EditCheckbox = ({ isChecked, onCheckboxChange }) => {

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="editCheckbox"
        checked={isChecked}
        onChange={onCheckboxChange}
      />
      <label className="form-check-label" htmlFor="editCheckbox">
        Edit
      </label>
    </div>
  );
};

export default EditCheckbox;
