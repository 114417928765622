// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page1Content {
  background-color: rgba(0, 128, 0, 1); /* Green background */
  color: white;
  height: 100%;
}

.page2Content {
  background-color: rgba(0, 0, 128, 1); /* Blue background */
  color: white;
  padding: 20px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TwoPageOverlay/TestTwoPageOverlay.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC,EAAE,qBAAqB;EAC3D,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,oCAAoC,EAAE,oBAAoB;EAC1D,YAAY;EACZ,aAAa;EACb,YAAY;AACd","sourcesContent":[".page1Content {\n  background-color: rgba(0, 128, 0, 1); /* Green background */\n  color: white;\n  height: 100%;\n}\n\n.page2Content {\n  background-color: rgba(0, 0, 128, 1); /* Blue background */\n  color: white;\n  padding: 20px;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
