import $ from 'jquery';    // React only

import {f2fBase} from '../js/base.js'
import {floatToolbar} from './float-toolbar.js';
import { f2futil } from '../utils/utils.js';


//import toggleButton from '../ui.components/toggle/js/toggle.js';
//import {split2Col} from '../ui.components/sticky.split/split-2col.js';
//import {skyBrowse} from '../ui.components/sky.browse/sky-browse.js'; 

/**
 * Toggle button on top to make content editable.
 * 
 * When on it put article section under 2 column split panel left side. Right
 * side is resource browser.
 */

  /**
   * 
   * 
   * Input:
   *  toggleDivId: it's defined in base html for top nav bar.
   *  articleContainerId: article container div.
   */

/*
//export class articleEditToggle extends toggleButton {
export class articleEditToggle {

  constructor(toggleDivId, articleContainerId, articleId) {

    const SPLIT_PANEL_ID = 'split-panel-container-id';
    const _RIGHT_PANEL_ID = 'sky-res-browser-id';
    
    //super(toggleDivId);
    
    this.articleId = articleId;
    this.SPLIT_PANEL_ID = SPLIT_PANEL_ID;
    this._RIGHT_PANEL_ID = _RIGHT_PANEL_ID;
    
    this.artiEditor = null;
    this.split = null;
    this.articleContainerId = articleContainerId;
    this.$articleContainerId = $('#' + this.articleContainerId);
  }
  
  event_click() {
    //super.event_click(); 
    
    // Set property state for toggle button, which is itself.
    this.checkStatus = this.$inputCheck.is(':checked');
    
    if (this.checkStatus) {
      // Create editor
      if (!this.artiEditor) {
        this.artiEditor = new articleEditor(this.articleId);
        
        // Add split 2col
        let $split = $("<div>", {id: this.SPLIT_PANEL_ID});
        $split.insertBefore(this.$articleContainerId);  // Anchor it so we know where it is.
        
        //$div.append(this.$articleContainerId);
        //this.$articleContainerId.append("<div id='hlai'></div>");

        
        //let $split = $(document.createElement('div')).attr('id', this.SPLIT_PANEL_ID);
        //$split.insertBefore(this.$articleContainerId);  // Anchor it so we know where it is.
        
        
        // We know the div is already created.
        this.split = new split2Col(this.SPLIT_PANEL_ID);
        
        // Move article to left of split panel.
        this.$articleContainerId.appendTo(this.split.$left)

        // Create resource browser and add it to right.
        this.split.$right.attr('id', this._RIGHT_PANEL_ID);
        this.resBrowser = new skyBrowse(this._RIGHT_PANEL_ID,
                                        window.dir_tree,
                                        //window.loi_channel['channel_filename'],
                                        //window.loi_channel['content_list'],
                                        'article',
                                        [],
                                        this.contentListUI); 
      }

      // Enable editable elements.
      $('article').children().each(function () {
        $(this).attr('contenteditable', 'true');
      });
      
      // Change movie player sizes to small.
      $('.video-play').each(function() {
        $(this).removeClass('size-normal').addClass('size-small');
      });
      
      // Change youtube player sizes to small.
      $('.player-yt').each(function() {
        $(this).removeClass('size-normal').addClass('size-small');
        //$(this).find('div > iframe.player-iframe').first().removeClass('size-normal').addClass('size-small');
      });
    } else {
      // Disable editable elements.
      $('article').children().each(function () {
        $(this).attr('contenteditable', 'false');
      });
      
      // Move article out from split view.
      this.$articleContainerId.insertBefore(this.split.$div);

      // Change movie player sizes to normal.
      $('.video-play').each(function() {
        $(this).removeClass('size-small').addClass('size-normal');
      });
      
      // Change youtube player sizes to normal.
      $('.player-yt').each(function() {
        // 2 elements
        $(this).removeClass('size-small').addClass('size-normal');
        //$(this).find('div > iframe.player-iframe').first().removeClass('size-small').addClass('size-normal');
      });
      
      // Delete split view
      this.split.destroy();
      delete this.split;
      this.split = null;
      
      // Delete editor.
      if (this.artiEditor) {
        this.artiEditor.destroy();
        delete this.artiEditor;
        this.artiEditor = null;
      }
    }
  }
}


export class articleBase extends blockToolbar {
  
  constructor(divId, options) {
    
    super(divId, options);
    
  }
}
*/


/**
 * Handle edit mode.
 */
export class articleEditToggle extends f2fBase {
  constructor(articleContainerId, articleId, options=null) {
    super(null, articleId);

    this.articleId = articleId;
  }

  enableEdit(editMode) {

    if (editMode) {
      this.$div.children().each(function () {
        $(this).attr('contenteditable', 'true');
      });
    } else {
      this.$div.children().each(function () {
        $(this).attr('contenteditable', 'false');
      });
    }

  }
}

/**
 * Article editor.
 * 
 * Make sure there is at least 1 article element, which can't be deleted, so that
 * editor can be anchored.
 */
export class articleEditor extends floatToolbar {
  // Simply load css and js in main html for now.
  /*
  static get JQUERY_1_12_1_CSS() {
    return {
      name: '../../3rdparty/jquery-ui-1-12-1/jquery-ui.css',
      import: import('../../3rdparty/jquery-ui-1-12-1/jquery-ui.css')
    };
  }
  */
  static get CSS_ARTICLE_EDITOR() {
    return {
      name: './article-editor.css',
      import: import('./article-editor.css')
    };
  }

  constructor(articleId, options=null) {
    const ARTICLE_EDITOR_ID = 'article-editor';

    const TMPL_ARTICLE = `
      <h1>New title...</h1>
      <p>Enter here...</p>
    `;
    
    const TMPL_DROPBOX = `
      <ul id="dropbox" class="dropbox-ul" contenteditable="true">
  
      </ul>
    `;
    
    const TMPL_PHOTO = ({img64, title, url}) => `
      <figure class="photo">
        <img src="data:image/png;base64, ${img64}">
        <figcaption>${title}</figcaption>
      </figure>
    `;

    const TMPL_MOVIE =`
      <figure class="movie" contenteditable="true">

      </figure>
    `;
    
    const TMPL_MOVIE_CONTENT =  ({img64, title, url}) => `
      <img src="data:image/png;base64, ${img64}">
      <figcaption>${title}</figcaption>
    `;
    
    const TMPL_MOVIE_PLAYER = ({movie_src, file_ext, title, movie_url}) => `
      <figure class='movie' data-value='${movie_url}' contentEditable="true">
        <video class="video-play size-small" muted={false} playsinline controls>
          <source src="${movie_src}" type="video/${file_ext}">
          Your browser does not support the video tag.
        </video>
        <figcaption>${title}</figcaption>
      </figure>
    `;
    
    const TMPL_YOUTUBE_PLAYER = ({youtube_id, title, youtube_url}) => `
      <figure class='youtube' data-value='${youtube_url}' contentEditable="true">
        <iframe class='player-yt size-small' frameborder="0"
            src="https://www.youtube.com/embed/${youtube_id}?autoplay=0">
        </iframe>
        <figcaption>${title}</figcaption>
      </figure>
    `;

    var defaultSettings = {
        size: 'normal',
        shape: null,
        iconList: [
                  {iconId: 'down',
                   iconName: 'arrow-down',
                  },
                  {iconId: 'up',
                    iconName: 'arrow-up',
                  },
                  {iconId: 'add',
                   iconName: 'plus',
                   iconShow: false,
                  },
                  {iconId: 'fontS',
                    iconName: 'font',
                    iconShow: false,
                    iconSize: 'small'
                  },
                  {iconId: 'fontM',
                    iconName: 'font',
                  },
                  {iconId: 'fontL',
                    iconName: 'font',
                    iconShow: false,
                    iconSize: 'large'
                  },
                  {iconId: 'photo',
                    iconName: 'file-image-o',
                  },
                  {iconId: 'video',
                    iconName: 'file-movie-o',
                  },
                  {iconId: 'save',
                   iconName: 'floppy-o',
                  },
                  {iconId: 'delete',
                    iconName: 'trash-o',
                  },
                  {iconId: 'reply',
                    iconName: 'reply',
                    iconShow: false,
                   }],
        event_positionChange: null,
        event_click: null,
      };

    $("body").append("<div id='" + ARTICLE_EDITOR_ID + "'></div>");

    const menuStateEnum = {main: 1,
                           font: 2,
                           image: 3,
                           video: 4};
    Object.freeze(menuStateEnum)
    
    const defaultMediaSettings = {
      onChangeEditItem: null,
      onSave: null,
      onDropMediaContainer: null,
      onClickPasteMedia: null
    }

    let curSettings = $.extend(defaultMediaSettings, options);
    curSettings = $.extend(defaultSettings, curSettings);
    super(ARTICLE_EDITOR_ID, curSettings);

    this.loadCSS(articleEditor.CSS_ARTICLE_EDITOR);

    this.articleId = articleId;
    this.tmpl_DROPBOX = TMPL_DROPBOX;
    this.tmpl_PHOTO = TMPL_PHOTO;
    this.tmpl_MOVIE = TMPL_MOVIE;
    this.tmpl_YOUTUBE_PLAYER = TMPL_YOUTUBE_PLAYER;
    this.tmpl_MOVIE_PLAYER = TMPL_MOVIE_PLAYER;
    
    let clickHandlers = [
                        {iconId: 'down',
                          clickHandler: this.event_down
                        },
                        {iconId: 'up',
                          clickHandler: this.event_up
                        },
                        {iconId: 'add',
                          clickHandler: this.event_add
                        },
                        {iconId: 'fontS',
                          clickHandler: this.event_fontS
                        },
                        {iconId: 'fontM',
                          clickHandler: this.event_fontM
                        },
                        {iconId: 'fontL',
                          clickHandler: this.event_fontL
                        },
                        {iconId: 'photo',
                          clickHandler: this.event_photo
                        },
                        {iconId: 'video',
                          clickHandler: this.event_video
                        },
                        {iconId: 'save',
                          clickHandler: this.event_save
                        },
                        {iconId: 'reply',
                          clickHandler: this.event_reply
                        },
                        {iconId: 'delete',
                          clickHandler: this.event_delete
                        }];

    this.menuStateEnum = menuStateEnum;
    this.curState = this.menuStateEnum.main;
      
    let status = this.initButtonClickHandler(clickHandlers);

    // It contains all editable elements.
    this.editableContainerId = '#' + 'article-container';
    
    this.prevEditItem = $();
    this.prevItemIsDropbox = false;

    this.init();
  }

  destroy() {
    // Remove event handler. Otherwise, old event handler still exist after 
    // this class object is deleted. It will cause big problem.
    $(this.editableContainerId).off('click');
    $(this.editableContainerId).off('paste');
    $(this.editableContainerId).off('keypress');
    this.$div.remove();
  }

  init() {
    $(this.editableContainerId).on('keypress', (event) => {
      // Check if the pressed key has a keycode of 13 (Enter key)
      if (event.which === 13) {
        this.handleEnter(event);
      }
    });

    $(this.editableContainerId).on('paste', (event) => {
      //if ($(event.target).is("[contentEditable='true']")) {
        // Prevent the default paste behavior
        event.preventDefault();
    
        // Get the text content from the clipboard
        var text = event.originalEvent.clipboardData.getData('text/plain');
    
        // Insert the plain text at the cursor position
        document.execCommand("insertText", false, text);
      //}
      // // Find the closest parent with contentEditable='true' within the container
      // var $container = $('#' + this.articleId);
      // var $editableParent = $(event.target).closest('[contentEditable="true"]', $container);

      // if ($editableParent.length > 0) {
      //   // Prevent the default paste behavior
      //   event.preventDefault();

      //   // Get the text content from the clipboard
      //   var text = event.originalEvent.clipboardData.getData('text/plain');

      //   // Get the current selection
      //   var selection = window.getSelection();
      //   if (!selection.rangeCount) return;

      //   // Get the range of the current selection
      //   var range = selection.getRangeAt(0);
      //   range.deleteContents();

      //   // Create a new text node and insert it into the target element
      //   var textNode = document.createTextNode(text);
      //   range.insertNode(textNode);

      //   // Move the cursor to the end of the inserted text
      //   range.setStartAfter(textNode);
      //   range.setEndAfter(textNode);
      //   selection.removeAllRanges();
      //   selection.addRange(range);

      //   // Optional: focus the target element to ensure the cursor is visible
      //   event.target.focus();
      // }
    });
    
    $(this.editableContainerId).on('click', (event) => {
      // If clicked item is editable.
      // 1) Editable item click handler.
      //    For editable item, create click handler. It show save button toolbar
      //    when the item is clicked.
      // 2) Video editor will be placed at bottom of title div.

      if ($(event.target).is("[contentEditable='true']")) {
        // Before moving toolbar to newly clicked item, it check if previous
        // clicked item is dropbox container.
        // If adding dropbox by clicking video button on toolbar, the dropped container
        // will be under toolbar, which is equivalent to clicked item.


        // $(event.target) is title div.
        //this.$div.insertBefore($(event.target));
        // this.$wrapDiv.insertBefore($(event.target));  // work, use jquery function
        
        // Move toolbar to clicked item.
        this.appendBefore($(event.target),  // This is clicked item
                          this.$wrapDiv);   // This is toolbar.
        

        let changed = false;
        if (this.isJqueryObj(this.prevEditItem) && this.isJqueryObj($(event.target))) {

          // Handle media dropbox.
          // Check if previous edit item is media container ul. If empty, delete it.
          if (!this.equalJqueryObj(this.prevEditItem, $(event.target))) {
            // If clicked different item on html, and if previous 'clicked' (inserted dropbox which
            // is tracked) is dropped media container, it needs to know if it's empty, and empty 
            // container should be removed.
            const isDropbox = this.isThisJqueryObjByClass(this.prevEditItem, 'dropbox-ul');

            let empty = true;
            if (isDropbox) {
              empty = this.isEmptyDropbox(this.prevEditItem);

              // Remove previous dropbox if empty.
              if (empty) {
                this.prevEditItem.remove();
              }
            }
            
            this.prevEditItem = $(event.target);
            //changed = true;
          }
        }

        // 6/3/24
        // Detect if position is changed.
        if (typeof this.options.onChangeEditItem=== 'function') {
          this.options.onChangeEditItem(event, $(event.target), changed);
        }
         

        /*
        // Don't show title editor for new title, which has special new title editor.
        // If link has no domain, it means the new title is not saved yet.
        let tmp_$eleDiv = $(event.target);
        let tmp_$link = tmp_$eleDiv.children('a');
        let tmp_linkVal = tmp_$link.attr('href');
        
        if (!tmp_linkVal.includes(window.origin)) {
          this.$div.hide();
          return;
        }
        */
        this.show();
        /*
        // Insert video editor after title.
        // Since the editable div is dynamic depending on where the click is, it
        // needs to dynamically to create.
        this.$movieGrid = $(event.target).next().next();  // youtube editor is in middle of title and grid.
        this.$title = $(event.target);
        this.$titleLink = $(event.target).children('a');

        // When moving editor, if open, close it first.
        if (this.openStatus) {
          this.resetButton();
          this.openStatus = false;
        } else {
          // If closed, set it open.
          this.openStatus = true;
        }
        */
      // } else if () {
      //   // Handle element with editable parent. This item is entered:
      //   // 1) Enter key press in current p, and <div><br></div> will be inserted without
      //   //    editable  is("[contentEditable='true']" added.
      //   // 2) Paste?
      } else if ($(event.target).parent().is("[contentEditable='true']")) {
        // This is to handle cases new item is inserted as immediate children. It's not possible
        // to insert another layer of children. Only 1 layer is observed.
        // a) Hit enter, <div><br></div>
        // b) Pasted new items inter new line in a).
        // Here is to make sure toolbar is moved to above immediate child of 'articlaid' div.
        // not the new line or newly pasted text, which is in new <div> and has no editable mode
        // enabled.

        this.$wrapDiv.insertBefore($(event.target).parent());
        this.show();
        // var $container = $('#' + this.articleId);
        // var $editableParent = $(event.target).closest('[contentEditable="true"]', $container);
      
        // if ($editableParent.length > 0) {
      } else if($(event.target).closest('article').find("[contentEditable='true']").length > 0) {
        // What's goal here. 
        // All above check is event.target is under <article> container, 1 level down. For inserted
        // component with complicated layers, it does not work as the click is not 1 level down
        // of <article>.
        // Here is to handle event.target on elements inside react component.
        // Dropbox is added, and it's unfocus, and focus again. We are trying to identify the
        // object.
        // 6/3/24. For react inserted dropbox, it's little uncertain how many layer of parent
        // up to find ul element. All known is that it's under <article>.
        const $target = $(event.target);
        const $article = $target.closest('article');

        if ($article.length > 0) {
          const $findDropbox = $article.find('ul.dropbox-ul[contentEditable="true"]');
          
          if ($findDropbox.length > 0) {
            // Find parent of $target which is just clicked.
            let $parentDropbox = null;

            $findDropbox.each(function() {
                if ($target.closest(this).length > 0) {
                    $parentDropbox = $(this);
                    return false; // Break the loop once the parent is found
                }
            });

            // Move toolbar to correct ul dropbox, which is parent of $target.
            if ($parentDropbox && !this.toolbarAttached($parentDropbox)) {
              this.appendBefore($parentDropbox,  // This is dropbox identified by clicked $target.
              this.$wrapDiv);   // Toolbar.
              this.show();
            }
          }
          // if ($editableUl.length > 0) {
          //     if ($editableUl.find('div[class*="div-video-grid"] div[class*="video-cell"]').length > 0) {
          //         console.log('This ul contains a div-video-grid with a video-cell:', $editableUl.attr('id'));
          //     } else {
          //         console.log('This ul does not contain a div-video-grid with a video-cell:', $editableUl.attr('id'));
          //     }
          // }
        }

        //const $findDropbox = $(event.target).closest('article').find("[contentEditable='true']");


      } else if ($(event.target).is('figcaption')) {
        // Get figure which is parent of this figure catpion.
        let $fig = $(event.target).parent();
        $fig.trigger('click');
      }
    });
  }

  // If user clicks insert button on toolbar, a video grid (react) will be
  // inserted into $ul. But, this video grid is empty now, unless user pastes
  // video item into this video grid by click this box again.
  isEmptyDropbox($ul) {
    const a = $ul.find('div[class*="div-video-grid"]');
    const b = $ul.find('div[class*="div-video-grid"] div[class*="video-cell"]');
    if ($ul.find('div[class*="div-video-grid"] div[class*="video-cell"]').length > 0) {
      console.log('This ul contains a div-video-grid:', $ul.attr('id'));
      return false;
    } else {
      console.log('This ul does not contain a div-video-grid:', $ul.attr('id'));
      return true;
    }
  }
    
  // Helper function to get the last line of a contenteditable element
  getLastLine(editableElement) {
    const lines = editableElement.textContent.split('\n');
    const lastLine = lines[lines.length - 1];
    const textNodes = editableElement.childNodes;
    let cumulativeLength = 0;
  
    // Find the last text node corresponding to the last line
    for (let i = 0; i < textNodes.length; i++) {
      const node = textNodes[i];
      const nodeLength = node.nodeType === Node.TEXT_NODE ? node.length : 1;
      cumulativeLength += nodeLength;
  
      if (cumulativeLength >= lastLine.length) {
        return node;
      }
    }
  
    return editableElement; // Fallback, return the whole element
  }

  countTextCharacters(element) {
    // Clone the element to avoid affecting the actual content
    const clonedElement = element.cloneNode(true);

    // Remove unnecessary elements (e.g., <div><br></div>)
    $(clonedElement).find('div:empty, br').remove();

    // Get the text content of the cloned element
    const textContent = clonedElement.textContent || clonedElement.innerText;

    // Count the characters in the text content
    return textContent.length;
  }

  isCursorAtEnd(editableElement) {
    var selection = window.getSelection();
    var range = selection.getRangeAt(0);

    // Check if the end container is the same as the editable element
    // and if the end offset is the length of the child nodes
    return (
      range.endContainer === editableElement &&
      range.endOffset === editableElement.childNodes.length
    );

    /*
    // Get the current selection
    const selection = window.getSelection();

    // Check if there is a selection
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Check if the end of the range is at the end of the last line
      const lastLine = this.getLastLine(editableElement);
      const isLastLine =
        range.endContainer === lastLine &&
        range.endOffset === lastLine.textContent.length;

      return isLastLine;
    }

    return false;
    */
  }

  handleEnter(event) {
    // Check if the cursor is at the end of the text
    //const editableElement = event.currentTarget;
    const editableElement = event.target;

    if (this.isCursorAtEnd(editableElement)) {
      // Cursor is at the end of the text.
      // Move toolbar 1 line down and add new line. 
      this.event_down(event);
      ///this.addNewLine();
    } else {
      // Cursor is not at the end of the text.
    }
  }


  // if ($(event.target).is("[contentEditable='true']")) {
  //   // Remove HTML from pasted content.
  //   // https://stackoverflow.com/questions/6899659/remove-formatting-from-a-contenteditable-div
  //   event.preventDefault();
    
  //   if ($(event.target).is('p') || 
  //       $(event.target).is('h3') || 
  //       $(event.target).is('h1')) {
  //     // Paste image from url
  //     // Paste image from local file.
  //     /*
  //     // paste image
  //     // https://stackoverflow.com/questions/6333814/how-does-the-paste-image-from-clipboard-functionality-work-in-gmail-and-google-c
  //     var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
  //     console.log(JSON.stringify(items)); // will give you the mime types
  //     // find pasted image among pasted items
  //     var blob = null;
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].type.indexOf("text/html") === 0) {
  //         blob = items[i].getAsString(function(str)
  //             {
  //                 dropstr=str; 
  //             }    
  //         );
  //       }

  //     }
  //     */
  //     var html = event.originalEvent.clipboardData.getData('text/html');
  //     document.execCommand("insertHTML", true, html);
  //     //var text = event.originalEvent.clipboardData.getData('text');
  //     //document.execCommand("insertHTML", false, text);
  //   } else {
  //     //var html = event.originalEvent.clipboardData.getData('text/html');
  //     //document.execCommand("insertHTML", true, html);
  //   }

  // }

  get $article() {
    return this.$toolbarDiv.parent();
  }
  
  /**
   * Get current element being editted.
   */
  cur_element() {
    return this.$toolbarDiv.next();
  }

  menuMain() {
    this.hideAllButtons();
    
    this.showButton('down', true);
    this.showButton('up', true);
    this.showButton('add', false);
    this.showButton('fontS', false);
    this.showButton('fontM', true);
    this.showButton('fontL', false);
    this.showButton('photo', true);
    this.showButton('video', true);
    this.showButton('save', true);
    this.showButton('delete', true);
    
    this.curState = this.menuStateEnum.main;
  }
  
  menuFontSub() {
    this.hideAllButtons();
    
    this.showButton('add', true);
    this.showButton('fontS', true);
    this.showButton('fontM', true);
    this.showButton('fontL', true);
    this.showButton('save', true);
    this.showButton('delete', true);
    this.showButton('reply', true);
    
    this.curState = this.menuStateEnum.font;
  }
  
  menuPhoto() {
    this.hideAllButtons();
    
    this.showButton('photo', true);
    this.showButton('save', true);
    this.showButton('delete', true);
    this.showButton('reply', true);
    
    this.curState = this.menuStateEnum.image;
  }
  
  menuVideo() {
    this.hideAllButtons();
    
    //this.showButton('video', true);
    this.showButton('save', true);
    this.showButton('delete', true);
    this.showButton('reply', true);
    
    this.curState = this.menuStateEnum.video;
  }
  
  menuChannel() {
    
  }
  
  
  // animate the jquery insertAfter, insertBefore.
  // https://stackoverflow.com/questions/17020758/add-sliding-animation-to-jquery-insertafter-and-insertbefore
  event_up(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    // Move DOM element.
    let ele = this.cur_element();
    let eleTarget = this.$toolbarDiv.prev();
    //eleTarget.insertBefore(ele);
    ele.insertBefore(eleTarget).hide().show('slow');
    
    // Move editor.
    this.$toolbarDiv.insertBefore(ele).hide().show('slow');
    //this.$div[0].scrollIntoView();
    /*
    this.$div[0].scrollIntoView({
      behavior: "smooth", // or "auto" or "instant"
      block: "start" // or "end"
    });
    */
    this.$toolbarDiv[0].scrollIntoView({
      behavior: "smooth", // or "auto" or "instant"
      block: "start" // or "end"
    });
  }
  
  event_down(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    //let ele = this.$div.parent().next();
    // Move DOM element.
    let eleTarget = this.cur_element().next().next();
    eleTarget.insertAfter(this.cur_element()).hide().show('slow');
    
    // Move editor.
    eleTarget.insertBefore(this.$toolbarDiv);
    this.$toolbarDiv.hide().show('slow')
    
    //this.$div[0].scrollIntoView();
    this.$toolbarDiv[0].scrollIntoView({
      behavior: "smooth", // or "auto" or "instant"
      block: "start" // or "end"
    });
  }
  
  addNewLine() {
    this.$toolbarDiv.after('<h3 contenteditable="true">Add new text here...</h3>');
  }

  event_add(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    this.addNewLine();
  }
  
  event_fontS(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    let $ele = this.cur_element();
    let content = $ele.text();
    $ele.replaceWith('<p contenteditable="true">' + content + '</p>');
  }
  
  event_fontM(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    if (this.curState == this.menuStateEnum.font) {
      let $ele = this.cur_element();
      let content = $ele.text();
      $ele.replaceWith('<h3 contenteditable="true">' + content + '</h3>');
    } else {
      // If previous is main menu, display font sub menu.
      this.menuFontSub();
    }
  }
  
  event_fontL(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    let $ele = this.cur_element();
    let content = $ele.text();
    $ele.replaceWith('<h1 contenteditable="true">' + content + '</h1>');
  }
  
  
  event_photo(evt) {
    evt.preventDefault();
    evt.stopPropagation();  // stop toolbar click propogate to parent

    this.menuPhoto();
    
    // Add photo.
    
  }

  // Toolbar menu click video icon event
  event_video(evt) {
    evt.preventDefault();
    evt.stopPropagation();  // stop toolbar click propogate to parent

    const util = new f2futil();
    const randomString = util.generateRandomString();

    // ------- Avoid use hard coded 'dropbox' ul id defined in template.
    // // Create a DOM element from the template
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(this.tmpl_DROPBOX, 'text/html');
    // const ulElement = doc.body.querySelector('ul');

    // // Modify the id attribute locally within the template
    // if (ulElement && ulElement.id) {
    //   ulElement.id += `_${randomString}`;
    // }

    // // Serialize the DOM element back to a string
    // const serializer = new XMLSerializer();
    // const tmplWithId = serializer.serializeToString(doc.body.firstChild);

    // Modify the template to include the random string in the id
    //const tmplWithId = this.tmpl_DROPBOX.replace('id="dropbox"', `id="dropbox_${randomString}"`);

    // Create a temporary container element
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = this.tmpl_DROPBOX;

    // Modify the id attribute within the template
    const ulElement = tempContainer.querySelector('ul');
    if (ulElement && ulElement.id) {
      ulElement.id += `_${randomString}`;
    }

    // Get the modified innerHTML
    const tmplWithId = tempContainer.innerHTML;
    
    // Create drop video container.
    const $mediaContainer = $(tmplWithId).insertBefore(this.$nextElement);

    // This dropped media container = clicked item. In both cases, toolbar will float
    // on top of it. 
    // Track clicked item is to determine if there is video inserted afterward. It needs
    // to be deleted if empty when user clicks another item in html.
    this.prevEditItem = $mediaContainer;
    this.prevItemIsDropbox = true;

    // When insert media toolbar button is clicked, notify parent to drop
    // video grid container.
    if (typeof this.options.onDropMediaContainer=== 'function') {
      this.options.onDropMediaContainer(evt, $mediaContainer, 'url');
    }

    // if (!this.equalJQueryObj(this.prevEditItem, $mediaContainer)) {


    //   // Track current editable item. 
    //   this.prevEditItem = $mediaContainer;

    //   // 
    // }


    // Create paste handler. User clicks video container, which hosts video grid
    // created by react component. 
    // Notify video grid component a new video is pasted, which needs to call rest API
    // to get pasted video thumbnail, video title, etc.
    $mediaContainer.on('click', (evt) => {
      evt.preventDefault();
      // Two kinds of clicks need to be distinguished.
      // 1) No toolbar yet. Click will bring in toolbar. It's not paste click.
      // 2) Toolbar attached. Click is paste.

      // Make sure the dropbox has toolbar on top. Otherwise, it's not pastable.
      if (this.toolbarAttached($mediaContainer)) {
        if (typeof this.options.onClickPasteMedia=== 'function') {
          $mediaContainer.focus();
          this.options.onClickPasteMedia(evt);
        }
      } else {

      }
    });

  }

  toolbarAttached($ulDropbox) {
    return this.$wrapDiv.next().is($ulDropbox);
  }

  // Originaly, it's using jquery ui to handle 2 lists drag/drop
  event_video_orig(evt) {
    evt.preventDefault();
    evt.stopPropagation(); 

    let prevState = this.curState;
    
    this.menuVideo();
    
    // Exclude transition from main to submenu, which is just a transition.
    if (prevState != this.menuStateEnum.main) {
      // Add video section.
      /*
      let insertList = [];
      insertList.push({list_id: this.listId});
      
      let insertItem = insertList.map(this.tmpl_MOVIE).join('')
      
      // Don't use html() which overwrites existing content. 
      this.$div.append(insertItem);
      $(insertItem).insertAfter(this.$div);
      */

      ////$( this.tmpl_DROPBOX).insertBefore(this.$nextElement);
      
      // let $tmpl = this.appendTmplTo$Ele(this.tmpl_MOVIE,
      //                                  this.$nextElement);  // Editor's next element.
    } else {
      //$( this.tmpl_DROPBOX).insertBefore(this.$nextElement);
    }
    
    $( this.tmpl_DROPBOX).insertBefore(this.$nextElement);
    
    let $dropbox = this.$nextElement;
    
    $dropbox.sortable({
      connectWith: ".connectedSortable"
    }).disableSelection();
    

    $dropbox.sortable({ 
      cancel: ".unsortable" 
    });
    
    $dropbox.sortable({
      update: function(evt) {
        // When item is dropped.
        this.handler_dropItem();
      }.bind(this),
      stop: function(evt) {
        // When item is dragged out.
        let a = 0;
          //var order = $("#sortable").sortable("serialize", {key:'order[]'});
          //$( "p" ).html( order );
      }.bind(this)
    });
  }
  
  dropMovie(file_list) {
    // At present, video drop container is in place. 
    // 1) Get parent of the container.
    //    ul container is next to editor.
    let $ulContainer = this.$nextElement;
    
    // 2) Remove it.
    $ulContainer.remove();
    
    // 3) Add new player under the parent. youtube_id, title
    let ulList = [];
    ulList.push({movie_src: file_list.source_src, 
                 file_ext: file_list.ext,
                 title: file_list.title,
                 movie_url: file_list.movie_url});
    
    let insertItem = ulList.map(this.tmpl_MOVIE_PLAYER).join('')
    $(insertItem).insertAfter(this.$toolbarDiv);
  }
  
  dropYoutube(file_list) {
    // 1) Get parent of the container.
    //    ul container is next to editor.
    let $ulContainer = this.$nextElement;
    
    // 2) Remove it.
    $ulContainer.remove();
    
    // 3) Add new player under the parent.
    let ulList = [];
    ulList.push({youtube_id: file_list.youtube_id, 
                 title: file_list.title,
                 youtube_url: file_list.youtube_url});
    
    let insertItem = ulList.map(this.tmpl_YOUTUBE_PLAYER).join('')
    $(insertItem).insertAfter(this.$toolbarDiv);
  }
  
  handler_dropItem() {
    // Replace dropped item with movie or youtube player.
    let $dropbox = this.$nextElement;
    let listItems = $dropbox.find('li');
    let channel_data = [];
    for (let li of listItems) {
        let data = $(li).attr('data-value');
        channel_data.push(data);
    }
    // Assume only 1 item is dropped.
    let defer = this.restApi.callLoiApiDefer('GET',
        null,
        'directorybrowse',
        'type=parse_mpi_param' +
        '&mpi=' + channel_data[0]);
    defer
    .done(function(data) {
      let file_list = JSON.parse(data.objects[0].file_result);
      let type = data.objects[0].root_name
      
      if ('movie' == type) {
        this.dropMovie(file_list);
      } else if ('youtube' == type) {
        this.dropYoutube(file_list);
      }
      /*
      let source_src = file_list.source_src;
      
      // At present, video drop container is in place. 
      // 1) Get parent of the container.
      //    ul container is next to editor.
      let $ulContainer = this.$nextElement;
      
      // 2) Remove it.
      $ulContainer.remove();
      
      // 3) Add new player under the parent.
      let ulList = [];
      ulList.push({movie_src: file_list.source_src, 
                   file_ext: file_list.ext,
                   title: file_list.title});
      
      let insertItem = ulList.map(this.tmpl_MOVIE_PLAYER).join('')
      $(insertItem).insertAfter(this.$div);
      */
    }.bind(this))
    .fail(function(data) {
      console.log('handler_dropItem() failed! Error:' + data.responseText);
    }.bind(this));
  }
  
  event_delete(evt) {
    evt.preventDefault();
    
    // Delete element next to editor toolbar.
    let $ele = this.cur_element();
    
    if ($ele) {
      $ele.remove();
    }
  }
  
  event_reply(evt) {
    evt.preventDefault();

    if (this.curState == this.menuStateEnum.video) {
      // User not dropt anything.
      if (this.$nextElement.attr('id') == 'dropbox') {
        // Delete element next to editor toolbar.
        let $ele = this.cur_element();
        
        if ($ele) {
          $ele.remove();
        }
      }
    }
    this.menuMain();
  }
  
  event_save(evt) {
    evt.preventDefault();

    if (typeof this.options.onSave=== 'function') {

      this.options.onSave(evt);
    }
  }

  event_save_back(evt) {
    evt.preventDefault();
    
    // Get all elements under article and remove all figure's children.
    // Figure data-value field has all information to construct original
    // media, which is used when article is loaded into page from db.
    // Make a copy and clear figure's children.
    let articles = this.$article.clone();
    let figures = articles.find('figure');
    
    // Process figures
    let html = articles.html();
    figures.each(function () {
      // Remove children.
      $(this).empty();
    });
    
    articles.children().each(function() {
      // Remove edit mode.
      $(this).removeAttr('contenteditable');
      //$(this).attr('contenteditable', false) 
    })
    // Remove editor
    let $editor = articles.find('#article-editor');
    $editor.remove();
    
    // Extracted article.
    let articleHtml = articles.html().trim();
    let articleContent = articles.text().trim();
    
    let articleTitle = null;
    // Get 1st bold h1 selector. Simply treat it as title, regardless it's location.
    articleTitle = $(articles).find("h1:first").text();
    if (!articleTitle) {
      articleTitle = $(articles).find("h3:first").text();
      if (!articleTitle) {
        articleTitle = $(articles).find("p:first").text();
        
        if (!articleTitle) {
          articleTitle = '(empty)';
        }
      }
    }

    if (articleTitle.length > 64) {
      articleTitle = articleTitle.substring(0, 64);
    }
    /*
    if (articleContent.length > 76) {
      // Replace return with space.
      articleContent = articleContent.replace(/(?:\r\n|\r|\n)/g, ' ');
    }
    */
    
    let defer = this.restApi.callLoiApiDefer('POST',
        {type: 'save_article',
         article_path: '',
         article_id: this.articleId,
         article_title: articleTitle,
         article: articleHtml},
        'textmanager');
    
    defer
    .done(function(data) {
      let result = JSON.parse(data.result);
      
      if (this.status(result.status)) {
        this.articleId = result.article_id;
      } else {

      }
    }.bind(this))
    .fail(function(data) {
      alert('Save article failed!' + data.responseText)
    }.bind(this))
    .always(function(data) {

    }.bind(this));
  }
}
