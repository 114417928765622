import {f2fBase} from '../js/base.js'
import {ApiFileX} from '../../comjs/filex.api/filex-api';
import {dirShowFiles} from '../directory.view/directory-view.js'
import { ApiFileExplorer } from '../../comjs/filex.api/filex-api';
import {dlgOk} from '../utils/dialog.js'
import $ from 'jquery';    // React only

export class ytDirectoryView extends dirShowFiles {
  static get CSS() {
    return {
      name: './youtube-view.css',
      import: import('./youtube-view.css')
    };
  }

  constructor(g_data, 
              $container, 
              relpath,  // Position of video list. ytDirectoryView only, not base class dirShowFiles
              $SCRIPT_ROOT=null, 
              options=null, 
              sim_file_info=null, 
              editMode=false,
              onPageView=true) {
    let defaultOptions = {};
    const current_options = $.extend(defaultOptions, options);
    super(g_data, $container, $SCRIPT_ROOT, current_options, 
          sim_file_info, editMode, onPageView);

    // Overwrite base class g_data

    const data = {'en_relpath': g_data.dir_files[0].en_relpath}
    this.g_data = data;
    this.relpath = relpath;
    this.ytEdit = null;
    this.loadCSS(ytDirectoryView.CSS);

    // Append context menu to the container
    $container.append(`
      <div id="contextMenu" class="context-menu">
        <ul>
          <li id="getVideoUrl">Copy channel link</li>
        </ul>
      </div>
    `);

    // Initialize context menu
    //this.initContextMenu($container);
  }

  tmpl_Video(videoData) {
    const { playmovie_url, movie_title, clipthumbnail, duration } = videoData;
    const playButtonHtml = this.enablePlay ? `
      <div class="div-yt-play-button">
        <a href="${playmovie_url}">
          <div class="play-icon"></div>
        </a>
      </div>` : '';
    
    return `
      <div class="video-cell">
        <input type="hidden" class="video-url" value="${playmovie_url}">
        <div class="movie">
          <a href="${playmovie_url}">
            <img class="img-thumbnail-video" src="data:image/png;base64, ${clipthumbnail}">
          </a>
          <div class="time-label">${duration}</div>
        </div>
        ${playButtonHtml}
        <div class="movie-contents">
          <a class="a-content" href="${playmovie_url}">
            ${movie_title}
          </a>
        </div>
      </div>
    `;
  }

  /*
  tmpl_Video({playmovie_url, movie_title, clipthumbnail, duration}) {
    return `
      <div class="video-cell">
        <input type="hidden" class="video-url" value="${playmovie_url}">
        <div class="movie">
          <a href="${playmovie_url}">
            <img class="img-thumbnail-video" src="data:image/png;base64, ${clipthumbnail}">
          </a>
          <div class="time-label">${duration}</div>
        </div>
        <div class="div-yt-play-button">
          <a href="${playmovie_url}">
            <div class="play-icon"></div>
          </a>
        </div>
        <div class="movie-contents">
          <a class="a-content" href="${playmovie_url}">
          ${movie_title}
          </a>
        </div>
      </div>
    `;
  }
  */
  tmpl_VideoEdit() {
    return `
      <div class="video-cell">
      </div>
    `;
  }

  getMediaCpUri(mediaInfo, $this) {
    // Identify what kind of resource. local video - lv; youtube - yv.
    const videoId = $this.find('a').attr('href');

    let uri = `/yv/${videoId}`;
    if (this.relpath) {
      uri += `/${this.relpath}`;
    }

    return uri;
  }

  /*
  // Initialize context menu
  initContextMenu($container) {
    const self = this; // Store reference to the class instance

    $(document).ready(function() {
      let currentVideoUrl = '';
    
      // Handle right-click on video thumbnails
      $container.on('contextmenu', '.video-cell', function(event) {
        event.preventDefault();

        // 'dir-info' is dynamically added to <div class="video-cell">
        const dirInfo = $(this).data('dir-info');
    
        // If clicked on edit cell.
        if (!dirInfo) return false;

        // Get the video URL from the clicked thumbnail
        const videoId = $(this).find('a').attr('href')
        if (self.relpath) {
          // Has relpath. Not root.
          currentVideoUrl += `${videoId}/${self.relpath}`;
        } else {
          currentVideoUrl = videoId;
        }
        
        // Calculate the position of the context menu
        const menuWidth = $('#contextMenu').outerWidth();
        const menuHeight = $('#contextMenu').outerHeight();
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
    
        let left = event.clientX + $(document).scrollLeft(); // Adjust for horizontal scroll
        let top = event.clientY + $(document).scrollTop(); // Adjust for vertical scroll
    
        // Adjust position if menu goes beyond window boundaries
        if (left + menuWidth > windowWidth) {
          left -= menuWidth;
        }
        if (top + menuHeight > windowHeight) {
          top -= menuHeight;
        }
    
        // Show the context menu at the calculated position
        $('#contextMenu').css({
          display: 'block', // Make the menu visible
          left: left, // Set horizontal position
          top: top // Set vertical position
        });
    
        return false; // Prevent the default context menu
      });
    
      // Hide the context menu on click outside
      $(document).on('click', function(event) {
        if (!$(event.target).closest('#contextMenu').length) {
          $('#contextMenu').hide(); // Hide the menu
        }
      });
    
      // Handle click on "Get Video URL" menu item
      $('#getVideoUrl').on('click', function() {
        // Clear the clipboard by writing an empty string first
        navigator.clipboard.writeText('').then(function() {
          // Now write the new video URL to the clipboard
          navigator.clipboard.writeText(currentVideoUrl).then(function() {
            // alert('Video URL copied to clipboard: ' + currentVideoUrl);
            $('#contextMenu').hide(); // Hide the context menu
            currentVideoUrl = '';
          }).catch(function(err) {
            console.error('Could not copy text: ', err);
          });
        }).catch(function(err) {
          console.error('Could not copy text: ', err);
        });
      });
    });
  }
  */
  create() {
    this.wait.startWait();
    this.pages.add(0);

    const api = new ApiFileX();
    //let url = '/api_myfilex/directoryfiles/' + this.g_data.encoded_fullpath;

    let url = this.editMode ? `/api_yt/ytvedit` : `/api_yt/ytv`;
    if (this.g_data.en_relpath)
      url +=  `/${this.g_data.en_relpath}`;

    const allPromises = [
      //api.getYtVideo(this.g_data.encoded_fullpath)
      fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      })
    ];

    Promise.all(allPromises)
    .then(async (responses) => {
      if (!responses[0].ok) {
        throw new Error('Directory files response is not ok');
      }

      const resp = await responses[0].json();

      if (resp.result.status) {
        //let retDirectory = JSON.parse(resp.result.data);
        let retDirectory = resp.result.data[0];
        let dirFiles = retDirectory.dir_files;
        let dirInfo = retDirectory.dir_info;

        // Display the result.
        this.curPage = retDirectory.page;
        if (this.createView(retDirectory)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else if (resp.result.status) { 
        // ??
        // let retDirectory = JSON.parse(resp.result.data);
        let retDirectory = resp.result.data[0];
        this.curPage = retDirectory.page;
        if (this.createView(retDirectory)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else {
        console.log(resp.result.error);
        let dlg = new dlgOk('Error',
            'Load media files loadFiles() failed! /api/dirfiles/ ' + 'Error: ' + resp.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      // If any of the promises fail, this catch block will execute
      console.error('Error:', error);
    })
    .finally(() => {
      this.wait.stopWait();
    });
  }

  // Overwrite it and do nothing, as it's called in base class constructor.
  loadFiles() {}

  // Insert tmpl_VideoEdit, and return element pointer.
  addYtEdit() {
    this.ytEdit = this.insertChildTop(this.$video, this.tmpl_VideoEdit);
    return this.ytEdit;
  }

  removeYtEdit() {
    if (!this.ytEdit) {
      return;
    }

    this.ytEdit.remove();
    this.ytEdit = null;
  }

  loadFiles_back() {
    this.wait.startWait();

    // Track page at the begining of the request, as UI other events may trigger in parallel.
    // this.curPage tracks loaded current page.
    // Therefore, this set combined with this.curPage are used to prevent multiple loading of a page.
    this.pages.add(0);

    let url = '/api_myfilex/directoryfiles/' + this.g_data.encoded_fullpath;
    // let urlSimilar = '/api_myfilex/similarfiles/' + this.g_data.encoded_fullpath + '/' + this.file_info;
    let urlSimilar = '/api_myfilex/similarfiles/' + this.en_fullpath_file;

    const allPromises = [
      fetch(url, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      }),
      fetch(urlSimilar, {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      })
    ];

    Promise.all(allPromises)
    .then(async (responses) => {
      if (!responses[0].ok) {
        throw new Error('Directory files response is not ok');
      }
      if (!responses[1].ok) {
        throw new Error('Similar files response is not ok');
      }

      const resp = await responses[0].json();
      const respSimilar = await responses[1].json();
      
      if (resp.result.status && respSimilar.result.status) {
        let retDirectory = JSON.parse(resp.result.data);
        let dirFiles = retDirectory.dir_files;
        let dirInfo = retDirectory.dir_info;
        let similarFiles = respSimilar.result.data;

        // Remove similar files from directory file list.
        let filteredDirFiles = dirFiles.filter(item2 => {
          return !similarFiles.some(item1 => item1.id === item2.id);
        });

        // Merge similar file into directory files, and make similar files on top of array.
        let mergedList = similarFiles.concat(filteredDirFiles);
        retDirectory.dir_files = mergedList;

        // Display the result.
        this.curPage = retDirectory.page;
        if (this.createView(retDirectory)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else if (resp.result.status) {
        let retDirectory = JSON.parse(resp.result.data);
        this.curPage = retDirectory.page;
        if (this.createView(retDirectory)) {
          // Load next page 1.
          this.appendNextPage(1);
        }
      } else {
        console.log(responses.result.error);
        let dlg = new dlgOk('Error',
            'Load media files loadFiles() failed! /api/dirfiles/ ' + 'Error: ' + responses.result.error);
        dlg.open().then((ret) => {})
      }
    })
    .catch(error => {
      // If any of the promises fail, this catch block will execute
      console.error('Error:', error);
    })
    .finally(() => {
      this.wait.stopWait();
    });
  }

  addItem(file, insertBottom=true, nextToEditor=false) {
    if (!(file.file_type == 2 || file.file_type == 3 || file.file_type == 1 )) {
      return;
    }

    let insertItem = null;
    let item = [];

    let retItem = null;
    // For youtube, when it's saved, youtube thumbnail is stored as photo. Actually, it's 
    // youtube. 
    // In this case, file.file_type == 2 is not photo, but youtube.
    if (file.file_type == 2) {
      // Video
      item.push({
        playmovie_url: file['file_name_url'],
        movie_title: file['file_title'],
        clipthumbnail: file['thumbnail'],
        duration: file['duration']
      });
      insertItem = item.map(this.tmpl_Video.bind(this)).join('');

      if (insertBottom) {
        this.$lastVideo = this.insertChildBottom(this.$video, insertItem);
        retItem = this.$lastVideo;
      } else if (nextToEditor && !insertBottom) {
        this.$lastVideo = this.appendAfter(this.ytEdit, insertItem);
        retItem = this.$lastVideo;
      } else {
        this.$lastVideo = this.insertChildTop(this.$video, insertItem);
        retItem = this.$lastVideo;
      }

      const $ele = this.$lastVideo;
      if (this.options.onClickVideo) {
        const $link = $ele.find('a');
        $link.attr('href', file['youtube_id']);

        // Store the object, so that click even is able to retrieve it
        // which is used to get files under the directory.
        let fileObj = {
          dir_files: []
        };
        fileObj.dir_files.push(file);
        $ele.data('dir-info', fileObj);

        // 3 href handlers are added.
        $link.unbind('click');  
        $link.on('click', (evt) => {
            evt.preventDefault();

            // Call React handler
            if (typeof this.options.onClickVideo=== 'function') {
              let $item = $(evt.target);
              let foundItem = true;
              let $parent = null;

              if ($item.hasClass('img-thumbnail-video') || 
                  $item.hasClass('play-icon') || $item.hasClass('div-play-button')) {
                $parent = $item.parent().parent().parent();
              } else if ($item.hasClass('a-content')) {
                $parent = $item.parent().parent();
              } else {
                foundItem = false;
              }

              if (foundItem) {
                // const mediaInfo = $parent.data('dir-info');
                // const YtId = mediaInfo.dir_files[0].youtube_id;

                const title = $parent.find('.movie-contents a').html();

                if (this.enablePlay) {
                  // Fire the event to parent.
                  this.options.onClickVideo(evt, title.trim());
                }
              } else {
                console.debug('Error: clicked movie is not found!')
              }
            }
        })
      }
    }

    return retItem;
  }

  appendViewItems(data) {
    let files = data.dir_files;
    let dirInfo = data.dir_info;

    // Create video and photo sections.
    let visibleVideo = true;
    let visiblePhoto = true;
    let visibleFolder = true;

    for (let file of files) {
      this.addItem(file);
    }
  }
}