/**
 * Photo grid view for channel.
 */
import React, { useEffect, useState, useRef } from 'react';
//import $ from 'jquery';
//import {photoGrid, photoGridTest} from '../../comjs/photo.grid/photo-grid';
//import PintoLayout from './PintoLayout';
import './CustomAlert';

// PintoPanel component uses Jquery PintoPlugin.
import PintoPanel from './PintoPanel';
import $ from 'jquery'


class DataFetcher {
  async fetchData() {
    let retData = null;
    try {
      const response = await fetch('/api_myfilex/tvchannel', {
        method: 'GET',
        credentials: 'include', // Include cookies with the request
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.result.status) {
          const dataJson = JSON.parse(data.result.data);
          const tmp = dataJson.dir_files;
          data.result.dir_files = tmp;
          retData = data.result;
        }
      } else {
        retData = null;
      }
    } catch (error) {
      retData = null;
      console.log(error.message);
    }

    return retData;
  }
}

const PhotoGridView = () => {
  const [photos, setPhotos] = useState([]);
  let photoGridPanel = null;
  let photoGridPanelTest = null;
  let $photoGridWrapper = null;
  const $photoContainerRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(() => {
    // Run this code once the component is mounted and the DOM is ready
    $('#showAlert').customAlert({
      message: 'This is a custom alert box!',
      backgroundColor: '#ffa500', // Orange background
      textColor: '#fff', // White text color
      borderRadius: '10px', // Rounded corners
    });
    //const $photoContainer = $($photoContainerRef.current);

    /*
    const loadJQuery = async () => {
      try {
        // Dynamically import jQuery
        const $ = await import('jquery');
        
        // Now you can use $ and $.fn
        $(document).ready(() => {
          // Your jQuery code here using $.fn
          $('p').changeTextColorToRed(); // Assuming you have a jQuery plugin called 'changeTextColorToRed'
        });
      } catch (error) {
        // Handle error if jQuery fails to load
        console.error('Failed to load jQuery:', error);
      }
    };
    
    // Load jQuery only if it hasn't been loaded yet
    if (!window.jQuery) {
      loadJQuery();
    }
    */

    /*
    // Function to load CSS file dynamically
    const loadViewCSS = () => {
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = '/3rdparty/pinto/pinto.css';
      linkElement.id = 'photogrid-pinto-styles';
      document.head.appendChild(linkElement);
    };

    // Function to load JavaScript file dynamically
    const loadViewJS = () => {
      const scriptElement = document.createElement('script');
      scriptElement.src = '/3rdparty/pinto/pinto.js';
      scriptElement.id = 'photogrid-pinto-script';
      document.body.appendChild(scriptElement);
    };
    */
    // Call the function to load the CSS when the component is mounted
    //loadViewCSS();
    //loadViewJS();

    const fetchDataFromAPI = async () => {
      const dataFetcher = new DataFetcher();
      const result = await dataFetcher.fetchData();
      setPhotos(result);
    };

    fetchDataFromAPI();


    // Function to unload CSS file when the component is unmounted
    return () => {
      console.log('Cleanup function called');
      /*
      const linkElement = document.getElementById('photogrid-pinto-styles');
      if (linkElement) {
        linkElement.remove();
      }
      const scriptElement = document.getElementById('photogrid-pinto-script');
      if (scriptElement) {
        scriptElement.remove();
      }
      */
    };
  }, []); // Empty dependency array to ensure the effect runs only once


  return (
    <>
      {photos && <PintoPanel imageList={photos} />}
    </>
  );
};

export default PhotoGridView;
