import React, { useState, useEffect, useRef } from 'react';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';
import { shareMedia } from '../../comjs/webrtc.share.media/share-media.js';


const ShareScreen = ({ title, author, content }) => {
  const [mediaObject, setMediaObject] = useState(null);
  

  useEffect(() => {
    const media = new shareMedia();
    setMediaObject(media);
  }, [])

  return (
    <div id="container">
      <h1><a href="//webrtc.github.io/samples/" title="WebRTC samples homepage">WebRTC samples</a> <span>getDisplayMedia</span></h1>

      <video id="video" autoPlay playsInline muted></video>
      <button id="startButton" disabled>Start</button>
      <fieldset id="options" style={{ display: 'none' }}>
        <legend>Advanced options</legend>
        <select id="displaySurface" defaultValue="default">
          <option value="default">Show default sharing options</option>
          <option value="browser">Prefer to share a browser tab</option>
          <option value="window">Prefer to share a window</option>
          <option value="monitor">Prefer to share an entire screen</option>
        </select>
      </fieldset>
      <div id="errorMsg"></div>

      <p>Display the screensharing stream from <code>getDisplayMedia()</code> in a video element.</p>

      <a href="https://github.com/webrtc/samples/tree/gh-pages/src/content/getusermedia/getdisplaymedia" title="View source for this page on GitHub" id="viewSource">View source on GitHub</a>
    </div>

  );
}

export default ShareScreen;