import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from './ConfigContext';

import { IconMenuBar } from '../IconMenuBar/IconMenuBar.jsx';
import './SystemSettings.css';

const SystemSettings = () => {
  const navigate = useNavigate();
  const { config, updateConfig } = useConfig();
  const [backgroundImage, setBackgroundImage] = useState(config?.backgroundImage || '');
  const [channelConfig, setChannelConfig] = useState(config?.channelConfig || '');

  useEffect(() => {
    if (config.ytEditMode) {
      document.body.classList.add('edit-mode-overlay');
    } else {
      document.body.classList.remove('edit-mode-overlay');
    }
  }, [config]);

  const handleEditModeChange = (event) => {
    // event.preventDefault();

    // if (event.target.checked) {
    //   document.body.classList.add('edit-mode-overlay');
    // } else {
    //   document.body.classList.remove('edit-mode-overlay');
    // }

    updateConfig({ ytEditMode: event.target.checked });
  };

  const handleBackgroundImageChange = (event) => {
    event.preventDefault();

    setBackgroundImage(event.target.value);
    updateConfig({ backgroundImage: event.target.value });
  };

  const handleChannelConfigClick = (event) => {
    event.preventDefault();

    let url = `/autoplaylist`;
    navigate(url, { replace: true });
  };

  const handleBallClick = () => {
    let url = `/sysicon`;
    navigate(url, { replace: true });
  };

  useEffect(() => {
    if (config.ytEditMode) {

    } else {

    }
  }, [config]);

  return (
    <div className="viewport">
      {config ? (
        <div className="configuration-container">
          <div className="configuration-category">
            <h2>Edit Youtube Videos and Channels</h2>
            <div className="configuration-item">
              <label className={config.ytEditMode ? 'edit-mode-border' : ''}>
                <input
                  type="checkbox"
                  checked={config.ytEditMode}
                  onChange={handleEditModeChange}
                />
                Edit Mode
              </label>
            </div>
          </div>

          <div className="configuration-category">
            <h2>Channel Configuration</h2>
            <div className="configuration-item">
              <label>
                <a href="#" onClick={handleChannelConfigClick}>Auto Playlist</a>
              </label>
            </div>
          </div>

          <div className="configuration-category">
            <h2>Background Settings</h2>
            <div className="configuration-item">
              <label>
                Background Image URL:
                <input
                  type="text"
                  value={backgroundImage}
                  onChange={handleBackgroundImageChange}
                />
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
      <IconMenuBar onClickRotatingBall={handleBallClick} />
    </div>
  );
};

export default SystemSettings;