// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-media-view {
  text-align: center;
  margin-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/DirectoryView/DirectoryView.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".main-media-view {\n  text-align: center;\n  margin-top: 20px;\n  padding-left: 8px;\n  padding-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
