// TODO: LoginBg does not belong to this component.

import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import './TwoPages.css'
import { AppContext } from '../../AppContext';
import LoginBg from '../ImageCarousel/LoginBg';
import Channels from '../Channels/Channels';
import NameTag from '../NameTag/NameTag';
import MuteButton from '../Mute/Mute';
import ChannelMediaGrid from '../MediaGridView/ChannelMediaGrid';
import { LanguageContext } from '../Localization/LanguageContext/LanguageContext';
import { useAutoPlay } from '../System/AutoPlayContext';
import PlaylistWithTitle from '../Playlist/PlaylistWithTitle';

// Wrap the ImageCarousel component with the HOC
//const PhotoPlayerFull = PhotoPlayer(PhotoPlayerUI);

//const TwoPages = ({onBallMenuClick}) => {
const TwoPages = ({onPage2Visible}) => {
  const navigate = useNavigate();
  const { autoPlaylist, loading } = useAutoPlay();
  const [hasAutoPlay, setHasAutoPlay] = useState(false);
  const [isAutoPlayChecked, setIsAutoPlayChecked] = useState(false);
  const { language } = useContext(LanguageContext);
  const { loginUserName, updateLoginUserName } = useContext(AppContext);
  const [isLogin, setIsLogin] = useState(false);
  const [showLoginPanel, setShowLoginPanel] = useState(true);
  const [showPageTwo, setshowPageTwo] = useState(false);
  const [relPath, setRelPath] = useState('');
  const [curPlaylist, setCurPlaylist] = useState(null);
  const labels = {
    en: { loading: "Loading..."},
    zh: { loading: "请稍等。。。"}
  };

  let initPageDone = false;

  useEffect(() => {
    if (!loading) {
      if (autoPlaylist.length > 0) {
        setHasAutoPlay(true);
      } else {
        setHasAutoPlay(false);
        navigate('/sysicon', { replace: true });
      }
    }
  }, [autoPlaylist, loading]);

  useEffect(() => {
    // This code will run after the component is mounted,
    // similar to DOMContentLoaded event.
    
    const firstPage = document.querySelector('.page-1');
    const secondPage = document.querySelector('.page-2');
    // const autoScrollThreshold = window.innerHeight / 2;
    const autoScrollThreshold = window.innerHeight * 0.4;
    let isSecondPageVisible = false;
  
    // Function to handle the scrolling effect
    function handleScroll() {
      if (!initPageDone) {
        const scrollTop = window.scrollY;
  
        if (0 == scrollTop) {
          initPageDone = true;
          onPage2Visible(false);
        }
  
        return;
      }

      if (!secondPage) {
        return;
      }

      let scrollPosition = 0;

      if (initPageDone) {
        scrollPosition = window.scrollY;
      }
  
      // Check if the user has scrolled past half a page
      if (scrollPosition >= autoScrollThreshold && !isSecondPageVisible) {
        isSecondPageVisible = true;
        animateScroll(secondPage.offsetTop); // Scroll to the second 
  
        firstPage.style.opacity = 0; // Hide the first page
        secondPage.style.opacity = 1; // Show the second page
        setshowPageTwo(true);

        // Notify page 2 is visible.
        onPage2Visible(true);
      } else if (scrollPosition < autoScrollThreshold && isSecondPageVisible) {
        isSecondPageVisible = false;
        animateScroll(firstPage.offsetTop); // Scroll back to the first page
  
        firstPage.style.opacity = 1; // Show the first page
        secondPage.style.opacity = 0; // Hide the second page
        
        onPage2Visible(false);
      }
    }
  
    // Function to animate the scroll
    function animateScroll(destination) {
      const duration = 800; // Animation duration in milliseconds
      const start = window.scrollY;
      const startTime = performance.now();
  
      function step(currentTime) {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easing = easeInOutQuad(progress);
        window.scrollTo(0, start + (destination - start) * easing);
  
        if (progress < 1) {
          requestAnimationFrame(step);
        }
      }
  
      requestAnimationFrame(step);
    }
  
    // Easing function (quadratic ease-in-out)
    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
  
    // Call the handleScroll function on initial load and whenever the user scrolls
    window.addEventListener('scroll', handleScroll);

    handleScroll(); // Initially check the visibility

    if (!initPageDone) {
      // Will trigger multiple handleScroll() events. From these events, it's know init
      // is done after scrolling to top.
      window.scrollTo(0, 0);
      //initPageDone = true;

      // Parent will know to hide menu bar.
      //onPage2Visible(false);
    }

    // Don't forget to clean up any side effects when the component unmounts.
    return () => {
      // Clean-up code (if necessary)
      // Cleanup: remove the scroll event listener when the component unmounts
      // Otherwise, when the component is unmounted, the scroll event is still processed
      // by this component.
      window.removeEventListener('scroll', handleScroll);

    };
  }, [loginUserName, isLogin]); 

  const onePage = () => {
    const body = document.body;
    if (body) {
        body.style.height = '100vh';
    }
  }

  const twoPage = () => {
    const body = document.body;
    if (body) {
        body.style.height = '200vh';
    }
  }

  const handleOnLoginSuccess = (loginUser) => {
    console.log(`loginUser: ${loginUser}`);

    if (loginUser) {
      updateLoginUserName(loginUser);
      setIsLogin(true);
    }
    else {
      updateLoginUserName('');
      setIsLogin(false);
    }
  };

  const handleOnClickUnmute = () => {
    // When mute button is clicked, determine the parent state.

  };

  const handleBallClick = () => {

    //onBallMenuClick();
  };

  // When channel of photos are played, the folder is sent here.
  const handleOnPlayFolder = (relPath) => {
    setRelPath(relPath);
  }

  const hanelOnChannelDataReady = (channelFileContent) => {
    if (channelFileContent) {
      const key = Object.keys(channelFileContent[0])[0];
      const val = channelFileContent[0][key];
      setCurPlaylist(val);
    }
  }

  return (
    <>
    <div className="two-page-container">
      <section className="page page-1">
        {loginUserName === null ? (
          <div>{labels[language].loading}</div>
        ) : loginUserName ? (
          hasAutoPlay ? (
            <Channels 
              onPlayFolder={handleOnPlayFolder} 
              onChannelDataReady={hanelOnChannelDataReady}
            />
          ) : null     
        ) : (
          <LoginBg onLoginSuccess={handleOnLoginSuccess} />
        )}

        {loginUserName && (
          <NameTag name={loginUserName} />
        )}
        {/* relPath is photo only. So video will not show MuteButton */}
        {loginUserName && relPath && (
          <MuteButton onClickUnmute={handleOnClickUnmute}/>
        )}
      </section>
      {loginUserName ? (
        <section className="page page-2">
          {/* <ChannelMediaGrid relPath={relPath} /> */}
          <PlaylistWithTitle 
            playlist={curPlaylist} 
          />
        </section>
      ) : null}
    </div>
    </>
  )
};

export default TwoPages
