/**
 * Main purpose is to define channel program interface, which is required basic features,
 * such as onFinish event. All channel program should fire this event when play is finished.
 */
import React, { useEffect } from 'react';


const ProgramBase = ({ onStart, onFinish }) => {
  // const {OnStart, onFinish} = OnStart1;

  useEffect(() => {
    onStart(); // Notify the parent that child's display is starting

    return () => {
      onFinish();
    };
  }, [onStart, onFinish]);

  return null;
}

export default ProgramBase;
