/**
 * Program video does not have intro component as the tittle will be shown in video player.
 * Photo program display intro component with title defined in channel.json.
 * 
 * Each program consists of:
 * 
 * 1. Introduction play. The title is from channel json, or original dir name if json does
 *    not define title.
 * 2. Image carousel play, which slides through all photos in a directory.
 */
import React, { useState, useEffect, useContext } from 'react';
import CinemaPhoto from '../CinemaPhoto/CinemaPhoto';
import { ApiFileX } from '../../comjs/filex.api/filex-api.js';


/**
 * showIntro=false is a must setting to avoid continous video list playing issue (one of the cause).
 * 
 * @param {*} param0 
 * @returns 
 */
const ProgramPhoto = ({ 
    onStart, 
    onFinish, 
    mediaPath,   // video: full path, photo: 
    title: propTitle,   // Rename title to proTitle to avoid possible conflict. Default value is null.
    paShowPlayer=true,
    paPlay=false
  }) => {  
  const [title, setTitle] = useState(propTitle || null);

  return title ? (
    <CinemaPhoto 
      photoDirTitle={title} 
      photoPath={mediaPath} 
      onPhotoStart={onStart}
      onPhotoEnd={onFinish}
      showIntro={true}
      paShowPlayer={paShowPlayer}
      paPlay={paPlay} />
  ) : null;
}

export default ProgramPhoto;
