import React, { useState, useEffect } from 'react';
import './DirectoryView.css';
import $ from 'jquery';
import { myMediaMain } from '../../comjs/my.media.main/my-media-main';
import {ApiFileX} from '../../comjs/filex.api/filex-api';


function DirectoryView({
    onBallMenuClick,        
    onClickVideo,
    onClickPhoto,
    onClickFolder}) {
  const [projectDirectoryName] = useState(getProjectDirectoryName());
  let mainView = null;

  const handleOnClickVideo = (evt, title) => {
    const parentDiv = $(evt.target).closest('a').parent().parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');  // Otherwise, evt.attr('href'); will get <img> only
    const videoRelPath = href.attr('href');

    onClickVideo(videoRelPath, title, dirInfo);
    // window.history.replaceState(null, null, `/video/${videoRelPath}`);
    window.history.pushState(null, null, `/video/${videoRelPath}`);
  }
  
  const handleOnClickPhoto = (evt) => {
    const parentDiv = $(evt.target).closest('a').parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');  // Otherwise, evt.attr('href'); will get <img> only
    const photoRelPath = href.attr('href');

    // Get section title which is photo directory name.
    const section = parentDiv.parent().parent();
    const dirName = section.find('.div-section-title a').text();

    onClickPhoto(photoRelPath, dirInfo, dirName);
    window.history.pushState(null, null, `/photo/${photoRelPath}`);
  }

  const handleOnClickFolder = (evt, title) => {
    const parentDiv = $(evt.target).closest('a').parent().parent();
    const dirInfo = parentDiv.data('dir-info');

    const href = $(evt.target).closest('a');  // Otherwise, evt.attr('href'); will get <img> only
    const folderRelPath = href.attr('href');

    onClickFolder(folderRelPath, dirInfo, title);
    window.history.pushState(null, null, `/folder/${folderRelPath}`);
  }

  const redirectToCurrentDomain = () => {
    const currentDomain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    window.location.href = currentDomain;
  }
  

  useEffect(() => {
    console.log("DirectoryView is mounted.")
    const api = new ApiFileX();
    api.initDirView().then(async (retData) => {
        if (retData && retData.status) {
          const options = {
            onClickFolder: handleOnClickFolder,  // Click section title.
            onClickVideo: handleOnClickVideo,
            onClickPhoto: handleOnClickPhoto
          }
          mainView = new myMediaMain($('.main-media-view'), retData.data, null, options);
        } else {
          console.error("initDirView failed due to server return null.");

          redirectToCurrentDomain();
        }
      }
    )

    return () => {
      if (mainView) {
        mainView.destroy();
      }
    }
  }, []);

  function getProjectDirectoryName() {
    const scriptPath = import.meta.url;
    const scriptURL = new URL(scriptPath);
    const projectDirectoryName = scriptURL.pathname.split('/').slice(-2, -1)[0];
    return projectDirectoryName;
  }

  const handleBallClick = () => {
    // You can perform any other actions here
    onBallMenuClick();
  };

  return (
    <>
    <div className="main-media-view"></div>
     {/* <ShiningBall color="orange" onClick={handleBallClick} /> */}
    </>
  );
}

export default DirectoryView;
