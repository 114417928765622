import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfilePanel from './UserProfilePanel';
import EditCheckbox from './ArticleEditMode';
import NavControl from '../IconMenuBar/NavControl';
import { MenuPanelLeft, MenuPanelRight, MenuPanelCtrlRight, MenuPanelCtrlLeft } from './MenuPanel';
import OnlineUsers from '../Online/OnlineUsers';
import ChatSPRoomUsers from '../Online/ChatSPRoomUsers';
import styles from './IconMenuBar.module.css';

const MenuModes = {
  EMPTY: 'EMPTY',
  USER_PROFILE: 'USER_PROFILE',
  EDIT_ARTICLE: 'EDIT_ARTICLE',
  EDIT_RECIPE: 'EDIT_RECIPE',
  TINY_THEATER: 'TINY_THEATER',
};

/**
 * Menu behaviors:
 * 
 * 1. Mouse hover whole control. Menu tall and opacity = 1 forever.
 * 2. Mouse leave control after hovering. Menu short and opacity < 1 (hide) after few sencods.
 * 3. Scroll. menu short + opacity = 1. Few seconds later, short + opacity < 1.
 * 4. Continues scroll (5 seconds gap). menu short + opacity = 1.
 * 
 * These are controlled by:
 *       onMouseEnter={handleMouseEnter}
 *       onMouseLeave={handleMouseLeave}
 * 
 * not by, which controls the ball.
 * 
 *           <NavControl onClickHome={handleClickHome}
 *                     onEnterNavControl={handleEnterNav}
 *                     onLeaveNavControl={handleLeaveNav}
 * 
 * Parameters:
 * 
 *    onEnterNavControlParent:
 *      Notify parent MainPage mouse entering NavControl, and nav url needs to be displayed. 
 *      onEnterNavControlParent will get nav url info from MainPage and pass it to IconMenuBar.
 * 
 *    onNavDoneParent: (TODO?)
 *      Clicking backward/forward button is done. New nav state needs to be displayed.
 * 
 * @param {*} param0 
 * @returns 
 */
const IconMenuBar = ({
  onClickRotatingBall,
  showIconMenuBar = true,
  menuMode = MenuModes.USER_PROFILE,
  onEnterNavControlParent = null,  
  onBackwardClick = null,
  onForwardClick = null,
  onScrollStart = null,
  onScrollEnd = null,
  onEditModeChange,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(showIconMenuBar);
  const [showNavText, setShowNavText] = useState(false);
  const [timer, setTimer] = useState(null);
  const [backwardName, setBackwardName] = useState('');
  const [forwardName, setForwardName] = useState('');
  const [conScroll, setConScroll] = useState(false);

  const cleanTimer = () => {
    if (timer) {
      clearTimeout(timer); // Reset the timer when scrolling
      setTimer(null);
    }
  };

  const showMenuBar = () => {
    // If enter/leave too fast, old timer should be deleted before creating new one.
    cleanTimer();  
    setIsVisible(true);
  };

  const hideMenuBar = () => {
    cleanTimer();
    let timerTmp = setTimeout(() => {
      // This timer hide menu is disabled if continuous scroll is in progress.
      if (!conScroll) {
        setIsVisible(false);
      }
    }, 3000); // Hide after 3 seconds of no scrolling
    setTimer(timerTmp);
  };

  useEffect(() => {}, [isVisible]);

  //------ start. Handle control raise, show, hide. 
  // Mouse enter, scroll, continuous scroll.
  const handleContinuousScrollStart = () => {
    setConScroll(true);
    setIsHovered(true);
    // A timer will hide menu after default 3 seconds. This timer should not hide menu
    // as continuous scroll is started. 
    showMenuBar();
  };

  const handleContinuousScrollEnd = () => {
    setConScroll(false);
    setIsHovered(false);
    hideMenuBar();
  };

  // Mouse enter menu control.
  const handleMouseEnter = (evt) => {
    setIsHovered(true);
    showMenuBar();
  };

  const handleMouseLeave = (evt) => {
    // Don't interrupt continuous scroll, which raise the menu control.
    if (!conScroll) {
      setIsHovered(false);
      hideMenuBar();
    }
  };

  // Handle scroll events from scroll to determine show/hide control. 
  // Control show/hide status is not simply controlled by hovering.
  useEffect(() => {
    if (onScrollStart) {
      handleContinuousScrollStart();
    } else if (onScrollEnd) {
      handleContinuousScrollEnd();
    }
  }, [onScrollStart, onScrollEnd]);
 //------ end. Handle control raise, show, hide

  // For scrolling, if user does not touch the menu bar, the behavior is that 
  // it shows and auto hidden.
  // It is different if user uses mouse to enter and leave.
  useEffect(() => {
    window.addEventListener('scroll', showMenuBar);
    window.addEventListener('scroll', hideMenuBar);

    return () => {
      window.removeEventListener('scroll', showMenuBar);
      window.removeEventListener('scroll', hideMenuBar);
    };
  }, []);

  const handleClickRotatingBall = (evt) => {
    if (onClickRotatingBall) {
      onClickRotatingBall(evt);
    }
  };

  const handleEnterNav = () => {
    setShowNavText(true);

    if (onEnterNavControlParent) {
      const navInfo = onEnterNavControlParent();

      // Display backward/forward url name.
      if (navInfo) {
        setBackwardName(navInfo.backwardName);
        setForwardName(navInfo.forwardName);
      } else {
        setBackwardName(null);
        setForwardName(null);
      }
    }
  };
  
  const handleLeaveNav = () => {
    setShowNavText(false);
  };

  const handleClickHome = (evt) => {
    handleClickRotatingBall(evt);
    // TODO: integrate in future.
    //navigate('/gridicon');
  };

  const handleCtrlArticleEditModeChange = (evt) => {
    if (onEditModeChange) {
      onEditModeChange(evt);
    }
  };

  const handleBackward = (evt) => {
    if (onBackwardClick) {
      onBackwardClick(evt);
    }
  };
  
  const handleForward = (evt) => {
    if (onForwardClick) {
      onForwardClick(evt);
    }
  };
  
  return (
    <>
    {showIconMenuBar && (
      <div
        className={`${styles.icon_menu_bar} ${isHovered ? styles.tall : styles.short} ${isVisible ? styles.bar_visible : styles.bar_hidden}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`${styles.icon_menu_bar_item}`}>
          {(() => {
            switch (menuMode) {
              case MenuModes.TINY_THEATER:
                //return null;
                return <MenuPanelCtrlLeft ctrlComp={<ChatSPRoomUsers excludeSelf={true} />} />;
                // return <MenuPanelCtrlLeft ctrlComp={<OnlineUsers excludeSelf={true} />} />;
              default:
                return null;
            }
          })()}
          {/* {showNavText ? (
            <MenuPanelLeft text={backwardName}/>
          ) : (
            (() => {
              switch (menuMode) {
                case MenuModes.USER_PROFILE:
                  // return <MenuPanelCtrlLeft ctrlComp={<OnlineUsers />} />;
                default:
                  return null;
              }
            })()
          )} */}
        </div>

        <div className={`${styles.icon_menu_bar_middle}`}>
          <NavControl onClickHome={handleClickHome}
                      onEnterNavControl={handleEnterNav}
                      onLeaveNavControl={handleLeaveNav}
                      onClickBackward={handleBackward}
                      onClickForward={handleForward}
          />
        </div>

        <div className={`${styles.icon_menu_bar_item}`}>
          {(() => {
            switch (menuMode) {
              case MenuModes.USER_PROFILE:
                return <MenuPanelCtrlRight ctrlComp={<UserProfilePanel logoutHandler={() => console.log('Logged out')} />} />;
              case MenuModes.EDIT_ARTICLE:
                return <MenuPanelCtrlRight ctrlComp={<EditCheckbox onCheckboxChange={handleCtrlArticleEditModeChange} />} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    )}
    </>
  );
};

export { MenuModes, IconMenuBar };
